
import { floatingManager } from '../inputs/layer';
import { content } from '../../viewer/contents/shared/content';
import { option3D } from '../../viewer/tools/interface';
import { ui_slider, ui_textinput } from '../inputs/input';
import { component } from '../component/component';
// import { borderEditors } from '../dragzone/bordereditors';
import { pointofview } from '../../viewer/scenery/pointofview';
import { pointofviewManager, pointofviewChannel } from '../journey/pointofviewmanager';

import { setStyle, el, mount } from 'redom';

/*
  +------------------------------------------------------------------------+
  | POINT MANAGER                                                          |
  +------------------------------------------------------------------------+
*/

export class mainSettingsClass extends floatingManager {

  constructor () {
    super('Main settings');
    let copybutton = el('div.right-icon.copy-icon.icon-copypaste',
      {onclick:()=>{this.checkCopyPaste()}, onmouseenter:()=>{this.title.textContent = 'Copy/Paste Geometry'}, onmouseleave:()=>{this.title.textContent = 'Main settings'}},
      [el('span.path1'), el('span.path2'), el('span.path3')]
    );
    mount(this.control, copybutton);
    this.setPointInputs();
  }

  copy:any;
  checkCopyPaste () {
    if (this.copy) {
      this.setVectorChange('position', this.copy.position);
      this.setVectorChange('rotation', this.copy.rotation);
      this.setVectorChange('scale', this.copy.scale);
      this.setPointValue('position', this.copy.position);
      this.setPointValue('rotation', this.copy.rotation);
      this.setPointValue('scale', this.copy.scale);
      this.copy = null;
    } else {
      this.copy = {};
      let pos = this.pointInputs.position;
      let rot = this.pointInputs.rotation;
      let sca = this.pointInputs.scale;
      this.copy.position = {x:pos.x.value, y:pos.y.value, z:pos.z.value};
      this.copy.rotation = {x:rot.x.value, y:rot.y.value, z:rot.z.value};
      this.copy.scale = {x:sca.x.value, y:sca.y.value, z:sca.z.value};
    }
  }

  pointInputs:any = {};
  opacityInput:ui_slider;
  hyperlinkInput:ui_textinput;
  setPointInputs () {
    this.pointInputs.position = this.addVectorInput('Position', {step:0.5}, (vector) => {
      this.setVectorChange('position', vector);
    });

    this.pointInputs.rotation = this.addVectorInput('Rotation', {step:10, min:-180, max:180}, (vector) => {
      this.setVectorChange('rotation', vector);
    });

    this.pointInputs.scale = this.addVectorInput('Scale', {step:0.1, min:0.01}, (vector) => {
      // Scale can't be negative
      for (let key in vector) {
        if (vector[key] < 0) vector[key] = 0;
      }
      this.setVectorChange('scale', vector);
    });

    this.opacityInput = this.addSlider('Opacity', {value:1, min:0, max:1, step:0.1}, (value) => {
      this.content.setOpacity(value);
    });

    this.hyperlinkInput = this.addTextInput('Hyperlink', "https://", (value) => {
      if (value == '') this.content.setHyperlink(undefined);
      else this.content.setHyperlink(value);
    });
    setStyle(this.hyperlinkInput.el, {left: '82px', width: '160px'});
  }

  content:content;
  component:component;
  setComponent (component:component) {
    this.pointofviewManager = undefined;
    this.component = component;
    this.content = component.content;
    this.setContentValues(this.content);
    this.unfreezeAllInputs();
    this.setAvailableInputs(component.availableAxis);
  }

  setContentValues (content:content) {
    this.setAllPointValue(content);
    this.opacityInput.setValue(content.opacity);
    this.hyperlinkInput.setValue(content.hyperlink);
  }

  pointofview:pointofview;
  pointofviewManager:pointofviewManager;
  setpointofviewManager (pointofviewManager:pointofviewManager) {
    this.component = undefined;
    this.pointofviewManager = pointofviewManager;
    this.pointofview = pointofviewManager.pointofview;
    this.setAllPointValue(this.pointofview);
    this.unfreezeAllInputs();
    this.setAvailableInputs(pointofviewManager.availableAxis);
  }

  unfreezeAllInputs () {
    this.freezeVectorInputs('position', false);
    this.freezeVectorInputs('rotation', false);
    this.freezeVectorInputs('scale', false);
    this.freezeInput(this.opacityInput, false);
    this.freezeInput(this.hyperlinkInput, false);
  }

  setAvailableInputs (availableAxis:any) {
    let p = 0;
    for (let key in availableAxis.position) {
      this.freezeInput(this.pointInputs.position[key], !availableAxis.position[key]);
      p += availableAxis.position[key];
    }
    if (p) setStyle(this.pointInputs.position.container.el, {display:'block'});
    else setStyle(this.pointInputs.position.container.el, {display:'none'});

    let r = 0;
    for (let key in availableAxis.rotation) {
      this.freezeInput(this.pointInputs.rotation[key], !availableAxis.rotation[key]);
      r += availableAxis.rotation[key];
    }
    if (r) setStyle(this.pointInputs.rotation.container.el, {display:'block'});
    else setStyle(this.pointInputs.rotation.container.el, {display:'none'});

    let s = 0;
    for (let key in availableAxis.scale) {
      this.freezeInput(this.pointInputs.scale[key], !availableAxis.scale[key]);
      s += availableAxis.scale[key];
    }
    if (s) setStyle(this.pointInputs.scale.container.el, {display:'block'});
    else setStyle(this.pointInputs.scale.container.el, {display:'none'});

    if (availableAxis.opacity) setStyle(this.opacityInput.container.el, {display:'block'});
    else setStyle(this.opacityInput.container.el, {display:'none'});

    if (availableAxis.hyperlink) setStyle(this.hyperlinkInput.container.el, {display:'block'});
    else setStyle(this.hyperlinkInput.container.el, {display:'none'});
  }

  freezeVectorInputs (vector:string, bool:boolean) {
    let vectorInput = this.pointInputs[vector];
    for (let key in vectorInput) {
      if (key != 'label') this.freezeInput(vectorInput[key], bool);
    }
  }

  setVectorChange (arg:string, vector:option3D) {
    if (this.component) {
      if (arg == 'position') this.content.setPosition(vector);
      if (arg == 'scale') this.setContentSize(vector);
      if (arg == 'rotation') this.content.setRotation(vector);
      // borderEditors.setComponentEditorsPosition();
    } else if (this.pointofviewManager) {
      if (arg == 'position') this.pointofview.setPosition(vector);
      if (arg == 'rotation') this.pointofview.setRotation(vector);
      // borderEditors.setPointofviewEditorsPosition();
      pointofviewChannel.publish('update');
    }
  }

  setContentSize (vector:option3D) {
    if (this.component.keepratio) {
      let axe:any = Object.keys(vector)[0];
      let value = vector[axe];
      vector = {};
      for (let key in this.component.availableAxis.scale) {
        if (this.component.availableAxis.scale[key]) vector[key] = value;
      }
      this.content.setScale(vector);
      this.setPointValue('scale', this.content.scale);
    } else {
      this.content.setScale(vector);
    }
  }

  setAllPointValue (content:content|pointofview) {
    this.setPointValue('position', content.position);
    this.setPointValue('rotation', content.rotation);
    this.setPointValue('scale', content.scale);
  }

  decimal = 100;
  setPointValue (vector:string, point:option3D) {
    if (point.x !== undefined) this.pointInputs[vector].x.setValue(Math.round(point.x*this.decimal)/this.decimal);
    if (point.y !== undefined) this.pointInputs[vector].y.setValue(Math.round(point.y*this.decimal)/this.decimal);
    if (point.z !== undefined) this.pointInputs[vector].z.setValue(Math.round(point.z*this.decimal)/this.decimal);
  }
}

export let mainSettings = new mainSettingsClass();
