
import { el, mount, setStyle } from 'redom';

/*
  +------------------------------------------------------------------------+
  | SHORTCUTS LAYER                                                        |
  +------------------------------------------------------------------------+
*/

export class shortcutsLayerClass {

    shortcuts = [
      {key:'Esc', meaning:'Unselect'},
      {key:'Suppr', meaning:'Delete'},
      {key:'Ctrl + Z', meaning:'Undo'},
      {key:'Ctrl + Shift + Z', meaning:'Redo'},
      {key:'Ctrl + D', meaning:'Duplicate'},
      {key:'Ctrl + Scroll', meaning:'ZoomIn/ZoomOut'},
      {key:'left Click', meaning:'Change Position'},
      // {key:'Right Click', meaning:'Change Rotation'},
      {key:'Ctrl + Select', meaning:'Group Components'},
      {key:'Ctrl + F or F', meaning:'Focus on Component'},
      {key:'← ↑ → ↓', meaning:'Move Component'},
    ];
    control:HTMLElement;

    constructor () {
      this.control = el('div.help-layer.siimple-list',
        this.shortcuts.map(p =>
          el('div.help-container.siimple-list-item',
            [
              el('div.shortcut-key', p.key),
              el('span.siimple-tag.siimple-tag--rounded.shortcut-value', p.meaning)
            ]
          )
        )
      );
      mount(document.body, this.control);
      setStyle(this.control, {height:'auto', 'z-index':100, display:'none'});
    }
}

export let shortcutsLayer = new shortcutsLayerClass();
