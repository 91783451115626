
import { ui_slider, ui_numberinput } from '../inputs/input';
import { ui_colorbutton, ui_textassetbutton } from '../inputs/pickers';
import { environmentInterface } from '../../viewer/scenery/environment'
import { floatingManager, layerRight } from '../inputs/layer';
import { undoChannel } from '../service/undo';
import { environment } from '../service/editintale';

import { setChildren, setStyle } from 'redom';

undoChannel.subscribe("environmentchange", (data, envelope) => {
  environmentSettings.checkInput(data);
  environment.checkOption(data);
});

/*
  +------------------------------------------------------------------------+
  | ENVIRONMENT MANAGER                                                    |
  +------------------------------------------------------------------------+
*/

class environmentSettingsClass extends floatingManager {

    constructor () {
      super('Sky');
      this.addEnvironmentInput();
    }

    fogColorInput:ui_colorbutton;
    skyDiffuseInput:ui_textassetbutton;
    skyColorInput:ui_colorbutton;
    skyBlurInput:ui_slider;
    skyBrightnessInput:ui_slider;

    lightColorInput:ui_colorbutton;
    lightIntensityInput:ui_numberinput;
    // shadowInput:ui_checkbox;
    // shadowDarknessInput:ui_numberinput;
    // shadowBlurInput:ui_numberinput;

    ambiantSoundInput:ui_textassetbutton;
    ambiantSoundVolumeInput:ui_slider;

    addEnvironmentInput () {
      this.skyDiffuseInput = this.addTextAssetInput('Texture', {type:'cubetexture', url:''}, (image) => {
        environment.setSkyDiffuse(image);
        this.checkReflectionColorVisibility();
      });
      this.skyColorInput = this.addColorInput('Reflection color', undefined, (value) => {
        environment.setSkyColor(value);
      });
      this.skyBlurInput = this.addSlider('Sky blur', {value:1, min:0, max:1, step:0.1}, (value) => {
        environment.setSkyBlur(value);
      });
      this.skyBrightnessInput = this.addSlider('Sky brightness', {value:1, min:0, max:100, step:10}, (value) => {
        environment.setSkyBrightness(value);
      });
      this.fogColorInput = this.addColorInput('Fog', undefined, (rgba) => {
        environment.setFogColor(rgba);
      });

      this.control.addText('Main Light', 'parameter-subtitle');
      this.lightColorInput = this.addColorInput('Light color', undefined, (rgba) => {
        environment.setLightColor(rgba);
      });
      this.lightIntensityInput = this.addNumberInput('Light intensity', {step:0.1, value:1, unit:''}, (value) => {
        environment.setLightIntensity(value);
      });
      // this.shadowInput = this.addCheckBox('Cast shadow', false, (checked) => {
      //   environment.setShadow(checked);
      // });
      // this.shadowDarknessInput = this.addNumberInput('Darkness', {step:0.1, value:0, unit:'', min:0, max:1}, (value) => {
      //   environment.setShadowDarkness(value);
      // });
      // this.shadowBlurInput = this.addNumberInput('BLUR', {value:environment.shadowblur, unit:'', min:0, max:1}, (value) => {
      //   environment.setShadowBlur(value);
      // });

      this.control.addText('Sound', 'parameter-subtitle');
      this.ambiantSoundInput = this.addTextAssetInput('file', {type:'sound', url:undefined}, (sound) => {
        environment.setAmbiantSound(sound);
      });
      this.ambiantSoundVolumeInput = this.addSlider('volume', {value:1, max:10, min:0}, (sound) => {
        environment.setAmbiantSoundVolume(sound);
      });
    }

    resetInput (environmentOptions:environmentInterface) {
      this.fogColorInput.setValue(environmentOptions.fogcolor);
      this.skyDiffuseInput.setValue(environmentOptions.skydiffuse);
      this.skyColorInput.setValue(environmentOptions.skycolor);
      this.skyBlurInput.setValue(environmentOptions.skyblur);
      this.lightColorInput.setValue(environmentOptions.lightcolor);
      this.lightIntensityInput.setValue(environmentOptions.lightintensity);
      // this.shadowInput.setValue(environmentOptions.shadow);
      // this.shadowDarknessInput.setValue(environmentOptions.shadowdarkness);
      // this.shadowBlurInput.setValue(environmentOptions.shadowblur);
      this.ambiantSoundInput.setValue(environmentOptions.ambientsound);
      this.ambiantSoundVolumeInput.setValue(environmentOptions.ambientsoundvolume);
      this.checkReflectionColorVisibility();
    }

    checkInput (environmentOptions:environmentInterface) {
      if (environmentOptions.fogcolor !== undefined) this.fogColorInput.setValue(environmentOptions.fogcolor);
      if (environmentOptions.skydiffuse !== undefined) this.skyDiffuseInput.setValue(environmentOptions.skydiffuse);
      if (environmentOptions.skycolor !== undefined) this.skyColorInput.setValue(environmentOptions.skycolor);
      if (environmentOptions.skyblur !== undefined) this.skyBlurInput.setValue(environmentOptions.skyblur);
      if (environmentOptions.skybrightness !== undefined) this.skyBrightnessInput.setValue(environmentOptions.skybrightness);
      if (environmentOptions.lightcolor !== undefined) this.lightColorInput.setValue(environmentOptions.lightcolor);
      if (environmentOptions.lightintensity !== undefined) this.lightIntensityInput.setValue(environmentOptions.lightintensity);
      // if (environmentOptions.shadow !== undefined) this.shadowInput.setValue(environmentOptions.shadow);
      // if (environmentOptions.shadowdarkness !== undefined) this.shadowDarknessInput.setValue(environmentOptions.shadowdarkness);
      // this.shadowBlurInput.setValue(environmentOptions.shadowblur);
      if (environmentOptions.ambientsound !== undefined) this.ambiantSoundInput.setValue(environmentOptions.ambientsound);
      if (environmentOptions.ambientsoundvolume !== undefined) this.ambiantSoundVolumeInput.setValue(environmentOptions.ambientsoundvolume);
      this.checkReflectionColorVisibility();
    }

    checkReflectionColorVisibility () {
      if (environment.skydiffuse) {
        setStyle(this.skyColorInput.container.el, {display:'block'});
        setStyle(this.skyBlurInput.container.el, {display:'block'});
        setStyle(this.skyBrightnessInput.container.el, {display:'block'});
      } else {
        setStyle(this.skyColorInput.container.el, {display:'none'});
        setStyle(this.skyBlurInput.container.el, {display:'none'});
        setStyle(this.skyBrightnessInput.container.el, {display:'none'});
      }
    }

    showEnvironment () {
      setChildren(layerRight, [this.control.el]);
    }
}

export let environmentSettings = new environmentSettingsClass();
