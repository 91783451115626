
import { globals } from './globals';

import axios from 'axios';
import Cookies from 'js-cookie';
import toastr from 'toastr'

interface postOption {
  body?:any;
  header?:any;
}

class apiClass {
  host = '';
  access_token = '';
  refresh_token = '';
  currentcheck = false;

  constructor () {
    if (globals.save) {
      let tokenCookie = Cookies.get('token');
      let userCookie = Cookies.get('user');
      if (userCookie == undefined || tokenCookie == undefined) {
        this.disconnect();
      }
      let tokenObject = JSON.parse(tokenCookie);
      this.setToken(tokenObject);
    }
    this.host = globals.apiurl;

    window.onfocus = () => {
      let userCookie = Cookies.get('user');
      if (globals.save) {
        if (userCookie == undefined) {
          this.disconnect();
        }
      }
    }
  }

  ObjecToQuery (obj:any) {
    var str = '';
    for (let key in obj) {
      let param = encodeURIComponent(obj[key]);
      str += key+'='+param+'&';
    }
    return str;
  }

  setToken (token) {
    this.access_token = token.access_token;
    this.refresh_token = token.refresh_token;
    // Can't set it in common because some request like cloudinary doesn't allow Authorization headers
    // axios.defaults.headers.common['Authorization'] = token.access_token;
  }

  saveToken (token) {
    var cookie = {access_token:token.access_token, refresh_token:token.refresh_token};
    Cookies.set('token', JSON.stringify(cookie), { expires: 7 });
  }

  get (path:string, arg:any, callback:Function) {
    let query = arg ? this.ObjecToQuery(arg) : '' ;
    let url = this.host+path+'?'+query;
    this.send('GET', url, '', {}, (data) => {
      callback(data);
    });
  }

  post (path:string, arg:any, options:postOption, callback:Function) {
    let bodystring = options.body ? options.body : '' ;
    let header = options.header ? options.header : {} ;
    let query = arg ? this.ObjecToQuery(arg) : '' ;
    let url = this.host+path+'?'+query;
    this.send('POST', url, bodystring, header, (data) => {
      callback(data);
    });
  }

  send (method:string, url:string, body:any, headers:any, callback:Function) {
    headers.Authorization = this.access_token;
    if (method == 'POST') {
      axios.post( url, body, {headers: headers})
      .then((response) => {callback(response.data)})
      .catch((error) => {
        console.log(error);
        this.checkError(error, method, url, body, headers, callback)
      });
    } else if (method == 'GET') {
      axios.get( url, {headers: headers})
      .then((response) => {callback(response.data)})
      .catch((error) => {
        console.log(error);
        this.checkError(error, method, url, body, headers, callback)}
      );
    } else {
      return console.log('Method must be GET or POST');
    }
  }

  // If catch error after request, there is no response in error.
  checkError (error:any, method:string, url:string, body:any, headers:any, callback:Function) {
    if (error) {
      if (error.response) {
        this.checkToken(url, error.response.data, (result) => {
          if (result) {
            this.send(method, url, body, headers, callback);
          } else {
            callback(error.response.data);
          }
        });
      } else {
        callback({success:false});
      }
    } else {
      callback({success:false});
    }
  }

  checkToken (url:string, error:any, callback:Function) {
    if ((error.message == 'Bad Token.' || error.message == 'No token provided.') && url.indexOf('refreshtoken') == -1) {
      this.refreshToken(callback);
    } else {
      callback(false);
    }
  }

  refreshToken (callback:Function) {
    this.post('v1/user/refreshtoken', {refresh_token: this.refresh_token}, {}, (data) => {
      if (data.success) {
        this.setToken(data);
				this.saveToken(data);
        callback(true);
      } else {
        this.disconnect();
      }
    });
  }

  disconnect () {
    toastr.error("It seems you have been disconnected 🤷, let's redirect you to the login page, ready?");
    if (globals.save) {
			let i = 0;
			if (globals.getRedirect()) {
				setInterval(() => {
					if (i == 3) toastr.warning("3 🕒");
					else if (i == 4) toastr.warning("2 🕑");
					else if (i == 5) toastr.warning("1 🕐");
					else if (i == 6) window.location = '/login';
					i++;
				}, 1000);
			}
      // If can't refresh token go back to login
      Cookies.remove('user');
      Cookies.remove('token');
    }
	}
	getToken() {
		return this.access_token;
	}
}

export let api = new apiClass();
