
import { system } from '../tools/system';

import remove from 'lodash/remove';
import { el, mount } from 'redom';

/*
  +------------------------------------------------------------------------+
  | RESPONSIVE                                                             |
  +------------------------------------------------------------------------+
*/

export class responsiveClass {

    _system:system;

    lastReportedWidth:number;
    constructor (system:system) {
      this._system = system;
      this.checkViewport();
      this.addEngineEvent();
    }

    addEngineEvent () {
      this._system.engine.onResizeObservable.add(() => {
        this.checkSize();
      });

      // To avoid iphone flash on resize, we put resize here on every frame
      // Don't worry resize will be calculated only when needed
      this._system.scene.registerBeforeRender(() => {
        this._system.engine.resize();
      });

      this.checkSize();
    }

    checkViewport () {
      // In order to have good scale and text size, we need to check for the viewport meta in header
      // This makes the scene a bit blurry on iphones, need to find a solution
      let viewport = document.querySelector("meta[name=viewport]");
      if (!viewport) {
        let viewporttoadd = el('meta', {content: "width=device-width, initial-scale=1", name: "viewport"});
        mount(document.getElementsByTagName('head')[0], viewporttoadd);
      }
      // Should check for the viewport and adapt to it.
      // else {
      //   let content = viewport.getAttribute("content");
      //   if (content) {
      //     let scalecheck = content.indexOf('initial-scale');
      //     if (scalecheck == -1) this.ratio = window.devicePixelRatio;
      //   }
      // }
    }

    screenratio = 1;
    width = 100;
    height = 100;
    realwidth = 100;
    realheight = 100;
    scale = 1;

    checkSize () {
      this._system.setFrustrum();
      this.width = this._system.engine.getRenderWidth();
      this.height = this._system.engine.getRenderHeight();
      this.scale = 1/this._system.engine.getHardwareScalingLevel();
      // this.checkScaling();
      this.realwidth = this.width / this.scale;
      this.realheight = this.height / this.scale;
      this.screenratio = this.realwidth / this.realheight - 1;
      // Keep that for test purpose
      // console.log(window.orientation, window.devicePixelRatio)
      // console.log(this.realwidth, this.realheight)
      // console.log(this.screenratio)
      this.sendToListsteners();
    }

    checkScaling () {
      const devicePixelRatio = window.devicePixelRatio;
      let newScale = Math.min(this._system.maxScaling, devicePixelRatio);
      // We make sure scene stays fluid on big screen by forcing scale to 1
      // console.log(newScale)
      // console.log(this.width/newScale, this.height/newScale)
      if (this.width/newScale > 800 || this.height/newScale > 800) newScale = 1;
      // setHardwareScalingLevel will call resize which call onResizeObservable which call checkScaling
      // So we make sure something has change inorder to avoid infinite loop
      if (newScale != this.scale) {
        this.scale = newScale;
        this._system.engine.setHardwareScalingLevel(1/this.scale);
      }
    }

    sendToListsteners () {
      for (let i = 0; i < this.listeners.length; i++) {
        this.listeners[i](this.screenratio, this.realwidth, this.realheight, this.scale);
      }
    }

    listeners:Array<Function> = [];
    addListener (callback:Function) {
      this.listeners.push(callback);
    }

    removeListener (callback:Function) {
      remove(this.listeners, (c) => {c == callback});
    }
}
