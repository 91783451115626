
import { size } from '../../viewer/tools/interface';
import { animation } from '../../viewer/tools/animation';
import { _system } from '../service/editintale';

import { el, setStyle, mount } from 'redom';

/*
  +------------------------------------------------------------------------+
  | MODAL                                                                  |
  +------------------------------------------------------------------------+
*/

let modalList = [];

export class modal {

  anim:animation;
  control:HTMLElement;
  header:HTMLElement;
  title:HTMLElement;
  description:HTMLElement;
  icon:HTMLElement;

  constructor (size:size, icon:string, title:string, description?:string) {
    this.anim = new animation(_system, 30);

    this.control = el('div.modal',
      this.header = el('div.modal-title-background',
        [
          this.title = el('div.modal-title', title),
          this.description = el('div.modal-description', description),
          this.icon = el('div.modal-icon.icon-'+icon,
            [el('span.path1'),el('span.path2'),el('span.path3')]
          ),
        ]
      )
    );
    setStyle(this.control, {width:size.width+'px', height:size.height+'px', display:'none'});
    mount(document.body, this.control);
    this.back = el('div.modal-background', {onclick:() => {this.backgroundClick();}},
      el('div.modal-close.icon-close', {onclick:()=>{this.backgroundClick();}},
        [el('span.path1'),el('span.path2'),el('span.path3')]
      ),
    );
    mount(document.body, this.back);
    modalList.push(this);
    return this;
  }

  back:HTMLElement;
  backopacity = 0.9;
  onmodalclose:Function;

  backgroundClick () {
    this.hideModal();
    if (this.onmodalclose) this.onmodalclose();
  }

  showModal () {
    for (let i = 0; i < modalList.length; i++) {
      setStyle(modalList[i].back, {display:'none'});
      setStyle(modalList[i].control, {display:'none'});
    }
    setStyle(this.back, {display:'block'});
    setStyle(this.control, {display:'block'});
    this.anim.simple(20, (count, perc)=>{
      let op = perc*this.backopacity;
      setStyle(this.back, {opacity:op});
      setStyle(this.control, {opacity:op});
    }, ()=>{
      setStyle(this.back, {opacity:this.backopacity});
      setStyle(this.control, {opacity:1});
    });
  }

  hideModal () {
    this.anim.simple(20, (count, perc)=>{
      let op = (1-perc)*this.backopacity;
      setStyle(this.back, {opacity:op});
      setStyle(this.control, {opacity:op});
    }, ()=>{
      setStyle(this.back, {display:'none'});
      setStyle(this.control, {display:'none'});
    });
  }
}
