
import { coreSystem } from '../../tools/coreSystem';
import { shape } from './shape';
import { meshMaterialClass } from './meshmaterial';

import { Vector3 } from '@babylonjs/core/Maths/math'
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh';
import { PBRMaterial } from '@babylonjs/core/Materials/PBR/pbrMaterial';

export class meshparent extends shape {

  _system:coreSystem;
  scalemesh:shape;
  center:Vector3;

  constructor (coreSystem:coreSystem) {
    super(coreSystem, {lightEnabled:false}, 'cube');
    this.scalemesh = new shape(coreSystem, {lightEnabled:false}, 'cube');
    this.scalemesh.setParent(this);
    this.setMeshInvisible(this.mesh);
    this.setMeshInvisible(this.scalemesh.mesh);
  }

  setMeshInvisible (mesh:AbstractMesh) {
    // Need to keep it visible if we want to have boundingbox to show in editor
    mesh.isVisible = false;
    mesh.isPickable = false;
    mesh.visibility = 0;
  }

  setTransparentMaterial (visibility?:number) {
    this._setTransparentMaterial(visibility);
    this.scalemesh.mesh.isVisible = true;
    this.scalemesh.mesh.isPickable = true;
    if (visibility) this.scalemesh.mesh.visibility = visibility;
    else this.scalemesh.mesh.visibility = 0.0001;
    this.scalemesh.mesh.material = this.transparentmaterial;
  }

  on (event:string, funct:Function) {
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].on(event, funct);
    }
    return this;
  }

  off () {
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].off();
    }
    return this;
  }

  listenEvent (bool:boolean) {
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].listenEvent(bool);
    }
  }

  forcelistenEvent (bool:boolean) {
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].forcelistenEvent(bool);
    }
  }

  meshesnumber = 0;
  meshes:Array<meshMaterialClass> = [];
  addMesh (mesh:meshMaterialClass) {
    if (this.meshes.indexOf(mesh) == -1) {
      this.meshes.push(mesh);
      mesh.setParent(this.scalemesh);
    }
  }

  // When group already exist and add from user
  addMeshWithNewBounds (mesh:meshMaterialClass) {
    let formerposition = this.mesh.position.clone();
    this.checkBounds([mesh]);
    this.resetParentPosition();
    let change = formerposition.subtract(this.mesh.position);
    this.setMeshesChangePosition(change);
    this.addMesh(mesh);
    this.setMeshRelativePosition(mesh);
  }

  setMeshesChangePosition (change) {
    for (let i = 0; i < this.meshes.length; i++) {
      this.setMeshChangePosition(this.meshes[i], change);
    }
  }

  setMeshChangePosition (mesh:meshMaterialClass, change:Vector3) {
    mesh.mesh.position.addInPlace(change);
  }

  setMeshRelativePosition (mesh:meshMaterialClass) {
    mesh.mesh.position.subtractInPlace(this.center);
  }

  maximum:Vector3;
  minimum:Vector3;
  getCenter () {
    if (this.minimum == undefined) return;
    let sum = this.maximum.add(this.minimum);
    this.center = sum.divide(new Vector3(2, 2, 2));
  }

  resetParentPosition () {
    this.getCenter();
    this.mesh.position = this.center.clone();
  }

  separateAllMeshes () {
    this.setMeshesOriginalPosition();
    for (let i = 0; i < this.meshes.length; i++) {
      let mesh = this.meshes[i];
      mesh.mesh.parent = undefined;
    }
    this.meshes = [];
  }

  separateMesh (mesh:meshMaterialClass) {
    mesh.mesh.parent = undefined;
    let index2 = this.meshes.indexOf(mesh);
    if (index2 != -1) this.meshes.splice(index2, 1);
  }

  // When group already exist and add from user
  separateMeshWithNewBounds (mesh:meshMaterialClass) {
    let formerposition = this.mesh.position.clone();
    this.setMeshOriginalPosition(mesh);
    this.separateMesh(mesh);
    this.minimum = undefined;
    this.checkBounds(this.meshes);
    this.resetParentPosition();
    let change = formerposition.subtract(this.mesh.position);
    this.setMeshesChangePosition(change);
  }

  setMeshesOriginalPosition () {
    for (let i = 0; i < this.meshes.length; i++) {
      let mesh = this.meshes[i];
      this.setMeshOriginalPosition(mesh);
    }
  }

  setMeshOriginalPosition (mesh:meshMaterialClass) {
    mesh.mesh.position.addInPlace(this.mesh.position);
  }

  checkBounds (meshes:Array<meshMaterialClass>) {
    if (meshes[0] == undefined) return;
    for (var i = 0; i < meshes.length; i++) {
      let mesh = meshes[i].mesh;
      let boundingInfo = mesh.getHierarchyBoundingVectors();
      if (this.minimum == undefined) {
        this.maximum = boundingInfo.max;
        this.minimum = boundingInfo.min;
      } else {
        this.maximum.maximizeInPlace(boundingInfo.max);
        this.minimum.minimizeInPlace(boundingInfo.min);
      }
    }
  }

  ////// Extend mesh with group
  setMeshesParam (what:string, value1?:any, value2?:any, value3?:any, value4?:any) {
    if (this.meshes == undefined) return; // for functions called during mesh construction
    for (let i = 0; i < this.meshes.length; i++) {
      let mesh:meshMaterialClass = this.meshes[i];
      mesh[what](value1, value2, value3, value4);
    }
  }

  highlight (test:boolean, color?:string) {
    this.setMeshesParam('highlight', test, color)
    return this;
  }

  // Use parent setenabled to really show/hide group
  show () {
    this.setMeshesParam('show');
    return this;
  }

  hide () {
    this.setMeshesParam('hide');
    return this;
  }

  addShadow () {
    this.setMeshesParam('addShadow');
    return this;
  }

  removeShadow () {
    this.setMeshesParam('removeShadow');
    return this;
  }

  addGlow  () {
    this.setMeshesParam('addGlow');
    return this;
  }

  removeGlow  () {
    this.setMeshesParam('removeGlow');
    return this;
  }

  addToWaters () {
    this.setMeshesParam('addToWaters');
  }

  removeFromWaters () {
    this.setMeshesParam('removeFromWaters');
  }

  setMaterialType (materialtype?:any, temp?:boolean) {
    // this.setMeshesParam('setMaterialType', materialtype, temp);
    return this;
  }

  deleteMaterial () {
    // this.setMeshesParam('deleteMaterial');
    return this;
  }

  setMaterialProperties (prop:any) {
    this.setMeshesParam('setMaterialProperties', prop);
  }

  setMaterialProperty (key:string, value:any) {
    this.setMeshesParam('setMaterialProperty', key, value);
  }

  deleteTextureColors () {
    this.setMeshesParam('deleteTextureColors');
  }

  deleteTextureColor (type:string) {
    this.setMeshesParam('deleteTextureColor', type);
  }

  setTextureColors () {
    this.setMeshesParam('setTextureColors');
  }

  setTextureColor (type:string, color:Array<number>) {
    this.setMeshesParam('setTextureColor', type, color);
    return this;
  }

  deleteTextures () {
    this.setMeshesParam('deleteTextures');
  }

  deleteTexture (type:string, always?:boolean) {
    this.setMeshesParam('deleteTexture', type, always);
  }

  setTextures () {
    this.setMeshesParam('setTextures');
    return this;
  }

  textures:any = {};
  setTexture (type:string, texture:any, clone?:boolean) {
    this.setMeshesParam('setTexture', type, texture, clone);
    return this;
  }

  setTextureSize (size:number) {
    this.setMeshesParam('setTextureSize', size);
    return this;
  }

  enableReflection (enable:boolean) {
    this.setMeshesParam('enableReflection', enable);
    return this;
  }

  enableRefraction (enable:boolean) {
    this.setMeshesParam('enableRefraction', enable);
    return this;
  }

  setIndexOfRefraction (index:number) {
    this.setMeshesParam('setIndexOfRefraction', index);
    return this;
  }

  setOpacity (op:number) {
    // NOTE opacity on group erase content opacity which is not right
    // this.setMeshesParam('setOpacity', op);
    return this;
  }

  setFrame (frame:boolean) {
    this.setMeshesParam('setFrame', frame);
    return this;
  }

  destroy () {
    this.mesh.dispose();
    this.scalemesh.mesh.dispose();
    this._destroyMeshes();
    return this;
  }

  _destroyMeshes () {
    this.setMeshesParam('destroy');
    if (this.meshes) {
      for (let i = 0; i < this.meshes.length; i++) {
        this.meshes[i].mesh.dispose();
        this.meshes[i].mesh.parent = undefined;
      }
      this.setMeshesOriginalPosition();
    }
    this.meshes = [];
    this.maximum = undefined;
    this.minimum = undefined;
  }

}
