
import { projectInterface } from '../service/projectInterface';
import { shape } from '../contents/patterns/shape';
import { vector3interface } from '../contents/shared/content';
import { option3D, point3D } from '../tools/interface';
import { system } from '../tools/system';
import { pipelineInterface, pipelineJson, defaultPipelineValues } from './pipeline';

import { Vector3 } from '@babylonjs/core/Maths/math'
import clone from 'lodash/clone';

export interface pointofviewInterface {
  name:string,
  panelindex?:number,
  position:point3D,
  rotation:point3D,
  pipeline:pipelineInterface,
}

projectInterface.pointofview = {
  name:'n',
  panelindex:'pi',
  position:{
    dim:'p',
    next:vector3interface
  },
  rotation:{
    dim:'r',
    next:vector3interface
  },
  pipeline:{
    dim:'pip',
    next:pipelineJson
  },
}

export class pointofview {

  _system:system;

  meshParent:shape;
  name:string;
  panelindex:number;
  position:Vector3;
  rotation:Vector3;
  scale:Vector3 = new Vector3(2, 2, 2);

  pipeline:pipelineInterface = clone(defaultPipelineValues);

  constructor (system:system, pointofviewOptions:pointofviewInterface) {
    this._system = system;
    this.name = pointofviewOptions.name;
    this.panelindex = pointofviewOptions.panelindex;
    this.setPipelineValues(pointofviewOptions.pipeline);
    this.setMeshParent();

    this.position = this.meshParent.mesh.position.clone();
    this.rotation = this.meshParent.mesh.rotation.clone();

    this.setPosition(pointofviewOptions.position);
    this.setRotation(pointofviewOptions.rotation);
  }

  setPipelineValues (pipeline:pipelineInterface) {
    for (let key in pipeline) {
      this.pipeline[key] = pipeline[key];
    }
  }

  setMeshParent () {
    this.meshParent = new shape(this._system, {}, 'cube');
    this.meshParent.setMaterialType('standard');
    // Need to keep it visible if we want to have boundingbox to show in editor
    this.meshParent.setOpacity(0.000001);
    this.meshParent.setMaxLights(0);
    this.meshParent.listenEvent(false);
  }

  setPosition (pos:option3D) {
    this.meshParent.setPosAxis(pos);
    for (let key in pos) {
      this.position[key] = pos[key];
    }
  }

  setRotation (rot:option3D) {
    this.meshParent.setAngleAxis(rot);
    for (let key in rot) {
      this.rotation[key] = rot[key];
    }
  }
}
