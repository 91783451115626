
import { ui_slider } from '../inputs/input';
import { ui_colorbutton } from '../inputs/pickers';
import { pipelineInterface, defaultPipelineValues } from '../../viewer/scenery/pipeline';
import { pointofview } from '../../viewer/scenery/pointofview';
import { text } from '../../viewer/contents/patterns/text';
import { floatingManager } from '../inputs/layer';
import { _system, _pipeline } from '../service/editintale';

import clone from 'lodash/clone';

/*
  +------------------------------------------------------------------------+
  | PIPELINE MANAGER                                                    |
  +------------------------------------------------------------------------+
*/

class pipelineSettingsClass extends floatingManager {

    constructor () {
      super('Filters');
      this.addPipelineInput();
    }

    fieldOfView:ui_slider;
    vignette:ui_colorbutton;
    bloom:ui_slider;
    focalLength:ui_slider;
    focalDistance:ui_slider;
    contrast:ui_slider;
    exposure:ui_slider;
    sharpenEdge:ui_slider;
    sharpenColor:ui_slider;
    chromatic:ui_slider;

    addPipelineInput () {
      this.addButton({ui:'text', text:'Reset'}, () => {
        this.reset();
      });

      this.vignette = this.addColorInput('Vignette', undefined, (rgba:Array<number>) => {
        // Need to alway have default value
        if (rgba === undefined) {
          rgba = defaultPipelineValues.vignette;
          this.vignette.setValue(rgba);
        }
        if (this.entity) this.entity.pipeline.vignette = rgba;
        _pipeline.setVignette(rgba);
      });

      this.fieldOfView = this.addSlider('Field of view', { value:defaultPipelineValues.fieldOfView, min:0.1, max:3.14, step:0.5 }, (value) => {
        if (this.entity) this.entity.pipeline.fieldOfView = value;
        _pipeline.setFieldOfView(value);
      });

      this.focalLength = this.addSlider('Focal length', { value:0, min:0, max:1000, step:100 }, (value:number) => {
        if (this.entity) this.entity.pipeline.focalLength = value;
        _pipeline.setFocalLength(value);
      });

      this.focalDistance = this.addSlider('Focal distance', { value:0, min:0, max:100, step:10 }, (value:number) => {
        if (this.entity) this.entity.pipeline.focalDistance = value;
        _pipeline.setFocalDistance(value);
      });

      this.bloom = this.addSlider('Bloom', { value:defaultPipelineValues.bloom, min:0, max:1, step:0.1 }, (value) => {
        if (this.entity) this.entity.pipeline.bloom = value;
        _pipeline.setBloom(value);
      });

      this.contrast = this.addSlider('Contrast', { value:defaultPipelineValues.contrast, min:0, max:5, step:1 }, (value) => {
        if (this.entity) this.entity.pipeline.contrast = value;
        _pipeline.setContrast(value);
      });

      this.exposure = this.addSlider('Exposure', { value:defaultPipelineValues.exposure, min:0, max:5, step:1 }, (value) => {
        if (this.entity) this.entity.pipeline.exposure = value;
        _pipeline.setExposure(value);
      });

      this.sharpenEdge = this.addSlider('Sharpen edge', { value:defaultPipelineValues.sharpenEdge, min:0, max:2, step:0.5 }, (value) => {
        if (this.entity) this.entity.pipeline.sharpenEdge = value;
        _pipeline.setSharpenEdge(value);
      });

      this.sharpenColor = this.addSlider('Sharpen color', { value:defaultPipelineValues.sharpenColor, min:0, max:1, step:0.1 }, (value) => {
        if (this.entity) this.entity.pipeline.sharpenColor = value;
        _pipeline.setSharpenColor(value);
      });

      this.chromatic = this.addSlider('Chromatic', { value:defaultPipelineValues.chromatic, min:0, max:2, step:0.5 }, (value) => {
        if (this.entity) this.entity.pipeline.chromatic = value;
        _pipeline.setChromatic(value);
      });
    }

    reset () {
      this.resetInput(defaultPipelineValues);
      _pipeline.checkOption(defaultPipelineValues);
      if (this.entity) this.entity.pipeline = clone(defaultPipelineValues);
    }

    resetInput (pipelineOptions:pipelineInterface) {
      this.fieldOfView.setValue(pipelineOptions.fieldOfView);
      this.vignette.setValue(pipelineOptions.vignette);
      this.focalLength.setValue(pipelineOptions.focalLength);
      this.focalDistance.setValue(pipelineOptions.focalDistance);
      this.bloom.setValue(pipelineOptions.bloom);
      this.contrast.setValue(pipelineOptions.contrast);
      this.exposure.setValue(pipelineOptions.exposure);
      this.sharpenEdge.setValue(pipelineOptions.sharpenEdge);
      this.sharpenColor.setValue(pipelineOptions.sharpenColor);
      this.chromatic.setValue(pipelineOptions.chromatic);
    }

    checkInput (pipelineOptions:pipelineInterface) {
      if (pipelineOptions.fieldOfView !== undefined) this.fieldOfView.setValue(pipelineOptions.bloom);
      if (pipelineOptions.vignette !== undefined) this.vignette.setValue(pipelineOptions.vignette);
      if (pipelineOptions.focalLength !== undefined) this.focalLength.setValue(pipelineOptions.focalLength);
      if (pipelineOptions.focalDistance !== undefined) this.focalDistance.setValue(pipelineOptions.focalDistance);
      if (pipelineOptions.bloom !== undefined) this.bloom.setValue(pipelineOptions.bloom);
      if (pipelineOptions.contrast !== undefined) this.contrast.setValue(pipelineOptions.contrast);
      if (pipelineOptions.exposure !== undefined) this.exposure.setValue(pipelineOptions.exposure);
      if (pipelineOptions.sharpenEdge !== undefined) this.sharpenEdge.setValue(pipelineOptions.sharpenEdge);
      if (pipelineOptions.sharpenColor !== undefined) this.sharpenColor.setValue(pipelineOptions.sharpenColor);
      if (pipelineOptions.chromatic !== undefined) this.chromatic.setValue(pipelineOptions.chromatic);
    }

    entity:pointofview|text;
    setEntity (entity:pointofview|text) {
      this.entity = entity;
      this.resetInput(entity.pipeline);
    }
}

export let pipelineSettings = new pipelineSettingsClass();
