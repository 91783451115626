
import { pointofview, pointofviewInterface } from './pointofview';
import { pipelineClass } from './pipeline';
import { projectInterface } from '../service/projectInterface';
import { util } from '../tools/util';
import { scrollCatcherClass } from '../service/scrollcatcher';
import { mouseCatcherClass } from '../service/mousecatcher';
import { progressBarClass } from './progressBar';
import { system } from '../tools/system';
import { animation } from '../tools/animation';

import { Vector3, Vector2, Curve3 } from '@babylonjs/core/Maths/math'
import { FreeCamera } from '@babylonjs/core/Cameras/freeCamera'
import { VRDeviceOrientationFreeCamera } from '@babylonjs/core/Cameras/VR/vrDeviceOrientationFreeCamera'

export interface navigationInterface {
  name?:string,
  duration:number,
  followmouse:boolean,
}

projectInterface.navigation = {
  name:'an',
  duration:'adu',
  followmouse:'afm',
}

export class navigationClass {

  _system:system;
  _pipeline:pipelineClass;
  progressBar:progressBarClass;
  anim:animation;

  camera:FreeCamera|VRDeviceOrientationFreeCamera;
  guicamera:FreeCamera|VRDeviceOrientationFreeCamera;

  name = '';
  duration = 1;
  followmouse:boolean = false;

  pointofviews:any = {};

  constructor (system:system, scrollCatcher:scrollCatcherClass, mouseCatcher:mouseCatcherClass, pipeline:pipelineClass, progressBar:progressBarClass) {
    this._system = system;
    this.setEvents(scrollCatcher, mouseCatcher);
    this.anim = new animation(this._system);
    this._pipeline = pipeline;
    this.progressBar = progressBar;
  }

  init (navigationOptions:navigationInterface) {
    this.camera = this._system.freecamera;
    this.guicamera = this._system.guicamera;
    this.checkOption(navigationOptions);
    this.setNormalCamera();
  }

  setPointOfViews (pointofviews:Array<pointofviewInterface>) {
    for (let i = 0; i < pointofviews.length; i++) {
      this.addPointOfView(pointofviews[i]);
    }
  }

  addPointOfView (pointofviewdata:pointofviewInterface) {
    let newpointofview = new pointofview(this._system, pointofviewdata);
    this._addPointOfView(newpointofview);
    return newpointofview;
  }

  _addPointOfView (pointofview:pointofview) {
    this.pointofviews[pointofview.name] = pointofview;
  }

  _removePointOfView (pointofview:pointofview) {
    delete this.pointofviews[pointofview.name];
  }

  start (callback?:Function) {
    this.animViewPercentage(0); // To force correct pipeline parameters
    let frontpos = this._system.freecamera.getFrontPosition(20);
    let startpos = this._system.freecamera.position;
    let change = startpos.subtract(frontpos);
    this.anim.simple(50, (count, perc) => {
      let navperc = 1 - Math.pow(perc, 1/20);
      let pos = startpos.add(change.multiply(new Vector3(navperc, navperc, navperc)));
      this.setCameraPosition(pos);
      perc = Math.pow(perc, 5); // Big power to make smooth effect
    }, () => {
      if (callback) callback();
    });
  }

  checkOption (navigationOptions:navigationInterface) {
    if (navigationOptions.duration !== undefined) this.duration = navigationOptions.duration;
    if (navigationOptions.followmouse !== undefined)  this.followmouse = navigationOptions.followmouse;
  }

  cameramode:'free'|'vr';
  setVRCamera () {
    this._system.scene.activeCamera = this._system.vrcamera;
    this.camera = this._system.vrcamera;
    this.camera.attachControl(this._system.canvas);
    this.cameramode = 'vr';
    this._pipeline.removeCamera(this._system.freecamera);
    this._pipeline.addCamera(this._system.vrcamera);
  }

  setNormalCamera () {
    if (this.camera) this.camera.detachControl(this._system.canvas);
    this._system.scene.activeCameras = [this._system.freecamera, this._system.guicamera];
    this.camera = this._system.freecamera;
    this.guicamera = this._system.guicamera;
    this.cameramode = 'free';
    this._pipeline.removeCamera(this._system.vrcamera);
    this._pipeline.addCamera(this._system.freecamera);
  }

  setPointofviewChangeEvent () {
    // 120 to have more detail on the line or it can jump from one point to another
    this.initPositionandRotation();
    this.setScrollPointofviews();
  }

  initPositionandRotation () {
    this.startRotation = this.currentRotation = this.camera.rotation.clone();
    this.startPosition = this.currentPosition = this.camera.position.clone();
    let pointofviewsKey = Object.keys(this.pointofviews);
    let nb = pointofviewsKey.length;
    if (nb == 1) return this.showStartPointofview();
  }

  scrollCatcher:scrollCatcherClass;
  currentmousepos:Vector2;
  setEvents (scrollCatcher:scrollCatcherClass, mouseCatcher:mouseCatcherClass) {
    this.scrollCatcher = scrollCatcher;
    scrollCatcher.addListener((scroll:number, scrollPercentage:number) => {
      this.animViewPercentage(scrollPercentage);
    });

    this.currentmousepos = new Vector2(0, 0);
    mouseCatcher.addListener((mousepos:Vector2) => {
      this.currentmousepos = mousepos;
      if (this.followmouse) {
        if (this.currentRotation) {
          let newrot = this.currentRotation.add(new Vector3(mousepos.y, mousepos.x, 0));
          this.setCameraRotation(newrot);
        }
      }
    });
  }

  pointofviewCurves:any;
  curveLength:number;
  getSpline (pointofviewsSorted:Array<string>) {
    let pointofviewPositions = [];
    let pointofviewRotations = [];
    let checkRotations = [];
    let firstpointofview = pointofviewsSorted[0];

    if (pointofviewsSorted.length < 2) return this.showPointofview(this.pointofviews[firstpointofview]);
    let pi = 180;
    for (let i = 0; i < pointofviewsSorted.length; i++) {
      pointofviewPositions.push(this.pointofviews[pointofviewsSorted[i]].position.clone());
      checkRotations.push(this.pointofviews[pointofviewsSorted[i]].rotation.clone());
    }
    // We check for the closest rotation angle between two point of views
    for (let i = 0; i < pointofviewsSorted.length; i++) {
      let rotationnew = checkRotations[i];
      if (this.pointofviews[pointofviewsSorted[i-1]]) {
        let rotationprevius = checkRotations[i-1];
        let rotationgap = rotationnew.subtract(rotationprevius);
        for (let key in {x:0, y:0, z:0}) {
          let gap = rotationgap[key];
          if (Math.abs(gap) > pi) {
            rotationnew[key] = rotationprevius[key];
            rotationnew[key] += (gap < 0)? gap + (pi * 2) : gap - (pi * 2);
          } else {
            rotationnew[key] = rotationprevius[key];
            rotationnew[key] += gap;
          }
        }
      }
      pointofviewRotations[i] = rotationnew.multiply(new Vector3(util.degtoradratio, util.degtoradratio, util.degtoradratio));
    }

    this.getAllPointofviewsCurve(pointofviewPositions, pointofviewRotations);
    this.showPointofview(this.pointofviews[firstpointofview]);
  }

  positions:Array<Vector3>;
  rotations:Array<Vector3>;
  journeylength:number;
  getAllPointofviewsCurve (pointofviewPositions:Array<Vector3>, pointofviewRotations:Array<Vector3>) {
    let catmullRomPos = Curve3.CreateCatmullRomSpline(pointofviewPositions, this.curveLength, false);
    this.positions = catmullRomPos.getPoints();

    let catmullRomRot = Curve3.CreateCatmullRomSpline(pointofviewRotations, this.curveLength, false);
    this.rotations = catmullRomRot.getPoints();

    this.journeylength = this.positions.length;
  }

  setScrollPointofviews () {
    this.getNavigationSpline();
    this.scrollCatcher.setScrollStep(this.sortedPointofViews.length - 1);
    this.progressBar.setSteps(this.sortedPointofViews.length - 1)
    // Sometime page load at a scroll position != 0
  }

  getNavigationSpline () {
    let pointofviewsSorted = this.getSortedPointofViews();
    let nb = pointofviewsSorted.length;
    this.curveLength = Math.round(this.duration * 3 / nb); // *3 or you can see step when scroll is slowing
    this.getSpline(pointofviewsSorted);
  }

  sortedPointofViews:Array<string>;
  getSortedPointofViews () {
    return this.sortedPointofViews = Object.keys(this.pointofviews).sort((a,b) => {return this.pointofviews[a].panelindex-this.pointofviews[b].panelindex});
  }

  showStartPointofview () {
    let firstpointofview = Object.keys(this.pointofviews)[0];
    let newpointofview = this.pointofviews[firstpointofview];
    this.showPointofview(newpointofview);
  }

  showPointofview (pointofview:pointofview) {
    this.setCameraPosition(pointofview.position);
    let rot = pointofview.rotation.multiply(new Vector3(util.degtoradratio, util.degtoradratio, util.degtoradratio));
    this.currentRotation = rot;
    this.setCameraRotation(rot);
  }

  startPosition:Vector3;
  startRotation:Vector3;
  movePosition:Vector3;
  moveRotation:Vector3;
  currentPosition:Vector3;
  currentRotation:Vector3;
  animViewPercentage (perc:number) {
    if (!this.journeylength) return; // Safety 1
    let step = Math.min(Math.round(this.journeylength * perc), this.journeylength - 1);
    if (!this.positions[step]) return;  // Safety 2
    this.currentPosition = this.positions[step];
    this.setCameraPosition(this.positions[step]);
    // ROTATION
    let newrot = this.rotations[step].clone();
    this.currentRotation = newrot;
    if (this.followmouse) newrot = newrot.add(new Vector3(this.currentmousepos.y, this.currentmousepos.x, 0));
    this.setCameraRotation(newrot);
    this.setPipelineChange(perc);
  }

  setCameraPosition (pos:Vector3) {
    this.camera.position = pos;
    this.guicamera.position = pos;
  }

  setCameraRotation (rot:Vector3) {
    this.camera.rotation = rot;
    this.guicamera.rotation = rot;
    // Keep that for future VR camera rotation
    // let target = this._system.freecamera.getFrontPosition(10);
    // this._system.mobilecamera.setTarget(target);
    // this._system.mobilecamera.lockedTarget = target;
    // this._system.mobilecamera.lockedTarget = null;
  }

  previousPov:number;
  setPipelineChange (perc:number) {
    let l = this.sortedPointofViews.length - 1;
    let progress = perc * l;
    let floor = Math.floor(progress);
    floor = Math.min(floor, l - 1); // Security in case perc > 1
    let povPerc = progress - floor;
    let previousPovName = this.sortedPointofViews[floor];
    let nextPovName = this.sortedPointofViews[floor + 1];
    let previousPov = this.pointofviews[previousPovName];
    let nextPov = this.pointofviews[nextPovName];
    if (this.previousPov != floor) this._pipeline.checkOption(previousPov.pipeline);
    this.previousPov = floor;
    this._pipeline.setOptionProgress(previousPov.pipeline, nextPov.pipeline, povPerc);
  }
}
