
import { layerRight } from '../inputs/layer';

import { el, setChildren } from 'redom';

export class animationCreatorClass {

    componenttitle:any;
    container:any;

    constructor () {
      this.container = el('div.component-creator',
        [
          el('div.parameter-group',
            [
              this.componenttitle = el('div.parameter-title', 'Add Animation'),
              el('div.text', 'Select a component in order to add an animation on it')
            ]
          ),
        ]
      )
    }

    showCreator () {
      setChildren(layerRight, this.container);
    }
}

export let animationCreator = new animationCreatorClass();
