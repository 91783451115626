
import { _system } from '../service/editintale';

import { el, setStyle, mount } from 'redom';

/*
  +------------------------------------------------------------------------+
  | PERFORMANCE LAYER                                                      |
  +------------------------------------------------------------------------+
*/

export class performanceLayerClass {

    // filetypelist = ['bump','particle','diffuse','image','video','model'];
    filetypetext:any = {}
    control:HTMLElement;
    fpsnode:HTMLElement;
    loadtime:HTMLElement;
    vertices:HTMLElement;
    meshes:HTMLElement;

    constructor () {
      this.control = el('div.help-layer.siimple-list',
        [
          el('div.help-container.siimple-list-item',
            [
              el('div.performance-label', 'FPS'),
              this.fpsnode = el('div.performance-value.siimple-tag', 'fps'),
            ]
          ),
          el('div.help-container.siimple-list-item',
            [
              el('div.performance-label', 'Total Vertices'),
              this.vertices = el('div.performance-value.siimple-tag'),
            ]
          ),
          el('div.help-container.siimple-list-item',
            [
              el('div.performance-label', 'Total Meshes'),
              this.meshes = el('div.performance-value.siimple-tag'),
            ]
          ),
        ]
      );
      mount(document.body, this.control)
      setStyle(this.control, {display:'none'});
    }

    // addContent () {
    //   // for (let i = 0; i < this.filetypelist.length; i++) {
    //   //   let type = this.filetypelist[i];
    //   //   this.control.addText(type, {x:10, y: (i + 1) * 20 + 32}, {color:colorgrey});
    //   //   this.filetypetext[type] = this.control.addText('', {x:90, y: 0}, {color:colorgrey, 'font-weight':'600'}).setScreenPosition({top:(i + 1) * 20 + 32, right:10});
    //   //   this.setHeight(( i + 4 ) * 20);
    //   // }
    // }

    interval:any;
    show () {
      if (this.interval) clearInterval(this.interval);
      setInterval(() => {
        this.fpsnode.textContent = _system.engine.getFps().toFixed().toString();
        this.vertices.textContent = _system.scene.getTotalVertices().toString();
        this.meshes.textContent = _system.scene.getActiveMeshes().length.toString();
      }, 50);

      // for (let i = 0; i < this.filetypelist.length; i++) {
      //   let type = this.filetypelist[i];
      //   let num = Object.keys(assetLoader[type]).length;
      //   // TODO Add file weight and estimated website loading time
      //   this.filetypetext[type].setText(num.toString());
      // }

      // this.loadtime.setText(engine.getFps().toFixed());
      setStyle(this.control, {display:'block'});
    }

    hide () {
      if (this.interval) clearInterval(this.interval);
      setStyle(this.control, {display:'none'});
    }
}

export let performanceLayer = new performanceLayerClass();
