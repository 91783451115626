
import { shapecontent } from './shape';
import { particlecontent } from './particle';
import { imagecontent } from './image';
import { videocontent } from './video';
import { modelcontent } from './model';
import { lightcontent } from './light';
import { heightmapcontent } from './heightmap';
import { groupcontent } from './group';
import { hotspotcontent } from './hotspot';
import { soundcontent } from './sound';
import { contentMove } from './shared/contentmove';
import { contentsTriggerClass } from './contentsTrigger';
import { hotspotsManagerClass } from './hotspotsManager';
import { contentInterface } from './shared/content';
import { system } from '../tools/system';
import { pipelineClass } from '../scenery/pipeline';
import { scrollCatcherClass } from '../service/scrollcatcher';
import { mouseCatcherClass } from '../service/mousecatcher';

import find from 'lodash/find';

export class contentsManagerClass extends contentsTriggerClass {

  hotspotsManager:hotspotsManagerClass;
  _system:system;
  _pipeline:pipelineClass;

  constructor (system:system, hotspotsManager:hotspotsManagerClass, mouseCatcher:mouseCatcherClass, scrollCatcher:scrollCatcherClass, pipeline:pipelineClass) {
    super(mouseCatcher, scrollCatcher);
    this._system = system;
    this._pipeline = pipeline;
    this.hotspotsManager = hotspotsManager;
  }

  setContents (contents:any) {
    for (let i = 0; i < contents.length; i++) {
      let cd = contents[i];
      this.addNewContent(cd.type, cd.contentdata, cd.typedata);
    }
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].type == 'group') this.list[i].addContents(this.list);
		}
    this.hideContents();
  }

  addContentAnimation (id:string, animations:any) {
    let content = find(this.list, (o) => { return o.id == id });
    content.addAnimations(animations);
    if (content.play) content.playAnimation();
    else content.pauseAnimation();
	}

  stopContents () {
    for (let i = 0; i < this.list.length; i++) {
      this.list[i].pauseAnimation();
    }
  }

  hideContents () {
    for (let i = 0; i < this.list.length; i++) {
      this.list[i].hide(true);
    }
  }

  showContents () {
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].type != 'hotspot') {
        if (this.list[i].visible) this.list[i].show();
        else this.list[i].hide();
      }
    }
    if (this.reflectionColor) this.setReflectionColor(this.reflectionColor);
  }

  reflectionColor:Array<number>;
  setReflectionColor (color:Array<number>) {
    this.reflectionColor = color;
    for (let i = 0; i < this.list.length; i++) {
      let content = this.list[i];
      this.checkContentReflection(content, color);
    }
  }

  checkContentReflection (content:contentMove, color:Array<number>) {
    if (content.material) {
      if (content.material.type != 'water') { // WATER doesn't have reflection texture and it creates a bug otherwise
        // Need to clone so that every content can set the reflection level
        if (color) content.pattern.setTextureColor('reflection', color);
        else content.pattern.deleteTextureColor('reflection');
      }
    }
  }

  addContent (content:contentMove) {
    this._addContent(content);
    if (content.type == 'hotspot') {
      this.hotspotsManager.addHotspot(content);
    }
  }

  removeContent (content:contentMove) {
    this._removeContent(content);
    if (content.type == 'hotspot') {
      this.hotspotsManager.removeHotspot(content);
    }
  }

  addNewContent (type:string, content:contentInterface, typeStyle:any) {
    let newcontent:contentMove;
    switch (type) {
      case 'sound':
				newcontent = new soundcontent(this._system, content, typeStyle);
        break;
      case 'hotspot':
        newcontent = new hotspotcontent(this._system, content, typeStyle, this._pipeline);
        break;
      case 'particle':
        newcontent = new particlecontent(this._system, content, typeStyle);
        break;
      case 'shape':
        newcontent = new shapecontent(this._system, content, typeStyle);
        break;
      case 'image':
        newcontent = new imagecontent(this._system, content, typeStyle);
        break;
      case 'video':
        newcontent = new videocontent(this._system, content, typeStyle);
        break;
      case 'model':
        newcontent = new modelcontent(this._system, content, typeStyle);
        break;
      case 'light':
        newcontent = new lightcontent(this._system, content, typeStyle);
        break;
      case 'heightmap':
        newcontent = new heightmapcontent(this._system, content, typeStyle);
        break;
      case 'group':
        newcontent = new groupcontent(this._system, content, typeStyle);
        break;
    }
    this.addContent(newcontent);
    if (this.reflectionColor) this.checkContentReflection(newcontent, this.reflectionColor);
    return newcontent;
  }
}
