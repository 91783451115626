
import { option3D } from '../tools/interface';
import { contentMove } from './shared/contentmove';
import { projectInterface } from '../service/projectInterface';
import { contentInterface } from './shared/content';
import { shape } from './patterns/shape';
import { coreSystem } from '../tools/coreSystem';

/*
  +------------------------------------------------------------------------+
  | VIDEO CONTENT                                                          |
  +------------------------------------------------------------------------+
*/

export interface videoStyle {
  url:string;
  loop?:boolean,
  autoplay?:boolean,
  coloroverlay?:Array<number>;
}

projectInterface.video = {
  url:'u',
  coloroverlay:'c',
  loop:'lp',
  autoplay:'a',
}

export class videocontent extends contentMove {

  type = 'video';

  video:any;
  videowidth:number;
  videoheight:number;
  videoratio:number;
  texture:any;

  url:string;
  loop = true;
  autoplay = true;
  coloroverlay:Array<number>;
  pattern:shape;

  constructor (coreSystem:coreSystem, content:contentInterface, videoStyle:videoStyle) {
    super(coreSystem, content, 'plane');
    this.pattern.setMaterialType('standard');
    this.setTypeParameter(videoStyle);
  }

  setTypeParameter (videoStyle:videoStyle) {
    if (videoStyle.url !== undefined) this.loadVideo(videoStyle.url);
    if (videoStyle.coloroverlay !== undefined) this.setColorOverlay(videoStyle.coloroverlay);
    if (videoStyle.loop !== undefined) this.setLoop(videoStyle.loop);
    if (videoStyle.autoplay !== undefined) this.setAuto(videoStyle.autoplay);
  }

  loadVideo (url:string) {
    if (url == undefined) return this.pattern.deleteTexture('albedo');
    this.url = url;
    this._system.loader.getVideo(url, (asset) => {
      if (asset) {
        this.setVideo(asset.texture);
        if (this.loop) this.setLoop(this.loop);
        if (this.autoplay) this.setAuto(this.autoplay);
        if (this.coloroverlay) this.setColorOverlay(this.coloroverlay);
        this.setShadow(this.shadow);
      } else {
        this.pattern.deleteTexture('albedo');
      }
    });
  }

  setVideo (texture:any) {
    this.texture = texture;
    this.pattern.setTexture('albedo', this.texture, false);
    this.video = this.texture.video;
    this.getVideoRatio();
    this.setScale({x:this.scale.x});
  }

  getVideoRatio () {
    this.videoheight = this.video.videoHeight;
    this.videowidth = this.video.videoWidth;
    if (this.videowidth == 0) this.videoratio = 1;
    else this.videoratio = this.videoheight/this.videowidth;
  }

  setColorOverlay (color:Array<number>) {
    this.coloroverlay = color;
    this.pattern.setTextureColor('albedo', color);
  }

  setScale (pos:option3D) {
    this.setVector('scale', pos);
    if (this.videoratio !== undefined) {
      if (pos.x !== undefined) this.scale.y = pos.x * this.videoratio;
      else if (pos.y !== undefined) this.scale.x = pos.y / this.videoratio;
      this.scale.z = 1; // Need scale to be at least 1 or image gets black
      this.pattern.setScaleAxis(this.scale);
    }
  }

  setAuto (autoplay:boolean) {
    this.autoplay = autoplay;
    if (this.video) {
      if (autoplay) this.video.play();
      else this.video.pause();
    }
  }

  setLoop (loop:boolean) {
    this.loop = loop;
    if (this.video) {
      this.video.loop = loop;
      if (this.autoplay) this.video.play();
    }
  }

  hide (fast?:boolean) { // Fast used in editor to quickly hide some content which have fade animation
    this._hideMove();
    if (this.video) this.video.pause();
  }

  show () { // Fast used in editor to quickly hide some content which have fade animation
    this._showMove();
    if (this.video) this.video.play();
  }
}
