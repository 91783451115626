
import { navigationInterface } from '../../viewer/scenery/navigation';
import { ui_numberinput, ui_checkbox } from '../inputs/input';
import { animation } from '../../viewer/tools/animation';
import { floatingManager, layerRight, layerLeft } from '../inputs/layer';
import { pointofviewManager, pointofviewsManager, pointofviewChannel } from './pointofviewmanager';
import { undoChannel } from '../service/undo';
import { pointofviewSight } from './pointofviewSight';
import { progressBarSettings } from '../journey/progressBarSettings';
import { shape } from '../../viewer/contents/patterns/shape';
import { _system, navigation } from '../service/editintale';
import { zoomzone, zoomManager } from '../dragzone/zoomManager';

import { setChildren, el, mount, setStyle } from 'redom';
import Sortable from 'sortablejs';
import { Curve3 } from '@babylonjs/core/Maths/math'
import { Mesh } from '@babylonjs/core/Meshes/mesh';

pointofviewChannel.subscribe("unselect", (data:pointofviewManager, envelope) => {
  journeyManager.showJourney();
});

pointofviewChannel.subscribe("select", (data:pointofviewManager, envelope) => {
  journeyManager.stopPreview();
  journeyManager.selectedPointofview(data.pointofview.name);
});

pointofviewChannel.subscribe("change", (data:pointofviewManager, envelope) => {
  journeyManager.drawRails();
  journeyManager.updateSortable();
});

// When point of view is dragged (see gradmanager) we update the rail
pointofviewChannel.subscribe("move", (data:pointofviewManager, envelope) => {
  journeyManager.drawRails();
});

pointofviewChannel.subscribe("update", (data:pointofviewManager, envelope) => {
  journeyManager.drawRails();
});

pointofviewChannel.subscribe("delete", (data:pointofviewManager, envelope) => {
  journeyManager.drawRails();
  journeyManager.updateSortable();
});

pointofviewChannel.subscribe("added", (pointofviewManager:pointofviewManager, envelope) => {
  pointofviewManager.select();
  journeyManager.drawRails();
  journeyManager.updateSortable();
  journeyManager.setAllPointofviewIndex(); // Reset all index to make sure all pointofview has the right one
});

pointofviewChannel.subscribe("rename", (data, envelope) => {
  journeyManager.updateSortable();
});

undoChannel.subscribe("pointofviewchange", (data, envelope) => {
  journeyManager.updateSortable();
  journeyManager.drawRails();
});

undoChannel.subscribe("undelete", (data, envelope) => {
  journeyManager.drawRails();
  journeyManager.updateSortable();
});

undoChannel.subscribe("navigationchange", (data, envelope) => {
  navigationManager.checkInput(data);
  navigation.checkOption(data);
});

zoomzone.addEventListener("mousewheel", (evt) => {
  journeyManager.checkPreviewsSize();
});

/*
  +------------------------------------------------------------------------+
  | JOURNEY MANAGER                                                        |
  +------------------------------------------------------------------------+
*/

class journeyManagerClass {

    selectionContainer:any;
    container:any;
    sight:pointofviewSight;
    anim:animation;

    constructor () {
      this.container = el('div.preset-group',
        [
          el('div.parameter-title',
            el('div.title-text', 'Selection Panel')
          ),
          el('div.select-main-icon-container', [
            el('div.right-icon.select-main-icon.icon-play',
              {onclick:()=>{this.launchJourneyPreview()}},
              [el('span.path1'), el('span.path2'), el('span.path3')]
            )
          ]),
          this.selectionContainer = el('div.list-group.editor-scroll.pointofview-panel')
        ]
      );
      this.anim = new animation(_system);
    }

    parentPreview:shape;
    previewInterval:any;
    previewTimeout:any;
    launchJourneyPreview () {
      this.unselectPointofviews();
      this.setNavigationForPreview();
      this.sight.show();
      this.hideSigths();
      this.launchPreviewAnimation();
    }

    launchPreviewAnimation () {
      navigation.getNavigationSpline();
      navigation.initPositionandRotation();
      navigation.showStartPointofview();
      let povList = navigation.getSortedPointofViews();
      let povLength = povList.length - 1;
      let lastPov = 0;
      this.selectedPointofview(povList[0]);
      this.anim.setParam(povLength * 100);
      this.anim.go((count, perc) => {
        navigation.animViewPercentage(perc);
        let newPov = Math.round(perc * povLength);
        if (newPov != lastPov) {
          this.selectedPointofview(povList[newPov]);
          lastPov = newPov;
        }
      }, () => {
        this.stopPreview();
      });
    }

    stopPreview () {
      this.showSigths();
      clearInterval(this.previewInterval);
      clearTimeout(this.previewTimeout);
      this.resetNavigationAfterPreview();
      if (this.sight) this.sight.hide();
      this.unselectedPointofview();
    }

    createPreview () {
      if (!this.sight) {
        this.parentPreview = new shape(_system, {}, 'cube');
        this.parentPreview.setMaterialType('standard');
        this.parentPreview.setMaterialProperties({alpha:0});
        this.sight = new pointofviewSight(this.parentPreview);
        this.sight.setSightLength(5);
        this.sight.hide();
      }
      this.parentPreview.mesh.parent = navigation.camera;
    }

    navigationpropertiesbeforepreview:navigationInterface;
    previewlength = 10;
    setNavigationForPreview () {
      this.navigationpropertiesbeforepreview = {
        duration:navigation.duration,
        followmouse:navigation.followmouse,
      }
      navigation.duration = this.previewlength * 100;
      navigation.followmouse = false;
    }

    resetNavigationAfterPreview () {
      if (this.navigationpropertiesbeforepreview) {
        navigation.duration = this.navigationpropertiesbeforepreview.duration;
        navigation.followmouse = this.navigationpropertiesbeforepreview.followmouse;
      }
      this.anim.stop();
    }

    showJourney () {
      setChildren(layerRight, [navigationManager.control.el, progressBarSettings.control.el]);
      setChildren(layerLeft, [this.container]);
      this.createPreview();
      this.unselectedPointofview();
      this.updateSortable();
      this.showSigthsAndRail();
    }

    unselectPointofviews () {
      for (let key in pointofviewsManager) {
        pointofviewsManager[key].unselect();
      }
    }

    hideSigthsAndRail () {
      this.hideSigths();
      this.checkRail();
    }

    hideSigths () {
      this.sightsVisible = false;
      for (let key in pointofviewsManager) {
        pointofviewsManager[key].hideBox();
      }
    }

    sightsVisible = false;
    showSigthsAndRail () {
      this.showSigths();
      this.drawRails();
    }

    showSigths () {
      this.sightsVisible = true;
      for (let key in pointofviewsManager) {
        pointofviewsManager[key].showBox();
      }
    }

    checkPreviewsSize () {
      if (!this.sightsVisible) return;
      let radius = zoomManager.cameraedit.radius;
      let scale = 0.06 * radius;
      for (let key in pointofviewsManager) {
        pointofviewsManager[key].sight.parent.setScaleAxis({x:scale, y:scale, z:scale});
      }
      this.sight.parent.setScaleAxis({x:scale, y:scale, z:scale});
    }

    updateSortable () {
      this.deleteSortable();
      let pointofviewsSorted = navigation.getSortedPointofViews();
      for (let i = 0; i < pointofviewsSorted.length; i++) {
        this.addPointofviewInSelection(pointofviewsSorted[i]);
      }
      this.setSortable(this.selectionContainer);
    }

    pointofviewList:Array<HTMLElement> = [];
    addPointofviewInSelection (name:string) {
      let pointofviewselect = el('div.select-button.panel.pointofview-panel-button.draggable.icon-pointofview', {
        onclick:()=>{pointofviewsManager[name].select()},
        onmouseenter:()=>{pointofviewsManager[name].showSelector()},
        onmouseleave:()=>{pointofviewsManager[name].hideSelector()},
        id: name,
      }, [
        el('span.path1'),el('span.path2'),el('span.path3'),
        el('div.selection-tag', name)
      ]);
      this.pointofviewList.push(pointofviewselect);
      mount( this.selectionContainer, pointofviewselect);
    }

    unselectedPointofview () {
      for (let i = 0; i < this.pointofviewList.length; i++) {
        setStyle(this.pointofviewList[i], {background:''});
      }
    }

    selectedPointofview (selected:string) {
      this.unselectedPointofview();
      let rgbstring = 'rgba(240,240,251, 0.5)';
      for (let i = 0; i < this.pointofviewList.length; i++) {
        if (selected == this.pointofviewList[i].id) setStyle(this.pointofviewList[i], {background:rgbstring});
      }
    }

    sortableList:any;
    setSortable (el:any) {
      this.sortableList = Sortable.create(el,
        {
          animation: 150,
          scroll:true,
          // delay: 100, // FIXME delay not working to avoir shadow showing on click
          group: "panel",
          draggable: '.draggable',
          ghostClass: "sortable-ghost",  // Class name for the drop placeholder
          chosenClass: "sortable-chosen",  // Class name for the chosen item
          dragClass: "sortable-drag",  // Class name for the dragging item
          onEnd: (evt) => {
            this.setAllPointofviewIndex();
            this.drawRails();
            pointofviewChannel.publish('update');
          },
        }
      );
    }

    deleteSortable () {
      if (this.sortableList) this.sortableList.destroy();
      setChildren( this.selectionContainer, []);
    }

    setAllPointofviewIndex () {
      let el = this.sortableList.el;
      for (let j = 0; j < el.childNodes.length; j++) {
        let name = el.childNodes[j].textContent;
        navigation.pointofviews[name].panelindex = j;
      }
    }

    curves:Mesh;
    railVisible = false;
    setRailVisible () {
      this.railVisible = !this.railVisible;
      this.checkRail();
    }

    checkRail () {
      if (this.railVisible) this.drawRails();
      else this.deleteRails();
    }

    drawRails () {
      this.deleteRails();
      let pointofviewsSorted = navigation.getSortedPointofViews();
      if (pointofviewsSorted.length == 1) return;
      let viewsPoints = [];
      for (let i = 0; i < pointofviewsSorted.length; i++) {
        viewsPoints.push(navigation.pointofviews[pointofviewsSorted[i]].position);
      }
      let catmullRom = Curve3.CreateCatmullRomSpline(viewsPoints, 60, false);
      this.curves = Mesh.CreateLines("catmullRom", catmullRom.getPoints(), _system.scene);
      this.curves.layerMask = 0x20000000;
    }

    deleteRails () {
      if (this.curves) this.curves.dispose();
    }
}

export let journeyManager = new journeyManagerClass();

/*
  +------------------------------------------------------------------------+
  | PAGE ANIM MANAGER                                                      |
  +------------------------------------------------------------------------+
*/

class navigationManagerClass extends floatingManager {

    constructor () {
      super();
      this.setInputs();
    }

    durationbutton:ui_numberinput;
    followmousebutton:ui_checkbox;
    setInputs () {
      let addbutton = this.addButton({ui:'text', text:'Add point of view'}, () => {
        this.addPointofview();
      });
      setStyle(addbutton.el.parentNode, {margin:'10px 10px'});

      this.control.addText('Navigation options', 'parameter-subtitle');

      this.durationbutton = this.addNumberInput('Duration', {step:1, value:1, unit:'PX', min:100}, (value) => {
        navigation.duration = Math.round(value*10)/10;
      });

      this.followmousebutton = this.addCheckBox('Follow mouse', false, (value) => {
        navigation.followmouse = value;
      });
    }

    resetInput (navigationOptions:navigationInterface) {
      this.durationbutton.setValue(navigationOptions.duration);
      this.followmousebutton.setValue(navigationOptions.followmouse);
    }

    checkInput (navigationOptions:navigationInterface) {
      if (navigationOptions.duration !== undefined) this.durationbutton.setValue(navigationOptions.duration);
      if (navigationOptions.followmouse !== undefined) this.followmousebutton.setValue(navigationOptions.followmouse);
    }

    addPointofview() {
      let povList = navigation.getSortedPointofViews();
      let nb = povList.length;
      let pointofviewname = 'Point of view '+(nb+1).toString();
      while (povList.indexOf(pointofviewname) != -1) { // To make sure we don't have twice the same name
        pointofviewname = 'Point of view '+(nb+1).toString();
        nb++;
      }
      let lastPov = navigation.pointofviews[povList[povList.length - 1]];
      let p = lastPov.position;
      let newPos = {x:p.x, y:p.y, z:p.z};
      let r = lastPov.rotation;
      let newRot = {x:r.x, y:r.y, z:r.z};
      newPos.x += 1;
      let newpointofview = new pointofviewManager({name:pointofviewname, position:newPos, rotation:newRot, pipeline:{}});
      newpointofview.showBox();
      pointofviewChannel.publish('added', newpointofview);
    }
}

export let navigationManager = new navigationManagerClass();
