
import { colorthirdgrey, colormain, colordarkgrey } from '../service/colors';
import { _system } from '../service/editintale';
import { globals } from '../service/globals';
import { util } from '../../viewer/tools/util';
import { loadingScreenClass } from '../../viewer/scenery/loadingscreen';

import ProgressBar from 'progressbar.js';
import toastr from 'toastr'
import { el, mount, setStyle } from 'redom';

class editorLoadingScreenClass extends loadingScreenClass {

  assetcontainer:any;
  loaderopacity = 0.9;

  constructor () {
    super(_system);
    this.build();
    mount(document.body, this.container);
    let bgrgb = util.hexToRgb(colordarkgrey);
    setStyle(this.container, {background:'rgba( '+bgrgb[0]+', '+bgrgb[1]+', '+bgrgb[2]+', '+this.loaderopacity+')'});
    setStyle(this.loadingText, {top: 'calc(50% - 200px)', transform: 'translate(-50%)', position: 'absolute', 'font-size':'30px', width:'500px'});
    setStyle(this.progressbarContainer, {display:'none'});

    this.assetcontainer = el('div.asset-center');
    mount(this.container, this.assetcontainer);
    this.setTypeLoaders();
  }

  error = '';
  loadingsuccess = true;
  addNakerTask () {
    _system.loader.createManager();
    _system.loader.onSuccessLoad = (type:string, url:string) => {
      this.addLoadSuccess(type);
    }

    _system.loader.onErrorLoad = (title:string, error:string) => {
      toastr.error(title+' : '+error);
      this.loadingsuccess = false;
    }

    // Default bump water and particle
    _system.loader.loadAsset('bump', 'https://asset.naker.io/textures/bumps/Water.jpg', () => {});
    // NOTE Particle delete for now so don't need to import particle default texture
    // _system.loader.loadAsset('particles', 'https://asset.naker.io/textures/particles/Flare.png', () => {});
  }

  typetext:any = {};
  loadedtype:any = {};
  progresstype:any = {};
  callback:Function;
  timeout:any;
  startEditorLoad (timeout:boolean, callback?:Function) {
    this.resetLoaders();
    this.showTypeLoaders();
    setStyle(this.loadingText, {'text-align':'center'});
    this.loading = true;
    this.loadingsuccess = true;
    this.callback = callback;
    _system.loader.manager.onFinish = () => {
      if (this.loading) {
        this.successLoader();
        if (callback) callback(this.loadingsuccess);
      }
      _system.loader.firstload = false;
      _system.loader.manager.onFinish = () => {};
      _system.loader.manager.reset();
    };


    if (timeout) {
      let timeoutlength;
      if (globals.mode == 'development') timeoutlength = 100;
      else timeoutlength = 10000;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.loading) {
          toastr.info("Assets still loading in the background! Try to reduce the size of files in order to have a better loading time");
          this.hideLoader(0);
          if (callback) callback(false);
          _system.loader.manager.onFinish = () => {};
          _system.loader.manager.reset();
        }
      }, timeoutlength);
    }
  }

  resetLoaders () {
    this.resultlines = 0;
    for (let key in this.loadedtype) {
      this.loadedtype[key] = 0;
      this.progresstype[key].set(0);
      setStyle(this.typetext[key], {display:'none'});
      setStyle(this.progresstype[key].svg, {display:'none'});
    }
  }

  resultlines = 0;
  setTypeLoaders () {
    for (let key in _system.loader.loadwaiting) {
      this.typetext[key] = el('div.asset-text', key);
      mount(this.assetcontainer, this.typetext[key])
      this.loadedtype[key] = 0;
      this.progresstype[key] = new ProgressBar.Line(this.assetcontainer, {
        strokeWidth: 20,
        easing: 'easeInOut',
        duration: 400,
        color: colormain,
        trailColor: colorthirdgrey,
        svgStyle: {width: '100%', height: '4px', position:'relative', top:'-5px', left: '0px', 'border-radius': '5px'},
      });
      setStyle(this.typetext[key], {display:'none'});
      setStyle(this.progresstype[key].svg, {display:'none'});
    }
  }

  showTypeLoaders () {
    this.resetLoaders();
    for (let key in this.loadedtype) {
      if (_system.loader.loadwaiting[key] != 0) {
        this.typetext[key].textContent = key+' 0/'+_system.loader.loadwaiting[key];
        setStyle(this.typetext[key], {display:'block'});
        setStyle(this.progresstype[key].svg, {display:'block'});
        this.resultlines++;
      }
    }
  }

  addLoadSuccess (type:string) {
    this.loadedtype[type]++;
    this.typetext[type].textContent = type+' '+this.loadedtype[type]+'/'+_system.loader.loadwaiting[type];
    this.progresstype[type].animate(this.loadedtype[type]/_system.loader.loadwaiting[type]);
  }

  showLoader (text:string) {
    this.showTypeLoaders();
    this.loading = true;
    this.show(text);
  }

  hideLoader (timeout:number) {
    _system.loader.firstload = false;
    _system.loader.resetLoadWaiting();
    this.resetLoaders();
		if (this.timeout) clearTimeout(this.timeout);
    this.loading = false;
    setTimeout(() => {
      this.hide();
    }, timeout);
  }

  successLoader () {
    _system.loader.firstload = false;
    _system.loader.resetLoadWaiting();
    this.hideLoader(500);
  }

}

export let editorLoadingScreen = new editorLoadingScreenClass();
