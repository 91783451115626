
import { system } from '../tools/system';
import { animation } from '../tools/animation';
import { responsiveClass } from './responsive';

import remove from 'lodash/remove';
import { Vector2 } from '@babylonjs/core/Maths/math'

export class scrollCatcherClass {

  _system:system;

  realscroll = 0;
  scrollcatch = 0;
  scrollpercentage = 0;
  scrollheight = 1000;
  animation:animation;

  constructor (system:system, responsive:responsiveClass) {
    this._system = system;
    this.animation = new animation(this._system, 10);
    responsive.addListener(() => {
      this.checkHeight(this.scrollheight);
    });

    this.setScrollEvent();
    this.setMobileDragEvent();
  }

  setScrollHeight (height:number) {
    if (!this.scrollheight) this.followscroll = false;
    this.checkHeight(height);
  }

  followscroll = false;
  checkHeight (height:number) {
    if (this._system.container) {
      if (this._system.container == document.body) {
        // If overflow style = hidden, there is no scrollingElement on document
        if (document.scrollingElement) {
          this.scrollheight = document.scrollingElement.scrollHeight - window.innerHeight;
        }
      } else {
        this.scrollheight = this._system.container.scrollHeight - this._system.container.clientHeight;
      }
    }
    // On some browser or phone, you can have a small different even if page not scrollable
    // Plus 50 is way too short to make et scene scroll
    if (this.scrollheight <= 50) {
      this.scrollheight = height;
      this.followscroll = false;
    } else {
      this.followscroll = true;
    }
  }

  setScrollEvent () {
    // Body use different evnet for scroll
    if (this._system.container == document.body) {
      window.addEventListener("scroll", (evt) => {
        if (!this.followscroll) return;
        // If overflow style = hidden, there is no scrollingElement on document
        if (document.scrollingElement) {
          let top = document.scrollingElement.scrollTop;
          if (this.catching) this.catchTop(top);
        }
      });
    } else {
      this._system.container.addEventListener("scroll", (evt) => {
        if (!this.followscroll) return;
        let top = this._system.container.scrollTop;
        if (this.catching) this.catchTop(top);
      });
    }

    this._system.container.addEventListener("mousewheel", (evt) => {
      let top = this.realscroll + evt.deltaY;
      this.mouseWheel(evt, top);
    });

    // Firefox use DOMMouseScroll
    this._system.container.addEventListener("DOMMouseScroll", (evt:any) => {
      let top = this.realscroll + evt.detail * 50;
      this.mouseWheel(evt, top);
    });
  }

  mousestart = Vector2.Zero();
  mousegap = Vector2.Zero();
  dragscrollstart:number = 0;
  setMobileDragEvent () {
    let count = 0;
    this._system.canvas.addEventListener( "touchstart", (evt) => {
      this.mousestart.x = evt.changedTouches[0].clientX;
      this.mousestart.y = evt.changedTouches[0].clientY;
      this.dragscrollstart = this.realscroll;
      count = 0;
    });
    this._system.canvas.addEventListener( "touchmove", (evt) => {
      if (this.mousestart && this.catching) {
        let x = evt.changedTouches[0].clientX;
        let y = evt.changedTouches[0].clientY;
        this.mousegap.x = (this.mousestart.x - x);
        this.mousegap.y = (this.mousestart.y - y);
        if (Math.abs(this.mousegap.x) < Math.abs(this.mousegap.y)) {
          let top = this.realscroll + this.mousegap.y;
          if (this.catching) this.catchTop(top);
          count++;
          if (count == 50) {
            this.mousestart.x = x;
            this.mousestart.y = y;
            count = 0;
          }
        }
      }
    });
  }

  restart () {
    this.stop();
    this.start();
  }

  catching = false;
  start () {
    this.catching = true;
    if (this.followscroll) {
      if (this._system.container == document.body) {
        if (document.scrollingElement) {
          let top = document.scrollingElement.scrollTop;
          this.catchTop(top);
        }
      } else {
        let top = this._system.container.scrollTop;
        this.catchTop(top);
      }
    } else {
      let top = this.realscroll;
      this.catchTop(top);
    }
    this.sendToListsteners();
    this.sendStartCallbacks();
  }

  sendStartCallbacks () {
    for (let i = 0; i < this.startCallbacks.length; i++) {
      this.startCallbacks[i]();
    }
  }

  stop () {
    this.animation.stop();
    this.catching = false;
    this.movingToStep = false;
    this.sendStopCallbacks();
  }

  sendStopCallbacks () {
    for (let i = 0; i < this.stopCallbacks.length; i++) {
      this.stopCallbacks[i]();
    }
  }

  steps = 2;
  setScrollStep (step:number) {
    this.steps = step;
    this.setScrollHeight(step * 500);
  }

  startCallbacks:Array<Function> = [];
  stopCallbacks:Array<Function> = [];
  on (what:'start'|'stop', funct:Function) {
    if (what == 'start') this.startCallbacks.push(funct);
    else if (what == 'stop') this.stopCallbacks.push(funct);
  }
  // Used by text which need to be hidden when mousewheel
  onMouseWheel:Function;
  mouseWheel (evt, top:number) {
    if (this._system.container != document.body) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    if (this.onMouseWheel) this.onMouseWheel();
    if (this.followscroll) return;
    if (this.catching) this.catchTop(top);
  }

  catchTop (top:number) {
    if (!this.movingToStep) this.catch(top, 30);
    if (!this.followscroll) this.checkStep();
  }

  stepLimit = 20;
  checkStep () {
    setTimeout(() => {
      if (!this.movingToStep) {
        let scroll = this.scrollheight * this.currentStep / this.steps;
        if (this.scrollcatch - scroll > this.stepLimit) this.goToNextStep();
        else if (this.scrollcatch - scroll < -this.stepLimit) this.goToFormerStep();
      }
    }, 100);
  }

  currentStep = 0;
  currentStepScroll = 0;
  goToNextStep () {
    let nextStep = this.currentStep + 1;
    if (nextStep > this.steps) return;
    this.moveToStep(nextStep);
  }

  goToFormerStep () {
    let formerStep = this.currentStep - 1;
    if (formerStep < 0) return;
    this.moveToStep(formerStep);
  }

  movingToStep = false;
  moveToStep (step:number) {
    let scroll = this.scrollheight * step / this.steps;
    this.catch(scroll, 200);
    this.currentStep = step;
    this.movingToStep = true;
  }

  catch (scroll:number, speed:number) {
    // Sometimes on iphone, scroll can go below 0
    if (!scroll) scroll = 0;
    scroll = Math.max(0, scroll);
    scroll = Math.min(this.scrollheight, scroll);
    if (scroll == this.realscroll) return;
    this.realscroll = scroll;
    this.animation.infinite(() => {
      let gapscroll = this.realscroll - this.scrollcatch;
      let step = Math.sign(gapscroll) * Math.min(Math.abs(gapscroll)/20, speed);
      this.scrollcatch += step;
      this.scrollpercentage = this.scrollcatch/this.scrollheight;
      if (Math.abs(gapscroll) < 2) this.animation.running = false;
      if (Math.abs(gapscroll) < 20) this.movingToStep = false;
      this.sendToListsteners();
    });
  }

  sendToListsteners () {
    for (let i = 0; i < this.listeners.length; i++) {
      this.listeners[i](this.scrollcatch, this.scrollpercentage);
    }
  }

  listeners:Array<Function> = [];
  addListener (callback:Function) {
    this.listeners.push(callback);
  }

  removeListener (callback:Function) {
    remove(this.listeners, (c) => {c == callback});
  }
}
