
import { point2D, option3D } from './interface';

class owClass {
  Pim2 = Math.PI * 2;
  Pi = Math.PI;
  Pio4 = Math.PI/4;
  Pio2 = Math.PI/2;
  degtoradratio = (this.Pi/180);
  radtodegratio = (180/this.Pi);

  deg2rad (deg:number) {
    return deg * this.degtoradratio;
  }

  rad2deg (rad:number) {
    return rad * this.radtodegratio;
  }

  getRandomKey (obj:Object) {
    let keys = Object.keys(obj);
    let l = keys.length;
    let random = Math.round(Math.random()*l+0.5);
    if (random<0) random = 0;
    if (random>l-1) random = l - 1;
    return keys[random];
  }

  getRandom (arr:Array<any>) {
    let l = arr.length;
    let random = Math.round(Math.random()*l+0.5);
    if (random<0) random = 0;
    if (random>l-1) random = l - 1;
    return arr[random];
  }

	sumAxis (point1:option3D, point2:option3D) {
		let newpoint:any = {};
		for (let key in point1) {
		   newpoint[key] = point1[key] + point2[key];
		}
		return newpoint;
	}

	substractAxis (point1:option3D, point2:option3D) {
    let newpoint:any = {};
		for (let key in point1) {
			newpoint[key] = point1[key] - point2[key];
		}
		return newpoint;
	}

  negateAxis (point:option3D) {
    let newpoint:any = {};
    for (let key in point) {
      newpoint[key] = -point[key];
    }
    return newpoint;
  }

  divideAxisNumber (point:option3D, divider:number) {
    let newpoint:option3D = {};
    for (let key in point) {
      newpoint[key] = point[key]/divider;
    }
    return newpoint;
  }

  multiplyAxis (point1:option3D, point2:option3D) {
		let newpoint:any = {};
		for (let key in point1) {
			newpoint[key] = point1[key] * point2[key];
		}
		return newpoint;
	}

  multiplyAxisNumber (point1:option3D, multiplier:number) {
    let newpoint:any = {};
    for (let key in point1) {
      newpoint[key] = point1[key] * multiplier;
    }
    return newpoint;
  }

  positiveAxis (point:option3D) {
    let newpoint:any = {};
    for (let key in point) {
      newpoint[key] = Math.abs(point[key]);
    }
    return newpoint;
  }

  maximizeAxis (point1:option3D, point2:option3D) {
    let newpoint:any = {};
    for (let key in point1) {
      newpoint[key] = Math.max(point1[key], point2[key]);
    }
    return newpoint;
  }

  getMaximum (point:option3D) {
    let max:number = -100000000000;
    for (let key in point) {
      if (Math.abs(point[key]) > max) max = point[key];
    }
    return max;
  }

  // direction ( point1:point3D, point2:point3D ) {
  //   let ret:any = {};
  //   let distance = Vector3.Distance(point1, point2);
  //   ret.x = (point2.x - point1.x) / distance;
  //   ret.z = (point2.z - point1.z) / distance;
  //   ret.y = (point2.y - point1.y) / distance;
  //   return ret;
  // }

  getOrthogonal ( vector:point2D, clockwise:boolean ) {
		let ortho:any = {};
    if (clockwise) {
      ortho.x = -vector.y;
      ortho.y = vector.x;
    } else {
      ortho.x = vector.y;
      ortho.y = -vector.x;
    }
    return ortho;
  }

  // getRadian (v1, v2) {
  //   return Angle.BetweenTwoPoints(v1, v2)._radians;
  // }
	//
  // getDegree (v1:point3D, v2:point3D) {
  //   let angle = this.getRadian(v1, v2);
  //   return angle * 180 / Math.PI;
  // }
	//
  // getDegreeLatlng (latlng1:latlng, latlng2:latlng) {
  //   let v1 = new Vector3(latlng1.lng, 0, latlng1.lat);
  //   let v2 = new Vector3(latlng2.lng, 0, latlng2.lat);
  //   return this.getDegree(v1, v2);
  // }

	copyObject (Obj) {
		let newObj:any = {};
		for (let key in Obj) {
			newObj[key] = Obj[key];
		}
		return newObj;
	}

	copyArray (Arr) {
		let newArr:any = [];
		for (let i = 0; i < Arr.length; i++) {
			newArr[i] = Arr[i];
		}
		return newArr;
	}

	mergeObject (obj1:Object, obj2:Object) {
	  let newobj:any = {};
	  for (var key in obj1) {
	    newobj[key] = obj1[key];
	  }
	  for (var key in obj2) {
	    newobj[key] = obj2[key];
	  }
	  return newobj;
	}

	mergeArray (arr1, arr2) {
		let newarr = [];
		for (let i = 0; i < arr1.length; i++) {
		   newarr.push(arr1[i]);
		}
		for (let i = 0; i < arr2.length; i++) {
		   newarr.push(arr2[i]);
		}
	  return newarr;
	}

	componentToHex (c:number) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
	}

	babylonRgbToHex (brgb:any) {
		let rgb = {r:Math.round(brgb.r*250), g:Math.round(brgb.g*250), b:Math.round(brgb.b*250)};
		return this.rgbToHex(rgb);
	}

	rgbToHex (rgb:any) {
	   return "#" + this.componentToHex(rgb.r) + this.componentToHex(rgb.g) + this.componentToHex(rgb.b);
	}

	hexToRgb (hex:string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : [0, 0, 0];
	}

  hexToRgba (hex:string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
      1
    ] : [0, 0, 0, 0];
  }

	hexToRgbBabylon (hex:string) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
				r: parseInt(result[1], 16)/255,
				g: parseInt(result[2], 16)/255,
				b: parseInt(result[3], 16)/255
		} : null;
	}

  rgbaToString (rgba:Array<number>) {
    return 'rgba('+rgba[0]+', '+rgba[1]+', '+rgba[2]+', '+rgba[3]+')';
  }

}

export let util = new owClass();
