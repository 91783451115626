
import { contentMove } from './contentmove';
import { projectInterface } from '../../service/projectInterface';
import { asset } from '../../tools/loader';
import { shape } from '../patterns/shape';

/*
  +------------------------------------------------------------------------+
  | MATERIAL CONTENT                                                       |
  +------------------------------------------------------------------------+
*/

export interface materialInterface {
  name?:string;
  type?:string;
  texturesize?:number;
  alpha?:number;
  metallic?:number;
  roughness?:number;
  reflection?:boolean,
  refraction?:boolean,
  indexofrefraction?:number;
  glow?:boolean,
  albedocolor?:Array<number>;
  ambientcolor?:Array<number>;
  specularcolor?:Array<number>;
  emissivecolor?:Array<number>;
  reflectivitycolor?:Array<number>;
  watercolor?:Array<number>;
  albedotexture?:string;
  ambienttexture?:string;
  speculartexture?:string;
  emissivetexture?:string;
  opacitytexture?:string;
  bumptexture?:string;
  // reflectiontexture?:string;
  reflectivitytexture?:string;
  windforce?:number;
  waveheight?:number;
  bumpheight?:number;
  // winddirection?:number;
  colorblendfactor?:number;
  wavelength?:number;
}

export interface pbrPropertiesInterface {
  metallic?:number;
  roughness?:number;
}

export interface waterPropertiesInterface {
  windforce?:number;
  waveheight?:number;
  bumpheight?:number;
  // winddirection?:number;
  colorblendfactor?:number;
  wavelength?:number;
}

projectInterface.material = {
  name:'mn',
  type:'tp',
  texturesize:'mt',
  alpha:'mal',
  metallic:'mme',
  roughness:'mro',
  reflection:'mre',
  refraction:'mra',
  indexofrefraction:'miora',
  glow:'mg',
  albedocolor:'mabc',
  ambientcolor:'mac',
  specularcolor:'msc',
  emissivecolor:'mec',
  reflectivitycolor:'mryc',
  reflectioncolor:'mrc',
  watercolor:'mwc',
  albedotexture:'mab',
  ambienttexture:'ma',
  speculartexture:'ms',
  emissivetexture:'me',
  bumptexture:'mb',
  opacitytexture:'mo',
  // reflectiontexture:'mr',
  reflectivitytexture:'mry',
  windforce:'mwf',
  waveheight:'mwwh',
  bumpheight:'mwbh',
  // winddirection:'',
  colorblendfactor:'mwcb',
  wavelength:'mwwl',
}

export class materialcontent extends contentMove {

  material:materialInterface = {reflection: true, refraction: false, texturesize:1};
  pattern:shape;

  colors = ['albedo', 'ambient', 'specular', 'emissive', 'water', 'reflection', 'reflectivity'];
  textures:Array<asset["type"]> = ['albedo', 'ambient', 'specular', 'emissive', 'bump', 'opacity', 'reflection', 'refraction', 'reflectivity'];
  setMaterial (material:materialInterface) {
    if (material.type !== undefined) this.setType(material.type);
    else if (this.material.type == undefined) this.setType('standard');
    for (let i = 0; i < this.colors.length; i++) {
      let color = this.colors[i];
      if (material[color+'color'] !== undefined) this.setTextureColor(color, material[color+'color']);
    }
    for (let i = 0; i < this.textures.length; i++) {
      let texture = this.textures[i];
      if (material[texture+'texture'] !== undefined) this.setTexture(texture, material[texture+'texture']);
    }
    // alpha can be null due to undo, but setting alpha to null will break the mesh
    if (material.texturesize === null || material.texturesize === 0) material.texturesize = 1;
    if (material.texturesize !== undefined) this.setTextureSize(material.texturesize);
    // alpha can be null due to undo, but setting alpha to null will break the mesh
    if (material.alpha === null) material.alpha = 1;
    if (material.glow !== undefined) this.setGlow(material.glow);
    // this.setReflection(material.reflection);
    this.setMaterialProperties(material);
  }

  setType (type:string) {
    this.eraseMaterial();
    this.material.type = type;
    this.pattern.setMaterialType(type);
  }

  setTypeDefaultValues (type:string) {
    this.setTextureSize(1);
    if (type == 'water') {
      // check if content should not be needed but this is a security
      this.setTexture('bump', 'https://asset.naker.io/textures/bumps/Water.jpg');
      // this.pattern.deleteTextureColor('water');
      // Can't delete water color so we set it to black
      this.setTextureColor('water', [0, 0, 100, 1]);
      this.setMaterialProperties({windforce:10, waveheight:0, bumpheight:0.3, wavelength:0.4, texturesize:1});
    } else if (type == 'standard') {
      // Default BABYLON color is white
      this.setMaterialProperties({albedocolor:[255, 255, 255, 1], metallic:0, roughness:1, alpha:1, texturesize:1});
    }
  }

  eraseMaterial () {
    this.pattern.deleteTextures();
    this.pattern.deleteTextureColors();
    this.material = {reflection: true, refraction: false, texturesize:1};
  }

  setReflection (reflection:boolean) {
    this.material.reflection = reflection;
    this.pattern.enableReflection(reflection);
  }

  setRefraction (refraction:boolean) {
    this.material.refraction = refraction;
    this.pattern.enableRefraction(refraction);
  }

  setIndexOfRefraction (refraction:number) {
    this.material.indexofrefraction = refraction;
    this.pattern.setIndexOfRefraction(refraction);
  }

  setMaterialProperties (properties:materialInterface) {
    for (let key in properties) {
      this.material[key] = properties[key];
      this.pattern.setMaterialProperty(key, properties[key]);
    }
  }

  setGlow (glow:boolean) {
    this.material.glow = glow;
    this.pattern.setMaterialOptions({glowEnabled:glow});
    if (glow) this.pattern.addGlow();
    else this.pattern.removeGlow();
  }

  setTextureColor (texture:string, color:Array<number>) {
    this.material[texture+'color'] = color;
    this.pattern.setTextureColor(texture, color);
  }

  setTextureSize (size:number) {
    this.material.texturesize = size;
    this.pattern.setTextureSize(size);
  }

  setTexture (texture:asset["type"], url:string, callback?:Function) {
    this.material[texture+'texture'] = url;
    this.pattern.deleteTexture(texture);
    if (url == undefined || url == null) return;
    // FIXME We should always use value with an s to avoid mistake
    this._system.loader.getAsset(texture, url, (asset) => {
      if (asset) {
        this.pattern.setTexture(texture, asset.texture);
        this.setTextureSize(this.material.texturesize);
      } else {
        this.pattern.deleteTexture(texture);
      }
      if (callback) callback();
    });
  }
}
