
export let colortuto = '#2F2F70';
export let colorgrey = '#2c2c3b';
export let colorsecondgrey = '#2F2F3E';
export let colorthirdgrey = '#404050';
export let colorfourthgrey = '#606070';
export let colordarkgrey = '#020025';
export let colorlightgrey = '#9090A5';
export let colortext = '#F0F0FB';
export let colormain = '#7A8EE8';
export let colormainlight = '#ADBEFF';
export let colorsecondtext = '#000000';
export let colorsecondmain = '#440a80';
export let colorerror = '#BC3925';
export let colorsuccess = '#64B45C';

export let colorGizmoX = '#FF6D9B';
export let colorGizmoY = '#81FFAA';
export let colorGizmoZ = '#6C84FF';
