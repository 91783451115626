
import { api } from '../service/api';
import { globals } from '../service/globals';
import { exporter } from './exporter';

import io from 'socket.io-client';
import toastr from 'toastr';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import { undoBar } from '../project/bottombar';
import { undo } from './undo';

class socketManagerClass {
// Problem: I need the project's ID to make changes in the database
	socket:any;
	firstConnection: boolean = true;
  constructor () {
		// getSubdomain allows testing on localhost
    if (globals.getSave()) {
      // Creates a socket and connects it to the Back-End
      this.socket = io.connect(globals.getApiUrl(), {
					'reconnection': true,
					'reconnectionDelay': 2000,
					'reconnectionAttempts': 10
			});

      this.socket.on('connect_error', (err) => {
				if (this.firstConnection)
        	this.error("We didn't manage to connect you to the project");
			});

			this.socket.on('reconnect', (attempt) => {
				if (attempt >= 5)
					toastr.success('Successfully reconnected!');
				this.reconnectToWork();
			});

			this.socket.on('reconnect_attempt', (attempt) => {
				if (attempt == 5)
					toastr.error('Connection error, trying to reconnect');
				if (attempt == 10)
					this.error("We didn't manage to reconnect you");
			});

      this.socket.on('disconnect', (err) => {
        //this.error('You have been disconnected');
			});

			this.socket.on('compression', (response) => {
				let percentage = response.percentage;
				let message = response.message;
				if (response.done && response.success)
					undoBar.successLoad(message);
				else if (response.done && !response.success)
					undoBar.errorLoad(message);
				else {
					undoBar.animateLoadingBar(percentage);
					undoBar.setText(message);
				}
			});

			window.addEventListener('beforeunload', (event) => {
			  this.saveProject();
			});
    }
	}

	reconnectToWork(callback?:Function) {
		let projectId = globals.getProjectId();
		let designerId = globals.getDesignerId();
		api.refreshToken(() => { });
		let token = api.getToken();
		this.socket.emit('work', { projectId: projectId, designerId: designerId, token: token }, (data) => {
			if (data.success && data.json) {
				if (callback) {
					callback(data.json);
					this.setAutoSave();
				}
			} else {
				if (data.message) this.error(data.message);
				else this.error('Error while getting your project');
			}
		});
	}

  work (callback:Function) {
    let projectId = globals.getProjectId();
		let designerId = globals.getDesignerId();
		let token = api.getToken();
    // Updates the DB to know that someone is working
		this.socket.emit('work', { projectId, designerId, token }, (data) => {
      if (data.success && data.json) {
				this.firstConnection = false;
				callback(data.json);
				this.setAutoSave();
      } else {
				if (data.token_error)
					this.reconnectToWork(callback);
        else if (data.message) this.error(data.message);
        else this.error('Error while getting your project');
      }
    });
  }

  autoSaveInt:any;
  autoSaveSecond = 30; // REMETTRE A 30
  lastsave:any;
  setAutoSave () {
    this.lastsave = new Date().getTime();
    this.autoSaveInt = setInterval(() => {
      if (document.hasFocus()) {
        let now = new Date().getTime();
        if ( now - this.lastsave < this.autoSaveSecond * 800 ) return;
        this.lastsave = now;
        this.saveOnline();
      }
    }, this.autoSaveSecond * 1000);
  }

  errorshown = false;
  saveOnline (callback?:Function) {
    this.saveProject((saved) => {
      if (saved) console.log('project saved successfully');
      if (saved && callback) return callback();
      if (!saved) {
        this.errorshown = true;
        toastr.error('We currently have difficulties saving your project 😱, we will try again later 🕵️');
      } else if (this.errorshown) {
        this.errorshown = false;
        toastr.success('We manage to save your project again 👨‍🔧, you are good to go!');
      }
    });
  }

  // NOTE be careful to not put Array in projectjson (not working with changes)
  lastexport = {};
  saveProject (callback?:Function) {
    let projectjson = exporter.getProjectWithSummaryJson();

    if (Object.keys(projectjson.pointofviews).length == 0) {
      console.log(projectjson);
      callback(false);
      throw new Error("Can't save project, missing pointofview");
    }
    let inventories = exporter.getInventories();
    let fullProjectJson = merge(projectjson, inventories);
     // Nothing has changed and previus saved has worked
    if (isEqual(fullProjectJson, this.lastexport) && socketManager.saved) {
      return callback(true);
    }
    // FIXME Find a way to use only changes when saving
    // let changejson = undo.getDifference(exportjson, this.lastexport);
    // let deletejson = undo.getDifference(this.lastexport, exportjson);
    // let updatejson = {change:changejson, delete:deletejson};
    let summary = globals.getSummary();
    this.save(summary, fullProjectJson, callback)
    this.lastexport = fullProjectJson;
  }

  saved = true;
  failednumber = 0;
  save (summary:any, json:any, callback?:Function) {
    if (globals.getSave()) {
      let postJson = JSON.stringify(json);
      this.socket.emit('save', {user: summary.designerid, project:summary.projectid, json: postJson}, (data) => {
        callback(data.success);
        this.saved = data.success;
        if (data.success) this.failednumber = 0;
        else this.failednumber++;
        if (this.failednumber > 3) this.error("Error while saving your project");
      })
    }
  }

  error (text:string) {
		toastr.error('🤷 '+ text + ', you will be redirected to your dashboard');
		console.log(globals.getRedirect());
		if (globals.getRedirect()) {
			setTimeout(() => {
				window.location = '/dashboard/projects';
			}, 6000);
		}
  }
}

export let socketManager = new socketManagerClass();
