
import { scrollCatcherClass } from '../../viewer/service/scrollcatcher';
import { mouseCatcherClass } from '../../viewer/service/mousecatcher';
import { responsiveClass } from '../../viewer/service/responsive';
import { environmentClass } from '../../viewer/scenery/environment';
import { navigationClass } from '../../viewer/scenery/navigation';
import { pipelineClass } from '../../viewer/scenery/pipeline';
import { progressBarClass } from '../../viewer/scenery/progressbar';
import { virtualJoystickClass } from '../../viewer/scenery/virtualjoystick';
import { contentsManagerClass } from '../../viewer/contents/contentsManager';
import { hotspotsManagerClass } from '../../viewer/contents/hotspotsManager';
import { system } from '../../viewer/tools/system';

let container = document.getElementById('container');
export let _system = new system(container);
export let _responsive = new responsiveClass(_system);
export let _scrollCatcher = new scrollCatcherClass(_system, _responsive);
export let _mouseCatcher = new mouseCatcherClass( _system);
export let _pipeline = new pipelineClass(_system, _responsive);

export let hotspotsManager = new hotspotsManagerClass(_system, _scrollCatcher, _mouseCatcher, _responsive);
export let contentsManager = new contentsManagerClass(_system, hotspotsManager, _mouseCatcher, _scrollCatcher, _pipeline);

export let environment = new environmentClass(_system, contentsManager);
export let progressBar = new progressBarClass(_system, hotspotsManager, _scrollCatcher, _responsive);
export let virtualJoystick = new virtualJoystickClass(_system, _scrollCatcher);
export let navigation = new navigationClass(_system, _scrollCatcher, _mouseCatcher, _pipeline, progressBar);
