
import { system } from '../tools/system';
import { responsiveClass } from '../service/responsive';
import { scrollCatcherClass } from '../service/scrollcatcher';
import { mouseCatcherClass } from '../service/mousecatcher';
import { hotspotcontent } from './hotspot';

import remove from 'lodash/remove';

export class hotspotsManagerClass {

  _system:system;
  _scrollCatcher:scrollCatcherClass;
  _mouseCatcher:mouseCatcherClass;
  _responsive:responsiveClass;
  list:Array<hotspotcontent> = [];

  constructor (system:system, scrollCatcher:scrollCatcherClass, mouseCatcher:mouseCatcherClass, responsive:responsiveClass) {
    this._system = system;
    this._scrollCatcher = scrollCatcher;
    this._mouseCatcher = mouseCatcher;
    this._responsive = responsive;

    this.setEvents();
  }

  addHotspot (hotspot:hotspotcontent) {
    if (this.list.indexOf(hotspot) == -1) this.list.push(hotspot);
  }

  removeHotspot (hotspot:hotspotcontent) {
    remove(this.list, (o) => {return o.id === hotspot.id;});
  }

  started = false; // Used by Component List
  start () {
    for (let i = 0; i < this.list.length; i++) {
      let hotspot = this.list[i];
      hotspot.hide(); // Make sphere hidden
      hotspot.text.onHide = () => {
        this.checkVisibility(this._scrollCatcher.scrollpercentage);
        this._mouseCatcher.start();
        this._scrollCatcher.start();
      };

      hotspot.text.onShow = () => {
        hotspot.text.checkResponsive(this._responsive.screenratio, this._responsive.realwidth, this._responsive.realheight);
        this._mouseCatcher.stop();
        this._scrollCatcher.stop();
				this.hideHotspots();
				this.sendToListsteners(hotspot);
      };
    }

    this.checkStartVisibility();
    this.started = true;
  }

  stop () {
    this.started = false;
    for (let i = 0; i < this.list.length; i++) {
      let hotspot = this.list[i];
      hotspot.text.onHide = () => {};
      hotspot.text.onShow = () => {};
    }
  }

  setEvents () {
    this._scrollCatcher.addListener((top, perc) => {
      if (!this.started) return;
      this.checkVisibility(perc);
      this.checkLabelVisibility();
    });

    this._scrollCatcher.onMouseWheel = () => {
      if (!this.started) return;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].text.present) {
          this.list[i].text.fadeOut();
        }
      }
    }

    this._responsive.addListener((ratio, width, height, scale) => {
      if (!this.started) return; // keep it so that label stay correctly attach to mesh whatever
      for (let i = 0; i < this.list.length; i++) {
        let hotspot = this.list[i];
        if (hotspot.text.shown || hotspot.text.present) return hotspot.text.checkResponsive(ratio, width, height);
      }
      this.checkLabelVisibility();
    });
  }

  checkVisibility (perc:number) {
    for (let i = 0; i < this.list.length; i++) {
      let hotspot = this.list[i];
      if (perc >= hotspot.visibilityStart && perc <= hotspot.visibilityEnd) hotspot.fadeIn();
      else hotspot.fadeOut();
    }
  }

  checkStartVisibility () {
    for (let i = 0; i < this.list.length; i++) {
      let hotspot = this.list[i];
      if (hotspot.visibilityStart == 0) hotspot.fadeIn();
      else hotspot.hideLabel();
    }
  }

  ignoreResponsive = false;
  checkLabelVisibility (ignoreResponsive?:boolean) {
    // On mobile we make sure only one label is shown at a time
    if ((this._responsive.realwidth < 600 || this._responsive.realheight < 600) && !ignoreResponsive) {
      this.hideLabels();
      let lastVisible = this.getLastVisibleHotspot();
      if (lastVisible) lastVisible.showLabel(true);
    } else {
      for (let i = 0; i < this.list.length; i++) {
        let hotspot = this.list[i];
        hotspot.hideLabel(); // hide to force babylon to redraw correctly the label
        let hotspotvisiblebycamera = this._system.freecamera.isInFrustum(hotspot.pattern.mesh);
        if (hotspot.present && hotspotvisiblebycamera) hotspot.showLabel(false);
      }
    }
  }

  getLastVisibleHotspot () {
    let lastVisible:hotspotcontent;
    let visibilityStart = 0;
    let perc = this._scrollCatcher.scrollpercentage;
    for (let i = 0; i < this.list.length; i++) {
      let hotspot = this.list[i];
      if (perc >= hotspot.visibilityStart && perc <= hotspot.visibilityEnd) {
        if (hotspot.visibilityStart >= visibilityStart) {
          visibilityStart = hotspot.visibilityStart;
          lastVisible = hotspot;
        }
      }
    }
    return lastVisible;
  }

  hideHotspots () {
    for (let i = 0; i < this.list.length; i++) {
      this.list[i].fadeOut();
    }
  }

  hideLabels () {
    for (let i = 0; i < this.list.length; i++) {
      this.list[i].hideLabel();
    }
	}

	sendToListsteners(hotspot) {
		for (let i = 0; i < this.listeners.length; i++) {
			this.listeners[i](hotspot);
		}
	}

	listeners: Array<Function> = [];
	addListener(callback: Function) {
		this.listeners.push(callback);
	}

	removeListener(callback: Function) {
		remove(this.listeners, (c) => { c == callback });
	}
}
