
import { coreSystem } from '../../tools/coreSystem';
import { meshMaterialClass } from './meshmaterial';

import { Vector3 } from '@babylonjs/core/Maths/math';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import '@babylonjs/core/Audio/audioSceneComponent';
import '@babylonjs/core/Audio/audioEngine';
import { Sound } from '@babylonjs/core/Audio/sound';

export class sound extends meshMaterialClass {

  constructor (coreSystem:coreSystem) {
		super(coreSystem, {fogEnabled:false, lightEnabled:false, waterEnabled:false, shadowEnabled:false});
		this.key = Math.random().toString(36).substring(7);
    let mesh = MeshBuilder.CreateSphere(this.key+"soundsphere", {}, this._system.scene);
    this.setMesh(mesh);
    mesh.isVisible = false;
    this.setMaterialType('standard');
    this.setMaterialProperties({alpha: 0, metallic: 0, roughness: 1});

    this.mesh.position = new Vector3(0, 0, 0);
    return this;
  }

  sound:Sound;
  setSound (sound:Sound) {
    this.sound = sound;
  }

  setVolume (volume:number) {
    if (this.sound) this.sound.setVolume(volume);
    return this;
  }

  show () {
    if (this.sound) {
      this.sound.loop = true;
      this.sound.play();
    }
    this._show();
    return this;
  }

  hide () {
    if (this.sound) this.sound.stop();
    this._hide();
    return this;
  }

  destroy () {
    this.mesh.dispose();
    this.sound.dispose();
    return this;
  }
}
