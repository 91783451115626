
import {
  particlecomponent,
	shapecomponent,
  imagecomponent,
  videocomponent,
  modelcomponent,
  lightcomponent,
  groupcomponent,
  hotspotcomponent,
  heightmapcomponent,
  sphereHelperSize,
} from './componentlist';
import { contentInterface } from '../../viewer/contents/shared/content';
import { _system, contentsManager, hotspotsManager, _pipeline } from '../service/editintale';
import { component, componentList, componentChannel } from './component';
import { zoomManager, zoomzone } from '../dragzone/zoomManager';

import filter from 'lodash/filter';
import find from 'lodash/find';
import { Vector3 } from '@babylonjs/core/Maths/math'

componentChannel.subscribe("showtext", (data, envelope) => {
  if (componentManager.live) return;
  componentManager.hideHotspots();
  _pipeline.addCamera(zoomManager.cameraedit);
});

componentChannel.subscribe("hidetext", (data, envelope) => {
  if (componentManager.live) return;
  componentManager.showHotspots();
  _pipeline.removeCamera(zoomManager.cameraedit);
});

zoomzone.addEventListener("mousewheel", (evt) => {
  if (componentManager.mode == 'content') componentManager.checkLightsSize();
  if (componentManager.mode == 'hotspot') componentManager.checkHotspotsSize();
});

/*
  +------------------------------------------------------------------------+
  | COMPONENT MANAGER                                                          |
  +------------------------------------------------------------------------+
*/

export class componentManagerClass {

	setComponents (components:any) {
    for (let i = 0; i < components.length; i++) {
      let cd = components[i];
			this.addComponent(cd.type, cd.contentdata, cd.typedata);
    }
    for (let i = 0; i < componentList.length; i++) {
      if (componentList[i].content.type == 'group') componentList[i].addComponents();
    }
	}

	addComponentAnimation (id:string, animations:any) {
    let component = find(componentList, (o) => { return o.content.id == id });
		component.addAnimations(animations);
	}

  unselect () {
    for (let i = 0; i < componentList.length; i++) {
      let component = componentList[i];
      component.unselect();
    }
  }

  mode:'content'|'animation'|'hotspot';
  setMode (mode:'content'|'animation'|'hotspot') {
    // Keep showComponent at first so that lights and hotspots have the right size
    if (mode == 'content') this.showComponents(false);
    else this.showComponents(true);

    if (mode == 'content') this.setComponentMode();
    else if (mode == 'animation') this.setComponentMode();
    else if (mode == 'hotspot') this.setHotspotMode();
  }

  setComponentMode () {
    this.mode = 'content';
    this.hideHotspots();
    this.unselectHotspots();
    this.setMeshesForceListenEvent(true);
    this.hideTexts();
    this.showLights();
  }

  setHotspotMode () {
    this.mode = 'hotspot';
    this.unselectComponents();
    this.hideLights();
    this.setMeshesForceListenEvent(false);
    this.showHotspots();
  }

  getComponents ():Array<component> {
    return filter(componentList, (c) => {return c.content.type != 'hotspot'});
  }

	showComponents (animation:boolean) {
    let list = this.getComponents();
    for (let i = 0; i < list.length; i++) {
      // Do not use component.show() function here or it will set the visible value of the content
      if (list[i].content.visible) list[i].content.show();
      else list[i].content.hide();
      if (animation) list[i].restartAnimations();
      else list[i].stopAllAnimations();
		}
	}

	hideComponents () {
    let list = this.getComponents();
    for (let i = 0; i < list.length; i++) {
			list[i].content.hide(true);
		}
	}

  unselectComponents () {
    let list = this.getComponents();
    for (let i = 0; i < list.length; i++) {
      let component = list[i];
      component.unselect();
    }
  }

  deleteAllEvents () {
    for (let i = 0; i < componentList.length; i++) {
      componentList[i].deleteSelector();
    }
  }

  getHotposts ():Array<hotspotcomponent> {
    return filter(componentList, (c) => {return c.content.type == 'hotspot'});
  }

  checkHotspotsSize () {
    let radius = zoomManager.cameraedit.radius;
    let scale = sphereHelperSize * radius;

    let list = this.getHotposts();
    for (let i = 0; i < list.length; i++) {
      let hotspot = list[i];
      hotspot.content.pattern.setScaleAxis({x:scale, y:scale, z:scale});
    }
  }

  showHotspots () {
    hotspotsManager.checkLabelVisibility(true);
    let list = this.getHotposts();
    for (let i = 0; i < list.length; i++) {
      list[i].content.pattern.forcelistenEvent(true);
      list[i].show();
		}
	}

	hideHotspots () {
    let list = this.getHotposts();
    for (let i = 0; i < list.length; i++) {
      list[i].hide();
      list[i].content.pattern.forcelistenEvent(false);
		}
	}

  unselectHotspots () {
    let list = this.getHotposts();
    for (let i = 0; i < list.length; i++) {
      list[i].unselect();
    }
  }

  hideTexts () {
    let list = this.getHotposts();
    for (let i = 0; i < list.length; i++) {
      let component = list[i];
      component.content.hideText();
    }
  }

  getLights ():Array<lightcomponent> {
    return filter(componentList, (c) => {return c.content.type == 'light' && c.content.visible});
  }

  checkLightsSize () {
    let radius = zoomManager.cameraedit.radius;
    let scale = sphereHelperSize * radius;
    let lights = this.getLights();
    for (let i = 0; i < lights.length; i++) {
      let light = lights[i];
      light.content.pattern.setScaleAxis({x:scale, y:scale, z:scale});
    }
  }

  hideLights () {
    let lights = this.getLights();
    for (let i = 0; i < lights.length; i++) {
      let light = lights[i];
      light.content.pattern.setMaterialProperty('alpha', 0);
      if (light.selected) light.helper.hide();
    }
  }

  showLights () {
    this.checkLightsSize();
    let lights = this.getLights();
    for (let i = 0; i < lights.length; i++) {
      let light = lights[i];
      light.content.pattern.setMaterialProperty('alpha', 1);
      if (light.selected) light.helper.show();
    }
  }

  live = false;
  setLive () {
    this.hideTexts();
    this.hideLights();
    for (let i = 0; i < componentList.length; i++) {
      let component:component = componentList[i];
      if (component.content.type == 'hotspot') {
        component.content.listenEvent(true);
      } else {
        component.restartAnimations();
      }
    }
    this.setMeshesListenEvent(true);
    contentsManager.showContents();
    this.live = true;
  }

  unsetLive () {
    let list = this.getHotposts();
    for (let i = 0; i < list.length; i++) {
      list[i].content.listenEvent(false);
      list[i].content.hideLabel();
      list[i].content.present = false;
      // list[i].content.glowLayer.intensity = component.content.startglow * 5;
    }
    this.hideTexts();
    this.setMode(this.mode);
    this.setMeshesListenEvent(false);
    this.live = false;
  }

  setMeshesListenEvent (listen:boolean) {
    for (let i = 0; i < componentList.length; i++) {
      let component = componentList[i];
      let locked = component.content.locked;
      if (!locked && listen) component.content.pattern.listenEvent(listen);
      else component.content.pattern.listenEvent(false);
    }
  }

  setMeshesForceListenEvent (listen:boolean) {
    for (let i = 0; i < componentList.length; i++) {
      let component = componentList[i];
      let locked = component.content.locked;
      if (!locked && listen) component.content.pattern.forcelistenEvent(listen);
      else component.content.pattern.forcelistenEvent(false);
    }
  }

  getCreationPosition () {
    this.setMeshesForceListenEvent(true);
    var pickResult = _system.scene.pick(_system.engine.getRenderWidth()/2, _system.engine.getRenderHeight()/2);
    this.setMeshesListenEvent(false);
    let test;
    if (pickResult.hit) {
      let point = pickResult.pickedPoint;
      let dist = Vector3.Distance(zoomManager.cameraedit.globalPosition, point);
      test = zoomManager.cameraedit.getFrontPosition(dist - dist/5);
    } else {
      test = zoomManager.cameraedit.getFrontPosition(10);
    }
    return {x:test.x, y:test.y, z:test.z};
  }

	addComponent (type:string, content:contentInterface, typeStyle:any):component {
    // If component is new, special function to get its position
    if (content.position == null) content.position = this.getCreationPosition();
    if (!content.tag) {
      let cptype = filter(componentList, (c) => {return c.type == type});
      content.tag = type + ' ' +  (cptype.length + 1).toString();
    }
		let newcomponent;
	  switch (type) {
	    case 'particle':
	      newcomponent = new particlecomponent(content, typeStyle);
				break;
	    case 'shape':
	      newcomponent = new shapecomponent(content, typeStyle);
				break;
	    case 'image':
	      newcomponent = new imagecomponent(content, typeStyle);
				break;
	    case 'video':
	      newcomponent = new videocomponent(content, typeStyle);
				break;
	    case 'model':
	      newcomponent = new modelcomponent(content, typeStyle);
				break;
	    case 'light':
	      newcomponent = new lightcomponent(content, typeStyle);
				break;
	    case 'group':
	      newcomponent = new groupcomponent(content, typeStyle);
				break;
      case 'hotspot':
        newcomponent = new hotspotcomponent(content, typeStyle);
        break;
      case 'heightmap':
        newcomponent = new heightmapcomponent(content, typeStyle);
        break;
	  }
		return newcomponent;
	}
}

export let componentManager = new componentManagerClass();
