
import find from 'lodash/find';

class threedJsonHelperClass {

  correct (threedjson:any) {
    threedjson = this.correctPlugins(threedjson);
    threedjson.contents = this.correctContents(threedjson.contents);
    threedjson.pointofviews = this.correctPointofViews(threedjson.pointofviews);
    return threedjson;
  }

  temporaryscrollvalue:number;
  correctPlugins (threedjson:any) {
    if (threedjson.mask) threedjson.environment = threedjson.mask;

    if (threedjson.pageanim) threedjson.navigation = threedjson.pageanim;
    if (threedjson.navigation.ae == 'click') threedjson.navigation.ae = 'scroll';

    if (!threedjson.progressBar) threedjson.progressBar = {};
    if (!threedjson.loader) threedjson.loader = {};
    if (!threedjson.loader.color) threedjson.loader.c = threedjson.progressBar.c;

    this.temporaryscrollvalue = threedjson.navigation.adu * Object.keys(threedjson.pointofviews).length;

    return threedjson;
  }

  correctContents (contentsdata) {
    contentsdata = this.correctContentsType(contentsdata);
    // Need to create animation at the if animation has a target
    contentsdata = this.correctContentsAnimations(contentsdata);
    return contentsdata;
  }

  correctContentsType (contentsdata:any) {
    for (let key in contentsdata) {
      let contentdata = contentsdata[key];
      if (contentdata.tp == 'mesh') contentdata.tp = 'shape';
      if (contentdata.tp == 'light') {
        if (!contentdata.mo) contentsdata[key].mo = 'point';
        if (contentdata.ch === undefined) contentsdata[key].ch = true;
        if (contentdata.sd === undefined) contentsdata[key].sd = 0;
      }
      if (contentdata.tp == 'model') {
        if (contentdata.c) contentsdata[key].ma = {mabc:contentdata.c};
      }
      if (contentdata.tp == 'text') {
        let hotspotdata = find(contentsdata, (c) => {return c.tx == contentdata.i});
        if (hotspotdata === undefined) {
          delete contentsdata[key];
        } else {
          hotspotdata.tx = contentdata;
          delete contentsdata[key];
        }
      }
    }
    for (let key in contentsdata) {
      let contentdata = contentsdata[key];
      if (contentdata.tp == 'hotspot') {
        if (contentdata.tx == undefined) {
          delete contentsdata[key];
        } else {
          if (contentdata.tx.pip == undefined) {
            contentdata.tx.pip = {v:contentdata.tx.bgc, fl:contentdata.tx.fl}
          }
        }
      }
    }
    return contentsdata;
  }

  correctContentsAnimations (contentsdata:any) {
    for (let key in contentsdata) {
      let contentdata = contentsdata[key];
      if (contentdata.animations != undefined) {
        for (let animkey in contentdata.animations) {
          let anim = contentdata.animations[animkey];
          if (anim.ae == 'scroll') {
            if (anim.adu > 1) {
              contentdata.animations[animkey].ade = anim.ade / this.temporaryscrollvalue;
              contentdata.animations[animkey].adu = anim.adu / this.temporaryscrollvalue;
            }
          }
          if (anim.ao == undefined) contentdata.animations[animkey].ao = 0;
          if (anim.ade == undefined) contentdata.animations[animkey].ade = 0;
          if (anim.ac == undefined) contentdata.animations[animkey].ac = 'linear';
          if (anim.adu == undefined) contentdata.animations[animkey].adu = 1000000000000;
        }
      }
    }
    return contentsdata;
  }

  correctPointofViews (pointofviews:any) {
    return pointofviews;
  }

  recursiveJsonToObject (interfaceObject:Object, objectToTransale:Object) {
    let param:any = {};
    for (let key in interfaceObject) {
      let par = interfaceObject[key];
      let trad = par.dim;
      if (!trad) trad = par
      if (objectToTransale[trad] != undefined) {
        if (par.next != undefined) {
          param[key] = this.recursiveJsonToObject(par.next, objectToTransale[trad]);
        } else {
          param[key] = objectToTransale[trad];
        }
      }
    }
    return param;
  }

  recursiveObjectToJson (interfaceObject:Object, objectToTransale:Object) {
    let json = {};
    for (let key in interfaceObject) {
      if (objectToTransale[key] != undefined) {
        let par = interfaceObject[key];
        if (par.next != undefined) {
          json[par.dim] = this.recursiveObjectToJson(par.next, objectToTransale[key]);
        } else if (par.accuracy != undefined) {
          json[par.dim] = this.limitAccuracy(objectToTransale[key], par.accuracy);
        } else {
          json[par] = objectToTransale[key];
        }
      }
    }
    return json;
  }

  recursiveObjectToObject (interfaceObject:Object, objectToTransale:Object) {
    let json = {};
    for (let key in interfaceObject) {
      if (objectToTransale[key] != undefined) {
        let par = interfaceObject[key];
        if (par.next != undefined) {
          json[key] = this.recursiveObjectToObject(par.next, objectToTransale[key]);
        } else if (par.accuracy != undefined) {
          json[key] = this.limitAccuracy(objectToTransale[key], par.accuracy);
        } else {
          json[key] = objectToTransale[key];
        }
      }
    }
    return json;
  }

  limitAccuracy (number:number, length:number) {
    if (length == 1) return Math.round(number);
    let powLength = Math.pow(10, length);
    return Math.round(number*powLength)/powLength;
  }

}

export let threedJsonHelper = new threedJsonHelperClass();
