
import { pointofview, pointofviewInterface } from '../../viewer/scenery/pointofview';
import { selector } from '../dragzone/selector';
import { undoChannel } from '../service/undo';
import { pointofviewSight } from './pointofviewSight';
import { _system, navigation, _pipeline } from '../service/editintale';
import { pointofviewPreview } from '../journey/pointofviewPreview';

import postal from 'postal';
import find from 'lodash/find';

export let pointofviewsManager:any = {};
export let pointofviewsList = [];
export let permanentPointofviewsList = [];
export let pointofviewChannel = postal.channel("pointofview");
export let hoveredPointofview:pointofviewManager;
export let selectedPointofview:pointofviewManager;
export let currentpointofviewManager:pointofviewManager;

undoChannel.subscribe("pointofviewchange", (data, envelope) => {
  let pointofviewManager:pointofviewManager = find(permanentPointofviewsList, (o) => { return o.pointofview.name == data.who; });
	if (data.action == 'store') {
		pointofviewManager.store();
	} else if (data.action == 'unstore') {
		pointofviewManager.unstore();
	} else if (data.action == 'parameter') {
    pointofviewManager.setChange(data.pointofview);
	}
});

/*
  +------------------------------------------------------------------------+
  | POINT OF VIEW MANAGER                                                  |
  +------------------------------------------------------------------------+
*/

export class pointofviewManager extends selector {

	pointofview:pointofview;
  sight:pointofviewSight;

	availableAxis = {
		position : {x:1, y:1, z:1},
		rotation : {x:1, y:1, z:1},
		scale : {x:0, y:0, z:0},
		opacity : 0,
		hyperlink : 0,
	};
	availableOptions = {
		shadow: 0,
		play: 0,
		visible: 0,
		lock: 0,
	};

	constructor (pointofviewOptions:pointofviewInterface) {
		super();
		this.pointofview = navigation.addPointOfView(pointofviewOptions);
    this.sight = new pointofviewSight(this.pointofview.meshParent);
    this.pointofview.meshParent.mesh.layerMask = 0x10000000;

    pointofviewsManager[this.pointofview.name] = this;

    this.linkWithMesh(this.pointofview.meshParent);
    this.setText(this.pointofview.name);

    pointofviewsList.push(this);
    permanentPointofviewsList.push(this);
	}

  setChange (pointofview:pointofviewInterface) {
    if (pointofview.position) this.pointofview.setPosition(pointofview.position);
    if (pointofview.rotation) this.pointofview.setRotation(pointofview.rotation);
    if (pointofview.name) this.rename(pointofview.name);
    if (pointofview.pipeline) this.pointofview.setPipelineValues(pointofview.pipeline);
    if (this.selected) {
      pointofviewChannel.publish('change', this);
      _pipeline.setOption(this.pointofview.pipeline);
      navigation.showPointofview(this.pointofview);
    }
  }

	select () {
    if (this.selected) return;
		for (let key in pointofviewsManager) {
			if (key !== this.pointofview.name) pointofviewsManager[key].unselect();
		}
    this.selected = true;
    selectedPointofview = this;
		pointofviewChannel.publish('select', this);
		this.hideSelector();
		this.visibleSelector(false);
    this.sight.setSightLength(5);
    pointofviewPreview.show();
    navigation.showPointofview(this.pointofview);
    _pipeline.checkOption(this.pointofview.pipeline);
	}

	unselect () {
    if (!this.selected) return;
		this.selected = false;
		selectedPointofview = undefined;
		pointofviewChannel.publish('unselect', this);
		this.visibleSelector(true);
    this.sight.setSightLength(2);
    pointofviewPreview.hide();
	}

	showBox () {
		this.sight.show();
  }

	hideBox () {
    this.sight.hide();
	}

  show () {
		currentpointofviewManager = this;
		this.unselect();
		this.hideBox();
  }

  setHovered () {
    hoveredPointofview = this;
  }

  setNotHovered () {
    hoveredPointofview = null;
  }

	pointofviewnumber = 0;
	store () {
		let pointofviewname = this.pointofview.name;
		this.unselect();
    delete pointofviewsManager[pointofviewname];
    navigation._removePointOfView(this.pointofview);
		this.pointofview.meshParent.hide();
		this.hideBox();
    pointofviewChannel.publish('delete', this);
  }

  unstore () {
		let pointofviewname = this.pointofview.name;
    navigation._addPointOfView(this.pointofview);
    pointofviewsManager[pointofviewname] = this;
		this.pointofview.meshParent.show();
    this.showBox();
    pointofviewChannel.publish('undelete', this);
  }

  rename (newname:string) {
		let pointofviewname = this.pointofview.name;
    pointofviewsManager[newname] = pointofviewsManager[pointofviewname];
    delete pointofviewsManager[pointofviewname];
    navigation._removePointOfView(this.pointofview);
    this.pointofview.name = newname;
    navigation._addPointOfView(this.pointofview);
    this.setText(newname);
    pointofviewChannel.publish('rename', this);
  }
}
