
import { system } from '../tools/system';
import { animation } from '../tools/animation';
import { scrollCatcherClass } from '../service/scrollcatcher';
import { responsiveClass } from '../service/responsive';
import { progressBarInterface } from './progressbar';

import { Rectangle } from '@babylonjs/gui/2D/controls/rectangle'
import { Control } from '@babylonjs/gui/2D/controls/control'
import { TextBlock } from '@babylonjs/gui/2D/controls/textBlock'

export class virtualJoystickClass {

  _system:system;
  anim:animation;

  color:Array<number>;
  visible = true;

  constructor (system:system, scrollCatcher:scrollCatcherClass) {
    this._system = system;
    this.anim = new animation(this._system, 10);
    scrollCatcher.addListener((top, perc) => {
      if (perc != 0) this.stopArrowAnimation();
      // this.checkColor(scrollCatcher.step, scrollCatcher.mousegap.y);
    });

    scrollCatcher.on('stop', () => {
      this.stop();
    });
    scrollCatcher.on('start', () => {
      this.start();
    });

    this.createControl();
    this.startArrowAnimation();
    this.setStartDesign();
    this.setResponsiveScale(1);
  }

  init (progressBarOptions:progressBarInterface) {
    this.checkOption(progressBarOptions);
  }

  setOption (progressBarOptions:progressBarInterface) {
    this.setColor(progressBarOptions.color);
    this.setVisibility(progressBarOptions.visible);
  }

  checkOption (progressBarOptions:progressBarInterface) {
    if (progressBarOptions.color !== undefined) this.setColor(progressBarOptions.color);
    if (progressBarOptions.visible !== undefined) this.setVisibility(progressBarOptions.visible);
  }

  setVisibility (visible:boolean) {
    this.visible = visible;
  }

  container:Rectangle;
  createControl () {
    this.container = new Rectangle();
    this.container.thickness = 0;
    this.container.width = '40px';
    this.container.height = '100px';
    this.createButton();
  	this.createArrow(-20, 'top');
    this.createArrow(-27, 'top');
    this.createArrow(-34, 'top');
    this.createArrow(-41, 'top');
    this.createArrow(20, 'bottom');
    this.createArrow(27, 'bottom');
    this.createArrow(34, 'bottom');
    this.createArrow(41, 'bottom');
  }

  button:Rectangle;
  backbutton:Rectangle;
  text:TextBlock;
  createButton () {
    this.button = new Rectangle();
    this.button.thickness = 0;
    this.button.rotation = Math.PI/4;
    this.button.width = '12px';
    this.button.height = '12px';
    this.button.shadowBlur = 2;
    this.button.shadowColor = '#000';
    this.container.addControl(this.button);

    this.backbutton = new Rectangle();
    this.backbutton.thickness = 2;
    this.backbutton.rotation = Math.PI/4;
    this.backbutton.alpha = 0.8;
    this.backbutton.width = '20px';
    this.backbutton.height = '20px';
    this.backbutton.shadowBlur = 10;
    this.backbutton.shadowColor = '#000';
    this.container.addControl(this.backbutton);

    this.text = new TextBlock();
    this.text.text = 'Scroll To Explore';
    this.text.textWrapping = true;
    this.text.shadowBlur = 5;
    this.text.height = '200px';
    this.text.shadowColor = '#000';
    this.text.fontSize = '8px';
  }

  arrows:Array<Rectangle> = [];
  lines:Array<Rectangle> = [];
  createArrow (top:number, side:'top'|'bottom') {
    var control = new Rectangle();
    control.thickness = 0;
    control.width = '40px';
    control.height = '20px';

    var left = new Rectangle();
    left.thickness = 0;
    left.width = '16px';
    left.height = '3px';
    left.left = '5px';
    left.shadowBlur = 5;
    left.shadowColor = '#000';
    if (side == 'top') left.rotation = Math.PI/5;
    else left.rotation = -Math.PI/5;
    this.lines.push(left);
    control.addControl(left);

    var right = new Rectangle();
    right.thickness = 0;
    right.width = '16px';
    right.height = '3px';
    right.left = '-5px';
    right.shadowBlur = 5;
    right.shadowColor = '#000';
    if (side == 'top') right.rotation = -Math.PI/5;
    else right.rotation = Math.PI/5;
    this.lines.push(right);
    control.addControl(right);

    this.arrows.push(control);
    control.top = top + 'px';
    this.container.addControl(control);
  }

  arrowAnimationStarted = false;
  startArrowAnimation () {
    if (this.arrowAnimationStarted) return;
    this.arrowAnimationStarted = true;
    this.anim.infinite((count, perc) => {
      this.arrows[7].alpha = Math.max(Math.sin(count/10 + 0), this.normalalpha);
      this.arrows[6].alpha = Math.max(Math.sin(count/10 + Math.PI/4), this.normalalpha);
      this.arrows[5].alpha = Math.max(Math.sin(count/10 + Math.PI/2), this.normalalpha);
      this.arrows[4].alpha = Math.max(Math.sin(count/10 + 3 * Math.PI/4), this.normalalpha);
    });
  }

  setStartDesign () {
    this.container.removeControl(this.arrows[0]);
    this.container.removeControl(this.arrows[1]);
    this.container.removeControl(this.arrows[2]);
    this.container.removeControl(this.arrows[3]);
    this.container.removeControl(this.button);
    this.container.removeControl(this.backbutton);
    this.container.addControl(this.text);
    this.setScale(3);
    this.container.left = '0px';
    this.container.top = '0px';
    this.container.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    this.container.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
  }

  normal = false;
  setNormalDesign () {
    // if (!this.normal) {
    //   this.normal = true;
    //   // need to set rotation to 0 before changing alignment
    //   // orelse some lines can disappear
    //   for (let i = 0; i < this.lines.length; i++) {
    //     this.lines[i].rotationtemp = this.lines[i].rotation;
    //     this.lines[i].rotation = 0;
    //   }
    //   this.container.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    //   this.container.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    //   this.container.left = '20px';
    //   this.container.top = '-30px';
    //   this.container.addControl(this.arrows[0]);
    //   this.container.addControl(this.arrows[1]);
    //   this.container.addControl(this.arrows[2]);
    //   this.container.addControl(this.arrows[3]);
    //   this.container.addControl(this.button);
    //   this.container.addControl(this.backbutton);
    //   this.container.removeControl(this.text);
    //   this.setScale(1);
    //   for (let i = 0; i < this.lines.length; i++) {
    //     this.lines[i].rotation = this.lines[i].rotationtemp;
    //   }
    // }
    for (let i = 0; i < this.arrows.length; i++) {
      this.container.removeControl(this.arrows[i]);
    }
    this.container.removeControl(this.text);
  }

  stopArrowAnimation () {
    if (!this.arrowAnimationStarted) return;
    this.arrowAnimationStarted = false;
    this.anim.stop();
    this.anim.simple(20, (count, perc) => {
      if (perc < 0.5) {
        this.container.alpha = (0.5 - perc) * 2;
      } else {
        this.container.alpha = (perc - 0.5) * 2;
        this.setNormalDesign();
      }
    });
  }

  setColor (color:Array<number>) {
    this.color = color;
    let rgb = 'rgb('+color[0]+', '+color[1]+', '+color[2]+')';
    for (let i = 0; i < this.lines.length; i++) {
      this.lines[i].background = rgb;
    }
    this.button.background = rgb;
    this.backbutton.color = rgb;
    this.text.color = rgb;
    this.setNormalArrows();
  }

  normalalpha = 0.3;
  setNormalArrows () {
    for (let i = 0; i < this.arrows.length; i++) {
      this.arrows[i].alpha = this.normalalpha;
    }
    this.button.alpha = this.normalalpha;
  }

  checkColor (step:number, gap:number) {
    this.setNormalArrows();
    if (step > 0) {
      this.arrows[0].alpha = Math.max(Math.min(Math.abs(step), 1), this.normalalpha);
      this.arrows[1].alpha = Math.max(Math.min(Math.abs(step/3), 1), this.normalalpha);
      this.arrows[2].alpha = Math.max(Math.min(Math.abs(step/9), 1), this.normalalpha);
      this.arrows[3].alpha = Math.max(Math.min(Math.abs(step/27), 1), this.normalalpha);
    } else {
      this.arrows[4].alpha = Math.max(Math.min(Math.abs(step), 1), this.normalalpha);
      this.arrows[5].alpha = Math.max(Math.min(Math.abs(step/3), 1), this.normalalpha);
      this.arrows[6].alpha = Math.max(Math.min(Math.abs(step/9), 1), this.normalalpha);
      this.arrows[7].alpha = Math.max(Math.min(Math.abs(step/27), 1), this.normalalpha);
    }
    if (Math.abs(gap) > 1) this.button.alpha = 1;
    this.button.top = -gap/10 + 'px';
  }

  scale = 4;
  responsiveScale = 1;
  setResponsiveScale (scale:number) {
    // FIXME Arrow positionning not working
    for (let i = 0; i < this.arrows.length; i++) {
      this.arrows[i].topInPixels = this.arrows[i].topInPixels * this.responsiveScale/scale;
    }
    this.responsiveScale = scale;
    this.setCombineScale();
  }

  setScale (scale:number) {
    this.scale = scale;
    this.setCombineScale();
  }

  setCombineScale () {
    this.container.scaleY = this.responsiveScale * this.scale;
    this.container.scaleX = this.responsiveScale * this.scale;
  }

  setBarDesign (bar:Rectangle) {
    bar.isPointerBlocker = true;
    bar.hoverCursor = 'pointer';
    bar.thickness = 0;
  }

  start () {
    if (this.visible) {
      this._system.sceneAdvancedTexture.addControl(this.container);
    }
  }

  stop () {
    this._system.sceneAdvancedTexture.removeControl(this.container);
    this.setNormalArrows();
  }
}
