
import { coreSystem } from '../tools/coreSystem';
import { option3D } from '../tools/interface';
import { contentMove } from './shared/contentmove';
import { projectInterface } from '../service/projectInterface';
import { contentInterface } from './shared/content';
import { shape } from './patterns/shape';

import { Texture } from '@babylonjs/core/Materials/Textures/texture';

/*
  +------------------------------------------------------------------------+
  | IMAGE CONTENT                                                          |
  +------------------------------------------------------------------------+
*/

export interface imageStyle {
  url:string;
  coloroverlay?:Array<number>;
}

projectInterface.image = {
  url:'u',
  coloroverlay:'c',
}

export class imagecontent extends contentMove {

  type = 'image';

  image:any;
  imagewidth:number;
  imageheight:number;
  imageratio:number;
  texture:any;
  url:string;
  coloroverlay:Array<number>;
  pattern:shape;

  constructor (coreSystem:coreSystem, content:contentInterface, imageStyle:imageStyle) {
    super(coreSystem, content, 'plane');
    this.pattern.setMaterialType('standard');
    this.setTypeParameter(imageStyle);
  }

  setTypeParameter (imageStyle:imageStyle) {
    if (imageStyle.url !== undefined) this.loadImage(imageStyle.url);
    if (imageStyle.coloroverlay !== undefined) this.setColorOverlay(imageStyle.coloroverlay);
  }

  setShadow (shadow:boolean) {
    this.shadow = shadow;
    if (this.pattern.mesh.material) {
      if (shadow) {
        // this.pattern.deleteTexture('opacity');
        this.pattern.addShadow();
      } else {
        // this.pattern.setTexture('opacity', this.texture);
        this.pattern.removeShadow();
      }
    }
  }

  loadImage (url:string) {
    // TODO Do loading on plane when loading instead of all page to allow designer to continue
    this.url = url;
    if (url == undefined) return this.pattern.deleteTexture('albedo');
    this._system.loader.getImage(url, (asset) => {
      if (asset) {
        this.setImage(asset.texture);
        this.setShadow(this.shadow);
        if (this.shown) this.show();
        else this.hide();
      } else {
        this.pattern.deleteTexture('albedo');
        // this.pattern.deleteTexture('opacity');
      }
    });
  }

  setImage (texture:any) {
    if (this.texture !== undefined) this.texture.dispose();
    this.texture = texture.clone();
    this.pattern.setTexture('albedo', this.texture);
    // this.pattern.setTexture('opacity', this.texture);
    this.pattern.mesh.material.albedoTexture.hasAlpha = true;
    // CLAMP avoid border line to appear in albedo with alpha
    this.pattern.mesh.material.albedoTexture.wrapU = Texture.CLAMP_ADDRESSMODE;
    this.pattern.mesh.material.albedoTexture.wrapV = Texture.CLAMP_ADDRESSMODE;
    console.log(this.pattern.mesh)
    let imagesize = this.texture.getBaseSize(); // And not .getSize() because ratio will not be good on Edge and Safari
    this.imagewidth = imagesize.width;
    this.imageheight = imagesize.height;
    this.setScale({x:this.scale.x});
    if (this.coloroverlay !== undefined) this.setColorOverlay(this.coloroverlay);
    if (this.opacity !== undefined) this.setOpacity(this.opacity);
  }

  setColorOverlay (color:Array<number>) {
    this.coloroverlay = color;
    if (color) this.pattern.setTextureColor('albedo', color);
    else this.pattern.deleteTextureColor('albedo');
  }

  setScale (pos:option3D) {
    this.setVector('scale', pos);
    if (!this.imagewidth) this.imageratio = 1;
    else this.imageratio = this.imageheight/this.imagewidth;
    if (this.imageratio !== undefined) {
      if (pos.x !== undefined) this.scale.y = pos.x * this.imageratio;
      else if (pos.y !== undefined) this.scale.x = pos.y / this.imageratio;
      this.scale.z = 1; // Need scale to be at least 1 or image gets black
      this.pattern.setScaleAxis(this.scale);
    }
  }
}
