
import { scrollCatcherClass } from '../service/scrollcatcher';
import { mouseCatcherClass } from '../service/mousecatcher';
import { contentMove } from './shared/contentmove';

import { Vector2 } from '@babylonjs/core/Maths/math';
import remove from 'lodash/remove';

export class contentsTriggerClass {

  list:Array<contentMove> = [];

  constructor (mouseCatcher:mouseCatcherClass, scrollCatcher?:scrollCatcherClass) {
    mouseCatcher.addListener((mousepos:Vector2) => {
      // multiply by 10 so that it maches between real scene values and mouse screen position
      this.mousemoveContents(mousepos.x * 10, -mousepos.y * 10);
    });

    if (scrollCatcher) {
      scrollCatcher.addListener((scroll:number, scrollPercentage:number) => {
        this.scrollContents(scrollPercentage);
      });
    }
  }

  _addContent (content:contentMove) {
		if (this.list.indexOf(content) == -1) this.list.push(content);
  }

  _removeContent (content:contentMove) {
    remove(this.list, (o) => {return o.id === content.id;});
  }

  scrollContents (top:number) {
    for (let i = 0; i < this.list.length; i++) {
      let content = this.list[i];
      if (content.play) {
        for (let key in content.animations) {
          let animation = content.animations[key];
          if (animation.event == 'scroll' && !animation.forcestop) {
            content.animateScroll(animation, top);
          }
        }
      }
    }
  }

  mousemoveContents (x:number, y:number) {
    for (let i = 0; i < this.list.length; i++) {
      let content = this.list[i];
      if (content.play) {
        for (let key in content.animations) {
          let animation = content.animations[key];
          if (animation.event == 'move' && !animation.forcestop) {
            content.animateMouse(animation, x, y);
          }
        }
      }
    }
  }
}
