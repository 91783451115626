
import { colorpicker, assetpicker } from '../inputs/pickers';
import { editorLoadingScreen } from '../project/editorloadingscreen';
import { globals } from '../service/globals';
import { projectInterface } from '../../viewer/service/projectInterface';
import { socketManager } from './socket';
import { _system, environment, _pipeline, progressBar, virtualJoystick, contentsManager, navigation, _responsive } from './editintale';

import { componentManager } from '../component/componentmanager';
import { environmentSettings } from '../environment/environmentSettings';
import { progressBarSettings } from '../journey/progressBarSettings';
import { navigationManager } from '../journey/journeymanager';
import { pointofviewManager } from '../journey/pointofviewmanager';
import { topbar } from '../project/topbar';
import { scrollline } from '../project/bottombar';
import { materialSettings } from '../component/componentSettings';
import { animationInterface } from '../../viewer/contents/shared/contentmove';
import { materialInterface } from '../../viewer/contents/shared/contentmaterial';
import { animationProp } from '../animation/animationSettings';
import { undo } from './undo';
import { threedJsonHelper } from '../../viewer/service/threedjsonhelper';

class builderClass {

  createProject (project:any) {
    let desc:any = threedJsonHelper.recursiveJsonToObject(projectInterface.summary, project);

    editorLoadingScreen.addNakerTask();

    project = threedJsonHelper.correct(project);
    socketManager.lastexport = project;
    this.addInventories(project);
    this.addPlugins(project);
    _pipeline.start();

    this.addAllProjectComponents(project.contents);
    this.addAllProjectPointofViews(project.pointofviews);

    // Keep that at the end so that cameraedit is used
    globals.setProjectData(desc);
    topbar.start(desc.name);
    editorLoadingScreen.startEditorLoad(true, () => {
      contentsManager.showContents();
      _responsive.sendToListsteners();
      environment.checkFog();
      _system.launchRender();
      scrollline.updateLegend();
      topbar.checkStep();
      // Image size for instance are calculated once loaded
      // Wait everything is loaded before saving first State
      undo.saveState();
    });
    _system.loader.manager.load();
  }

  addInventories (project:any) {
    // Animations
    if (project.animations != undefined) {
      for (let key in project.animations) {
        let animation:animationInterface = threedJsonHelper.recursiveJsonToObject(projectInterface.animation, project.animations[key]);
        animationProp.addAnimationInInventory(key, animation);
      }
    }
    // Materials
    if (project.materials != undefined) {
      for (let key in project.materials) {
        let material:materialInterface = threedJsonHelper.recursiveJsonToObject(projectInterface.material, project.materials[key]);
        materialSettings.addMaterialInInventory(key, material);
      }
    }
    // Colors
    colorpicker.set(project.palette);
    assetpicker.initAllAsset(project.assetimages);
  }

  addPlugins (project:any) {
    let envdata = threedJsonHelper.recursiveJsonToObject(projectInterface.environment, project.environment);
    environment.init(envdata);
    environmentSettings.resetInput(envdata);

    let navdata = threedJsonHelper.recursiveJsonToObject(projectInterface.navigation, project.navigation);
    navigation.init(navdata);
    navigationManager.resetInput(navdata);

    let progressBardata:any = threedJsonHelper.recursiveJsonToObject(projectInterface.progressBar, project.progressBar);
    progressBar.init(progressBardata);
    virtualJoystick.init(progressBardata);
    progressBarSettings.resetInput(progressBardata);
  }

  addAllProjectPointofViews (pointofviews:any) {
    for (let key in pointofviews) {
      let pointofviewtranslated:any = threedJsonHelper.recursiveJsonToObject(projectInterface.pointofview, pointofviews[key]);
      new pointofviewManager(pointofviewtranslated);
    }
  }

  addAllProjectComponents (contentsdata) {
    this.addAllComponents(contentsdata);
    // Need to create animation at the if animation has a target
    this.addAllAnimations(contentsdata);
  }

  addAllComponents (contentsdata:any) {
    let components = [];
    for (let key in contentsdata) {
      let contentdata = contentsdata[key];
      let contenttranslated:any = threedJsonHelper.recursiveJsonToObject(projectInterface.content, contentdata);
      let type = contenttranslated.type;
      let contenttypetranslated = threedJsonHelper.recursiveJsonToObject(projectInterface[type], contentdata);
      components.push({type:type, contentdata:contenttranslated, typedata:contenttypetranslated});
    }
    componentManager.setComponents(components);
  }

  addAllAnimations (contentsdata:any) {
    for (let key in contentsdata) {
      let contentdata = contentsdata[key];
      if (contentdata.animations != undefined) {
        let contentanimations = {};
        for (let animkey in contentdata.animations) {
          contentanimations[animkey] = threedJsonHelper.recursiveJsonToObject(projectInterface.animation, contentdata.animations[animkey]);
        }
        componentManager.addComponentAnimation(key, contentanimations);
      }
    }
  }

  loadProject (callback?:Function) {
    socketManager.work((data) => {
      if (!data) return callback(false);
      if (data.success != undefined) return callback(false);
      this.createProject(data);
      if (callback) callback(data);
    });
  }
}

export let builder = new builderClass();
