
import { coreSystem } from '../tools/coreSystem';
import { point3D } from '../tools/interface';
import { contentInterface } from './shared/content';
import { contentMove } from './shared/contentmove';
import { meshparent } from './patterns/meshparent';
import { projectInterface } from '../service/projectInterface';

import filter from 'lodash/filter';
import { Vector3 } from '@babylonjs/core/Maths/math';


export interface groupStyle {
  key:string;
  contents:Array<string>;
  panelopen:boolean;
}

projectInterface.group = {
  key:'g',
  contentids:'c',
  panelopen:'po',
}

export class groupcontent extends contentMove {

  type = 'group';
  pattern:meshparent;

  contentids:Array<string> = [];
  panelopen = false;

  contentsnumber = 0;
  contents:Array<contentMove> = [];
  group = true;

  validated = true; // For multiselect purpose, we create temporary groupcomponent, validated allow us to check if group was indeed created

  constructor (coreSystem:coreSystem, content:contentInterface, groupStyle:groupStyle) {
    super(coreSystem, content, 'group');

    this.groupkey = groupStyle.key;
    this.pattern.setScaleAxis = (axis:point3D) => {
      this.pattern.mesh.scaling = new Vector3(1, 1, 1);
      return this.pattern;
    }
    if (groupStyle.panelopen !== undefined) this.panelopen = groupStyle.panelopen;
    this.setContentParameter(content, true);
  }

  addContents (contentList:Array<contentMove>) {
    let contents = this.getContents(contentList, this.groupkey);
    // If group is empty empty
    if (contents.length == 0) return;
    for (let i = 0; i < contents.length; i++) {
      this.addContentGroupValues(contents[i]);
      this.pattern.addMesh(contents[i].pattern);
    }
    this.getContentsNewPosition();
  }

  getContents (contentList:Array<contentMove>, groupkey:string) {
    return filter(contentList, (o) => { return o.type != 'group' && o.groupkey == groupkey; });
  }

  addContent (content:contentMove) {
    // In case unstored
    if (this.contents.indexOf(content) == -1) {
      this.addContentGroupValues(content);
      this.pattern.addMeshWithNewBounds(content.pattern);
      this.getContentsNewPosition();
    }
  }

  addContentGroupValues (content:contentMove) {
    content.ingroup = true;
    content.groupcontent = this;
    content.groupkey = this.groupkey;
    if (this.contents.indexOf(content) == -1) this.contents.push(content);
    if (this.contentids.indexOf(content.id) == -1) this.contentids.push(content.id);
  }

  getContentsNewPosition () {
    for (let i = 0; i < this.contents.length; i++) {
      let pos = this.contents[i].pattern.getPosition();
      this.contents[i].setPosition(pos);
    }
  }

  getGroupNewPosition () {
    let newpos = this.pattern.getPosition();
    this.setPosition(newpos);
  }

  setContentsParam (what:string, value?:any) {
    if (this.contents == undefined) return; // for functions called during content construction
    for (let i = 0; i < this.contents.length; i++) {
      let content:contentMove = this.contents[i];
      content[what](value);
    }
  }

  separateAllContents () {
    this.pattern.separateAllMeshes();
    for (let i = 0; i < this.contents.length; i++) {
      let content = this.contents[i];
      this.removeContentGroupValues(content);
    }
    this.getContentsNewPosition();
    this.contents = [];
    this.contentids = [];
  }

  removeContentGroupValues (content:contentMove) {
    content.ingroup = false;
    content.groupkey = '';
    content.groupcontent = undefined;
  }

  removeContentFromList (content:contentMove) {
    let index = this.contentids.indexOf(content.id);
    this.contentids.splice(index, 1);
    let index2 = this.contents.indexOf(content);
    this.contents.splice(index2, 1);
  }

  // Splice important because separate all loop on this.contents so when must not splice in that case
  separateContent (content:contentMove) {
    this.pattern.separateMeshWithNewBounds(content.pattern);
    this.getContentsNewPosition();
    if (content.visible) content.show();
    if (content.play) content.playAnimation();
    this.removeContentGroupValues(content);
    this.removeContentFromList(content);
  }

  addContentsGroupKey () {
    for (let i = 0; i < this.contents.length; i++) {
      this.contents[i].groupkey = this.groupkey;
    }
  }

  removeContentsGroupKey () {
    for (let i = 0; i < this.contents.length; i++) {
      this.contents[i].groupkey = '';
    }
  }

  hide () {
    this._hideMove();
    return this;
  }

  show () {
    this._showMove();
    return this;
  }
}
