
import { api } from '../service/api';
import { globals } from '../service/globals';
import { undo } from './undo';
import { projectInterface } from '../../viewer/service/projectInterface';
import { animationProp } from '../animation/animationSettings';
import { materialSettings } from '../component/componentSettings';
import { colorpicker, assetpicker } from '../inputs/pickers';
import { navigation, _system } from './editintale';
import { threedJsonHelper } from '../../viewer/service/threedjsonhelper';

import merge from 'lodash/merge';
import gifshot from 'gifshot';
import { Tools } from '@babylonjs/core/Misc/tools';
import { VideoRecorder } from '@babylonjs/core/Misc/videoRecorder';

class exporterClass {
// Problem: I need the project's ID to make changes in the database
  element:any;
  socket:any;
  constructor () {
    this.element = document.createElement('a');
  }

  getProjectWithSummaryJson () {
    let projectjson = undo.getProjectJson();
    let summary = globals.getSummary();
    let summaryjson = threedJsonHelper.recursiveObjectToJson(projectInterface.summary, summary);
    let json = merge(summaryjson, projectjson);
    return json;
  }

  local () {
    let json = this.getProjectWithSummaryJson();
    let inventories = this.getInventories();
    json = merge(json, inventories);
    this.localupload(json);
  }

  publish (callback?:Function) {
    let publishjson = this.getProjectWithSummaryJson();
    let postJson = JSON.stringify(publishjson);

    let header = {'Content-Type': 'application/json; charset=utf-8'};
    api.post('v1/project/publish', {project:globals.projectid}, {header:header, body:postJson}, (data) => {
      if (callback) callback(data.success);
    });
  }

  getInventories () {
    let inventoryJson:any = {};
    // Animations
    let projectanimations = {};
    for (let key in animationProp.animationInventoryList) {
      let animation = animationProp.animationInventoryList[key];
      let animationtrad = threedJsonHelper.recursiveObjectToJson(projectInterface.animation, animation);
      projectanimations[key] = animationtrad;
    }
    inventoryJson.animations = projectanimations;
    // Materials
    let projectmaterials = {};
    for (let key in materialSettings.materialInventoryList) {
      let material = materialSettings.materialInventoryList[key];
      let materialtrad = threedJsonHelper.recursiveObjectToJson(projectInterface.material, material);
      projectmaterials[key] = materialtrad;
    }
    inventoryJson.materials = projectmaterials;

    inventoryJson.assetimages = assetpicker.assetImages;
    inventoryJson.palette = colorpicker.palette;
    return inventoryJson;
  }

  localupload (json) {
    let dataStr = JSON.stringify(json);
    let dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);

    let exportFileDefaultName = 'data.json';

    this.element.setAttribute('href', dataUri);
    this.element.setAttribute('download', exportFileDefaultName);
    this.element.click();
  }

  getProjectGif (callback:Function) {
    this.getImageList(10, 3, (images) => {
      gifshot.createGIF({
        images: images,
        gifWidth: this.imageWidth,
        gifHeight: this.imageHeight,
      }, (obj) => {
        if (!obj.error) {
        } else {
          var gif = obj.image;
          callback(gif);
        }
      });
    });
  }

  getProjectVideo (name:string, callback:Function) {
    if (VideoRecorder.IsSupported(_system.engine)) {
      var recorder = new VideoRecorder(_system.engine);
      if (!name) name = 'naker';
      console.log(name+".webm")
      recorder.startRecording(name+".webm", 5).then(() => {
        callback(true);
      });
    } else {
      callback(false, 'Hum 😑, video compilation is not supported by your browser');
    }
  }

  imageHeight = 300;
  imageWidth = 425;
  getImageList (frame:number, time:number, callback:Function) {
    let images = [];
    // Make sure there is only one camera or screenshot doesn't work
    _system.scene.activeCamera = _system.freecamera;
    _system.scene.activeCameras = [];
    setTimeout(()=>{
      Tools.CreateScreenshotUsingRenderTarget(_system.engine, _system.freecamera, {width:this.imageWidth, height:this.imageHeight}, (image) => {
        images.push(image);
      });
    });
    navigation.setPointofviewChangeEvent();
    let imgInterval = setInterval(() => {
      Tools.CreateScreenshotUsingRenderTarget(_system.engine, _system.freecamera, {width:this.imageWidth, height:this.imageHeight}, (image) => {
        images.push(image);
      });
    }, 1000/frame);

    setTimeout(() => {
      clearInterval(imgInterval);
      callback(images)
    }, time);
  }

  getProjectImage (callback:Function) {
    setTimeout(() => {
      // Make sure there is only one camera or screenshot doesn't work
      _system.scene.activeCamera = _system.freecamera;
      _system.scene.activeCameras = [];
      Tools.CreateScreenshotUsingRenderTarget(_system.engine, _system.freecamera, {width:this.imageWidth, height:this.imageHeight}, (image) => {
        callback(image);
      });
    }, 200);
  }

  setProjectImage (projectid:string, callback:Function) {
    this.getProjectImage((image) => {
      this.uploadFile(projectid, image, callback);
    });
  }

  uploadFile (projectid:string, image:string, callback:Function) {
    var fd = new FormData();
    fd.append("image", image);
    const header = { "X-Requested-With": "XMLHttpRequest", "Content-Type": "multipart/form-data" };
    api.post('v1/project/imageupdate', {id:projectid}, {body:fd, header:header}, (data) => {
      callback(data.success, data.image);
    });
  }
}

export let exporter = new exporterClass();
