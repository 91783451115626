
import { el, setStyle, mount } from 'redom';

export class aboveLayerClass {

    container:HTMLElement;
    title:HTMLElement;

    constructor () {
      this.container = el('div.abovelayer',
        el('div.abovelayer-container',
          this.title = el('div.abovelayer-title'),
        ),
      );

      mount(document.body, this.container);
    }

    show (text:string) {
      setStyle(this.container, {display:'block'});
      this.title.innerHTML = text;
    }

    hide () {
      setStyle(this.container, {display:'none'});
    }
}

export let aboveLayer = new aboveLayerClass();
