
import { layerRight, layerRightList } from '../inputs/layer';
import { ui_textinput, ui_button } from '../inputs/input';
import { colorthirdgrey, colorgrey } from '../service/colors';
import { undo } from '../service/undo';
import { pointofviewManager, pointofviewsManager, pointofviewChannel } from '../journey/pointofviewmanager';
import { mainSettings } from '../settings/mainSettings';
import { component, componentChannel } from '../component/component';
import { componentManager } from '../component/componentmanager';
import { groupSettings } from '../component/componentSettings';
import { animationButtons, animationParam, animationProp } from '../animation/animationSettings';
import { pipelineSettings } from './pipelineSettings';

import toastr from 'toastr'
import { el, setStyle, setAttr, setChildren, mount } from 'redom';
import clone from 'lodash/clone';

componentChannel.subscribe("select", (component:component, envelope) => {
  tagManager.setComponent(component);
});

componentChannel.subscribe("new", (component:component, envelope) => {
  tagManager.setComponent(component);
});

componentChannel.subscribe("unselect", (component, envelope) => {
  tagManager.component = undefined;
  animationButtons.setRealPlay();
  if (tagManager.mode == 'animation') {
    component.restartAnimations();
  }
});

componentChannel.subscribe("change", (component:component, envelope) => {
  tagManager.setComponent(component);
});

componentChannel.subscribe("rename", (component:component, envelope) => {
  tagManager.tag.setValue(component.content.tag);
});

pointofviewChannel.subscribe("select", (pointofviewManager:pointofviewManager, envelope) => {
  tagManager.setpointofviewManager(pointofviewManager);
});

pointofviewChannel.subscribe("change", (pointofviewManager:pointofviewManager, envelope) => {
  tagManager.setpointofviewManager(pointofviewManager);
  pipelineSettings.setEntity(pointofviewManager.pointofview);
});

/*
  +------------------------------------------------------------------------+
  | TAG MANAGER                                                          |
  +------------------------------------------------------------------------+
*/

export class tagManagerClass {

  control:HTMLElement
  deletebutton:HTMLElement;
  duplicatebutton:HTMLElement;
  tag:ui_textinput;

  constructor () {
    this.control = el('div.tag-container',
      el('div.tag-option-container',
        [
          el('div.tag-option-button.right-icon.icon-close', {onclick:()=>{this.unselect();}},
            [el('span.path1'),el('span.path2'),el('span.path3')]
          ),
          this.deletebutton = el('div.tag-option-button.right-icon.icon-delete', {onclick:()=>{this.deleteSelected();}},
            [el('span.path1'),el('span.path2'),el('span.path3')]
          ),
          this.duplicatebutton = el('div.tag-option-button.right-icon.icon-duplicate', {onclick:()=>{this.duplicateSelected()}},
            [el('span.path1'),el('span.path2'),el('span.path3')]
          )
        ]
      )
    );
    this.createTagInput();
    mount(layerRight, this.control);

    // Ungroup Function = Delete group Component
    groupSettings.groupStatusChange((test) => {
      if (test) {
        componentChannel.publish('new', this.component);
        undo.pushState();
      } else {
        this.deleteComponent();
      }
    });
  }

  validate:ui_button;
  focustag:string;
  createTagInput () {
    this.tag = new ui_textinput(this.control, '', 'tag-input');
    setAttr(this.tag.el, {maxlength:30});
    setStyle(this.tag.el, {background:colorgrey});
    this.tag.on('focus', (text) => {
      this.focustag = text;
      setStyle(this.tag.el, {background:colorthirdgrey});
      if (text == '') this.tag.setPlaceholder('');
    });
    let timeout:any;
    this.tag.on('input', (text) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.tag.el.blur();
      }, 4000);
    });
    this.tag.on('change', (text) => {
      if (timeout) clearTimeout(timeout);
      this.tag.el.blur();
    });
    this.tag.on('blur', (text) => {
      if (text != this.focustag) this.namechange(text)
      setStyle(this.tag.el, {background:colorgrey});
    });
  }

  namechange (text:string) {
    if (this.component) {
      this.component.rename(text);
      if (this.component.type == 'hotspot') this.component.content.text.setTitle(text);
      undo.pushState();
    } else if (this.pointofviewManager) {
      if (pointofviewsManager[text] != undefined) {
        toastr.error('🙅‍, name already used for another point of view');
        this.tag.setValue(this.focustag);
      } else {
        this.pointofviewManager.rename(text);
        undo.pushState();
      }
    }
  }

  unselect () {
    if (this.component) {
      this.component.unselect();
    } else if (this.pointofviewManager) {
      this.pointofviewManager.unselect();
    }
  }

  deleteSelected () {
    if (this.component) {
      this.deleteComponent();
    } else if (this.pointofviewManager) {
      this.deletePointofview();
    }
  }

  mode:'content'|'animation'|'hotspot' = 'content';
  setMode (mode:'content'|'animation'|'hotspot') {
    this.mode = mode;
    if (this.component) this.setComponent(this.component);
  }

  component:component;
  setComponent (component:component) {
    this.pointofviewManager = undefined;
    this.component = component;
    if (component.content.tag) this.tag.setValue(component.content.tag);
    else this.tag.setPlaceholder('Enter tag');
    if (this.component.type == 'group') setStyle(this.duplicatebutton, {display:'none'});
    else setStyle(this.duplicatebutton, {display:'block'});

    this.component.stopAllAnimations();
    setChildren(layerRight, [this.control, layerRightList]);
    if (this.mode == 'content' || this.mode == 'hotspot') {
      mainSettings.setComponent(this.component);
      setChildren(layerRightList, [mainSettings.control.el, this.component.manager.control.el]);
      if (this.component.secondmanager) mount(layerRightList, this.component.secondmanager.control.el);
    } else {
      animationButtons.setComponent(this.component);
      setChildren(layerRightList, [animationButtons.control.el, animationParam.control.el, animationProp.control.el]);
    }
  }

  duplicateSelected () {
    if (this.component) this.duplicateComponent();
    else if (this.pointofviewManager) this.duplicatePointofviewManager();
  }

  duplicateComponent () {
    // Check if compoentn for shortcut call
    if (this.component) {
      if (this.component.type == 'group') return;
      let clonedcomponent = this.cloneComponent(this.component);
      clonedcomponent.newComponent();
    }
  }

  duplicatePointofviewManager () {
    // Check if compoentn for shortcut call
    if (this.pointofviewManager) {
      let nb = Object.keys(pointofviewsManager).length + 1;
      let pointofviewname = 'Point of view '+nb;
      while (pointofviewsManager[pointofviewname] != undefined) { // To make sure we don't have twice the same name
        pointofviewname = 'Point of view '+nb;
        nb++;
      }
      let pr = this.pointofviewManager.pointofview.rotation;
      let newrotation = {x:pr.x, y:pr.y, z:pr.z};
      let pp = this.pointofviewManager.pointofview.position;
      let newposition = {x:pp.x + 0.5, y:pp.y, z:pp.z};
      let index = this.pointofviewManager.pointofview.panelindex + 0.5;
      let pipeline = clone(this.pointofviewManager.pointofview.pipeline);
      let newpointofview = new pointofviewManager({name:pointofviewname, position:newposition, rotation:newrotation, panelindex:index, pipeline:pipeline});
      newpointofview.showBox();
      pointofviewChannel.publish('added', newpointofview);
    }
  }

  cloneComponent (component:component) {
    let clonedata = component.getCloneData();
    let clonedcomponent = componentManager.addComponent(clonedata.type, clonedata.content, clonedata.typestyle);
    clonedcomponent.addAnimations(clonedata.content.animations);
    return clonedcomponent;
  }

  deleteComponent () {
    // Check if compoentn for shortcut call
    if (this.component) {
      this.component.setNotHovered();
      this.component.store();
      undo.pushState();
    }
  }

  pointofviewManager:pointofviewManager;
  setpointofviewManager (pointofviewManager:pointofviewManager) {
    this.component = undefined;
    this.pointofviewManager = pointofviewManager;
    let pointofview = pointofviewManager.pointofview;
    if (pointofview.name) this.tag.setValue(pointofview.name);
    else this.tag.setPlaceholder('Enter point of view name');
    setStyle(this.duplicatebutton, {display:'block'});
    mainSettings.setpointofviewManager(pointofviewManager);
    pipelineSettings.setEntity(pointofviewManager.pointofview);
    setChildren(layerRight, [this.control, layerRightList]);
    setChildren(layerRightList, [mainSettings.control.el, pipelineSettings.control.el]);
  }

  deletePointofview () {
    let pointofviewnumber = Object.keys(pointofviewsManager).length;
    if (pointofviewnumber == 2) return toastr.error('Sorry, but you need to keep at least two pointofview to make a journey');
    this.pointofviewManager.setNotHovered();
    this.pointofviewManager.store();
    undo.pushState();
  }
}

export let tagManager = new tagManagerClass();
