
import { animation } from '../tools/animation';
import { system } from '../tools/system';
import { projectInterface } from '../service/projectInterface';

import { el, mount, setStyle } from 'redom';

export interface loaderInterface {
  container:any;
  loadervisible?:boolean;
  color?:Array<number>;
}

projectInterface.loader = {
  loadervisible:'v',
  color:'c',
}

export class loadingScreenClass {

  _system:system;
  anim:animation;

  progressbar:HTMLElement;
  loadingText:HTMLElement;
  progressbarContainer:HTMLElement;
  container:HTMLElement;
  shown = true;

  loadervisible = true;
  color:Array<number>;

  constructor (system:system) {
    this._system = system;
    this.anim = new animation(this._system);
    this.build();
  }

  init (loaderOptions:loaderInterface) {
    if (loaderOptions.loadervisible != undefined) this.loadervisible = loaderOptions.loadervisible;
    if (loaderOptions.color != undefined) this.setColor(loaderOptions.color);
    if (loaderOptions.container && loaderOptions.loadervisible) {
      mount(loaderOptions.container, this.container);
    }
  }

  setColor (color:Array<number>) {
    this.color = color;
    setStyle(this.loadingText, {color:'rgb('+color[0]+','+color[1]+','+color[2]+')'});
    setStyle(this.progressbar, {background:'rgb('+color[0]+','+color[1]+','+color[2]+')'});
  }

  build () {
    this.container = el('div', {style:{position:'absolute', top: '0px', left: '0px', width: '100%', height: '100%', 'overflow-y': 'hidden !important', 'overflow-x': 'hidden !important', outline:'none', 'z-index':100000, background:'rgba(0,0,0,0)'}});
    let loadercontainer = el('div', {style:{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)'}});
    mount(this.container, loadercontainer);
    this.loadingText = el('div', {style:{position:'relative', height:'auto', width:'80%', left:'10%', 'font-family':'Arial', 'text-align':'center', color:'#7A8EE8', 'text-shadow': '#000 0px 0 10px'}});
    mount(loadercontainer, this.loadingText);

    this.progressbarContainer = el('div', {style:{position:'relative', background:'#000', width:'200px', 'max-width':'500px', 'margin-top':'15px', left:'50%', transform:'translateX(-50%)', 'border-radius':'20px'}});
    mount(loadercontainer, this.progressbarContainer);
    this.progressbar = el('div', {style:{position:'relative', background:'#7A8EE8', width:'0%', height:'100%', 'border-radius':'20px'}});
    mount(this.progressbarContainer, this.progressbar);
    this.setSize(1);
  }

  ratio = 1;
  setSize (ratio:number) {
    this.ratio = ratio;
    setStyle(this.loadingText, {'font-size':(ratio * 30).toString()+'px'});
    setStyle(this.progressbarContainer, {height:(ratio * 10).toString()+'px'});
  }

  loading = false;
  callback:Function;
  timeout:any;
  startLoad (text:string) {
    if (!this.shown) return;
    if (Object.keys(this._system.loader.successes).length == 0 ) return this.success();
    this.loadingText.textContent = text;
    setStyle(this.loadingText, {'display':'block'});
    this.loading = true;
    this._system.loader.manager.onProgress = (remainingCount, totalCount, lastFinishedTask) => {
      // In case of error we stop showing success
      if (this.loading) {
        this.animate(1 - remainingCount / totalCount);
      }
    };
  }

  animate (howmuch:number) {
    setStyle(this.progressbar, {width:(howmuch * 100).toString()+'%'});
  }

  show (text?:string) {
    this.shown = true;
    setStyle(this.container, {display:'block', opacity:1});
    this.loadingText.textContent = text;
    setStyle(this.loadingText, {'display':'block'});
  }

  // Make sure responsive check for value once loading finish
  hide (callback?:Function) {
    if (this.shown) {
      // setTimeout(() => {
        // this.pearl.dispose();
        // this.blackhole.dispose();
      // }, 1000);
      this.hideText(callback);
    }
  }

  hideText (callback?:Function) {
    this.anim.simple(20, (count, perc) => {
      setStyle(this.container, {opacity:1 - perc});
    }, () => {
      setStyle(this.container, {display:'none'});
      if (callback) callback();
    });
  }

  success (callback?:Function) {
    this.hide(callback);
    if (!this.loading || !this.shown) return;
    this.loading = false;
    this.animate(1);
    this._system.loader.resetLoadWaiting();
  }

  errorLoader (error:string) {
    this.loading = false;
    this.animate(0);
    this.loadingText.textContent = error;
    this._system.loader.resetLoadWaiting();
    this._system.loader.manager.onFinish = () => {};
  }

  // Tried to have a pearl in loader but doesn't have good fps while loading
  // pearl:any;
  // pearlpoints:any;
  // space:any;
  // blackhole:any;
  // setPearl () {
  //   if (!this.shown) return; // For option loader
  //   this.pearl = MeshBuilder.CreateSphere("pearl", {segments:5}, scene);
  //   this.pearl.scaling = new Vector3(2 * environment.skySize, 2 * environment.skySize, 2 * environment.skySize);
  //   this.pearl.material = new StandardMaterial('pearlmaterial', scene);
  //   this.pearl.material.diffuseColor = Color3.FromHexString('#7A8EE8');
  //   this.pearl.convertToFlatShadedMesh();
  //   this.pearlpoints = this.pearl.getVerticesData(VertexBuffer.PositionKind);
  //   scene.clearColor = Color3.FromHexString(colordarkgrey);
  //
  //   // LIGHT
  //   this.blackhole = new DirectionalLight("light", new Vector3(0, 0, 1), scene);
  //   this.blackhole.position = camera.position;
  //   this.blackhole.specular = new Color3(0, 0, 0);
  //   this.blackhole.direction = new Vector3(0, -1, 1);
  //
  //   camera.position= new Vector3(0, 6 * environment.skySize, -7 * environment.skySize);
  //   camera.rotation= new Vector3(0.7, 0, 0);
  //
  //   var animationPearl = new Animation("yuiyui", "rotation.y", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
  //   var keys = [];
  //   //At the animation key 0, the value of scaling is "1"
  //   keys.push({
  //     frame: 0,
  //     value: 0
  //   });
  //   keys.push({
  //     frame: 10,
  //     value: Math.PI
  //   });
  //   animationPearl.setKeys(keys);
  //   this.pearl.animations = [];
  //   this.pearl.animations.push(animationPearl);
  //   scene.beginAnimation(this.pearl, 0, 100, true);
  // }
  //
  // updatePearl (perc:number) {
  //   let scale = (2 + 3 * perc) * environment.skySize;
  //   let newScale = new Vector3(scale, scale, scale);
  //   var animationPearl = new Animation("sblightanim", "scaling", 30, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
  //   var keys = [];
  //   //At the animation key 0, the value of scaling is "1"
  //   keys.push({
  //     frame: 0,
  //     value: this.pearl.scaling
  //   });
  //   keys.push({
  //     frame: 10,
  //     value: newScale
  //   });
  //   animationPearl.setKeys(keys);
  //   this.pearl.animations = [];
  //   this.pearl.animations.push(animationPearl);
  //   scene.beginAnimation(this.pearl, 0, 10, false);
  // }
}
