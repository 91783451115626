
import { ui_container } from './group';
import { numberoption, slideroption, radiooption, radioiconoption, textnode } from './input';
import { undo } from '../service/undo';
import { asset } from '../../viewer/tools/loader';

import { el, mount, setStyle } from 'redom';
import merge from 'lodash/merge';

export interface manageroption {
  title:string;
  text?:boolean;
  textcolor?:boolean;
  backcolor?:boolean;
  pickercolor?:boolean;
  opacity?:boolean;
  fixed?:boolean;
}

/*
  +------------------------------------------------------------------------+
  | MAIN MANAGER                                                           |
  +------------------------------------------------------------------------+
*/

export class manager {

  control:ui_container;

  setWidth (width:number) {
    setStyle(this.control.el, {width:width+'px'});
  }

  setHeight (height:number) {
    setStyle(this.control.el, {height:height+'px'});
  }

  addButton (textnode:textnode, callback:Function) {
    let inputContainer = this.control.addContainer('input-container');
    let button = inputContainer.addButton(textnode, 'siimple-btn siimple-btn--primary siimple-btn--fluid input-button');
    button.on('click', (text) => {
      callback(text);
      undo.pushState();
    });
    return button;
  }

  addTextInput (label:string, text:string, callback:Function) {
    let inputContainer = this.control.addContainer('input-container');
    let textlabel = inputContainer.addText(label, 'input-label');
    let textInput = inputContainer.addInput(text, 'input-parameter input-text');
    textInput.on('blur', (text) => {
      undo.pushState();
    });
    textInput.on('change', (text) => {
      callback(text);
    });
    textInput.label = textlabel;
    textInput.container = inputContainer;
    return textInput;
  }

  addParagraphInput (label:string, paragraph:string, callback:Function) {
    let inputContainer = this.control.addContainer('input-container container-height-auto');
    let paragraphlabel = inputContainer.addText(label, 'input-label input-label-paragraph');
    let paragraphInput = inputContainer.addParagraphInput(paragraph, 'input-paragraph editor-scroll');
    paragraphInput.on('blur', (paragraph) => {
      undo.pushState();
    });
    paragraphInput.on('change', (paragraph) => {
      callback(paragraph);
    });
    paragraphInput.label = paragraphlabel;
    paragraphInput.container = inputContainer;
    return paragraphInput;
  }

  currentCallback:any;
  addColorInput (label:string, color:Array<number>, callback:Function) {
    let inputContainer = this.control.addContainer('input-container');
    let colorlabel = inputContainer.addText(label, 'input-label');
    let colorInput = inputContainer.addColorButton(color, 'input-parameter');
    colorInput.on('change', (rgba) => {
      callback(rgba);
    });
    colorInput.on('blur', (rgba) => {
      undo.pushState();
    });
    colorInput.label = colorlabel;
    colorInput.container = inputContainer;
    return colorInput;
  }

  addAssetInput (label:string, asset:asset, callback:Function) {
    let inputContainer = this.control.addContainer('input-container');
    let assetlabel = inputContainer.addText(label, 'input-label');
    let assetInput = inputContainer.addAssetButton(asset, 'input-parameter');
    assetInput.on('change', (url) => {
      callback(url);
    });
    assetInput.on('blur', (url) => {
      undo.pushState();
    });
    assetInput.label = assetlabel;
    assetInput.container = inputContainer;
    return assetInput;
  }

  addColorAndAssetInput (label:string, color:Array<number>, asset:asset, callback:Function) {
    let inputContainer = this.control.addContainer('input-container');
    let labeldiv = inputContainer.addText(label, 'input-label');
    let colorInput = inputContainer.addColorButton(color, 'input-parameter-first');
    colorInput.on('change', (rgba) => {
      callback('color', rgba);
    });
    colorInput.on('blur', (rgba) => {
      undo.pushState();
    });
    colorInput.label = labeldiv;
    colorInput.container = inputContainer;
    let assetInput = inputContainer.addAssetButton(asset, 'input-parameter-second');
    assetInput.on('change', (url) => {
      callback('asset', url);
    });
    assetInput.on('blur', (url) => {
      undo.pushState();
    });
    assetInput.label = labeldiv;
    assetInput.container = inputContainer;
    return {color:colorInput, asset:assetInput};
  }

  addImageAssetInput (label:string, asset:asset, callback:Function) {
    let inputContainer = this.control.addContainer('input-container');
    let imageassetlabel = inputContainer.addText(label, 'input-label');
    let imageAssetInput = inputContainer.addImageAssetButton(asset, 'input-parameter');
    imageAssetInput.on('change', (url) => {
      callback(url);
    });
    imageAssetInput.on('blur', (url) => {
      undo.pushState();
    });
    imageAssetInput.label = imageassetlabel;
    imageAssetInput.container = inputContainer;
    return imageAssetInput;
  }

  addTextAssetInput (label:string, asset:asset, callback:Function) {
    let inputContainer = this.control.addContainer('input-container');
    let textassetlabel = inputContainer.addText(label, 'input-label');
    let textAssetInput = inputContainer.addTextAssetButton(asset, 'input-parameter');
    textAssetInput.on('change', (url) => {
      callback(url);
    });
    textAssetInput.on('blur', (url) => {
      undo.pushState();
    });
    textAssetInput.label = textassetlabel;
    textAssetInput.container = inputContainer;
    return textAssetInput;
  }

  addCheckBox (label:string, checked:boolean, callback:Function) {
    let inputContainer = this.control.addContainer('input-container');
    let checkboxlabel = inputContainer.addText(label, 'input-label');
    let checkboxInput = inputContainer.addCheckBox(checked, 'input-parameter');
    checkboxInput.on('change', (checked) => {
      callback(checked);
      undo.pushState();
    });
    checkboxInput.label = checkboxlabel;
    checkboxInput.container = inputContainer;
    return checkboxInput;
  }

  addSlider (label:string, slideroption:slideroption, callback:Function) {
    let inputContainer = this.control.addContainer('input-container input-container-big');
    let sliderlabel = inputContainer.addText(label, 'input-label');
    let sliderInput = inputContainer.addSlider(slideroption, 'input-parameter');
    sliderInput.on('change', (value) => {
      callback(value);
    });
    sliderInput.on('blur', (value) => {
      undo.pushState();
    });
    sliderInput.label = sliderlabel;
    sliderInput.container = inputContainer;
    return sliderInput;
  }

  addRadio (label:string, radiooption:radiooption, callback:Function) {
    let inputContainer = this.control.addContainer('input-container');
    let radiolabel = inputContainer.addText(label, 'input-label');
    let radioInput = inputContainer.addRadio(radiooption, 'input-parameter');
    radioInput.on('change', (value) => {
      callback(value);
      // PushState in change because blur is called before change and it won't be saved
      undo.pushState();
    });
    radioInput.label = radiolabel;
    radioInput.container = inputContainer;
    return radioInput;
  }

  addRadioIcon (label:string, radiooption:radioiconoption, callback:Function) {
    let inputContainer = this.control.addContainer('input-container');
    let radiolabel = inputContainer.addText(label, 'input-label');
    let radioInput = inputContainer.addRadioIcon(radiooption, 'input-parameter');
    radioInput.on('change', (value) => {
      callback(value);
      // PushState in change event because blur is called before change and it won't be saved
      undo.pushState();
    });
    for (let i = 0; i < radioInput.radiobuttons.length; i++) {
      let button = radioInput.radiobuttons[i];
      button.addEventListener('mouseenter', (evt) => {
        let value = evt.target.id;
        radiolabel.textContent = label+' '+value;
      });
      button.addEventListener('mouseleave', () => {
        radiolabel.textContent = label;
      });
    }
    radioInput.label = radiolabel;
    radioInput.container = inputContainer;
    return radioInput;
  }

  addSelect (label:string, radiooption:radiooption, callback:Function) {
    let inputContainer = this.control.addContainer('input-container');
    let selectlabel = inputContainer.addText(label, 'input-label');
    let selectInput = inputContainer.addSelect(radiooption, 'input-parameter');
    selectInput.on('change', (value) => {
      callback(value);
      undo.pushState();
    });
    selectInput.label = selectlabel;
    selectInput.container = inputContainer;
    return selectInput;
  }

   addNumberInput (label:string, numberoption:numberoption, callback:Function) {
    let inputContainer = this.control.addContainer('input-container');
    let numberlabel = inputContainer.addText(label,'input-label');
    let numberInput = inputContainer.addNumberInput(numberoption, 'input-parameter');
    numberInput.on('change', (text) => {
      callback(text);
    });
    numberInput.on('blur', (text) => {
      undo.pushState();
    });
    numberInput.label = numberlabel;
    numberInput.container = inputContainer;
    return numberInput;
  }

  addVectorInput (label:string, numberoption:numberoption, callback:Function) {
    let inputContainer = this.control.addContainer('input-container');
    let vectorlabel = inputContainer.addText(label, 'input-label');
    let vectorContainer = inputContainer.addContainer('vector-container');
    let vectorInputs:any = {};
    let i = 0;
    for (let key in {x:0, y:0, z:0}) {
      ((key)=>{
        let vectoroption = merge(numberoption, {value:0, unit:key.toUpperCase(), width:50, left:i*54, decimal:2});
        vectorInputs[key] = vectorContainer.addNumberInput(vectoroption, 'vector-input');
        vectorInputs[key].on('change', (number) => {
          let change = {};
          change[key] = number;
          callback(change);
        });
        vectorInputs[key].on('blur', (text) => {
          undo.pushState();
        });
      })(key)
      i++;
    }
    vectorInputs.container = inputContainer;
    vectorInputs.label = vectorlabel;
    return vectorInputs;
  }
}


/*
  +------------------------------------------------------------------------+
  | PARENT DESIGN MANAGER                                                  |
  +------------------------------------------------------------------------+
*/

export let layerLeft = el('div.layer-left.presets-container.editor-scroll');
mount(document.body, layerLeft);

/*
  +------------------------------------------------------------------------+
  | PARENT PARAMETER MANAGER                                                  |
  +------------------------------------------------------------------------+
*/

export let layerRight = el('div.layer-right.parameter-container.editor-scroll');
mount(document.body, layerRight);

/*
  +------------------------------------------------------------------------+
  | PARENT PARAMETER LIST                                                  |
  +------------------------------------------------------------------------+
*/

export let layerRightList = el('div.parameter-list-container.editor-scroll');
mount(layerRight, layerRightList);

/*
  +------------------------------------------------------------------------+
  | DESIGN MANAGER                                                         |
  +------------------------------------------------------------------------+
*/

export class floatingManager extends manager {

    title:HTMLElement;
    height:number;

    constructor (title?:string) {
      super();
      this.control = new ui_container(layerRight, 'parameter-group');
      if (title) this.addTitle(title);
      return this;
    }

    addTitle (title:string) {
      let titleparent = el('div.parameter-title',
        this.title = el('div.title-text', title)
      );
      mount(this.control.el, titleparent);
    }

    freezeInput (input:any, bool:boolean) {
      if (bool) setStyle(input.el, {display:'none'});
      else setStyle(input.el, {display:'block'});
      if (input.unit) {
        if (bool) setStyle(input.unit, {display:'none'});
        else setStyle(input.unit, {display:'block'});
      }
    }
}
