
import { projectInterface } from '../service/projectInterface';
import { system } from '../tools/system';
import { shape } from '../contents/patterns/shape';
import { sound } from '../contents/patterns/sound';
import { light } from '../contents/patterns/light';
import { contentsManagerClass } from '../contents/contentsManager';
import { animation } from '../tools/animation';

import { Color3 } from '@babylonjs/core/Maths/math'
import { Scene } from '@babylonjs/core/scene'
import { Texture } from '@babylonjs/core/Materials/Textures/texture';

// import { ReflectionProbe } from '@babylonjs/core/Probes/reflectionProbe';

export interface environmentInterface {
  fogcolor?:Array<number>,
  skydiffuse?:string,
  skycolor?:Array<number>,
  skybrightness?:number,
  skyblur?:number,
  reflection?:boolean,
  shadow?:boolean,
  shadowdarkness?:number,
  shadowblur?:number,
  light?:boolean,
  lightcolor?:Array<number>,
  lightintensity?:number,
  ambientsound?:string,
  ambientsoundvolume?:number,
}

projectInterface.environment = {
  fogcolor:'fc',
  skydiffuse:'sd',
  skycolor:'sc',
  skybrightness:'sbr',
  skyblur:'sbl',
  reflection:'r',
  shadow:'sh',
  shadowdarkness:'shd',
  shadowblur:'shb',
  light:'l',
  lightcolor:'lc',
  lightintensity:'li',
  ambientsound:'as',
  ambientsoundvolume:'asv',
}

export class environmentClass {

  _system:system;
  contentsManager:contentsManagerClass;
  anim:animation;

  skybox:shape;
  fogcolor:Array<number>;
  skydiffuse:string;
  skycolor:Array<number>;
  skybrightness:number;
  skyblur:number;

  mainshadowGenerator:any;
  shadow = false;
  shadowdarkness = 0;
  shadowblur:number;

  mainlight:light;
  light:boolean;
  lightcolor:Array<number>;
  lightintensity = 1;
  ambientsound:string;
  ambientsoundvolume = 1;
  sound:sound;

  skySize = 50;

  constructor (system:system, contentsManager:contentsManagerClass) {
    this._system = system;
    this.contentsManager = contentsManager;
    this.anim = new animation(this._system);
  }

  init (environmentOptions:environmentInterface) {
    this.skybox = new shape(this._system, {}, 'sphere');
    this.skybox.setScaleAxis({x:this.skySize * 10, y:this.skySize * 10, z:this.skySize * 10});
    this.skybox.setMaterialType('standard');
    // this.skybox.addToWaters();
    // this.skybox.mesh.infiniteDistance = true;
    this.skybox.mesh.material.disableLighting = true;
    this.skybox.mesh.material.twoSidedLighting = true;
    this.skybox.mesh.isPickable = false;

    this.mainlight = new light(this._system, 'directional');
    this.mainlight.setPosAxis({x:0, y:this.skySize/2, z:0});
    this.mainlight.setAngleAxis({x:90});

    // fog is use to define the sky color property
    // scene.fogMode = Scene.FOGMODE_EXP;
    this._system.scene.fogEnabled = true;
    this._system.scene.fogMode = Scene.FOGMODE_LINEAR;

    this.sound = new sound(this._system);

    this.checkOption(environmentOptions);
    if (this.fogcolor == undefined) this.setFogColor(undefined);
    this.setFogDensity(1);
  }

  checkFirstSceneRendering () {
    this._system.engine.runRenderLoop( () => {
      if (this.skybox.mesh.material.isReady(this.skybox.mesh)) {
        this._system.scene.render();
        this._system.engine.stopRenderLoop();
      }
    });
  }

  setOption (environmentOptions:environmentInterface) {
    this.setFogColor(environmentOptions.fogcolor);
    this.setSkyDiffuse(environmentOptions.skydiffuse);
    this.setSkyColor(environmentOptions.skycolor);
    this.setSkyBlur(environmentOptions.skyblur);
    this.setSkyBrightness(environmentOptions.skybrightness);
    this.setLight(environmentOptions.light);
    this.setLightColor(environmentOptions.lightcolor);
    this.setLightIntensity(environmentOptions.lightintensity);
    // this.setShadow(environmentOptions.shadow);
    // this.setShadowDarkness(environmentOptions.shadowdarkness);
    this.setAmbiantSound(environmentOptions.ambientsound);
  }

  checkOption (environmentOptions:environmentInterface) {
    if (environmentOptions.fogcolor !== undefined) this.setFogColor(environmentOptions.fogcolor);
    if (environmentOptions.skydiffuse !== undefined) this.setSkyDiffuse(environmentOptions.skydiffuse);
    if (environmentOptions.skycolor !== undefined) this.setSkyColor(environmentOptions.skycolor);
    if (environmentOptions.skyblur !== undefined) this.setSkyBlur(environmentOptions.skyblur);
    if (environmentOptions.skybrightness !== undefined) this.setSkyBrightness(environmentOptions.skybrightness);
    if (environmentOptions.light !== undefined) this.setLight(environmentOptions.light);
    if (environmentOptions.lightcolor !== undefined) this.setLightColor(environmentOptions.lightcolor);
    if (environmentOptions.lightintensity !== undefined) this.setLightIntensity(environmentOptions.lightintensity);
    // if (environmentOptions.shadow !== undefined) this.setShadow(environmentOptions.shadow);
    // if (environmentOptions.shadowdarkness !== undefined) this.setShadowDarkness(environmentOptions.shadowdarkness);
    if (environmentOptions.ambientsound !== undefined) this.setAmbiantSound(environmentOptions.ambientsound);
    if (environmentOptions.ambientsoundvolume !== undefined) this.setAmbiantSoundVolume(environmentOptions.ambientsoundvolume);
  }

  start (callback?:Function) {
		if (this.sound) this.playAmbientSound();
    if (!this.fogcolor) return this.setFogDensity(0);
    let endDensity = (this.fogcolor)? this.fogcolor[3] : 0;
    this.anim.simple(100, (count, perc) => {
      perc = Math.pow(perc, 5); // Big power to make smooth effect
      let density = (1 - perc) * (1 - endDensity) + endDensity;
      this.setFogDensity(density);
    }, () => {
      this.setFogDensity(endDensity);
      if (callback) callback();
    });
  }

  fogdensityratio = 3 * this.skySize;
  setFogColor (color:Array<number>) {
    this.fogcolor = color;
    if (color == undefined) return this._system.scene.fogEnabled = false;
    else this._system.scene.fogEnabled = true;
    this.checkSkyBoxParameters();
    let babyloncolor = new Color3(color[0]/255, color[1]/255, color[2]/255);
    this._system.scene.fogColor = babyloncolor;
    this.setFogDensity(color[3]);
  }

  setFogDensity (density:number) {
    let perc = 1 - Math.pow(density, 1/4);
    this._system.scene.fogStart = perc * 3 * this.skySize;
    this._system.scene.fogEnd = Math.min(perc * 30 * this.skySize, 5 * this.skySize);
  }

  checkFog () {
    if (this.fogcolor) this.setFogColor(this.fogcolor);
    else this.setFogDensity(0);
  }

  setSkyDiffuse (url:string) {
    this.skydiffuse = url;
    if (url == undefined) {
      return this.removeEnvironmentTexture();
    }
    if (url.indexOf('.env') == -1 && url.indexOf('.dds') == -1 && url.indexOf('.hdr') == -1) return this.setFormerSkyDiffuse(url);
    this._system.loader.getCubeTexture(url, (asset) => {
      if (asset) {
        this.addEnvironmentTexture(asset.texture);
      } else {
        this.removeEnvironmentTexture();
      }
    });
  }

  setFormerSkyDiffuse (url:string) {
    this._system.loader.getReflection(url, (asset) => {
      if (asset) {
        asset.texture.coordinatesMode = Texture.SPHERICAL_MODE;
        this.addEnvironmentTexture(asset.texture);
      } else {
        this.removeEnvironmentTexture();
      }
    });
  }

  setSkyColor (color:Array<number>) {
    this.skycolor = color;
    this.contentsManager.setReflectionColor(color);
    if (color) this.skybox.setTextureColor('reflection', color);
    else this.skybox.deleteTextureColor('reflection');
  }

  setSkyBrightness (brightness:number) {
    this.skybrightness = brightness;
    if (!brightness) brightness = 0;
    // this.environmentTexture.level = brightness;
    if (this.skybox.mesh.material.reflectionTexture) this.skybox.mesh.material.reflectionTexture.level = brightness;
  }

  // Test with reflection Probe
  // probe:ReflectionProbe;
  // addEnvironmentTexture (texture?:Texture) {
  //   if (this.reflectionTexture) this.reflectionTexture.dispose();
  //   if (this.probe) this.probe.dispose();
  //   this.probe = new ReflectionProbe("main", 128, this._system.scene);
  //   for (let i = 0; i < this._system.scene.meshes.length; i++) {
  //     let mesh = this._system.scene.meshes[i];
  //     if (mesh.material != undefined) {
  //       // Water materials must not have reflection texture
  //       if (mesh.material.waterColor == undefined) {
  //         this.probe.renderList.push(mesh);
  //       }
  //     }
  //   }
  //   this.probe.refreshRate = RenderTargetTexture.REFRESHRATE_RENDER_ONCE;
  //   // this.reflectionTexture = this.probe.cubeTexture;
  //   // this.refractionTexture = this.probe.cubeTexture;
  //   this._system.scene.environmentTexture = this.probe.cubeTexture;
  // }

  environmentTexture:Texture;
  addEnvironmentTexture (environmentTexture:Texture) {
    this.environmentTexture = environmentTexture;
    this._system.scene.environmentTexture = environmentTexture;
    this.skybox.setTexture('reflection', environmentTexture);
    this.skybox.setReflectionMode('skybox');
    if (this.skyblur != undefined) this.setSkyBlur(this.skyblur);
    if (this.skybrightness != undefined) this.setSkyBrightness(this.skybrightness);
    this.checkSkyBoxParameters();
  }

  setSkyBlur (blur:number) {
    this.skyblur = blur;
    // this.skybox.mesh.material.microSurface = blur;
    this.skybox.mesh.material.roughness = blur;
  }

  removeEnvironmentTexture () {
    if (this.environmentTexture) {
      this._system.scene.environmentTexture.dispose();
      this._system.scene.environmentTexture = undefined;
      this.skybox.deleteTexture('reflection');
    }
    this.setSkyColor(undefined);
    this.checkSkyBoxParameters();
  }

  checkSkyBoxParameters () {
    if (!this.skydiffuse) {
      if (this.fogcolor) {
        this.skybox.setTextureColor('ambient', this.fogcolor);
        this.skybox.show();
      } else {
        this.skybox.hide();
      }
    } else {
      this.skybox.show();
      this.skybox.deleteTextureColor('ambient');
    }
  }

  setLight (lightvisible:boolean) {
    this.light = lightvisible;
    if (lightvisible) this.mainlight.show();
    else this.mainlight.hide();
  }

  setLightColor (color:Array<number>) {
    this.lightcolor = color;
    if (color == undefined) {
      this.setLight(false);
    } else {
      this.setLight(true);
      this.mainlight.setColor(color);
    }
  }

  setLightIntensity (intensity:number) {
    this.lightintensity = intensity;
    this.mainlight.setIntensity(intensity);
  }

  setShadow (shadow:boolean) {
    this.shadow = shadow;
    this.mainlight.setCastShadow(shadow);
    this.setShadowDarkness(this.shadowdarkness);
  }

  setShadowDarkness (darkness:number) {
    this.shadowdarkness = darkness;
    this.mainlight.setShadowDarkness(darkness);
  }

  // Can't set blur but quality instead? mainshadowGenerator.filteringQuality = ShadowGenerator.QUALITY_LOW;
  // setShadowBlur (blur:number) {
  //   this.shadowblur = blur;
  //   mainshadowGenerator.blurKernel = blur;
  // }

  setAmbiantSound (url:string) {
    this.ambientsound = url;
    this._system.loader.getSound(url, (sound) => {
      this.sound.setSound(sound);
      this.sound.setVolume(this.ambientsoundvolume);
    });
  }

  setAmbiantSoundVolume (volume:number) {
    this.ambientsoundvolume = volume;
    this.sound.setVolume(volume);
  }

  playAmbientSound () {
    this.sound.show();
  }

  stopAmbientSound () {
    this.sound.hide();
  }
}
