import { Observer, Observable } from "@babylonjs/core/Misc/observable";
import { Nullable } from "@babylonjs/core/types";
import { PointerInfo } from "@babylonjs/core/Events/pointerEvents";
import { Vector3, Color3, Matrix, Axis } from "@babylonjs/core/Maths/math";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { PlaneBuilder } from "@babylonjs/core/Meshes/Builders/planeBuilder";
import { CylinderBuilder } from "@babylonjs/core/Meshes/Builders/cylinderBuilder";
import { PointerDragBehavior } from "@babylonjs/core/Behaviors/Meshes/pointerDragBehavior";
import { _TimeToken } from "@babylonjs/core/Instrumentation/timeToken";
import { _DepthCullingState, _StencilState, _AlphaState } from "@babylonjs/core/States/index";
import { Gizmo } from "@babylonjs/core/Gizmos/gizmo";
import { UtilityLayerRenderer } from "@babylonjs/core/Rendering/utilityLayerRenderer";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Scene } from "@babylonjs/core/scene";
import { GridMaterial } from '@babylonjs/materials/grid/gridMaterial';
import { Animation } from '@babylonjs/core/Animations/Animation';
import { HighlightLayer } from '@babylonjs/core/Layers/highlightLayer'
/**
 * Single plane drag gizmo
 */
export class NakerPlaneDragGizmo extends Gizmo {
    /**
     * Drag behavior responsible for the gizmos dragging interactions
     */
    public dragBehavior: PointerDragBehavior;
    private _pointerObserver: Nullable<Observer<PointerInfo>> = null;
    /**
     * Drag distance in babylon units that the gizmo will snap to when dragged (Default: 0)
     */
    public snapDistance = 0;
    /**
     * Event that fires each time the gizmo snaps to a new location.
     * * snapDistance is the the change in distance
     */
    public onSnapObservable = new Observable<{ snapDistance: number }>();

    private _plane: TransformNode;
    private _secondPlane: Mesh;
    private _coloredMaterial: StandardMaterial;
    private _hoverMaterial: StandardMaterial;

    private _isEnabled: boolean = false;
    public _isDragged: boolean = false;

    /** @hidden */
    public static _CreatePlane(scene: Scene, color: Color3, highlighter:HighlightLayer): TransformNode {
        var plane = new TransformNode("plane", scene);

        //make sure plane is double sided
        var material = new StandardMaterial("", scene);
        material.emissiveColor = color;
        material.backFaceCulling = false;
        let planeSize = 0.15

        var dragPlane = PlaneBuilder.CreatePlane("dragPlane", { width: planeSize, height: planeSize, sideOrientation: 2 }, scene);
        dragPlane.material = material.clone('');
        dragPlane.material.alpha = 0.5;
        dragPlane.parent = plane;

        var borderLeft = CylinderBuilder.CreateCylinder("topCylinder", { diameter: .01, height: planeSize }, scene);
        borderLeft.position.x = planeSize/2;
        borderLeft.material = material;
        borderLeft.parent = plane;
        highlighter.addMesh(borderLeft, Color3.Black());

        var borderRight = CylinderBuilder.CreateCylinder("topCylinder", { diameter: .01, height: planeSize }, scene);
        borderRight.position.x = -planeSize/2;
        borderRight.material = material;
        borderRight.parent = plane;
        highlighter.addMesh(borderRight, Color3.Black());

        var borderTop = CylinderBuilder.CreateCylinder("topCylinder", { diameter: .01, height: planeSize }, scene);
        borderTop.position.y = planeSize/2;
        borderTop.rotation.z = Math.PI/2;
        borderTop.material = material;
        borderTop.parent = plane;
        highlighter.addMesh(borderTop, Color3.Black());

        var borderBottom = CylinderBuilder.CreateCylinder("topCylinder", { diameter: .01, height: planeSize }, scene);
        borderBottom.position.y = -planeSize/2;
        borderBottom.rotation.z = Math.PI/2;
        borderBottom.material = material;
        borderBottom.parent = plane;
        highlighter.addMesh(borderBottom, Color3.Black());
        return plane;
    }

    /** @hidden */
    public static _CreateSecondPlane(scene: Scene, color: Color3): Mesh {
      //make sure plane is double sided
      var secondPlane = PlaneBuilder.CreatePlane("secondPlane", { width: .1, height: .1, sideOrientation: 2 }, scene);
      let material = new GridMaterial("groundMaterial", scene);

      material.lineColor = color;
      material.mainColor = color;
      material.gridRatio = 0.00007;
      material.gridOffset = new Vector3(0.00003, 0.00003, 0);
      // Force opacity != 1 to have no main color on grid material and let the plane2 visible
      material.opacity = 0;
      material.maxSimultaneousLights = 0;
      secondPlane.material = material;
      secondPlane.isVisible = false;

      // Position plane pointing normal to secondPlane normal
      return secondPlane;
    }

    /** @hidden */
    public static _CreateSecondArrow(scene: Scene, color: Color3): Mesh {
      //make sure plane is double sided
      var cylinder = CylinderBuilder.CreateCylinder("cylinder", { diameterTop: 0.1, height: 500, diameterBottom: 0.1, tessellation: 4 }, scene);
      let material = new StandardMaterial("groundMaterial", scene);
      material.emissiveColor = color;
      material.alpha = 0;
      material.maxSimultaneousLights = 0;
      cylinder.material = material;
      cylinder.isVisible = false;

      // Position plane pointing normal to secondPlane normal
      return cylinder;
    }

    /**
     * Creates a PlaneDragGizmo
     * @param gizmoLayer The utility layer the gizmo will be added to
     * @param dragPlaneNormal The axis normal to which the gizmo will be able to drag on
     * @param color The color of the gizmo
     */
    constructor(dragPlaneNormal: Vector3, color: Color3 = Color3.Gray(), gizmoLayer: UtilityLayerRenderer = UtilityLayerRenderer.DefaultUtilityLayer, scene:Scene, highlighter:HighlightLayer) {
        super(gizmoLayer);
        this.updateGizmoRotationToMatchAttachedMesh = false;
        // Create Material

        // Build plane mesh on root node
        this._plane = NakerPlaneDragGizmo._CreatePlane(gizmoLayer.utilityLayerScene, color, highlighter);

        this._plane.lookAt(this._rootMesh.position.add(dragPlaneNormal));
        this._plane.scaling.scaleInPlace(1 / 3);
        this._plane.position.addInPlace(dragPlaneNormal.scale(-0.04));
        this._plane.parent = this._rootMesh;

        // Build plane mesh on root node
        this._secondPlane = NakerPlaneDragGizmo._CreateSecondArrow(scene, color);
        this._secondPlane.lookAt(this._rootMesh.position.add(dragPlaneNormal));
        this._secondPlane.rotate(Axis.X, Math.PI/2);

        var currentSnapDragDistance = 0;
        var tmpVector = new Vector3();
        var tmpSnapEvent = { snapDistance: 0 };
        // Add dragPlaneNormal drag behavior to handle events when the gizmo is dragged
        this.dragBehavior = new PointerDragBehavior({ dragPlaneNormal: dragPlaneNormal });
        this.dragBehavior.moveAttached = false;
        this._rootMesh.addBehavior(this.dragBehavior);

        var localDelta = new Vector3();
        var tmpMatrix = new Matrix();
        this.dragBehavior.onDragObservable.add((event) => {
            if (this.attachedMesh) {
                // Convert delta to local translation if it has a parent
                if (this.attachedMesh.parent) {
                    this.attachedMesh.parent.computeWorldMatrix().invertToRef(tmpMatrix);
                    tmpMatrix.setTranslationFromFloats(0, 0, 0);
                    Vector3.TransformCoordinatesToRef(event.delta, tmpMatrix, localDelta);
                } else {
                    localDelta.copyFrom(event.delta);
                }
                // Snapping logic
                if (this.snapDistance == 0) {
                    this.attachedMesh.position.addInPlace(localDelta);
                } else {
                    currentSnapDragDistance += event.dragDistance;
                    if (Math.abs(currentSnapDragDistance) > this.snapDistance) {
                        var dragSteps = Math.floor(Math.abs(currentSnapDragDistance) / this.snapDistance);
                        currentSnapDragDistance = currentSnapDragDistance % this.snapDistance;
                        localDelta.normalizeToRef(tmpVector);
                        tmpVector.scaleInPlace(this.snapDistance * dragSteps);
                        this.attachedMesh.position.addInPlace(tmpVector);
                        tmpSnapEvent.snapDistance = this.snapDistance * dragSteps;
                        this.onSnapObservable.notifyObservers(tmpSnapEvent);
                    }
                }
                this._secondPlane.position = this.attachedMesh.position.clone();
            }
        });

        this._pointerObserver = gizmoLayer.utilityLayerScene.onPointerObservable.add((pointerInfo) => {
            if (this._customMeshSet || this._isDragged) {
                return;
            }
            var isHovered = pointerInfo.pickInfo && (this._rootMesh.getChildMeshes().indexOf(<Mesh>pointerInfo.pickInfo.pickedMesh) != -1);
            if ( isHovered ) {
              this._showHelper(scene);
              this._secondPlane.position = this._rootMesh.position.clone();
              // this._secondPlane.position.y = this.attachedMesh.getBoundingInfo().boundingBox.minimumWorld.y;
            } else {
              this._hideHelper(scene);
            }
        });

        this.dragBehavior.onDragStartObservable.add(() => {
          this._isDragged = true;
        });

        this.dragBehavior.onDragEndObservable.add(() => {
          this._isDragged = false;
        });
    }

    protected _attachedMeshChanged(value: Nullable<AbstractMesh>) {
        if (this.dragBehavior) {
            this.dragBehavior.enabled = value ? true : false;
        }
    }

    private _helperShown = false;
    private _maxOpacity = 0.5;
    protected _showHelper (scene:Scene) {
      if (this._helperShown) return;
      this._helperShown = true;

      this._secondPlane.isVisible = true;
      var animationPearl = new Animation("second_plane_anim", "material.alpha", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      var keys = [];
      keys.push({
        frame: 0,
        value: 0
      });
      keys.push({
        frame: 5,
        value: this._maxOpacity
      });
      animationPearl.setKeys(keys);
      this._secondPlane.animations = [];
      this._secondPlane.animations.push(animationPearl);
      scene.beginAnimation(this._secondPlane, 0, 5, false);
    }

    protected _hideHelper (scene:Scene) {
      if (!this._helperShown) return;
      this._helperShown = false;

      var animationPearl = new Animation("second_plane_anim", "material.alpha", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      var keys = [];
      keys.push({
        frame: 0,
        value: this._maxOpacity
      });
      keys.push({
        frame: 5,
        value: 0
      });
      animationPearl.setKeys(keys);
      this._secondPlane.animations = [];
      this._secondPlane.animations.push(animationPearl);
      scene.beginAnimation(this._secondPlane, 0, 5, false, 1, () => {
        // Make sure plane doesn't stay visible or selection stops working
        this._secondPlane.isVisible = false;
      });
    }
    /**
     * If the gizmo is enabled
     */
    public set isEnabled(value: boolean) {
        this._isEnabled = value;
        if (!value) {
            this.attachedMesh = null;
        }
    }
    public get isEnabled(): boolean {
        return this._isEnabled;
    }
    /**
     * Disposes of the gizmo
     */
    public dispose() {
        this.onSnapObservable.clear();
        this.gizmoLayer.utilityLayerScene.onPointerObservable.remove(this._pointerObserver);
        this.dragBehavior.detach();
        super.dispose();
        if (this._plane) {
            this._plane.dispose();
        }
        [this._coloredMaterial, this._hoverMaterial].forEach((matl) => {
            if (matl) {
                matl.dispose();
            }
        });
    }
}
