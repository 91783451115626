
import { shape } from '../../viewer/contents/patterns/shape';
import { util } from '../../viewer/tools/util';
import { colorsecondmain, colordarkgrey, colormain } from '../service/colors';
import { _system } from '../service/editintale';

import { Color4, Vector3 } from '@babylonjs/core/Maths/math'
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Material } from '@babylonjs/core/Materials/material';
import { Texture } from '@babylonjs/core/Materials/Textures/texture';

/*
  +------------------------------------------------------------------------+
  | POINT OF VIEW OBJECT                                                   |
  +------------------------------------------------------------------------+
*/

export class pointofviewSight {

  parent:shape;
  sight:shape;
	parentSight:shape;
  screen:shape;
  stamp:shape;
	stamp2:shape;

  name:string;

	constructor (parent:shape) {
    this.name = parent.mesh.name;
		this.setPointofviewSight();
		this.setPointofviewCamera();
    this.setParent(parent);
    this.setSightLength(2);
    this.hide();
	}

  sightlength = 2;
  sightMaterial:Material;
	setPointofviewSight () {
    this.parentSight = new shape(_system, {waterEnabled:false, shadowEnabled:false, lightEnabled:false, fogEnabled:false, glowEnabled:false}, 'cube');
    this.parentSight.setMaterialType('standard');
    this.parentSight.enableReflection(false);
    this.parentSight.setMaxLights(0);
    this.parentSight.hide();
    this.parentSight.setMaterialProperties({alpha:0});
    this.parentSight.setScaleAxis({z:0.5, y:1, x: 1});
    this.parentSight.setAngleAxis({x:-90});
    this.parentSight.mesh.layerMask = 0x20000000;

    this.sightMaterial = new StandardMaterial("sightMaterial", _system.scene);
    this.sightMaterial.opacityTexture = new Texture("https://asset.naker.io/textures/gradient2.jpg", _system.scene);
    this.sightMaterial.opacityTexture.wrapU = Texture.CLAMP_ADDRESSMODE;
    this.sightMaterial.opacityTexture.wrapV = Texture.CLAMP_ADDRESSMODE;
    this.sightMaterial.opacityTexture.getAlphaFromRGB = true;
    this.sightMaterial.maxSimultaneousLights = 0;
	}

  setSightLength (sight:number) {
    this.sightlength = sight;

    let faceColors = new Array(6);
    faceColors[0] = new Color4(0, 0, 0, 0);   // transparent bottom
    faceColors[2] = new Color4(0, 0, 0, 0);   // black screen

    let options = {
      diameterTop:1,
      diameterBottom:this.sightlength * 2,
      height: this.sightlength,
      tessellation: 4,
      faceColors: faceColors
    };

    let cone = MeshBuilder.CreateCylinder(this.name+"cone", options, _system.scene);
    cone.material = this.sightMaterial;
    cone.hasVertexAlpha = true;

    if (this.sight) this.sight.mesh.dispose();
    this.sight = new shape(_system, {waterEnabled:false, shadowEnabled:false, lightEnabled:false, fogEnabled:false, glowEnabled:false});
    this.sight.setMesh(cone)
    this.sight.mesh.layerMask = 0x20000000;

    this.sight.setAngleAxis({y:45});
		this.sight.setParent(this.parentSight);

    this.sight.setPosAxis({y:-this.sightlength/2});
    let color = util.hexToRgba(colorsecondmain);
    this.sight.setTextureColor('ambient', color);
  }

	setPointofviewCamera () {
		this.screen = new shape(_system, {waterEnabled:false, shadowEnabled:false, lightEnabled:false, fogEnabled:false, glowEnabled:false}, 'cube');
		this.screen.setAngleAxis({x:-90});
    this.screen.setScaleAxis({x:0.9, y:0.05, z:0.5});
		this.screen.setPosAxis({z:0.08});
    this.screen.setMaterialType('standard');
    this.screen.enableReflection(false);
    this.screen.setMaxLights(0);
		this.screen.hide();
    this.screen.mesh.layerMask = 0x20000000;
    let hexdark = util.hexToRgba(colordarkgrey);
    this.screen.setTextureColor('ambient', hexdark);

    let hexlight = util.hexToRgba(colormain);
    this.stamp = new shape(_system, {waterEnabled:false, shadowEnabled:false, lightEnabled:false, fogEnabled:false, glowEnabled:false}, 'cube');
    this.stamp.setPosAxis({y:0.25, z:0.08});
    this.stamp.setScaleAxis({x:0.9, y:0.01, z:0.05});
    this.stamp.setMaterialType('standard');
    this.stamp.enableReflection(false);
    this.stamp.setTextureColor('ambient', hexlight);
    this.stamp.setMaxLights(0);
    this.stamp.mesh.layerMask = 0x20000000;

    let disc = MeshBuilder.CreateDisc(this.name+"disc", {radius: 1, arc: 0.3, tessellation: 2, sideOrientation: Mesh.DOUBLESIDE}, _system.scene);
    this.stamp2 = new shape(_system, {waterEnabled:false, shadowEnabled:false, lightEnabled:false, fogEnabled:false, glowEnabled:false}, 'cube');
    this.stamp2.setMesh(disc)
    this.stamp2.setPosAxis({x:-0.1, y:0.35, z:0.1});
    this.stamp2.setScaleAxis({x:0.2, y:0.2, z:0.2});
    // this.stamp2.setAngleAxis({x:0});
    this.stamp2.setMaterialType('standard');
    this.stamp2.enableReflection(false);
    this.stamp2.setTextureColor('ambient', hexlight);
    this.stamp2.setMaxLights(0);
    this.stamp2.mesh.layerMask = 0x20000000;
	}

  setParent (parent:shape) {
    this.parent = parent;
    this.screen.setParent(parent);
    this.stamp.setParent(parent);
    this.stamp2.setParent(parent);
    this.parentSight.setParent(parent);
  }

	show () {
		this.parent.show();
		this.sight.show();
    this.screen.show();
    this.stamp.show();
		this.stamp2.show();
  }

	hide () {
		this.parent.hide();
		this.sight.hide();
		this.screen.hide();
    this.stamp.hide();
    this.stamp2.hide();
	}
}
