
import { colormain } from '../service/colors';
import { contentInterface, content } from '../../viewer/contents/shared/content';
import { contentMove, animationInterface } from '../../viewer/contents/shared/contentmove';
import { projectInterface } from '../../viewer/service/projectInterface';
import { undo, undoChannel } from '../service/undo';
import { floatingManager } from '../inputs/layer';
import { selector } from '../dragzone/selector';
import { _system, contentsManager } from '../service/editintale';
import { threedJsonHelper } from '../../viewer/service/threedjsonhelper';

import remove from 'lodash/remove';
import find from 'lodash/find';
import postal from 'postal';

export let hoveredComponent:component;
export let selectedComponent:component;

export let componentChannel = postal.channel("component");
export let componentList:Array<component> = [];
export let permanentComponentList:Array<component> = [];

undoChannel.subscribe("componentchange", (data, envelope) => {
  let component:component = find(permanentComponentList, (o) => { return o.content.id == data.who; });
	if (data.action == 'store') {
		component.store();
	} else if (data.action == 'unstore') {
		component.unstore();
	} else if (data.action == 'parameter') {
		component.setChange(data.content, data.type);
	} else if (data.action == 'animation') {
    component.setAnimations(data.animations);
  }
});

export class componentSettingsClass extends floatingManager {
  content:content;
  setContent:Function;
}

export interface scrollInputInterface {
  title:string,
  start:string,
  end:string,
}

export class component extends selector {

  manager:componentSettingsClass;
  secondmanager?:componentSettingsClass;

  content:contentMove;
  type:string;
  icon:string;

  availableAxis = {
    position: {x:1, y:1, z:1},
    rotation: {x:1, y:1, z:1},
    scale: {x:1, y:1, z:1},
    opacity: 1,
    hyperlink: 1,
  };
  keepratio = false;
  availableOptions = {
    shadow: 1,
    play: 1,
    visible: 1,
    lock: 1,
  };
  scrollInputs:scrollInputInterface;

  constructor (content:contentInterface, type:string, typeStyle:any) {
    super();
    this.type = type;
    // If new component, set id.
    if (content.id == undefined) content.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    this.content = contentsManager.addNewContent(type, content, typeStyle);
    // In edit mode, we don't want the content to trigger anything
    this.content.pattern.listenEvent(false);
    this.icon = (this.type == 'shape')? this.content.mesh : this.type;

    if (content.locked) this.lock();
    componentList.push(this);
    permanentComponentList.push(this);

    this.linkWithMesh(this.content.pattern);
    if (this.content.tag) this.setText(this.content.tag);
    else this.setText(this.content.type);
  }

  setTypeComponent (typeStyle:any) {}
  setChange (content:contentInterface, typeStyle:any) {
    this.content.setGeometryParameters(content);
    this.content.setContentParameter(content);
    this.setComponentParam(content);
    this.content.setTypeParameter(typeStyle);
    this.setTypeComponent(typeStyle);
    if (this.selected) {
      this.manager.setContent(this.content);
      if (this.secondmanager) this.secondmanager.setContent(this.content);
      componentChannel.publish('change', this);
    }
  }

  setComponentParam (content:contentInterface) {
    if (content.locked !== undefined) this.checkLock(content.locked);
  }

  highlightContent (test:boolean) {
    this.content.pattern.highlight(test, colormain);
  }

  // Some component type need to do stuff before selection
  select () {
    this._select();
  }
  _select () {
    selectedComponent = this;
    this.hideSelector()
    this.visibleSelector(false);
    this.selected = true;
    this.manager.setContent(this.content);
    if (this.secondmanager) this.secondmanager.setContent(this.content);
    this.stopAllAnimations();
    componentChannel.publish('select', this);
    // this.highlightContent(true);
  }

  unselect () {
    this._unselect();
  }
  _unselect () {
    if (!this.selected) return;
    // this.highlightContent(false);
    this.selected = false;
    selectedComponent = null;
    this.visibleSelector(true);
    componentChannel.publish('unselect', this);
    if (this.type == 'group' && !this.content.validated) this.store();
  }

  checkLock (locked:boolean) {
    if (locked) this.lock();
    else this.unlock();
  }

  lock () {
    this.content.locked = true;
    this.content.pattern.forcelistenEvent(false);
  }

  unlock () {
    this.content.locked = false;
    this.content.pattern.forcelistenEvent(true);
  }

  show () {
    this._show();
  }
  _show () {
    this.content.show();
    this.content.visible = true;
  }

  hide () {
    this._hide();
  }
  _hide () {
    this.content.hide();
    this.content.visible = false;
  }

  move = 0;
  setHovered () {
    hoveredComponent = this;
  }

  setNotHovered () {
    hoveredComponent = null;
  }

  getCloneData () {
    let contentdata:contentInterface = threedJsonHelper.recursiveObjectToObject(projectInterface.content, this.content);
    let typedata = threedJsonHelper.recursiveObjectToObject(projectInterface[this.content.type], this.content);
    let contentanimations = {};
    for (let animkey in this.content.animations) {
      contentanimations[animkey] = threedJsonHelper.recursiveObjectToObject(projectInterface.animation, this.content.animations[animkey]);
    }
    contentdata.animations = contentanimations;

    contentdata.position.x += 0.1;
    contentdata.tag = undefined; // To force new tag creation
    contentdata.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    return {type:this.type, content:contentdata, typestyle:typedata};
  }

  newComponent () {
		if (selectedComponent) selectedComponent.unselect();
    this.content.show();
    this.select();
    this.setHovered(); // Because borders editor will check hovered component
    this.setNotHovered(); // Because borders editor will check hovered component
    componentChannel.publish('new', this);
    undo.pushState();
  }

  stored = false;

  // Some component need to rewrite store and unstore function to do other things (group and hotspot)
  store () {
    this._store();
  }

  unstore () {
    this._unstore();
  }

  _store () {
    if (this.content.ingroup) {
      let component:component = find(permanentComponentList, (o) => { return o.content.groupkey == this.content.groupkey && o.content.type == 'group'; });
      // If just in case
      if (component) component.removeComponent(this, false);
    }
    this.unselect();
    this.stored = true;
    this.content.hide();
    let id = this.content.id;
    remove(componentList, (o) => {return o.content.id === id;});
    contentsManager.removeContent(this.content);
    componentChannel.publish('delete', this);
    return this;
  }

  _unstore () {
    this.stored = false;
    this.content.show();
    componentList.push(this);
    contentsManager._addContent(this.content);
    componentChannel.publish('undelete', this);
    return this;
  }

  setAnimations (animations:any) {
    let currentKeyAnim = Object.keys(this.content.animations);
    let newKeyAnim = Object.keys(animations);
    for (let i = 0; i < currentKeyAnim.length; i++) {
      if (newKeyAnim.indexOf(currentKeyAnim[i]) == -1) this.content.deleteAnimation(currentKeyAnim[i]);
    }
    for (let key in animations) {
      this.content.setAnimationParam(key, animations[key]);
    }
  }

  addAnimations (animations:animationInterface) {
    this.content.addAnimations(animations);
  }

  stopAllAnimations () {
    this.content.pauseAnimation();
    this.content.resetGeometry();
  }

  restartAnimations () {
    if (this.content.play) this.content.playAnimation();
    else this.content.pauseAnimation();
  }

  rename (newname:string) {
    this.content.tag = newname;
    this.setText(newname);
    componentChannel.publish('rename', this);
  }

  // Used to allow some component type to modify destroy function
  destroy () {
    this._destroy();
  }

  _destroy () {
    this.store();
    this.content.pattern.destroy();
  }

}
