
import { navigation, _system, _scrollCatcher, hotspotsManager, environment, _pipeline } from '../service/editintale';
import { exporter } from '../service/exporter';
import { publish } from '../modal/publish';
import { globals } from '../service/globals';
import { componentCreator } from '../component/componentcreator';
import { hotspotCreator } from '../component/hotspotcreator';
import { animationCreator } from '../animation/animationcreator';
import { environmentSettings } from '../environment/environmentSettings';
import { journeyManager } from '../journey/journeymanager';
import { pointofviewPreview } from '../journey/pointofviewPreview';
import { pointofviewManager, pointofviewChannel } from '../journey/pointofviewmanager';
import { api } from '../service/api';
import { bottombar } from './bottombar';
import { selectionPanel } from '../component/selectionpanel';
import { colorsecondmain, colormain, colorgrey, colortuto } from '../service/colors';
import { undoChannel } from '../service/undo';
import { tagManager } from '../settings/tag';
import { component, permanentComponentList, selectedComponent, componentChannel } from '../component/component';
import { componentManager } from '../component/componentmanager';
import { aboveLayer } from './aboveLayer';
import { layerLeft, layerRight } from '../inputs/layer';
import { zoomManager } from '../dragzone/zoomManager';
import { gizmoController } from '../dragzone/gizmoController';
import { hideDropzones } from '../modal/dropzones';
import { tutorial } from '../modal/tutorial';

import { setAttr, setStyle, el, mount, setChildren } from 'redom';
import toastr from 'toastr'
import ProgressBar from 'progressbar.js';
import find from 'lodash/find';

undoChannel.subscribe("environmentchange", (data, envelope) => {
  topbar.showEnvironment();
});

undoChannel.subscribe("pipelinechange", (data, envelope) => {
  topbar.showEnvironment();
});

undoChannel.subscribe("navigationchange", (data, envelope) => {
  topbar.showJourney();
});

undoChannel.subscribe("pointofviewchange", (pointofviewManager:pointofviewManager, envelope) => {
  topbar.showJourney();
});

undoChannel.subscribe("componentchange", (data, envelope) => {
  let component:component = find(permanentComponentList, (o) => { return o.content.id == data.who; });
  if (component.content.type == 'hotspot') topbar.showHotspots();
  else if (data.action == 'animation') topbar.showAnimation();
  else topbar.showContent();
});

pointofviewChannel.subscribe("pointofviewshow", (pointofviewManager:pointofviewManager, envelope) => {
  topbar.showContent();
});

componentChannel.subscribe("unselect", (component:component, envelope) => {
  componentManager.hideTexts();
  if (topbar.mode == 'hotspot') {
    hotspotCreator.showCreator();
  } else if (topbar.mode == 'content') {
    componentCreator.showCreator();
  } else if (topbar.mode == 'animation') {
    animationCreator.showCreator();
  }
});

export class topbarClass {

    container:HTMLElement;
    steplist:HTMLElement;
    pictureicon:HTMLElement;
    videoicon:HTMLElement;
    projectname:HTMLElement;
    dashboardbutton:HTMLElement;
    browserribbon:HTMLElement;
    normalbuttons:HTMLElement;
    previewbuttons:HTMLElement;
    previewwback:HTMLElement;
    leavebutton:HTMLElement;
    leavetext:HTMLElement;

    liveButton:HTMLElement;
    pictureButton:HTMLElement;
    videoButton:HTMLElement;
    tutorialButton:HTMLElement;
    cruiseTutorialButton:HTMLElement;

    step0:HTMLElement;
    step1:HTMLElement;
    step2:HTMLElement;
    step3:HTMLElement;
    step4:HTMLElement;
    step5:HTMLElement;

    constructor () {
      this.container = el('div.layer-top.siimple-navbar.main-top-bar',
        [
          this.dashboardbutton = el('a.siimple-navbar-title.dashboard-button', {href:'/dashboard/projects'},
            el('div.icon-projects', [el('span.path1'),el('span.path2'),el('span.path3')])
          ),
          this.projectname = el('div.project-name'),
          this.browserribbon = el('div.browser-ribbon',
            [
              el('div.browser-text', 'For a better creation experience, we advise you to use Google Chrome'),
              el('div.browser-button', {onclick:()=>{setStyle(this.browserribbon, {display:'none'})}}, 'OK'),
            ]
          ),
          this.steplist = el('div.siimple-navbar-title.steplist',
            [
              this.progressElement = el('div.topprogressbar'),
              el('div.siimple-breadcrumb',
                [
                  this.step0 = el('i.siimple-breadcrumb-item', {onclick:()=>{this.showEnvironment()}}, 'Environment'),
                  this.step1 = el('i.siimple-breadcrumb-item', {onclick:()=>{this.showContent()}}, 'Content'),
                  this.step2 = el('i.siimple-breadcrumb-item', {onclick:()=>{this.showAnimation()}}, 'Animation'),
                  this.step3 = el('i.siimple-breadcrumb-item', {onclick:()=>{this.showJourney()}}, 'Journey'),
                  this.step4 = el('i.siimple-breadcrumb-item', {onclick:()=>{this.showHotspots()}}, 'Hotspot'),
                  this.step5 = el('i.siimple-breadcrumb-item', {onclick:()=>{this.showPublish()}}, 'Publish'),
                ]
              ),
            ]
          ),
          this.normalbuttons = el('div.topbar-right',
            [
              this.liveButton = el('div.siimple-navbar-item.topbar-button',
                el('div.siimple-btn.siimple-btn--primary.topbar-icon', { onclick:(()=>{this.showLive(true)}), 'aria-label':'Show Preview', 'data-microtip-position':'bottom', 'role':'tooltip' },
                  el('i.icon-preview.icon-button', [el('span.path1'),el('span.path2'),el('span.path3')])
                )
              ),
              this.pictureButton = el('div.siimple-navbar-item.topbar-button',
                el('div.siimple-btn.siimple-btn--primary.topbar-icon', { onclick:(()=>{this.editThumbnail()}), 'aria-label':"Edit project's thumbnail", 'data-microtip-position':'bottom', 'role':'tooltip' },
                  this.pictureicon = el('i.icon-picture.icon-button', [el('span.path1'),el('span.path2'),el('span.path3')])
                )
              ),
              this.videoButton = el('div.siimple-navbar-item.topbar-button',
                el('div.siimple-btn.siimple-btn--primary.topbar-icon', { onclick:(()=>{this.downloadPreview()}), 'aria-label':"Download video preview", 'data-microtip-position':'bottom', 'role':'tooltip' },
                  this.videoicon = el('i.icon-exportvideo.icon-button', [el('span.path1'),el('span.path2'),el('span.path3')])
                )
              ),
              this.tutorialButton = el('div.siimple-navbar-item.topbar-button',
                el('div.siimple-btn.siimple-btn--primary.topbar-icon', { onclick:(()=>{this.showTutorial()}), 'aria-label':"Tutorial", 'data-microtip-position':'bottom', 'role':'tooltip' },
                  el('i.icon-help.icon-button', [el('span.path1'),el('span.path2'),el('span.path3')])
                )
              ),
              this.cruiseTutorialButton = el('div.siimple-navbar-item.topbar-button',
                el('div.siimple-btn.siimple-btn--primary', { onclick:(()=>{this.showTutorial()})}, 'TUTORIAL LIST'
                )
              ),
            ]
          ),
          this.previewbuttons = el('div.siimple-navbar-title.steplist',
            [
              this.previewwback = el('div.topprogressbar'),
              el('div.siimple-breadcrumb',
                [
                  // el('i.siimple-breadcrumb-item', {onclick:()=>{zoomManager.setScreen('vr')}}, 'VR'),
                  el('i.siimple-breadcrumb-item', {onclick:()=>{zoomManager.setScreen('computer')}}, 'Computer'),
                  el('i.siimple-breadcrumb-item', {onclick:()=>{zoomManager.setScreen('tablet')}}, 'Tablette'),
                  el('i.siimple-breadcrumb-item', {onclick:()=>{zoomManager.setScreen('mobile')}}, 'Mobile'),
                ]
              ),
            ]
          ),
          this.leavebutton = el('div.topbar-right', {style:{display: 'none'}},
            el('div.siimple-navbar-item.topbar-button',
              this.leavetext = el('div.siimple-btn.siimple-btn--primary', { onclick:(()=>{this.leaveMode()})}, 'Got it! 👌')
            ),
          )
        ]
      );

      mount(document.body, this.container);

      this.setProgressBar();
      // Sme step need to have the project data already there
      // this.checkStep();

      let browser = _system.getBrowser();
      if (browser != 'Chrome' && browser != 'Safari') setStyle(this.browserribbon, {display:'block'});

      publish.onmodalclose = () => {
        this.showHotspots();
      }
    }

    progressbar:any;
    progressElement:any;
    setProgressBar () {
      this.progressbar = new ProgressBar.Line(this.progressElement, {
        strokeWidth: 20,
        easing: 'easeInOut',
        duration: 200,
        color: colormain,
        trailColor: colortuto,
        svgStyle: {width: '100%', height: '100%'},
        from: {color: colorsecondmain},
        to: {color: colormain},
        step: (state, bar) => {
          this.setProgressColor(state.color);
          bar.path.setAttribute('stroke', state.color);
        }
      });

      setStyle(this.progressElement, {width:(105 * this.totalstep).toString() + 'px'});
      setStyle(this.previewwback, {width:(105 * this.totallivestep).toString() + 'px'});
    }

    setProgressColor (color:string) {
      for (let i = 0; i < this.totalstep; i++) {
        if (i < this.progress) setStyle(this['step'+i], {'border-left-color':color});
        else setStyle(this['step'+i], {'border-left-color':''});
      }
    }

    progress = 0;
    totalstep = 6;
    totallivestep = 4;
    setProgress (step:number) {
      if (step == this.progress) return;
      this.progress = step;
      if (step != this.totalstep) publish.hide();
      this.progressbar.animate(step/this.totalstep);
    }

    start (name:string) {
      if (globals.mode == 'cruise') {
        setStyle(this.pictureButton, {display:'none'});
        setStyle(this.videoButton, {display:'none'});
        setStyle(this.liveButton, {display:'none'});
        setStyle(this.tutorialButton, {display:'none'});
      } else {
        setStyle(this.cruiseTutorialButton, {display:'none'});
      }

      this.setProjectTitle(name);
      this.setEditorMode();
    }

    checkStep () {
      let query:any = globals.getAllUrlParams();
      // Force show to make sure we have the last data shown especially after loading is over
      if (query.step) {
        if (query.step == 'environment') this.showEnvironment(true);
        else if (query.step == 'content') this.showContent(true);
        else if (query.step == 'animation') this.showAnimation(true);
        else if (query.step == 'journey') this.showJourney(true);
        else if (query.step == 'hotspot') this.showHotspots(true);
        else this.showEnvironment(true);
        this.modebeforelive = query.step;
      } else {
        this.modebeforelive = 'environment';
        this.showEnvironment(true);
      }
       if (globals.user && !globals.user.tutorial) this.showTutorial();
    }

    setProjectTitle (name:string) {
      if (!name) this.projectname.textContent = 'My Project';
      else this.projectname.textContent = name;
    }

    mode = "";
    showEnvironment (force?:boolean) {
      if (this.mode == 'environment' && !force) return;
      this.mode = 'environment';
      this.setProgress(1);
      journeyManager.unselectPointofviews();
      journeyManager.hideSigthsAndRail(); // Keep hideSigthsAndRail after unselect or rail is staying on page
      componentManager.unselect();
      componentManager.hideHotspots();
      componentManager.hideLights();
      gizmoController.setNoMode();
      setChildren(layerLeft, []);
      environmentSettings.showEnvironment();
    }

    showContent (force?:boolean) {
      if (this.mode == 'content' && !force) return;
      this.mode = 'content';
      this.setProgress(2);
      journeyManager.unselectPointofviews()
      journeyManager.hideSigthsAndRail(); // Keep hideSigthsAndRail after unselect or rail is staying on page
      tagManager.setMode('content');
      selectionPanel.setMode('content');
      gizmoController.setMode('content');
      selectionPanel.showPanel();

      if (selectedComponent) tagManager.setComponent(selectedComponent);
      else componentCreator.showCreator();
      // keep this at last so that light and hotspot show with good size
      componentManager.setMode('content');
    }

    showAnimation (force?:boolean) {
      if (this.mode == 'animation' && !force) return;
      this.mode = 'animation';
      this.setProgress(3);
      journeyManager.unselectPointofviews();
      journeyManager.hideSigthsAndRail(); // Keep hideSigthsAndRail after unselect or rail is staying on page
      tagManager.setMode('animation');
      selectionPanel.setMode('animation');
      gizmoController.setMode('animation');
      selectionPanel.showPanel();
      if (selectedComponent) tagManager.setComponent(selectedComponent);
      else animationCreator.showCreator();
      // keep this at last so that light and hotspot show with good size
      componentManager.setMode('animation');
    }

    showJourney (force?:boolean) {
      if (this.mode == 'journey' && !force) return;
      this.mode = 'journey';
      this.setProgress(4);
      gizmoController.setMode('pointofview');
      journeyManager.showJourney();
      componentManager.unselect();
      componentManager.hideHotspots();
      componentManager.hideLights();
    }

    showHotspots (force?:boolean) {
      if (this.mode == 'hotspot' && !force) return;
      this.mode = 'hotspot';
      this.setProgress(5);
      journeyManager.unselectPointofviews()
      journeyManager.hideSigthsAndRail(); // Keep hideSigthsAndRail after unselect or rail is staying on page
      tagManager.setMode('hotspot');
      selectionPanel.setMode('hotspot');
      gizmoController.setMode('hotspot');
      componentManager.setMode('hotspot');
      // keep showcreator after setComponentMode or it won't been shown as there is component unselect
      hotspotCreator.showCreator();
    }

    showPublish (force?:boolean) {
      this.mode = 'publish';
      this.setProgress(6);
      hideDropzones();
      publish.show();
    }

    setLeaveMode (leavetext:string) {
      setStyle(this.leavebutton, {display: 'block'});
      setStyle(this.normalbuttons, {display: 'none'});
      this.leavetext.textContent = leavetext;
    }

    unsetLeaveMode () {
      setStyle(this.leavebutton, {display: 'none'});
      setStyle(this.normalbuttons, {display: 'block'});
    }

    live = false;
    modebeforelive:string;
    showLive (launch:Boolean) {
      this.live = true;
      this.modebeforelive = this.mode;
      this.mode = '';
      publish.hide();
      journeyManager.stopPreview();
      journeyManager.hideSigths();
      journeyManager.deleteRails();
      // Settimeout to make sur everything is ok before launching
      // Suspect the channels to be slower which can create error when launching
      setTimeout(() => {
        componentManager.setLive();
        this.setLiveStyle();
        hideDropzones();
        _pipeline.reset();
        if (launch) {
          hotspotsManager.start();
          navigation.setPointofviewChangeEvent();
          // ScrollCatcher at end to make sure everything is started before scroll event is sent
          _scrollCatcher.restart();
        }
      }, 10);
    }

    setLiveStyle () {
      hideDropzones();
      this.setLeaveMode('Back to Edit ✍️');
      setStyle(this.previewbuttons, {display: 'block'});
      setStyle(this.container, {background: colormain, 'border-width':'0px'});
      setStyle(this.steplist, {display: 'none'});
      setStyle(bottombar.container, {display:'none'});
      // pointofviewPreview.hide(); before zoomManager.setLiveMode(); or wrong camera will be set for preview
      pointofviewPreview.hide();
      zoomManager.setLiveMode();
      environment.playAmbientSound();
      setTimeout(() => {
        setStyle(layerLeft, {width:'0px'});
        setStyle(layerRight, {width:'0px'});
      }, 100);
    }

    hideLive () {
      this.live = false;
      this.setEditorMode();
      if (this.modebeforelive == 'environment') this.showEnvironment();
      else if (this.modebeforelive == 'content') componentManager.setMode('content');
      else if (this.modebeforelive == 'animation') componentManager.setMode('animation');
      else if (this.modebeforelive == 'journey') journeyManager.showSigthsAndRail();
      else if (this.modebeforelive == 'hotspot') componentManager.setMode('hotspot');
      else if (this.modebeforelive == 'publish') this.showPublish();
    }

    setEditorMode () {
      this.unsetLiveStyle();
      this.unsetLeaveMode();
      componentManager.unsetLive();
      hotspotsManager.stop();
      environment.stopAmbientSound();
      _pipeline.reset();
      _scrollCatcher.stop();
      // pause because if stop and navigation has show event, it will continue moving
      journeyManager.anim.pause();
    }

    unsetLiveStyle () {
      setStyle(this.previewbuttons, {display: 'none'});
      setStyle(this.container, {background: colortuto, 'border-width':'2px'});
      setStyle(this.steplist, {display: 'block'});
      setStyle(bottombar.container, {display:'block'});
      zoomManager.unsetLiveMode();
      setTimeout(() => {
        setStyle(layerLeft, {width:'220px'});
        setStyle(layerRight, {width:'250px'});
      }, 100);
    }

    editThumbnail () {
      journeyManager.setNavigationForPreview();
      this.showLive(false);
      aboveLayer.show('Creating your thumbnail 📷');
      zoomManager.setScreen('tablet');
      setStyle(this.previewbuttons, {display: 'none'});
      setStyle(this.leavebutton, {display: 'none'});
      setAttr(this.pictureicon, {class:'siimple-spinner loader-color siimple-spinner--small'});
      exporter.setProjectImage(globals.projectid, (saved, url) => {
        this.hideLive();
        aboveLayer.hide();
        setAttr(this.pictureicon, {class:'icon-picture'});
        journeyManager.resetNavigationAfterPreview();
        if (saved) {
          toastr.success('Thumbnail updated 🧐️');
          let t = document.querySelector('.toast');
          let thumbnail = el('img', {class:'thumbnail-preview', src:url});
          mount(t, thumbnail)
        } else {
          toastr.error('Hum 😑, we add an error while updating your thumbnail');
        }
      });
    }

    downloadPreview () {
      journeyManager.setNavigationForPreview();
      this.showLive(false);
      aboveLayer.show('Creating your video 📷');
      zoomManager.setScreen('youtube');
      setStyle(this.previewbuttons, {display: 'none'});
      setStyle(this.leavebutton, {display: 'none'});
      setAttr(this.videoicon, {class:'siimple-spinner loader-color siimple-spinner--small'});
      journeyManager.launchPreviewAnimation();
      exporter.getProjectVideo(globals.name, (saved, msg) => {
        this.hideLive();
        aboveLayer.hide();
        setAttr(this.videoicon, {class:'icon-exportvideo'});
        journeyManager.stopPreview();
        journeyManager.resetNavigationAfterPreview();
        if (!saved && msg) toastr.error(msg);
      });
    }

    showTutorial () {
      tutorial.show();
      if (globals.user && !globals.user.tutorial) {
        api.post( 'v1/user/update', {tutorial:true, id:globals.designerid}, {}, () => {});
        globals.saveUserData({tutorial:true});
      }
    }

    leaveMode () {
      if (this.live) this.hideLive();
    }
}

export let topbar = new topbarClass();
