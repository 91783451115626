
import { projectInterface } from '../service/projectInterface';
import { system } from '../tools/system';
import { animation } from '../tools/animation';
import { scrollCatcherClass } from '../service/scrollcatcher';
import { responsiveClass } from '../service/responsive';
import { renameEvent } from '../contents/patterns/text';
import { hotspotcontent } from '../contents/hotspot';
import { hotspotsManagerClass } from '../contents/hotspotsManager';

import { Ellipse } from '@babylonjs/gui/2D/controls/ellipse'
import { Rectangle } from '@babylonjs/gui/2D/controls/rectangle'
import { Control } from '@babylonjs/gui/2D/controls/control'
import { IEasingFunction, BezierCurveEase, CircleEase, BounceEase, ExponentialEase } from '@babylonjs/core/Animations/easing';

export interface progressBarInterface {
  color?:Array<number>,
  visible?:boolean,
}

projectInterface.progressBar = {
  color:'c',
  visible:'v',
}

export class progressBarClass {

  _system:system;
  _scrollCatcher:scrollCatcherClass;
  _responsive:responsiveClass;
  hotspotsManager:hotspotsManagerClass;

  animation:animation;
  easeclass:IEasingFunction;

  color:Array<number>;
  visible = true;

  constructor (system:system, hotspotsManager:hotspotsManagerClass, scrollCatcher:scrollCatcherClass, responsive:responsiveClass) {
    this._system = system;
    this._scrollCatcher = scrollCatcher;
    this._responsive = responsive;
    this.hotspotsManager = hotspotsManager;

    this.animation = new animation(this._system, 10);
    this.easeclass = new ExponentialEase();

    scrollCatcher.addListener((top, perc) => {
      this.setProgress(perc)
      // this.checkHotspotsIndicator(perc);
    });

    scrollCatcher.on('stop', () => {
      this.stop();
    });
    scrollCatcher.on('start', () => {
      this.start();
    });

    this.createScrollBar();
    this.setColor([255, 0, 0]); // Default color
    this.checkHeight(1);
  }

  init (progressBarOptions:progressBarInterface) {
    this.checkOption(progressBarOptions);
  }

  setOption (progressBarOptions:progressBarInterface) {
    this.setColor(progressBarOptions.color);
    this.setVisibility(progressBarOptions.visible);
  }

  checkOption (progressBarOptions:progressBarInterface) {
    if (progressBarOptions.color !== undefined) this.setColor(progressBarOptions.color);
    if (progressBarOptions.visible !== undefined) this.setVisibility(progressBarOptions.visible);
  }

  rgba:string;
  defaultBackgroundColor = 'rgba(20, 20, 20, 0.4)';
  setColor (color:Array<number>) {
    this.color = color;
    if (!color) color = [255, 255, 255, 1];
    this.rgba = 'rgba('+color[0]+', '+color[1]+', '+color[2]+', 1)'
    this.progressbar.background = this.rgba;
    this.defaultBackgroundColor = 'rgba('+color[0]+', '+color[1]+', '+color[2]+', 0.2)'
    this.progressbackground.background = this.defaultBackgroundColor;
  }

  setVisibility (visible:boolean) {
    this.visible = visible;
  }

  progressbar:Rectangle;
  progressbackground:Rectangle;
  progresscontainer:Rectangle;
  createScrollBar () {
    this.progresscontainer = new Rectangle("");
    this.setBarDesign(this.progresscontainer);
    this.progresscontainer.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.progresscontainer.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.progresscontainer.top = '20%';
    this.progresscontainer.height = '60%';
    this.progresscontainer.left = '-1%';

    this.progressbar = new Rectangle("");
    this.setBarDesign(this.progressbar);
    this.progressbar.height = '0%';
    this.progressbar.top = '10%';
    this.progressbar.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.progressbar.zIndex = 1;
    this.progresscontainer.addControl(this.progressbar);

    this.progressbackground = new Rectangle("");
    this.setBarDesign(this.progressbackground);
    this.progressbackground.height = this.scrollHeight+'%';
    this.progresscontainer.addControl(this.progressbackground);
  }

  scale:number;
  checkHeight (scale:number) {
    this.scale = scale;
    this.progresscontainer.width = 20 * scale + 'px';
    this.progressbar.width = 3 * scale + 'px';
    this.progressbackground.width = 3 * scale + 'px';
    for (let i = 0; i < this.hotspotindicators.length; i++) {
      this.hotspotindicators[i].indicator.height = 10 * scale + 'px';
      this.hotspotindicators[i].indicator.width = 10 * scale + 'px';
    }

    for (let i = 0; i < this.stepPoints.length; i++) {
      this.stepPoints[i].height = this.pointLarger * scale + 'px';
      this.stepPoints[i].width = this.pointLarger * scale + 'px';
    }
  }

  setBarDesign (bar:Rectangle) {
    // bar.isPointerBlocker = true;
    // bar.hoverCursor = 'pointer';
    bar.thickness = 0;
  }

  clickToScroll (evt:any) {
    let perc = evt.x / this._responsive.realwidth;
    let scroll = Math.round(perc * this._scrollCatcher.scrollheight);
    this._scrollCatcher.catchTop(scroll);
  }

  stepPoints:Array<Ellipse> = [];
  setSteps (steps:number) {
    this.deleteSteps();
    for (let i = 0; i <= steps; i++) {
      let top = i/steps;
      this.addStepPoint(top, i);
    }
  }

  scrollHeight = 80;
  addStepPoint (top:number, step:number) {
    let point = new Ellipse("");
    point.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    point.top = (8 + top * this.scrollHeight).toString() + '%';
    point.thickness = 0;
    point.isPointerBlocker = true;
    point.hoverCursor = 'pointer';
    point[renameEvent["click"]].add((evt) => {
      this._scrollCatcher.moveToStep(step);
    });

    // point[renameEvent["enter"]].add((evt) => {
    //   point.background = this.rgba;
    // });
    //
    // point[renameEvent["leave"]].add((evt) => {
    //   console.log(point.alpha)
    //   if (point.alpha != 1) point.background = this.defaultBackgroundColor;
    // });

    this.stepPoints.push(point);
    this.progresscontainer.addControl(point);
  }

  deleteSteps () {
    for (let i = 0; i < this.stepPoints.length; i++) {
      let point = this.stepPoints[i];
      this.progresscontainer.removeControl(point);
      point.dispose();
    }
    this.stepPoints = [];
  }

  hotspotindicators:Array<any> = [];
  setHotspotsPosition () {
    this.removeHotspots();
    for (let i = 0; i < this.hotspotsManager.list.length; i++) {
      this.addHotspot(this.hotspotsManager.list[i]);
    }
    this.checkHeight(this.scale);
  }

  removeHotspots () {
    for (let i = 0; i < this.hotspotindicators.length; i++) {
      this.progresscontainer.removeControl(this.hotspotindicators[i].indicator);
    }
    this.hotspotindicators = [];
  }

  addHotspot (hotspot:hotspotcontent) {
    let start = hotspot.visibilityStart;
    let indicator = new Rectangle("");
    indicator.height = '5px';
    indicator.rotation = Math.PI/4;
    indicator.thickness = 0;
    indicator.top = start * 100 + '%';
    indicator.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    indicator.transformCenterY = -0.5;
    indicator.transformCenterX = -0.1;
    indicator.zIndex = 0;
    let color = hotspot.color;
    indicator.background = 'rgba('+color[0]+', '+color[1]+', '+color[2]+', 1)';
    this.hotspotindicators.push({indicator:indicator, start:start});
  }

  setProgress (progress:number) {
    this.progressbar.height = progress * this.scrollHeight + '%';
    let l = this.stepPoints.length;
    for (let i = 0; i < l; i++) {
      let point = this.stepPoints[i];
      if (progress + 0.02 >= i/(l-1)) this.showPoint(point);
      else point.background = this.defaultBackgroundColor;
    }
  }

  pointLarger = 12;
  showPoint (point:Ellipse) {
    if (point.background == this.rgba) return;
    point.background = this.rgba;
    this.animation.simple(20, (count, perc) => {
      perc = this.easeclass.ease(perc);
      point.alpha = perc;
    }, () => {
      point.alpha = 1;
    });
  }

  scrollpercentage:number;
  checkHotspotsIndicator (perc:number) {
    this.scrollpercentage = perc;
    for (let i = 0; i < this.hotspotindicators.length; i++) {
      if (this.hotspotindicators[i].start <= perc) this.progresscontainer.addControl(this.hotspotindicators[i].indicator);
      else this.progresscontainer.removeControl(this.hotspotindicators[i].indicator);
    }
  }

  start () {
    if (this.visible) {
      this._system.sceneAdvancedTexture.addControl(this.progresscontainer);
      this.setHotspotsPosition();
      // this.checkHotspotsIndicator(this.scrollpercentage);
      this.setColor(this.color);
    }
  }

  stop () {
    this._system.sceneAdvancedTexture.removeControl(this.progresscontainer);
  }
}
