
import { _system, _responsive, contentsManager, navigation } from '../service/editintale';
import { componentList } from '../component/component';
import { pointofviewsList } from '../journey/pointofviewmanager';
import { component, componentChannel } from '../component/component';
import { pointofviewChannel, pointofviewManager } from '../journey/pointofviewmanager';
import { zoomManager } from './zoomManager';
import { groupcomponent } from '../component/componentlist';
import { componentManager } from '../component/componentmanager';
import { undo } from '../service/undo';
import { mainSettings } from '../settings/mainSettings';
import { util } from '../../viewer/tools/util';
import { colorGizmoX, colorGizmoY, colorGizmoZ } from '../service/colors';
import { nakerGizmo } from './nakerGizmo';

import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh';
import { GizmoManager } from '@babylonjs/core/Gizmos/gizmoManager';
import { Color3, Quaternion } from '@babylonjs/core/Maths/math';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import toastr from 'toastr'

componentChannel.subscribe("select", (component:component, envelope) => {
  gizmoController.setComponent(component);
});

componentChannel.subscribe("unselect", (data:component, envelope) => {
  gizmoController.unselectComponent();
});

componentChannel.subscribe("change", (data:component, envelope) => {
  gizmoController.setSelectedChange();
});

componentChannel.subscribe("showtext", (data, envelope) => {
  gizmoController.setNoMode();
});

componentChannel.subscribe("hidetext", (data, envelope) => {
  gizmoController.resetGizmo();
});

componentChannel.subscribe("showtext", (data, envelope) => {
  gizmoController.noGizmo();
});

pointofviewChannel.subscribe("select", (pointofview:pointofviewManager, envelope) => {
  gizmoController.setPointofview(pointofview);
});

pointofviewChannel.subscribe("unselect", (data:pointofviewManager, envelope) => {
  gizmoController.unselectPointofvue();
});

pointofviewChannel.subscribe("change", (data:pointofviewManager, envelope) => {
  gizmoController.setSelectedChange();
});

class gizmoControllerClass {

  component:component;
  hoveredComponent:component;
  pointofview:pointofviewManager;
  hoveredPointofview:pointofviewManager;
  xColor:Color3;
  yColor:Color3;
  zColor:Color3;

  xMaterial:StandardMaterial;
  yMaterial:StandardMaterial;
  zMaterial:StandardMaterial;

  mousecheck = 0;

  constructor () {
    this.setMaterials();
    this.setPointerEvents();
    this.setManagers();
    this.setKeypressEvents();

    zoomManager.onViewChange = () => {
      if (this.component) this.component.select();
      else if (this.pointofview) this.pointofview.select();
      this.manager.utilityLayer.setRenderCamera(zoomManager.cameraedit);
      this.manager.keepDepthUtilityLayer.setRenderCamera(zoomManager.cameraedit);
      this.checkNakerEnabled();
    }

    this.noGizmo();
  }

  setMaterials () {
    this.xColor = Color3.FromHexString(colorGizmoX);
    this.yColor = Color3.FromHexString(colorGizmoY);
    this.zColor = Color3.FromHexString(colorGizmoZ);

    this.xMaterial = new StandardMaterial("texture4", _system.scene);
    this.xMaterial.emissiveColor = this.xColor;
    this.yMaterial = new StandardMaterial("texture4", _system.scene);
    this.yMaterial.emissiveColor = this.yColor;
    this.zMaterial = new StandardMaterial("texture4", _system.scene);
    this.zMaterial.emissiveColor = this.zColor;
  }

  setPointerEvents () {
    _system.canvas.addEventListener('pointermove', () => {
      if (!this.checkHovered) return;
      if (this.changing) return this.checkChange();
      this.checkComponentBelowMouse();

    });

    _system.canvas.addEventListener("pointerdown", (evt) => {
      this.mousecheck = 0;
      this.mousedown = true;
    });

    _system.canvas.addEventListener("click", (evt) => {
      // Keep that order because mouseup check if scene has been dragged
      if (this.changing) this.stopChange();
      else this.selectorMouseUp(evt);
      this.mousedown = false;
    });
  }

  checkComponentBelowMouse () {
    this.mousecheck++;
    if (this.mousedown && this.mousecheck > 5) this.dragged = true;
    var pickResult = _system.scene.pick(_system.scene.pointerX, _system.scene.pointerY, null, true, zoomManager.cameraedit);
    if (pickResult.hit) {
      if (this.mode == 'content' || this.mode == 'hotspot') {
        let component = this.getComponentFromMesh(pickResult.pickedMesh);
        if (this.hoveredComponent == component) return;
        this.hideAllSelector();
        this.setHoveredComponent(component);
      } else if (this.mode == 'pointofview') {
        let pointofview = this.getPointOfViewFromMesh(pickResult.pickedMesh);
        if (this.hoveredPointofview == pointofview) return;
        this.hideAllSelector();
        this.setHoveredPointofview(pointofview);
      }
    } else {
      this.hideAllSelector();
    }
  }

  lastGizmo:'naker'|'position'|'rotation'|'scale' = 'naker';
  setKeypressEvents () {
    document.onkeydown = (e) => {
      if (!this.component || this.pointofview) return;
      let tag = document.activeElement.tagName;
      if (tag == 'INPUT') return;
      if(e.key == 'w' || e.key == 'e'|| e.key == 'r'|| e.key == 'q'){
        // Switch gizmo type
        this.noGizmo();
        if(e.key == 'w'){
          this.setGizmoPosition();
        }
        if(e.key == 'e'){
          this.setGizmoRotation();
        }
        if(e.key == 'r'){
          this.setGizmoScale();
        }
        if(e.key == 'q'){
          this.setGizmoNaker();
        }
        this.checkNakerEnabled();
      }
    };
  }

  manager:GizmoManager;
  nakerGizmo: nakerGizmo;
  setManagers () {
    this.manager = new GizmoManager(_system.scene);
    this.manager.utilityLayer._getSharedGizmoLight().groundColor = new Color3(1, 1, 1);
    this.manager.usePointerToAttachGizmos = false;
    this.nakerGizmo = new nakerGizmo(this.manager.utilityLayer);
    // Glitch to force manager light to have at least one object and ignore naker gizmo
    this.manager.positionGizmoEnabled = true;
    this.manager.positionGizmoEnabled = false;
  }

  noGizmo () {
    this.manager.positionGizmoEnabled = false;
    this.manager.positionGizmoEnabled = false;
    this.manager.rotationGizmoEnabled = false;
    this.manager.scaleGizmoEnabled = false;
    this.nakerGizmo.enabled = false;
  }

  resetGizmo () {
    if (this.lastGizmo == 'naker') this.setGizmoNaker();
    else if (this.lastGizmo == 'position') this.setGizmoPosition();
    else if (this.lastGizmo == 'rotation') this.setGizmoRotation();
    else if (this.lastGizmo == 'scale') this.setGizmoScale();
    this.checkNakerEnabled();
  }

  setGizmoNaker () {
    this.lastGizmo = 'naker';
    this.nakerGizmo.enabled = true;
    this.nakerGizmo.yPlaneGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('position');
    });
    this.nakerGizmo.topGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('position');
    });
    this.nakerGizmo.xPlaneGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('position');
    });
    this.nakerGizmo.zPlaneGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('position');
    });


    this.nakerGizmo.xBowGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('rotation');
    });
    this.nakerGizmo.yBowGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('rotation');
    });
    this.nakerGizmo.zBowGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('rotation');
    });

    this.nakerGizmo.scaleGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('scale');
    });

    this.checkNakerEnabled();
  }

  checkNakerEnabled () {
    this.setAvailableAxis();
    if (zoomManager.view == 'perspective') {
      this.nakerGizmo.xPlaneGizmo.isEnabled = false;
      this.nakerGizmo.zPlaneGizmo.isEnabled = false;
    } else if (zoomManager.view == 'top') {
      this.nakerGizmo.xPlaneGizmo.isEnabled = false;
      this.nakerGizmo.zPlaneGizmo.isEnabled = false;
      this.nakerGizmo.topGizmo.isEnabled = false;
    } else if (zoomManager.view == 'right') {
      this.nakerGizmo.yPlaneGizmo.isEnabled = false;
      this.nakerGizmo.zPlaneGizmo.isEnabled = false;
      this.nakerGizmo.topGizmo.isEnabled = false;
    } else if (zoomManager.view == 'front') {
      this.nakerGizmo.yPlaneGizmo.isEnabled = false;
      this.nakerGizmo.xPlaneGizmo.isEnabled = false;
      this.nakerGizmo.topGizmo.isEnabled = false;
    }
  }

  setGizmoPosition () {
    this.lastGizmo = 'position';
    if (this.component && !this.component.availableAxis.position.x) return toastr.error('Positions not available on this component');
    this.manager.positionGizmoEnabled = true;
    this.manager.gizmos.positionGizmo.xGizmo._coloredMaterial.diffuseColor = this.xColor.scale(0.5);
    this.manager.gizmos.positionGizmo.yGizmo._coloredMaterial.diffuseColor = this.yColor.scale(0.5);
    this.manager.gizmos.positionGizmo.zGizmo._coloredMaterial.diffuseColor = this.zColor.scale(0.5);
    this.manager.gizmos.positionGizmo.xGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('position');
    });
    this.manager.gizmos.positionGizmo.yGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('position');
    });
    this.manager.gizmos.positionGizmo.zGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('position');
    });
  }

  setGizmoRotation () {
    this.lastGizmo = 'rotation';
    if (this.component && !this.component.availableAxis.rotation.x) return toastr.error('Rotations not available on this component');
    this.manager.rotationGizmoEnabled = true;
    // this.manager.gizmos.rotationGizmo.xGizmo.coloredMaterial.diffuseColor = this.xColor.scale(0.5);
    // this.manager.gizmos.rotationGizmo.yGizmo.coloredMaterial.diffuseColor = this.yColor.scale(0.5);
    // this.manager.gizmos.rotationGizmo.zGizmo.coloredMaterial.diffuseColor = this.zColor.scale(0.5);
    this.manager.gizmos.rotationGizmo.xGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('rotation');
    });
    this.manager.gizmos.rotationGizmo.yGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('rotation');
    });
    this.manager.gizmos.rotationGizmo.zGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('rotation');
    });
  }

  setGizmoScale () {
    this.lastGizmo = 'scale';
    if (this.component && !this.component.availableAxis.scale.x) return toastr.error('Scale not available on this component');
    else if (this.pointofview) return toastr.error('Scale not available on point of view');
    this.manager.scaleGizmoEnabled = true;
    this.manager.gizmos.scaleGizmo.uniformScaleGizmo.isEnabled = false;
    this.manager.gizmos.scaleGizmo.xGizmo._coloredMaterial.diffuseColor = this.xColor.scale(0.5);
    this.manager.gizmos.scaleGizmo.yGizmo._coloredMaterial.diffuseColor = this.yColor.scale(0.5);
    this.manager.gizmos.scaleGizmo.zGizmo._coloredMaterial.diffuseColor = this.zColor.scale(0.5);
    this.manager.gizmos.scaleGizmo.xGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('scale');
    });
    this.manager.gizmos.scaleGizmo.yGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('scale');
    });
    this.manager.gizmos.scaleGizmo.zGizmo.dragBehavior.onDragObservable.add(() => {
      this.startChange('scale');
    });
  }

  changing:boolean;
  change:'position'|'rotation'|'scale';
  startChange (change:'position'|'rotation'|'scale') {
    this.change = change;
    this.changing = true;
    zoomManager.enableCameraMove(false);
  }

  setSelectedChange () {
    // Need to keep quaternion when object is selected
    this.currentMesh.rotationQuaternion = Quaternion.RotationYawPitchRoll(this.currentMesh.rotation.y, this.currentMesh.rotation.x, this.currentMesh.rotation.z);
  }

  stopChange () {
    zoomManager.enableCameraMove(true);
    // stopChange is called when mouleave screen if component is currently setted
    // But if not setted we avoid useless calculation
    if (this.changing) undo.pushState();
    this.changing = false;
    this.change = null;
  }

  checkChange () {
    if (this.change == 'position') {
      if (this.component) this.setComponentPos();
      else if (this.pointofview) this.setPointofviewPos();
    } else if (this.change == 'rotation') {
      if (this.component) this.setComponentRot();
      else if (this.pointofview) this.setPointofviewRot();
    } else if (this.change == 'scale') {
      if (this.component) this.setComponentScale();
    }
  }

  setPointofviewPos () {
    pointofviewChannel.publish('move', this.pointofview);
    this.pointofview.pointofview.setPosition({x:this.currentMesh.position.x, y:this.currentMesh.position.y, z:this.currentMesh.position.z});
    mainSettings.setPointValue('position', this.pointofview.pointofview.position);
    navigation.showPointofview(this.pointofview.pointofview);
    // Having a second camera visible break the mesh on screen calculation
    // zoomManager.checkObjectInCamera(this.pointofview.pointofview.meshParent.mesh);
  }

  setPointofviewRot () {
    let newrot = this.currentMesh.rotationQuaternion.toEulerAngles();
    this.pointofview.pointofview.setRotation({x:newrot.x * util.radtodegratio, y:newrot.y * util.radtodegratio, z:newrot.z * util.radtodegratio});
    mainSettings.setPointValue('rotation', this.pointofview.pointofview.rotation);
    navigation.showPointofview(this.pointofview.pointofview);
  }

  setComponentPos () {
    this.component.content.setPosition({x:this.currentMesh.position.x, y:this.currentMesh.position.y, z:this.currentMesh.position.z});
    mainSettings.setPointValue('position', this.component.content.position);
    zoomManager.checkObjectInCamera(this.component.content.pattern.mesh);
  }

  setComponentRot () {
    let newrot = this.currentMesh.rotationQuaternion.toEulerAngles();
    this.component.content.setRotation({x:newrot.x * util.radtodegratio, y:newrot.y * util.radtodegratio, z:newrot.z * util.radtodegratio});
    mainSettings.setPointValue('rotation', this.component.content.rotation);
  }

  setComponentScale () {
    this.component.content.setScale({x:this.currentMesh.scaling.x, y:this.currentMesh.scaling.y, z:this.currentMesh.scaling.z});
    this.component.content.setPosition({x:this.currentMesh.position.x, y:this.currentMesh.position.y, z:this.currentMesh.position.z});
    mainSettings.setPointValue('scale', this.component.content.scale);
    mainSettings.setPointValue('position', this.component.content.position);
    mainSettings.setPointValue('rotation', this.component.content.rotation);
  }

  hideAllSelector () {
    for (let i = 0; i < componentList.length; i++) {
      componentList[i].hideSelector();
    }
    for (let i = 0; i < pointofviewsList.length; i++) {
      pointofviewsList[i].hideSelector();
    }
    this.unsetHoveredComponent();
    this.unsetHoveredPointofview();
  }

  checkHovered = false;
  setPointofviewMode () {
    this.hideAllSelector();
    this.checkHovered = true;
    this.mode = 'pointofview';
  }

  mode:'pointofview'|'content'|'animation'|'no'|'hotspot' = 'no';
  setMode (mode:'pointofview'|'content'|'animation'|'no'|'hotspot') {
    this.mode = mode;
    if (mode == 'content') this.setComponentMode();
    else if (mode == 'animation') this.setComponentMode();
    else if (mode == 'hotspot') this.setHotspotMode();
    else if (mode == 'pointofview') this.setPointofviewMode();
    else if (mode == 'no') this.setNoMode();
  }

  setComponentMode () {
    this.hideAllSelector();
    this.checkHovered = true;
  }

  setHotspotMode () {
    this.hideAllSelector();
    this.checkHovered = true;
    this.mode = 'hotspot';
  }

  // Nothing to drag when on environment
  setNoMode () {
    this.checkHovered = false;
    this.hideAllSelector();
    this.mode = 'no';
  }

  setHoveredComponent (component:component) {
    this.hoveredComponent = component;
    if (component) component.showSelector();
  }

  unsetHoveredPointofview () {
    if (this.hoveredPointofview) this.hoveredPointofview.hideSelector();
    this.hoveredPointofview = null;
  }

  setHoveredPointofview (pointofview:pointofviewManager) {
    this.hoveredPointofview = pointofview;
    if (pointofview) pointofview.showSelector();
  }

  unsetHoveredComponent () {
    if (this.hoveredComponent) this.hoveredComponent.hideSelector();
    this.hoveredComponent = null;
  }

  getComponentFromMesh (pickedmesh:AbstractMesh) {
    let component = this.checkIfMeshMatchContent(pickedmesh);
    if (component) return component;
    while (pickedmesh.parent) {
      pickedmesh = pickedmesh.parent;
      let component = this.checkIfMeshMatchContent(pickedmesh);
      if (component) return component;
    }
  }

  checkIfMeshMatchContent (mesh:AbstractMesh) {
    let list = (this.mode == 'hotspot')? componentManager.getHotposts() : componentManager.getComponents();
    for (let i = 0; i < list.length; i++) {
      let content = list[i].content;
      if (!content.locked) {
        let patmesh = content.pattern.mesh;
        if (patmesh == mesh) return list[i];
      }
    }
    return false;
  }

  getPointOfViewFromMesh (pickedmesh:AbstractMesh) {
    for (let i = 0; i < pointofviewsList.length; i++) {
      let pointofview = pointofviewsList[i].pointofview;
      let patmesh = pointofview.meshParent.mesh;
      if (patmesh.name == pickedmesh.name) return pointofviewsList[i];
      let test = patmesh.getChildren((mesh) => {
        return mesh.name == pickedmesh.name;
      }, false);
      if (test.length != 0) return pointofviewsList[i];
    }
    return false;
  }

  setComponent (component:component) {
    this.component = component;
    this.noGizmo();
    this.resetGizmo();
    this.attachToMesh(component.content.pattern.mesh);
    this.checkNakerEnabled();
  }

  setPointofview (pointofviewManager:pointofviewManager) {
    this.pointofview = pointofviewManager;
    this.noGizmo();
    this.resetGizmo();
    this.attachToMesh(pointofviewManager.pointofview.meshParent.mesh);
    this.checkNakerEnabled();
  }

  currentMesh:AbstractMesh;
  attachToMesh (mesh:AbstractMesh) {
    this.currentMesh = mesh;
    this.manager.attachToMesh(mesh);
    this.nakerGizmo.attachedMesh = mesh;
    this.manager.utilityLayer.setRenderCamera(zoomManager.cameraedit);
    this.manager.keepDepthUtilityLayer.setRenderCamera(zoomManager.cameraedit);
  }

  detach () {
    if (!this.currentMesh) return;
    this.currentMesh.rotationQuaternion = null;
    this.currentMesh = null;
    this.manager.attachToMesh(null);
    this.nakerGizmo.attachedMesh = null;
  }

  setAvailableAxis () {
    if (this.component) {
      let availableAxis = this.component.availableAxis;
      if (this.lastGizmo == 'naker') {
        if (availableAxis.position.x) this.nakerGizmo.enablePosition(true);
        else this.nakerGizmo.enablePosition(false);
        if (availableAxis.rotation.x) this.nakerGizmo.enableRotation(true);
        else this.nakerGizmo.enableRotation(false);
        if (availableAxis.scale.x) this.nakerGizmo.enableScale(true);
        else this.nakerGizmo.enableScale(false);
      } else if (this.lastGizmo == 'position') {
        if (availableAxis.position.x) this.manager.positionGizmoEnabled = true;
        else this.manager.positionGizmoEnabled = false;
      } else if (this.lastGizmo == 'rotation') {
        if (availableAxis.rotation.x) this.manager.rotationGizmoEnabled = true;
        else this.manager.rotationGizmoEnabled = false;
      } else if (this.lastGizmo == 'scale') {
        if (availableAxis.scale.x) this.manager.scaleGizmoEnabled = true;
        else this.manager.scaleGizmoEnabled = false;
      }
    } else if (this.pointofview) {
      if (this.lastGizmo == 'naker') {
        this.nakerGizmo.enablePosition(true);
        this.nakerGizmo.enableRotation(true);
        this.nakerGizmo.enableScale(false);
      } else if (this.lastGizmo == 'position') {
        this.manager.positionGizmoEnabled = true;
      } else if (this.lastGizmo == 'rotation') {
        this.manager.rotationGizmoEnabled = true;
      } else if (this.lastGizmo == 'scale') {
        this.manager.scaleGizmoEnabled = false;
      }
    }
  }

  dragged = false;
  mousedown = false;
  selectorMouseUp (evt?:any) {
    if (zoomManager.livemode) return;
    if (!this.dragged) {
      // if (this.startsaved) return this.stopChange();
      this.checkSelection(evt);
    }
    this.dragged = false;
  }

  checkSelection (evt) {
    if (this.hoveredComponent) {
      // onresize check or it will create group when resizing with keepratio
      if ((evt.ctrlKey || evt.shiftKey || evt.metaKey) && this.component && onresize == null) this.checkGroup();
      else if (!this.hoveredComponent.selected) {
        this.pointofview = undefined;
        this.selectComponent();
      }
    } else if (this.hoveredPointofview) {
      if (!this.hoveredPointofview.selected) {
        this.component = undefined;
        this.hoveredPointofview.select();
      }
    } else {
      this.unselect(true);
    }
  }

  selectComponent () {
    this.unselect(false);
    this.selectedList.push(this.hoveredComponent);
    if (this.hoveredComponent.type == 'group') this.currentgroup = this.hoveredComponent;
    this.hoveredComponent.select();
  }

  unselect (waitBeforeDeleteComponent:boolean) {
    this.waitBeforeDeleteComponent = waitBeforeDeleteComponent;
    if (this.component) {
      this.component.unselect();
    } else if (this.pointofview) {
      this.pointofview.unselect();
    }
  }

  // need a settimeout in case of doucle click for multiview
  waitBeforeDeleteComponent = false;
  unselectComponent () {
    if (this.waitBeforeDeleteComponent) {
      setTimeout(() => {
        if (!zoomManager.multiview) {
          this.component = null;
          this.currentgroup = undefined;
        }
      }, 200);
    } else {
      this.component = null;
      this.currentgroup = undefined;
    }

    this.detach();
  }

  unselectPointofvue () {
    // settimeout in case of doucle click for multiview
    if (this.waitBeforeDeleteComponent) {
      setTimeout(() => {
        if (!zoomManager.multiview) {
          this.pointofview = null;
        }
      }, 500);
    } else {
      this.pointofview = null;
    }
    this.detach();
  }


  selectedList:Array<component> = [];
  checkGroup () {
    // Can't have group merge with another component or stop if component already in one group
   if (this.hoveredComponent.type == 'group' || this.hoveredComponent.content.ingroup) return;
   let index = this.selectedList.indexOf(this.hoveredComponent);
   if (index == -1) this.selectedList.push(this.hoveredComponent);
   if (this.currentgroup == undefined) {
     this.createGroup();
   } else {
     this.addInGroup();
   }
  }

  currentgroup:groupcomponent;
  createGroup () {
    this.component.unselect();
    let groupkey = Math.random().toString(36).substring(2, 15);
    // Can't merge with component already in one group
    for (let i = 0; i < this.selectedList.length; i++) {
      if (this.selectedList[i].content.groupkey != '') return;
    }
    for (let i = 0; i < this.selectedList.length; i++) {
      this.selectedList[i].content.groupkey = groupkey;
    }
    let group:groupcomponent = componentManager.addComponent('group', {position:{x:0, y:0, z:0}, scale:{x:0, y:0, z:0}, rotation:{x:0, y:0, z:0}}, {key:groupkey});
    group.addComponents();
    group.content.getGroupNewPosition();
    group.content.show();
    contentsManager.removeContent(group.content);
    group.content.removeContentsGroupKey();
    group.content.validated = false;
    this.currentgroup = group;
    // Don't know why small timeout needed or selector not at the right place
    setTimeout(() => {
      group.select();
    }, 10)
  }

  addInGroup () {
    this.currentgroup.addComponent(this.hoveredComponent, false);
    undo.pushState();
  }
}

export let gizmoController = new gizmoControllerClass();
