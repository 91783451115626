
import { gizmoAxisClass } from './gizmoAxis';
import { animation } from '../../viewer/tools/animation';
import { _system, _responsive, _scrollCatcher, environment, navigation, _pipeline } from '../service/editintale';
import { colordarkgrey, colormain } from '../service/colors';

import { el, setStyle, mount } from 'redom';
import { Mesh } from '@babylonjs/core/Meshes/Mesh';
import { Vector3, Matrix } from '@babylonjs/core/Maths/math';
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
import { DefaultRenderingPipeline } from '@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/defaultRenderingPipeline';
import { ImageProcessingConfiguration } from '@babylonjs/core/Materials/imageProcessingConfiguration';
import { FreeCamera } from '@babylonjs/core/Cameras/freeCamera'
// import { Camera } from '@babylonjs/core/Cameras/Camera'
import { Viewport } from '@babylonjs/core/Maths/math'

export let zoomzone = document.getElementById('zoomzone');
export let boxShadow = document.getElementById('boxShadow');

_scrollCatcher.addListener((scroll:number, scrollPercentage:number) => {
  zoomManager.setPercentage(scrollPercentage);
});

/*
  +------------------------------------------------------------------------+
  | COMPONENT MOVEMENT MANAGER                                             |
  +------------------------------------------------------------------------+
*/

class zoomManagerClass {

  screensizes = {
    mobile: {x:375, y:667},
    tablet: {x:1024, y:768},
    computer: {x:1920, y:1080},
    vr: {x:1920, y:1080},
    youtube: {x:1280, y:720},
    // youtube: {x:640, y:360},
  };
  rightblur:HTMLElement;
  leftblur:HTMLElement;
  topblur:HTMLElement;
  bottomblur:HTMLElement;
  percentage:HTMLElement;
  pointofview:HTMLElement;
  cameraedit:ArcRotateCamera;
  anim:animation;
  gizmoAxis:gizmoAxisClass;

  constructor () {
    this.rightblur = el('div', {class:'blurLayer'});
    mount(zoomzone, this.rightblur);
    this.leftblur = el('div', {class:'blurLayer'});
    mount(zoomzone, this.leftblur);
    this.topblur = el('div', {class:'blurLayer'});
    mount(zoomzone, this.topblur);
    this.bottomblur = el('div', {class:'blurLayer'});
    mount(zoomzone, this.bottomblur);
    this.percentage = el('div', {class:'preview-text preview-percentage'});
    mount(zoomzone, this.percentage);
    this.pointofview = el('div', {class:'preview-text preview-pointofview'});
    mount(zoomzone, this.pointofview);
    this.screensizes.vr = {x:window.innerWidth, y:window.innerHeight};
    this.anim = new animation(_system);

    _system.canvas.addEventListener('dblclick', () => {
      this.checkMultiView();
    });

    setStyle(_system.canvas, {position:'absolute', 'border-radius':'5px 5px 5px 5px'});

    this.createPerpectiveCamera();
    this.createAngleCamera();
    this.setAngleCameraEvent();
    this.addMultiViewUIs();
    _pipeline.removeCamera(this.cameraedit);
    this.gizmoAxis = new gizmoAxisClass(this.perspectivecamera);
    this.setView('perspective');
  }

  perspectivecamera:ArcRotateCamera;
  createPerpectiveCamera () {
    this.perspectivecamera = new ArcRotateCamera('perspective_camera', -Math.PI/2, 0.8, 10, new Vector3(0, 0, 0), _system.scene);
    this.perspectivecamera.inertia = 0;
    this.perspectivecamera.angularSensibilityX = 50000/environment.skySize;
    this.perspectivecamera.angularSensibilityY = 50000/environment.skySize;
    this.perspectivecamera.panningSensibility = 5000/environment.skySize;
    this.perspectivecamera.panningInertia = 0;
    this.perspectivecamera.wheelPrecision = 2;
    this.perspectivecamera.lowerRadiusLimit = environment.skySize/50;
    this.perspectivecamera.upperRadiusLimit = environment.skySize;
    this.perspectivecamera.layerMask = 0x0FFFFFFF|0x20000000;
    // Check camera doesn't go outside sky sphere
    _system.scene.registerBeforeRender(() => {
      if (!this.cameraedit.setPosition) return;
      if (Vector3.Distance(this.cameraedit.position, Vector3.Zero()) < 45) return;
      let pos = this.cameraedit.position.clone();
      for (let key in {x:0, y:0, z:0}) {
        if (pos[key] > environment.skySize) pos[key] = environment.skySize;
        if (pos[key] < -environment.skySize) pos[key] = -environment.skySize;
      }
      this.cameraedit.setPosition(pos);
    });
  }

  frontcamera:FreeCamera;
  rightcamera:FreeCamera;
  topcamera:FreeCamera;
  createAngleCamera () {
    this.frontcamera = new FreeCamera('front_camera', new Vector3(0, 0, 0), _system.scene);
    this.frontcamera.minZ = 0;
    this.frontcamera.layerMask = 0x0FFFFFFF|0x20000000;
    // this.frontcamera.mode = Camera.ORTHOGRAPHIC_CAMERA;

    this.rightcamera = new FreeCamera('right_camera', new Vector3(0, 0, 0), _system.scene);
    this.rightcamera.minZ = 0;
    this.rightcamera.layerMask = 0x0FFFFFFF|0x20000000;
    // this.frontcamera.mode = Camera.ORTHOGRAPHIC_CAMERA;

    this.topcamera = new FreeCamera('top_camera', new Vector3(0, 0, 0), _system.scene);
    this.topcamera.minZ = 0;
    this.topcamera.layerMask = 0x0FFFFFFF|0x20000000;
    // this.frontcamera.mode = Camera.ORTHOGRAPHIC_CAMERA;

    // Need to set a default pipeline to avoid conflit with real pipeline scene
    let defaultPipeline = new DefaultRenderingPipeline("edit_default_pipeline", true, _system.scene, [this.perspectivecamera, this.frontcamera, this.rightcamera, this.topcamera]);
    defaultPipeline.imageProcessingEnabled = true;
    defaultPipeline.imageProcessing.imageProcessingConfiguration = new ImageProcessingConfiguration();
    defaultPipeline.imageProcessing.imageProcessingConfiguration.applyByPostProcess = true;
    defaultPipeline.imageProcessing.imageProcessingConfiguration.contrast = 1;
  }

  cursorDown = false;
  scale = 0.01;
  oldCamState = { x: 0, y: 0, z: 0 };
  cursorState = { x: 0, y: 0 };
  cameraMoveEnable = true;

  enableCameraMove (bool:boolean) {
    this.cameraMoveEnable = bool;
  }

  setAngleCameraEvent () {
    _system.canvas.addEventListener('pointerdown', (evt, pic) => {
      if (this.view == 'perspective' || !this.cameraMoveEnable) return;
      this.cursorDown = true;
      this.oldCamState.x = this.cameraedit.position.x;
      this.oldCamState.y = this.cameraedit.position.y;
      this.oldCamState.z = this.cameraedit.position.z;

      this.cursorState.x = evt.clientX;
      this.cursorState.y = evt.clientY;
    });
    _system.canvas.addEventListener('pointerup', (evt, pic) => {
      this.cursorDown = false;
    });

    _system.canvas.addEventListener('pointermove', (evt, pic) => {
      if (this.view == 'perspective' || !this.cameraMoveEnable) return;
      if (this.cursorDown) {
        if (this.view == 'top') {
          this.cameraedit.position.z = this.oldCamState.z + this.scale *  1. * ( evt.clientY - this.cursorState.y );
          this.cameraedit.position.x = this.oldCamState.x + this.scale * -1. * ( evt.clientX - this.cursorState.x );
        } else if (this.view == 'right') {
          this.cameraedit.position.y = this.oldCamState.y + this.scale * -1. * ( evt.clientY - this.cursorState.y );
          this.cameraedit.position.z = this.oldCamState.z + this.scale *  1. * ( evt.clientX - this.cursorState.x );
        } else {
          this.cameraedit.position.y = this.oldCamState.y + this.scale * -1. * ( evt.clientY - this.cursorState.y );
          this.cameraedit.position.x = this.oldCamState.x + this.scale * -1. * ( evt.clientX - this.cursorState.x );
        }
      }
    });

    zoomzone.addEventListener("mousewheel", (evt) => {
      let move = evt.deltaY;
      if (this.view == 'top') {
        this.cameraedit.position.y += move/100;
      } else if (this.view == 'right') {
        this.cameraedit.position.x += move/100;
      } else {
        this.cameraedit.position.z += move/100;
      }
    });
  }

  multiViewUIs:any = {};
  view:'perspective'|'top'|'right'|'front' = 'perspective';
  addMultiViewUIs () {
    this.addMultiViewUI('perspective', 0, 0);
    this.addMultiViewUI('top', 50, 0);
    this.addMultiViewUI('right', 0, 50);
    this.addMultiViewUI('front', 50, 50);
  }

  addMultiViewUI (view:'perspective'|'top'|'right'|'front', left:number, top:number) {
    let container = el('div.multiviewcontainer',
      {onclick:()=>{this.setView(view)}, onmouseenter:()=>{this.setHighlightView(view)}, style:{display:'none', top: top+'%', left: left+'%'}},
      el('div.multiviewbutton', view.toUpperCase())
    );
    this.multiViewUIs[view] = container;
    mount(zoomzone, container);
    return container;
  }

  showMultiViewUI () {
    for (let key in this.multiViewUIs) {
      setStyle(this.multiViewUIs[key], {display:'block'});
    }
  }

  hideMultiViewUI () {
    for (let key in this.multiViewUIs) {
      setStyle(this.multiViewUIs[key], {display:'none'});
    }
  }

  setHighlightView (view:'perspective'|'top'|'right'|'front') {
    for (let key in this.multiViewUIs) {
      setStyle(this.multiViewUIs[key], {'box-shadow':'0px 0px 0px 1px '+colordarkgrey+' inset'});
    }
    setStyle(this.multiViewUIs[view], {'box-shadow':'0px 0px 0px 1px '+colormain+' inset'});
  }

  onViewChange:Function; // Callback used by gizmoController to keep selection on view change
  setView (view:'perspective'|'top'|'right'|'front') {
    this.multiview = false;
    this.view = view;
    let newcamera = this[view+'camera'];
    newcamera.viewport = new Viewport(0, 0, 1, 1);
    this.cameraedit = newcamera;
    _system.scene.activeCamera = newcamera;
    _system.scene.activeCameras = [];
    this.hideMultiViewUI();
    if (view == 'perspective') this.gizmoAxis.show();
    if (this.onViewChange) this.onViewChange(view);
  }

  multiview = false;
  checkMultiView () {
    if (this.livemode) return;
    if (!this.multiview) {
      this.setMultiView();
    }
    this.multiview = true;
  }

  setMultiView () {
    this.gizmoAxis.hide();
    this.perspectivecamera.viewport = new Viewport(0, 0.5, 0.5, 0.5);
    this.frontcamera.viewport = new Viewport(0.5, 0, 0.5, 0.5);
    this.rightcamera.viewport = new Viewport(0, 0, 0.5, 0.5);
    this.topcamera.viewport = new Viewport(0.5, 0.5, 0.5, 0.5);

    this.frontcamera.position = this.perspectivecamera.target.clone();
    this.frontcamera.position.z += 10;
    this.rightcamera.position = this.perspectivecamera.target.clone();
    this.rightcamera.position.x += 10;
    this.topcamera.position = this.perspectivecamera.target.clone();
    this.topcamera.position.y += 10;
    this.frontcamera.setTarget(this.perspectivecamera.target);
    this.rightcamera.setTarget(this.perspectivecamera.target);
    this.topcamera.setTarget(this.perspectivecamera.target);

    _system.scene.activeCameras = [this.frontcamera, this.rightcamera, this.topcamera, this.perspectivecamera];
    this.showMultiViewUI();
  }

  currentscreen:'mobile'|'tablet'|'computer'|'vr'|'youtube' = 'computer';
  setScreen (screen:'mobile'|'tablet'|'computer'|'vr'|'youtube') {
    this.currentscreen = screen;
    if (screen == 'vr') return this.setVRScreen();
    navigation.setNormalCamera();
    this.showBorders();
    let size = this.screensizes[screen];
    this.width = size.x;
    this.height = size.y;
    this.checkScreenSize();
    _system.engine.resize();
    _responsive.checkSize();
  }

  checkScreenSize () {
    if (window.innerWidth) {
      let screenWidth = window.innerWidth;
      let ratioWidth = screenWidth/this.width;
      let screenHeight = window.innerHeight - 50;
      let ratioHeight = screenHeight/this.height;
      let smallestScale = Math.min(ratioWidth, ratioHeight, 1);
      let newScale = Math.floor(smallestScale * 10)/10;
      this.setContainerSize(newScale);
    }
  }

  setVRScreen () {
    this.hideBorders();
    setStyle(_system.container, {height:'100%', width:'100%', transform:'translate(-50%, -50%) scale(1)'});
    navigation.setVRCamera();
    _system.engine.resize();
  }

  width = 1024;
  height = 768;
  containerScale:number = 1;
  setContainerSize (scale:number) {
    this.containerScale = scale;
    let halfheight = (this.height * this.containerScale / 2).toString();
    let halfwidth = (this.width * this.containerScale / 2).toString();
    let height = (this.height * this.containerScale).toString();
    let width = (this.width * this.containerScale).toString();
    setStyle(_system.container, {height:height+'px', width:width+'px'});
    setStyle(boxShadow, {height:height+'px', width:width+'px'});
    setStyle(this.rightblur, {top:'calc(50% - '+halfheight+'px)', left:'calc(50% + '+halfwidth+'px)', 'z-index':10});
    setStyle(this.leftblur, {bottom:'calc(50% - '+halfheight+'px)', right:'calc(50% + '+halfwidth+'px)', 'z-index':10});
    setStyle(this.topblur, {bottom:'calc(50% + '+halfheight+'px)', left:'calc(50% - '+halfwidth+'px)', 'z-index':10});
    setStyle(this.bottomblur, {top:'calc(50% + '+halfheight+'px)', right:'calc(50% - '+halfwidth+'px)', 'z-index':10});
  }

  currentpointofview:string;
  pointofviewmode = false;
  hideBorders () {
    this.currentpointofview = undefined;
    this.pointofviewmode = true;
    setStyle(boxShadow, {display:'none'});
    setStyle(this.rightblur, {display:'none'});
    setStyle(this.leftblur, {display:'none'});
    setStyle(this.topblur, {display:'none'});
    setStyle(this.bottomblur, {display:'none'});
    setStyle(this.percentage, {display:'none'});
    setStyle(this.pointofview, {display:'none'});
  }

  showBorders (pointofview?:string) {
    this.currentpointofview = pointofview;
    this.pointofviewmode = false;
    setStyle(boxShadow, {display:'block'});
    setStyle(this.rightblur, {display:'block'});
    setStyle(this.leftblur, {display:'block'});
    setStyle(this.topblur, {display:'block'});
    setStyle(this.bottomblur, {display:'block'});
    setStyle(this.percentage, {display:'block'});
    setStyle(this.pointofview, {display:'block'});
  }

  checkObjectInCamera (mesh:Mesh) {
    let rapidity = 0.01;
    if (this.view != 'perspective') return;
    if (!this.meshOnScreen(mesh)) {
      let change = mesh.position.subtract(zoomManager.cameraedit.target);
      let move = change.multiply(new Vector3(rapidity, rapidity, rapidity));
      let newtarget = zoomManager.cameraedit.target.add(move);
      zoomManager.cameraedit.setTarget(newtarget);
    }
  }

  meshOnScreen (mesh:Mesh) {
    let w = zoomzone.clientWidth;
    let h = zoomzone.clientHeight;
    let  gap = 100;
    let windowcoordinate = Vector3.Project(mesh.getAbsolutePosition(), Matrix.Identity(), _system.scene.getTransformMatrix(), this.cameraedit.viewport.toGlobal(w, h) );
    let onScreen = true;
    if (windowcoordinate.x < gap) onScreen = false;
    if (windowcoordinate.x > w - gap) onScreen = false;
    if (windowcoordinate.y < gap) onScreen = false;
    if (windowcoordinate.y > h - gap) onScreen = false;
    return onScreen;
  }

  focusOn (mesh:Mesh, force?:boolean) {
    if (this.meshOnScreen(mesh) && !force) return;
    let start = this.cameraedit.target.clone();
    let change = mesh.position.subtract(start);
    let startradius = this.cameraedit.radius;
    let maxScale = 0;
    for (let axe in {x:0, y:0, z:0}) {
      maxScale = Math.max(maxScale, mesh.scaling[axe])
    }
    let changeradius = maxScale * 4 - startradius;
    this.anim.simple(10, (count, perc) => {
      let move = change.multiply(new Vector3(perc, perc, perc));
      let newtarget = start.add(move);
      this.cameraedit.setTarget(newtarget);
      this.cameraedit.radius = startradius + changeradius * perc;
    }, () => {
      let newtarget = start.add(change);
      this.cameraedit.setTarget(newtarget);
      this.cameraedit.radius = startradius + changeradius;
    });
  }

  livemode = false;
  pointOfViewsSorted:Array<string>;
  setLiveMode () {
    this.gizmoAxis.hide();
    this.livemode = true;
    this.showBorders();
    let browser = _system.getBrowser();
    let canvasposition = (browser == 'Safari')? '-webkit-sticky' : 'sticky';
    mount(_system.container, _system.canvas);
    setStyle(_system.container, {display: 'block'});
    setStyle(_system.canvas, {'border-radius': '0px', position:canvasposition});
    setStyle(zoomzone, {height:'calc(100% - 38px)', top: '38px'});
    setStyle(zoomzone, {width:'calc(100% + 0px)', left:'0px'});
    this.cameraedit.detachControl(_system.canvas);
    _system.sceneAdvancedTexture.layer.layerMask = 0x10000000;
    this.setScreen(this.currentscreen);
    this.pointOfViewsSorted = navigation.getSortedPointofViews();
    this.setPercentage(0);
  }

  unsetLiveMode () {
    this.livemode = false;
    this.hideBorders();
    mount(zoomzone, _system.canvas);
    setStyle(_system.container, {display: 'none'});
    setStyle(_system.canvas, {'border-radius': '5px', position:'absolute'});
    setStyle(zoomzone, {height:'calc(100% - 105px)', top: '42px'});
    setStyle(zoomzone, {width:'calc(100% - 473px)', left:'221px'});
    this.cameraedit.attachControl(_system.canvas);
    // Force guicamera to use normal camera or VR mode can stay when leaving preview
    navigation.setNormalCamera();
    _system.scene.activeCameras = [];
    _system.scene.activeCamera = this.cameraedit;
    _system.sceneAdvancedTexture.layer.layerMask = 0x20000000;
    _system.engine.resize();
    _responsive.checkSize();
    this.gizmoAxis.show();
  }

  setPercentage (perc:number) {
    this.percentage.textContent = 'Journey: '+Math.round(perc * 100).toString()+'%';
    let povLength = this.pointOfViewsSorted.length - 1;
    let newPov = Math.round(perc * povLength);
    this.pointofview.textContent = 'Point of view: '+this.pointOfViewsSorted[newPov];
  }
}

export let zoomManager = new zoomManagerClass();
