
import { projectInterface } from '../../viewer/service/projectInterface';

import Cookies from 'js-cookie';

// Code to force typescript allow Intercom function
declare global {
    interface Window { Intercom: any; }
}
window.Intercom = window.Intercom || {};

interface user {
  id?:string,
  name?:string,
  password?:string,
  email?:string,
  company?:string,
  type?:string,
  projectcreated?:number,
  projectdeleted?:number,
  active?:Boolean,
  admin?:Boolean,
  tutorial?:Boolean,
  token?:string,
  refresh_token?:string
}

projectInterface.summary = {
  projectid:'projectid',
  version:'version',
  name:'name',
  designerid:'designerid',
  date:'date',
  timestamp:'timestamp',
}

interface summary {
  projectid?:string,
  version?:string,
  name?:string,
  designerid?:string,
  date?:string,
  timestamp?:number,
}

class globalsClass {

  environments = {
    app: {
      apiurl: 'https://naker-backend-prod.herokuapp.com/',
			save: true,
			redirect: true,
      intercom: true
    },
    staging: {
      apiurl: 'https://naker-backend-prod.herokuapp.com/',
			save: true,
			redirect: true,
      intercom: false
    },
    test: {
      apiurl: 'https://naker-backend.herokuapp.com/',
			save: true,
			redirect: false,
      intercom: false
    },
    development: {
      apiurl: 'http://localhost:3000/',
			save: false,
			redirect: true,
      intercom: false
    },
    cruise: {
      apiurl: 'https://naker-backend.herokuapp.com/',
      save: false,
      redirect: false,
      intercom: false
    },
  }

  mode:'app'|'staging'|'test'|'development'|'cruise';
  projectid:string;
  designer:string;
  designerid:string;
  name:string;
  date:string;
  timestamp:number;

  apiurl:string;
  save: boolean;
  redirect: boolean;
  intercom: boolean;
  // We force project to use last version of the viewer
  // NOTE Remenber to update version here when production update is made
  version = '1.0.1';

  subdomain:string;

  constructor () {
    this.subdomain = this.getSubdomain();
    if (!this.subdomain) this.mode = 'development';
    else this.mode = this.subdomain;

    this.apiurl = this.environments[this.mode].apiurl;
		this.save = this.environments[this.mode].save;
    this.redirect = this.environments[this.mode].redirect;
		this.intercom = this.environments[this.mode].intercom;

    this.initProjectValues();
  }

  user:user;
  initProjectValues () {
    let userCookie = Cookies.get('user');
    if (this.save) {
      if (userCookie == undefined) return;
      let userObject = JSON.parse(userCookie);
      this.user = userObject;
      this.designer = userObject.name;
      this.designerid = userObject.id;
      let url = window.location.href;
      this.projectid = url.split('/').pop();
    } else {
      this.designer = 'rere';
      this.designerid = '5ca2097051c02717248fbaae';
      this.projectid = '5cc85dd4b5b3860ad0f020df';
    }

    if (this.intercom && this.user) {
      window.Intercom('boot', {
        app_id: 'sz0pojg8',
        name: this.user.name, // Full name
        // email: this.user.email, // Email address
        user_id: this.user.id, // Email address
        // created_at: “<%= current_user.created_at.to_i %>” // Signup date as a Unix timestamp
      });
    }
  }

  getSave() {
    return this.save;
  }

  getApiUrl() {
    return this.apiurl;
  }

  getDesignerId() {
    return this.designerid;
  }

  getProjectId() {
    return this.projectid;
	}

	getRedirect() {
		return this.redirect;
	}

  saveUserData (data:user) {
    for (let key in data) {
      this.user[key] = data[key];
    }
    Cookies.set('user', JSON.stringify(globals.user), { expires: 7 });
  }

  setProjectData (data:summary) {
    //this.projectid = data.projectid;
    this.name = data.name;
    this.date = data.date;
    this.timestamp = data.timestamp;
  }

  getSummary () {
    let date = new Date();
    this.date = date.getFullYear()+'/'+date.getMonth()+'/'+date.getDate();
    this.timestamp = date.getTime();

    let summary:any = {};
    for (let key in projectInterface.summary) {
      summary[key] = this[key];
    }
    return summary;
	}

  getSubdomain () {
    let hostname = window.location.hostname;
    var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    var urlParts = regexParse.exec(hostname);
    return hostname.replace(urlParts[0],'').slice(0, -1);
  }

  getAllUrlParams () {
    var queryString = window.location.search.slice(1);
    var obj = {};

    if (queryString) {
      queryString = queryString.split('#')[0];
      var arr = queryString.split('&');

      for (var i = 0; i < arr.length; i++) {
        var a = arr[i].split('=');
        var paramName = a[0];
        var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

        paramName = paramName.toLowerCase();
        if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

        if (paramName.match(/\[(\d+)?\]$/)) {
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];

          if (paramName.match(/\[\d+\]$/)) {
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            obj[key].push(paramValue);
          }
        } else {
          if (!obj[paramName]) {
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string'){
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            obj[paramName].push(paramValue);
          }
        }
      }
    }

    return obj;
  }

}

export let globals = new globalsClass();
