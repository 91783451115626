
import { unmount, setStyle } from 'redom';

export class ui {

	el:any;

	renameEvent = {
		click:'click',
		down:'mousedown',
		up:'mouseup',
		enter:'mouseenter',
		leave:'mouseleave',
		move:'mousemove',
		dblclick:"dblclick",
	}
	on (event:string, funct:Function) {
		let nodeevent = this.renameEvent[event];
		this.el.addEventListener(nodeevent, (evt) => {
			funct(evt, this);
		});
		if (event == 'click') setStyle(this.el, {cursor:'pointer'});
		return this;
	}

	off () {
		// Clone to delete all events
		let parent = this.el.parentNode;
		let elClone = this.el.cloneNode(true);
		parent.replaceChild(elClone, this.el);
		this.el = elClone;
	}

	// TODO delete all eventlistenner properly
	destroy () {
		if (this.el.parentNode) unmount(this.el.parentNode, this.el);
		return this;
	}

	hide () {
		setStyle(this.el, {display:'none'});
		return this;
	}

	show () {
		setStyle(this.el, {display:'block'});
		return this;
	}
}
