
import { undoBar } from '../project/bottombar';
// import { undo } from '../service/undo';
import { zoomManager } from '../dragzone/zoomManager';
import { tagManager } from '../settings/tag';
// import { borderEditors } from '../dragzone/bordereditors';
import { selectedComponent } from '../component/component';
import { selectedPointofview } from '../journey/pointofviewmanager';
import { bankSearch } from '../modal/banksearch';
import { imageDropzone, videoDropzone, soundDropzone, cubetextureDropzone } from '../modal/dropzones';
// import { option3D } from '../../viewer/tools/interface';

// import clone from 'lodash/clone';
import hotkeys from 'hotkeys-js';

export class shortcuts {
  constructor () {
    hotkeys('command+z,ctrl+z', (event, param) => {
      undoBar.undo();
    });

    hotkeys('command+shift+z,ctrl+shift+z', (event, param) => {
      undoBar.redo();
    });

    hotkeys('command+d,ctrl+d', (event, param) => {
      event.preventDefault();
      tagManager.duplicateComponent();
    });

    hotkeys('esc', (event, param) => {
      if (selectedComponent) selectedComponent.unselect();
      else if (selectedPointofview) selectedPointofview.unselect();
    });

    hotkeys('delete', (event, param) => {
      tagManager.deleteSelected();
    });

    hotkeys('command+d,ctrl+f,f', (event, param) => {
      event.preventDefault();
      if (selectedComponent) zoomManager.focusOn(selectedComponent.content.pattern.mesh, true);
      if (selectedPointofview) zoomManager.focusOn(selectedPointofview.pointofview.meshParent.mesh, true);
    });

    // hotkeys('right,left,up,down', (event, param) => {
    //   this.getVectorChange(param);
    //   if (selectedComponent) this.moveComponent();
    // });
    //
    // window.onkeyup = (e) => {
    //   this.checkStopMoving();
    // };

    document.addEventListener('paste', (evt) => {
      this.handlePaste(evt);
    });

    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    }, false);
  }

  // vectorarrow:option3D = {x:0, z:0};
  // getVectorChange (param:any) {
  //   if (param.key == 'right') this.vectorarrow.x += 0.05;
  //   if (param.key == 'left') this.vectorarrow.x -= 0.05;
  //   if (param.key == 'up') this.vectorarrow.z += 0.05;
  //   if (param.key == 'down') this.vectorarrow.z -= 0.05;
  // }
  //
  // arrowTimeout:any;
  // stoppedkeymove = true;
  // startkeymove = false;
  // moveComponent () {
  //   if (this.stoppedkeymove) {
  //     borderEditors.hideAnimEditors(true);
  //     dragmanager.startposition = clone(selectedComponent.content.position);
  //   }
  //   this.stoppedkeymove = false;
  //   this.startkeymove = true;
  //
  //   dragmanager.setComponentPos(this.vectorarrow);
  // }
  //
  // checkStopMoving () {
  //   if (this.startkeymove) {
  //     this.startkeymove = false;
  //     if (this.arrowTimeout !== undefined) clearTimeout(this.arrowTimeout);
  //     this.arrowTimeout = setTimeout(() => {
  //       if (!this.startkeymove) {
  //         this.vectorarrow = {x:0, z:0};
  //         this.stoppedkeymove = true;
  //         if (selectedComponent) {
  //           undo.pushState();
  //           borderEditors.showAnimEditors();
  //         }
  //       }
  //     }, 1000);
  //   }
  // }

  // Url example to test copy/paste
  // https://d2uret4ukwmuoe.cloudfront.net/j606mcb8mnh6k7ir8tlodc/scene.glb
  handlePaste (e) {
    let clipboardData, pasted;
    // Stop data actually being pasted into div
    // e.stopPropagation();
    // e.preventDefault();
    // Get pasted data via clipboard API
    clipboardData = e.clipboardData || window.clipboardData;
    pasted = clipboardData.getData('Text');
    if (pasted.indexOf('http') != -1) {
      let extension = pasted.substr(pasted.lastIndexOf('.') + 1);
      if (imageDropzone.formats.indexOf(extension) != -1) {
        bankSearch.loadImage(pasted);
      } else if (videoDropzone.formats.indexOf(extension) != -1) {
        let urlArr = pasted.split('/');
        let name = urlArr[urlArr.length - 1];
        bankSearch.loadVideo(pasted, name);
      } else if (["gltf", "glb", "obj", "babylon"].indexOf(extension) != -1) {
        let urlArr = pasted.split('/');
        // Model name is often in the directory and not ine file
        let name = urlArr[urlArr.length - 2];
        bankSearch.loadModel(pasted, name);
      } else if (soundDropzone.formats.indexOf(extension) != -1) {
        bankSearch.loadSound(pasted, name);
      } else if (cubetextureDropzone.formats.indexOf(extension) != -1) {
        bankSearch.loadCubeTexture(pasted, name);
      }
    }
  }

}
