
import { content, contentInterface, vector3interface } from './content';
import { util } from '../../tools/util';
import { coreSystem } from '../../tools/coreSystem';
import { animation } from '../../tools/animation';
import { option3D, point3D } from '../../tools/interface';
import { projectInterface } from '../../service/projectInterface';

import find from 'lodash/find';
import clone from 'lodash/clone';
import { IEasingFunction, BezierCurveEase, CircleEase, BounceEase, ExponentialEase } from '@babylonjs/core/Animations/easing';
import { AnimationGroup } from '@babylonjs/core/Animations/animationGroup';

export interface animationInterface {
  name:string,
  event:'show'|'hover'|'enter'|'click'|'scroll'|'move',
  duration:number,
  curve:'linear'|'bezier'|'circle'|'exponential'|'bounce',
  way?:'once'|'alternate'|'loop',
  model?:string, // Model animation name if content is a model
  target?:string,
  position?:option3D,
  rotation?:option3D,
  scale?:option3D,
  opacity?:number,
  startposition?:option3D,
  startrotation?:option3D,
  startscale?:option3D,
  startopacity?:number,
  delay?:number,
  anim?:animation,
  modelanim?:AnimationGroup,
  timeout?:any,
  launched?:boolean,
  forcestop?:boolean, // forcestop used in animation edition
  started?:boolean, // forcestop used in animation edition
  finished?:boolean, // forcestop used in animation edition
  easeclass?:IEasingFunction, // forcestop used in animation edition
}

projectInterface.animation = {
  name:'an',
  event:'ae',
  duration:'adu',
  curve:'ac',
  target:'at',
  delay:'ade',
  way:'aw',
  model:'am',
  position:{
    dim:'ap',
    next:vector3interface
  },
  rotation:{
    dim:'ar',
    next:vector3interface
  },
  scale:{
    dim:'as',
    next:vector3interface
  },
  opacity:'ao',
}

export class contentMove extends content {

  animations:any = {};
  animposition:point3D;
  animrotation:point3D;
  animscale:point3D;
  animopacity:number;

  // Variable used by model content
  modelAnimations:Array<AnimationGroup> = [];
  modelAnimationsName:Array<string> = [];

  constructor (coreSystem:coreSystem, content:contentInterface, meshtype:any) {
    super(coreSystem, content, meshtype);
    this.animposition = clone(this.position);
    this.animrotation = clone(this.rotation);
    this.animscale = clone(this.scale);
    this.animopacity = clone(this.opacity);
  }

  setPlay (play:boolean) {
    this.play = play;
    if (play) this.playAnimation();
    else this.pauseAnimation();
  }

  addAnimations (animations:any) {
    for (let key in animations) {
      this.addAnimation(key, animations[key]);
    }
  }

  playAnimation () {
    this.resetGeometry();
    for (let key in this.animations) {
      if (this.animations[key].event == 'show' || this.animations[key].event == 'scroll' || this.animations[key].event == 'move') {
        this.startAnimation(this.animations[key]);
      }
    }
  }

  pauseAnimation () {
    for (let key in this.animations) {
      this.stopAnimation(this.animations[key]);
    }
    this.resetGeometry();
  }

  setAnimationParam (key:string, contentAnimation:animationInterface) {
    // If animation was deleted, we create it
    if (!contentAnimation.event) return this.deleteAnimation(key);
    if (!this.animations[key]) this.addAnimation(key, contentAnimation);
    for (let keyanim in contentAnimation) {
      this.animations[key][keyanim] = contentAnimation[keyanim];
    }
  }

  deleteAnimation (animkey:string) {
    if (this.animations[animkey]) this.stopAnimation(this.animations[animkey]);
    delete this.animations[animkey];
  }

  infinite = 1000000000000;
  addAnimation (key:string, contentAnimation:animationInterface) {
    if (contentAnimation.anim == undefined) contentAnimation.anim = new animation(this._system);
    contentAnimation.launched = false;
    contentAnimation.finished = false;
    contentAnimation.started = false;
    if (contentAnimation.opacity == undefined) contentAnimation.opacity = 0;
    if (contentAnimation.delay == undefined) contentAnimation.delay = 0;

    this.stopAnimation(contentAnimation);
    this.setCurveFunction(contentAnimation);

    // FIXME find a way to delete event when animation or content is deleted
    // this.pattern.off('enter', () => {
    //   this.startAnimation(contentAnimation);
    // });
    this.animations[key] = contentAnimation;
    if (contentAnimation.event != 'show' && contentAnimation.event != 'scroll' && contentAnimation.event != 'move') this.animationEvent(contentAnimation);
  }

  animationEvent (contentAnimation:animationInterface) {
    let target:content = this;
    // if (contentAnimation.target) target = this.getTarget(contentAnimation.target);
    if (contentAnimation.event == 'hover') {
      target.pattern.on('enter', () => {
        this.startAnimation(contentAnimation);
      });
      target.pattern.on('leave', () => {
        contentAnimation.anim.stop();
        this.resetGeometry();
      });

    } else if (contentAnimation.event == 'enter') {
      target.pattern.on('enter', () => {
        if (!contentAnimation.launched) this.startAnimation(contentAnimation);
      });
    } else if (contentAnimation.event == 'click') {
      target.pattern.on('click', () => {
        // FIXME BABYLON call twice the click event
        // it's ok but may cause error in the future
        this.startAnimation(contentAnimation);
      });
    }
  }

  // getTarget (id:string) {
  //   let target = find(contentList, (o) => { return o.id == id; });
  //   if (target) return target;
  //   else return this;
  // }

  startAnimation (contentAnimation:animationInterface) {
    this.stopAnimation(contentAnimation);
    this.finished = false;
    this.reseted = false;

    contentAnimation.anim.setParam(contentAnimation.duration * 60); // 60 FPS
    contentAnimation.forcestop = false;
    contentAnimation.finished = false;
    contentAnimation.started = false;

    if (contentAnimation.event != 'scroll' && contentAnimation.event != 'move') {
      contentAnimation.launched = true;
      this.setStartParam(contentAnimation);
      if (contentAnimation.delay) {
        contentAnimation.timeout = setTimeout(() => {
          this.animateWay(contentAnimation);
        }, contentAnimation.delay * 1000);
      } else {
        this.animateWay(contentAnimation);
      }
    }
  }

  setStartParam (contentAnimation:animationInterface) {
    contentAnimation.startposition = clone(this.animposition);
    contentAnimation.startrotation = clone(this.animrotation);
    contentAnimation.startscale = clone(this.animscale);
    contentAnimation.startopacity = clone(this.animopacity);
  }

  stopAnimation (contentAnimation:animationInterface) {
    contentAnimation.forcestop = true;
    if (contentAnimation.timeout) clearTimeout(contentAnimation.timeout);
    if (contentAnimation.anim) contentAnimation.anim.stop();
  }

  // Different animateWay function used in model
  animateWay (contentAnimation:animationInterface) {
    this._animateWay(contentAnimation);
  }

  _animateWay (contentAnimation:animationInterface) {
    if (contentAnimation.way == 'once') {
      contentAnimation.anim.go((count, perc) => {
        this.animate(contentAnimation, perc);
      }, () => {
        this.finishAnimation(contentAnimation);
      });
    } else if (contentAnimation.way == 'alternate') {
      contentAnimation.anim.alternate(this.infinite, contentAnimation.anim.howmany, (count, perc) => {
        this.animate(contentAnimation, perc);
      }, (count, perc) => {
        this.animate(contentAnimation, 1 - perc);
      });
    } else if (contentAnimation.way == 'loop') {
      contentAnimation.anim.loop(this.infinite, contentAnimation.anim.howmany, (count, perc) => {
        this.animate(contentAnimation, perc);
      }, () => {
        if (contentAnimation.delay) this.startAnimation(contentAnimation);
      });
    }
  }

  reseted = false;
  // Different resetGeometry function used in model
  resetGeometry () {
    this._resetGeometry();
  }

  _resetGeometry () {
    this.reseted = true;
    this.animposition = clone(this.position);
    this.animrotation = clone(this.rotation);
    this.animscale = clone(this.scale);
    this.animopacity = clone(this.opacity);
    this.pattern.setPosAxis(this.position);
    this.pattern.setScaleAxis(this.scale);
    this.pattern.setAngleAxis(this.rotation);
    this.pattern.setOpacity(this.opacity);
  }

  resetAnimationGeometry (contentAnimation:animationInterface) {
    this.pattern.setPosAxis(contentAnimation.startposition);
    this.pattern.setScaleAxis(contentAnimation.startscale);
    this.pattern.setAngleAxis(contentAnimation.startrotation);
    if (contentAnimation.opacity != undefined) {
      this.pattern.setOpacity(contentAnimation.startopacity);
    }
  }

  setCurveFunction (contentAnimation:animationInterface) {
    let curve = contentAnimation.curve;
    if (curve == 'linear') return contentAnimation.easeclass = {ease:(perc)=>{return perc;}};
    if (curve == 'bezier') return contentAnimation.easeclass = new BezierCurveEase(0.32, -0.73, 0.69, 1.59);
    if (curve == 'circle') return contentAnimation.easeclass = new CircleEase();
    if (curve == 'bounce') return contentAnimation.easeclass = new BounceEase();
    if (curve == 'exponential') return contentAnimation.easeclass = new ExponentialEase();

    // CircleEase()
    // BackEase(amplitude)
    // CubicEase()
    // ElasticEase(oscillations, springiness)
    // PowerEase(power)
    // QuadraticEase()
    // QuarticEase()
    // QuinticEase()
    // SineEase()
  }

  animate (contentAnimation:animationInterface, perc:number) {
    perc = contentAnimation.easeclass.ease(perc);
    let positionChange = util.multiplyAxisNumber(contentAnimation.position, perc);
    let positionNew = util.sumAxis(positionChange, contentAnimation.startposition);
    for (let key in positionNew) {
      this.animposition[key] = positionNew[key];
    }
    this.pattern.setPosAxis(positionNew);
    let rotationChange = util.multiplyAxisNumber(contentAnimation.rotation, perc);
    let rotationNew = util.sumAxis(rotationChange, contentAnimation.startrotation);
    for (let key in rotationNew) {
      this.animrotation[key] = rotationNew[key];
    }
    this.pattern.setAngleAxis(rotationNew);
    let scaleChange = util.multiplyAxisNumber(contentAnimation.scale, perc);
    let scaleNew = util.sumAxis(scaleChange, contentAnimation.startscale);
    for (let key in scaleNew) {
      this.animscale[key] = scaleNew[key];
    }
    this.pattern.setScaleAxis(scaleNew);
    if (contentAnimation.opacity != undefined && contentAnimation.opacity != 0) {
      let opacityNew = contentAnimation.startopacity + contentAnimation.opacity * perc;
      this.animopacity = clone(opacityNew);
      this.pattern.setOpacity(opacityNew);
    }
  }

  animateVector (contentAnimation:animationInterface, vector:point3D) {
    let xperc = contentAnimation.easeclass.ease(vector.x);
    let yperc = contentAnimation.easeclass.ease(vector.y);
    let zperc = contentAnimation.easeclass.ease(vector.z);
    let percVector = {x:xperc, y:yperc, z:zperc};
    let positionChange = util.multiplyAxis(contentAnimation.position, percVector);
    let positionNew = util.sumAxis(positionChange, this.animposition);
    this.pattern.setPosAxis(positionNew);
    // In order to follow mouse, rotation must invert x and y
    let rotationPercVector = {x:yperc, y:-xperc, z:zperc};
    let rotationChange = util.multiplyAxis(contentAnimation.rotation, rotationPercVector);
    let rotationNew = util.sumAxis(rotationChange, this.animrotation);
    this.pattern.setAngleAxis(rotationNew);
    let scaleChange = util.multiplyAxis(contentAnimation.scale, percVector);
    let scaleNew = util.sumAxis(scaleChange, this.animscale);
    this.pattern.setScaleAxis(scaleNew);
    if (contentAnimation.opacity != undefined && contentAnimation.opacity != 0) {
      let opacityNew = this.animopacity - contentAnimation.opacity * yperc;
      this.pattern.setOpacity(opacityNew);
    }
  }

  // Different animateScroll function used in model
  animateScroll (contentAnimation:animationInterface, top:number) {
    this._animateScroll(contentAnimation, top);
  }

  _animateScroll (contentAnimation:animationInterface, top:number) {
    if (top <= contentAnimation.delay) {
      if (contentAnimation.started) {
        contentAnimation.finished = false;
        contentAnimation.started = false;
        if (!contentAnimation.startposition) this.setStartParam(contentAnimation);
        this.animate(contentAnimation, 0);
      }
    } else if (top > contentAnimation.delay + contentAnimation.duration) {
      if (!contentAnimation.finished) {
        contentAnimation.started = true;
        contentAnimation.finished = true;
        if (!contentAnimation.startposition) this.setStartParam(contentAnimation);
        this.animate(contentAnimation, 1);
      }
    } else {
      if (!contentAnimation.launched) this.setStartParam(contentAnimation);
      contentAnimation.launched = true;
      contentAnimation.started = true;
      contentAnimation.finished = false;
      let perc = (top - contentAnimation.delay)/contentAnimation.duration;
      this.animate(contentAnimation, perc);
    }
  }

  // Different animateMouse function used in model
  animateMouse (contentAnimation:animationInterface, x:number, y:number) {
    this._animateMouse(contentAnimation, x, y);
  }

  _animateMouse (contentAnimation:animationInterface, x:number, y:number) {
    let xpos = x;
    let ypos = y;
    let xgap = Math.abs(xpos);
    let ygap = Math.abs(ypos);
    let xanim, yanim;
    if (xgap < contentAnimation.delay) xanim = 0;
    else if (xgap < contentAnimation.delay + contentAnimation.duration) xanim = xpos;
    else xanim = Math.sign(xpos) * (contentAnimation.delay + contentAnimation.duration);
    if (ygap < contentAnimation.delay) yanim = 0;
    else if (ygap < contentAnimation.delay + contentAnimation.duration) yanim = ypos;
    else yanim = Math.sign(ypos) * (contentAnimation.delay + contentAnimation.duration);
    this.animateVector(contentAnimation, {x:xanim, y:yanim, z:0});
  }

  finished = false;
  finishAnimation (contentAnimation:animationInterface) {
    this.finished = true;
    let posFinal = util.sumAxis(contentAnimation.position, this.position);
    this.pattern.setPosAxis(posFinal);
    let scaleFinal = util.sumAxis(contentAnimation.scale, this.scale);
    this.pattern.setScaleAxis(scaleFinal);
    let rotationFinal = util.sumAxis(contentAnimation.rotation, this.rotation);
    this.pattern.setAngleAxis(rotationFinal);
    if (contentAnimation.opacity != undefined) {
      let opacityNew = this.opacity + contentAnimation.opacity;
      this.pattern.setOpacity(opacityNew);
    }
  }

  // Used to allow some content type to modify hide and show function
  hide (fast?:boolean) { // Fast used in editor to quickly hide some content which have fade animation
    this._hideMove();
  }

  show () {
    this._showMove();
  }

  _showMove () {
    this.resetGeometry();
    if (this.play) this.playAnimation();
    this._show();
  }

  _hideMove () {
    this.pauseAnimation();
    this._hide();
  }

}
