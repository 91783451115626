
import { coreSystem } from '../../tools/coreSystem';
import { point3D, option3D } from '../../tools/interface';
import { meshMaterialClass } from '../patterns/meshmaterial';
import { shape } from '../patterns/shape';
import { light } from '../patterns/light';
import { sound } from '../patterns/sound';
import { meshparent } from '../patterns/meshparent';
import { modelparent } from '../patterns/modelparent';
import { projectInterface } from '../../service/projectInterface';

export interface contentInterface {
  id?:string,
  position?:point3D,
  scale?:point3D,
  rotation?:point3D,
  opacity?:number,
  locked?:boolean,
  visible?:boolean,
  play?:boolean,
  shadow?:boolean,
  animations?:any,
  hyperlink?:string,
  groupkey?:string,
  tag?:string,
}

export let vector3interface = {
  x:{dim:'x', accuracy:5},
  y:{dim:'y', accuracy:5},
  z:{dim:'z', accuracy:5}
};

projectInterface.content = {
  id:'i',
  position:{
    dim:'p',
    next:vector3interface
  },
  rotation:{
    dim:'r',
    next:vector3interface
  },
  scale:{
    dim:'s',
    next:vector3interface
  },
  opacity:{dim:'o', accuracy:2},
  hyperlink:'hl',
  locked:'l',
  visible:'v',
  play:'pl',
  shadow:'sh',
  groupkey:'g',
  tag:'t',
  type:'tp',
}

export class content {

  _system:coreSystem;

  id:string;
  position:point3D = {x:0, y:0, z:0};
  rotation:point3D = {x:0, y:0, z:0};
  scale:point3D = {x:0, y:0, z:0};
  opacity = 1;
  mesh:string;
  scrollposition = 0;
  type:string;
  pattern:meshMaterialClass;
  hyperlink:string;
  locked = false;
  shadow = true;
  visible = true;
  play = true;
	tag:string = '';
	listener: Function;

  ingroup = false;
  group = false;
  groupkey:string = '';
  groupcontent:any;

  constructor (coreSystem:coreSystem, content:contentInterface, meshtype:any) {
    this._system = coreSystem;
    if (meshtype == 'group') this.pattern = new meshparent(coreSystem);
    else if (meshtype == 'model') this.pattern = new modelparent(coreSystem);
    else if (meshtype == 'light') this.pattern = new light(coreSystem);
    else if (meshtype == 'sound') this.pattern = new sound(coreSystem);
    else this.pattern = new shape(coreSystem, {}, meshtype);
    this.id = content.id;

    this.setGeometryParameters(content);
    this.setContentParameter(content, true);
  }

  setTypeParameter (typeStyle:any) {}
  setGeometryParameters (content:contentInterface) {
    if (content.position !== undefined) this.setPosition(content.position);
    if (content.scale !== undefined) this.setScale(content.scale);
    if (content.rotation !== undefined) this.setRotation(content.rotation);
    if (content.opacity !== undefined) this.setOpacity(content.opacity);
  }
  setContentParameter (content:contentInterface, init?:boolean) {
    if (content.locked !== undefined) this.locked = content.locked;
    if (content.visible !== undefined) this.visible = content.visible;
    if (content.shadow !== undefined) this.setShadow(content.shadow);
    if (content.play !== undefined) this.play = content.play;
    if (content.hyperlink !== undefined) this.setHyperlink(content.hyperlink);
    if (content.groupkey !== undefined) this.groupkey = content.groupkey;
    if (content.tag !== undefined) this.tag = content.tag;
    // Must set Shadow or background can be black

    if (!init) {
      if (content.visible !== undefined) this.setVisible(content.visible);
      if (content.play !== undefined) this.setPlay(content.play);
    }
  }

	setListener(callback: Function) {
		this.listener = callback;
	}

  setHyperlink (hyperlink:string) {
    if (!this.hyperlink && hyperlink) {
      this.pattern.on('click', () => {
				this.listener();
        window.open(this.hyperlink, '_blank');
      });
    } else if (!hyperlink) {
      this.pattern.listenEvent(false);
      this.pattern.off();
    }
    this.hyperlink = hyperlink;
  }


  // NOTE need to set function here for setContentParam function
  setPlay (play:boolean) {}

  setPosition (pos:option3D) {
    this.setVector('position', pos);
    this.pattern.setPosAxis(pos);
  }

  setRotation (rot:option3D) {
    this.setVector('rotation', rot);
    this.pattern.setAngleAxis(rot);
  }

  setScale (scale:option3D) {
    this.setVector('scale', scale);
    this.pattern.setScaleAxis(scale);
  }

  setVector (param:string, vector:option3D) {
    for (let key in vector) {
      this[param][key] = vector[key];
    }
  }

  setOpacity (op:number) {
    this.opacity = op;
    this.pattern.setOpacity(op);
  }

  setShadow (shadow:boolean) {
    this.shadow = shadow;
    // Shadows not working yet on MAC
    if (shadow) this.pattern.addShadow();
    else this.pattern.removeShadow();
  }

  setVisible (visible:boolean) {
    this.visible = visible;
    if (visible) this.show();
    else this.hide();
  }

  shown = false;
  _show () {
    this.shown = true;
    this.pattern.show();
  }
  show () {}

  _hide () {
    this.shown = false;
    this.pattern.hide();
  }
  hide (fast?:boolean) {}  // Fast used in editor to quickly hide some content which have fade animation
}
