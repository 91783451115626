
import { builder } from './service/builder';
import { shortcuts } from './service/shortcuts';
import { editorLoadingScreen } from './project/editorloadingscreen';
import { globals } from './service/globals';

import 'microtip/microtip.css'
import '../../styles/custom-siimple.sass';
import '../../styles/font.css';
import '../../styles/main.sass';
import '../../styles/dropzone.sass';
import '../../styles/inputs.sass';
import '../../styles/quill.sass';
import '../../styles/suggestions.sass';
import '../../styles/toastr.sass';
import '../../styles/uislider.sass';

import test from '../../template/tutorial.json';

'use strict';

window.onload = () => {
  new shortcuts();

  if (globals.save) {
    builder.loadProject((data) => {
      if (!data) {
        editorLoadingScreen.showLoader("We are sorry, there was an error while loading your project");
        editorLoadingScreen.resetLoaders();
      } else {
        editorLoadingScreen.showLoader("Loading Project Assets");
      }
    });
  } else {
    editorLoadingScreen.showLoader("Loading Project Assets");
    builder.createProject(test);
  }
};
