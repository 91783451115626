
import { _system, _responsive } from '../service/editintale';
import { zoomManager } from '../dragzone/zoomManager';
import { colordarkgrey, colortext } from '../service/colors';

import { Viewport } from '@babylonjs/core/Maths/math'
import { Rectangle } from '@babylonjs/gui/2D/controls/rectangle';
import { TextBlock } from '@babylonjs/gui/2D/controls/textBlock';

class pointofviewPreviewClass {

  title:Rectangle;
  text:TextBlock;
  border:Rectangle;

  constructor () {
    this.title = new Rectangle();
    this.title.left = '33%';
    this.title.top = '15%';
    this.title.adaptWidthToChildren = true;
    this.title.height = '20px';
    this.title.cornerRadius = 4;
    this.title.thickness = 0;
    this.title.shadowBlur = 5;
    this.title.shadowColor = colordarkgrey;
    this.title.background = colordarkgrey;

    this.text = new TextBlock();
    this.text.width = '100px';
    this.text.resizeToFit = false;
    this.text.text = 'Preview';
    this.text.fontSize = '15px';
    this.text.color = colortext;
    this.title.addControl(this.text);

    this.border = new Rectangle();
    this.border.background = colordarkgrey;
    this.border.thickness = 0;
    this.border.isPointerBlocker = true;
    this.border.shadowBlur = 10;
    this.border.left = '33%';
    this.border.top = '33.2%';
    this.border.width = '30%';
    this.border.height = '30%';
    this.border.shadowColor = colordarkgrey;
  }
  
  show () {
    _system.freecamera.viewport = new Viewport(0.68, 0.02, 0.3, 0.3);
    _system.scene.activeCameras = [zoomManager.cameraedit, _system.freecamera];
    _system.sceneAdvancedTexture.addControl(this.border);
    _system.sceneAdvancedTexture.addControl(this.title);
  }

  hide () {
    _system.scene.activeCameras = [];
    _system.scene.activeCamera = zoomManager.cameraedit;
    _system.freecamera.viewport = new Viewport(0, 0, 1, 1);
    _system.sceneAdvancedTexture.removeControl(this.border);
    _system.sceneAdvancedTexture.removeControl(this.title);
  }
}

export let pointofviewPreview = new pointofviewPreviewClass();
