
export class animationManagerClass {

	fps = 60;
	fpsratio = 1;
	focusback = false;
	fpsnode:any;
	list = [];

	constructor () {
		window.addEventListener("focus", () => {
			this.setFocusBack();
		});

		window.addEventListener("blur", () => {
			this.setFocusBack();
		});
	}

	runAnimations (fps:number) {
		// if (mode == 'develoment') this.fpsnode.textContent = fps+' - '+this.list.length;
		this.fps = fps;
		this.fpsratio = 60/this.fps;

		// if (this.focusback) return;
		// To avoid acceleration when focus back
		let fpsratio = Math.min(this.fpsratio, 2);
		for (let i = 0; i < this.list.length; i++) {
			let anim = this.list[i];
			if (anim.running) {
				anim.funct(anim.count, anim.count / anim.howmany);
				if (anim.count >= anim.howmany) anim.stop(true);
				anim.count += anim.step * fpsratio;
			}
		}
	}

	stopAnimations () {
		this.setFocusBack();
		for (let i = 0; i < this.list.length; i++) {
			let anim = this.list[i];
			anim.stop(true);
		}
	}

	restartAnimations () {
		this.setFocusBack();
		for (let i = 0; i < this.list.length; i++) {
			let anim = this.list[i];
			anim.restart();
		}
	}

	setFocusBack () {
		this.focusback = true;
		localStorage.clear()
		setTimeout( () => {
			this.focusback = false;
		}, 200);
	}
}
