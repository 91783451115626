
import { coreSystem } from './coreSystem';

import remove from 'lodash/remove';

export class animation {

	_system:coreSystem;

	start = 0;
	count = 0;
	step = 1;
	rate = 17;
	running = false;
	howmany : number;
	funct : Function;
	functend : Function;
	key : string;
	ui = false;
	mode:string;

  constructor (coreSystem:coreSystem, howmany?:number, start?:number, step?:number, rate?:number) {
		this._system = coreSystem;
	  if (howmany) this.setParam(howmany, start, step, rate);
		this.key = Math.random().toString(36);
		return this;
	}

  setParam (howmany:number, start?:number, step?:number, rate?:number) {
    if (this.running) this.stop(true);
    this.howmany = howmany - 1;
    if (step) this.step = step;
    if (start) this.start = start;
    if (rate) this.rate = rate;
    this.count = this.start;
    return this;
  }

	infinite (funct:Function) {
		let howmany = 1000000000000;
		this.simple(howmany, funct);
		return this;
	}

	alternate (howmany:number, alter:number, funct1:Function, funct2?:Function, functend?:Function) {
		let ft = true;
		let alterstep = 0;
		this.simple(howmany, (count, perc) => {
			if (count > alter*(alterstep +1)) {
				ft = !ft;
				alterstep++;
			}
			count = count - alter*alterstep;
			perc = count/alter;
			if (ft) funct1(count, perc);
			else funct2(count, perc);
		}, functend);
		return this;
	}

	loop (howmany:number, alter:number, funct1:Function, functloop?:Function, functend?:Function) {
		let alterstep = 0;
		this.simple(howmany, (count, perc) => {
			if (count > alter*(alterstep +1)) {
				alterstep++;
				if (functloop) functloop();
			}
			count = count - alter*alterstep;
			perc = count/alter;
			funct1(count, perc);
		}, functend);
		return this;
	}

	reverse () {
		this.step = -this.step;
		return this;
	}

	steps (steps:any) {
		this.loopsteps(steps, 0);
		return this;
	}

	loopsteps (steps:any, step:number) {
		if (!steps[step]) return;
		let stepO = steps[step];
		this.simple(stepO.howmany, (count, perc) => {
			stepO.funct(count, perc);
		}, () => {
			stepO.functend();
			step++
			if (!this.running) this.loopsteps(steps, step);
		});
	}

	simple (howmany:number, funct:Function, functend?:Function) {
		if (this.running) this.stop();
		this.start = 0;
		this.count = 0;
		this.step = 1;
		this.howmany = howmany;
		this.go(funct, functend);
		return this;
	}

  go (funct:Function, functend?:Function) {
		this.pause();
    this.running = true;
    this.funct = funct;
    this.functend = functend;
		this.addInAnimationFrame();
    return this;
  }

  addInAnimationFrame () {
    if (this._system.animationManager.list.indexOf(this) == -1) this._system.animationManager.list.push(this);
  }

	restart () {
		if (this.running) {
			this.pause();
			this.go(this.funct, this.functend);
		}
	}

  stop (arg?:boolean) {
		remove(this._system.animationManager.list, (a) => { return a.key == this.key });
    this.count = this.start;
		if (this.functend && this.running) {
			this.running = false;
			this.functend(arg);
		}
    return this;
  }

  letal () {
		this.stop(true);
    return this;
  }

  pause () {
		remove(this._system.animationManager.list, (a) => { return a.key == this.key });
		this.running = false;
    return this;
  }

  play () {
		this.addInAnimationFrame();
    return this;
  }
}
