
import { componentManager } from '../component/componentmanager';
import { componentList } from '../component/component';
import { bankSearch } from '../modal/banksearch';
import { assetpicker } from '../inputs/pickers';
import { layerRight } from '../inputs/layer';
import { imageDropzone, videoDropzone, modelDropzone } from '../modal/dropzones';

import { el, setChildren } from 'redom';
import toastr from 'toastr'
import filter from 'lodash/filter';

export class componentCreatorClass {

    // NOTE Temporary delete particle creation before further tests
    // Particles make the scene really slow even with a few number (test if it comes from emitter)
    contentlist = ['model', 'heightmap', 'light', 'image', 'video', 'sphere', 'cube', 'cylinder', 'plane', 'disc'];
    meshcomponents = ['plane', 'cube', 'disc', 'cylinder', 'sphere'];

    banklist = [
      {
        name:'poly',
        image:'https://asset.naker.io/image/banks/poly.png'
      }, {
        name:'claraio',
        image:'https://asset.naker.io/image/banks/claraio.png'
      }, {
        name:'giphy',
        image:'https://asset.naker.io/image/banks/giphy.png'
      }, {
        name:'unsplash',
        image:'https://asset.naker.io/image/banks/unsplash.png'
      }
      // , {
      //   name:'nounproject',
      //   image:'https://asset.naker.io/image/banks/nounproject.png'
      // }
    ]
    componenttitle:any;
    bankstitle:any;
    container:any;

    constructor () {

      this.container = el('div.component-creator',
        [
          el('div.parameter-group',
            [
              this.componenttitle = el('div.parameter-title', 'Add Component'),
              el('div.add-component-list', this.contentlist.map(p =>
                el('div.add-component-button.siimple-btn.siimple-btn--primary', {onclick:()=>{this.createComponent(p)}, onmouseenter:()=>{this.componenttitle.textContent = 'Add '+p}, onmouseleave:()=>{this.componenttitle.textContent = 'Add component'}},
                  el('div.icon-button.icon-'+p, [el('span.path1'),el('span.path2'),el('span.path3')]
                  )
                )
              )),
            ]
          ),
          el('div.parameter-group',
            [
              this.bankstitle = el('div.parameter-title', 'Banks'),
              el('div.add-bank-list', this.banklist.map(p =>
                el('div.add-component-button', {onclick:()=>{bankSearch.show(p.name)}, onmouseenter:()=>{this.bankstitle.textContent = p.name}, onmouseleave:()=>{this.bankstitle.textContent = 'Banks'}},
                  el('img', {src:p.image})
                )
              )),
            ]
          )
        ]
      )
    }

    showCreator () {
      setChildren(layerRight, this.container);
    }

    createComponent (contenttype:string) {
      if (this.meshcomponents.indexOf(contenttype) != -1) this.createmesh(contenttype);
      else this['create'+contenttype]();
    }

    createparticle () {
      let newParticle:any = componentManager.addComponent('particle', {scale:{x:1, y:1, z:1}, rotation:{x:0, y:0, z:0}, position:null}, {color1:[108, 172, 255, 1], color2:[108, 172, 255, 1], power:1, particleSize:1, life:1, number:100});
      newParticle.newComponent();
    }

    createmesh (mesh:string) {
      let newMesh = componentManager.addComponent('shape', {scale:{x:1, y:1, z:1}, rotation:{x:0, y:0, z:0}, position:null}, {mesh:mesh, material:{}});
      newMesh.content.setTypeDefaultValues('standard');
      newMesh.newComponent();
    }

    createlight () {
      let lights = filter(componentList, (c) => {return c.content.type == 'light'});
      if (lights.length > 5) toastr.warning('Be careful, too much lights can really make your scene slow');
      if (lights.length > 10) return toastr.error("You already have 10 lights in your scene, unfortunetly objects can render a maximum of 10 lights");
      let newLight = componentManager.addComponent('light', {scale:{x:1, y:1, z:1}, rotation:{x:0, y:0, z:0}, position:null}, {color:[255,255, 255, 1], intensity:100, mode:'point'});
      newLight.newComponent();
    }

    createheightmap () {
      let newHeightmap = componentManager.addComponent('heightmap', {scale:{x:30, y:0, z:30}, rotation:{x:0, y:0, z:0}, position:{x:0, y:0, z:0}}, {heightmapurl:"https://asset.naker.io/image/particles/Flare.png", bumpheight:5, heightquality:50, material:{albedocolor:[108, 172, 255, 1], type:'standard', texturesize:1}});
      newHeightmap.content.setTypeDefaultValues('standard');
      newHeightmap.newComponent();
    }

    createimage () {
      imageDropzone.show();
      assetpicker.setAddAssetMode('image', (url) => {
        bankSearch.addImage(url);
        imageDropzone.hide();
      });
    }

    createvideo () {
      videoDropzone.show();
      assetpicker.setAddAssetMode('video', (url) => {
        bankSearch.addVideo(url);
        videoDropzone.hide();
      });
    }

    createmodel () {
      modelDropzone.show();
      assetpicker.setAddAssetMode('model', (url) => {
        bankSearch.addModel(url);
        modelDropzone.hide();
      });
    }
}

export let componentCreator = new componentCreatorClass();
