
import { floatingManager, layerRight } from '../inputs/layer';
import { componentManager } from '../component/componentmanager';

import { setChildren, setStyle } from 'redom';

/*
  +------------------------------------------------------------------------+
  | JOURNEY MANAGER                                                        |
  +------------------------------------------------------------------------+
*/

class hotspotCreatorClass extends floatingManager {

    selectionContainer:any;
    constructor () {
      super();
      let addbutton = this.addButton({ui:'text', text:'Add hotspot'}, () => {
        this.addHotspot();
      });
      setStyle(addbutton.el.parentNode, {margin:'10px 10px'});
    }

    showCreator () {
      setChildren(layerRight, [this.control.el]);
    }

    addHotspot() {
      let newHotspot = componentManager.addComponent('hotspot', {scale:{x:0.1, y:0.1, z:0.1}, rotation:{x:0, y:0, z:0}, position:null}, {color:[58, 102, 255, 1], textColor:[255, 255, 255, 1], text:{textColor:[255, 255, 255, 1], pipeline:{vignette:[58, 102, 255, 1]}}});
      newHotspot.newComponent();
      newHotspot.content.pattern.hide();
      newHotspot.content.text.setTitle(newHotspot.content.tag);
      newHotspot.content.setLabelText(newHotspot.content.tag);
    }
}

export let hotspotCreator = new hotspotCreatorClass();
