
import { shape } from '../../viewer/contents/patterns/shape';
import { renameEvent } from '../../viewer/contents/patterns/text';
import { hotspotcontent, hotspotStyle } from '../../viewer/contents/hotspot';
import { shapecontent, shapeStyle } from '../../viewer/contents/shape';
import { particlecontent, particleStyle } from '../../viewer/contents/particle';
import { imagecontent, imageStyle } from '../../viewer/contents/image';
import { videocontent, videoStyle } from '../../viewer/contents/video';
import { modelcontent, modelStyle } from '../../viewer/contents/model';
import { heightmapcontent, heightmapStyle } from '../../viewer/contents/heightmap';
import { lightcontent, lightStyle } from '../../viewer/contents/light';
import { groupcontent, groupStyle} from '../../viewer/contents/group';
import { contentsManager, _pipeline } from '../service/editintale';
import { zoomManager } from '../dragzone/zoomManager';
import { componentChannel } from './component';

import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { GridMaterial } from '@babylonjs/materials/grid/gridMaterial';
import { Color3, Color4 } from '@babylonjs/core/Maths/math'

import {
  textSettings,
  hotspotSettings,
  particleSettings,
  materialSettings,
  imageSettings,
  videoSettings,
  modelSettings,
  lightSettings,
  heightmapSettings,
  groupSettings
} from './componentSettings';

import { contentInterface } from '../../viewer/contents/shared/content';
import { component, componentList } from './component';
import { colormain } from '../service/colors';
import { _system } from '../service/editintale';

import filter from 'lodash/filter';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import find from 'lodash/find';
import { Vector3 } from '@babylonjs/core/Maths/math'

export let sphereHelperSize = 0.02;
/*
  +------------------------------------------------------------------------+
  | HOTSPOT COMPONENT                                                      |
  +------------------------------------------------------------------------+
*/

export class hotspotcomponent extends component {

  content:hotspotcontent;
  availableAxis = {
    position : {x:1, y:1, z:1},
    rotation : {x:0, y:0, z:0},
    scale : {x:0, y:0, z:0},
    opacity : 0,
    hyperlink : 0,
  };
  availableOptions = {
    shadow: 0,
    play: 0,
    visible: 0,
    lock: 0,
  };

  keepratio = true;

  manager = hotspotSettings;
  secondmanager = textSettings;

  constructor (content:contentInterface, hotspotStyle:hotspotStyle) {
    super(content, 'hotspot', hotspotStyle);
    this.content.pattern.setMaterialType('standard');
    this.content.pattern.setMaterialProperties({alpha: 0, metallic: 0, roughness: 1});
    this.content.pattern.setMaterialOptions({fogEnabled:false, lightEnabled:false, waterEnabled:false, shadowEnabled:false});
    this.content.pattern.forcelistenEvent(true);
    this.content.pattern.setTextureColor('emissive', this.content.color);

    this.content.text.container[renameEvent["click"]].add(() => {
      if (!zoomManager.livemode) {
        this.content.text.hide();
        componentChannel.publish('hidetext');
        textSettings.hideInputs();
      }
    });
  }

  store () {
    this._store();
    return this;
  }

  unstore () {
    this._unstore();
    return this;
  }

  select () {
    this._select();
    this.show();
  }

  unselect () {
    _pipeline.removeCamera(zoomManager.cameraedit);
    if (this.content.text.shown) {
      this.content.text.hide();
      componentChannel.publish('hidetext');
      textSettings.hideInputs();
    } else {
      this._unselect();
      this.content.hideLabel();
    }
  }

  show () {
    this._show();
    let radius = zoomManager.cameraedit.radius;
    let scale = sphereHelperSize * radius;
    this.content.pattern.setScaleAxis({x:scale, y:scale, z:scale});
    this.content.pattern.setMaterialProperty('alpha', 1);
    this.content.fadeIn();
  }

  hide () {
    this._hide();
    this.content.pattern.setMaterialProperty('alpha', 0);
    this.content.fadeOut();
    this.content.hideLabel();
  }

  destroy () {
    // this.text._destroy();
    this._destroy();
  }

  // Force selector to be bigger than hotspot size
  getWindowSize() : Vector3 {
    return this.windowsize = new Vector3(20, 20, 0);
  }

  scrollInputs = {
    title:'Hotspot visibility',
    start:'visibilityStart',
    end:'visibilityEnd'
  };
}

/*
  +------------------------------------------------------------------------+
  | PARTICLE COMPONENT                                                     |
  +------------------------------------------------------------------------+
*/

export class particlecomponent extends component {

  content:particlecontent;
  availableAxis = {
    position : {x:1, y:1, z:1},
    rotation : {x:1, y:1, z:1},
    scale : {x:1, y:1, z:1},
    opacity : 0,
    hyperlink : 1,
  };
  availableOptions = {
    shadow: 0,
    play: 1,
    visible: 1,
    lock: 1,
  };

  keepratio = false;

  manager = particleSettings;

  constructor (content:contentInterface, psStyle:particleStyle) {
    super(content, 'particle', psStyle);
  }

  destroy () {
    this.content.particle.stop();
    // Don't know why it bugs if plane is destroy with particle still on it
    this.content.particle.emitter = null;
    this._destroy();
  }
}

/*
  +------------------------------------------------------------------------+
  | MESH COMPONENT                                                         |
  +------------------------------------------------------------------------+
*/

export class shapecomponent extends component {

  content:shapecontent;
  availableAxis = {
    position : {x:1, y:1, z:1},
    rotation : {x:1, y:1, z:1},
    scale : {x:1, y:1, z:1},
    opacity : 1,
    hyperlink : 1,
  };
  availableOptions = {
    shadow: 1,
    play: 1,
    visible: 1,
    lock: 1,
  };

  keepratio = false;

  manager = materialSettings;

  // manager = meshSettings;
  // secondmanager = materialSettings;

  constructor (content:contentInterface, shapeStyle:shapeStyle) {
    super(content, 'shape', shapeStyle);
  }

  setTypeComponent () {
    // If material change, we have to put back the reflexion texture
    contentsManager.checkContentReflection(this.content, contentsManager.reflectionColor);
  }
}

/*
  +------------------------------------------------------------------------+
  | HEIGHTMAP COMPONENT                                                        |
  +------------------------------------------------------------------------+
*/

export class heightmapcomponent extends component {

  content:heightmapcontent;
  availableAxis = {
    position : {x:1, y:1, z:1},
    rotation : {x:1, y:1, z:1},
    scale : {x:1, y:1, z:1},
    opacity : 1,
    hyperlink : 0,
  };
  availableOptions = {
    shadow: 1,
    play: 1,
    visible: 1,
    lock: 1,
  };

  keepratio = false;

  manager = heightmapSettings;
  secondmanager = materialSettings;

  constructor (content:contentInterface, heightmapStyle:heightmapStyle) {
    super(content, 'heightmap', heightmapStyle);
  }
}

/*
  +------------------------------------------------------------------------+
  | IMAGE COMPONENT                                                        |
  +------------------------------------------------------------------------+
*/

export class imagecomponent extends component {

  content:imagecontent;
  availableAxis = {
    position : {x:1, y:1, z:1},
    rotation : {x:1, y:1, z:1},
    scale : {x:1, y:1, z:0},
    opacity : 1,
    hyperlink : 1,
  };
  availableOptions = {
    shadow: 1,
    play: 1,
    visible: 1,
    lock: 1,
  };

  keepratio = true;

  manager = imageSettings;

  constructor (content:contentInterface, imageStyle:imageStyle) {
    super(content, 'image', imageStyle);
  }

  destroy () {
    this.content.image.dispose();
    this.content.texture.dispose();
    this._destroy();
  }
}

/*
  +------------------------------------------------------------------------+
  | IMAGE COMPONENT                                                        |
  +------------------------------------------------------------------------+
*/

export class videocomponent extends component {

  content:videocontent;
  availableAxis = {
    position : {x:1, y:1, z:1},
    rotation : {x:1, y:1, z:1},
    scale : {x:1, y:1, z:0},
    opacity : 1,
    hyperlink : 1,
  };
  availableOptions = {
    shadow: 1,
    play: 1,
    visible: 1,
    lock: 1,
  };

  keepratio = true;

  manager = videoSettings;

  constructor (content:contentInterface, videoStyle:videoStyle) {
    super(content, 'video', videoStyle);
  }

  destroy () {
    this.content.video.dispose();
    this.content.texture.dispose();
    this._destroy();
  }
}

/*
  +------------------------------------------------------------------------+
  | MODEL COMPONENT                                                        |
  +------------------------------------------------------------------------+
*/

export class modelcomponent extends component {

  content:modelcontent;
  availableAxis = {
    position : {x:1, y:1, z:1},
    rotation : {x:1, y:1, z:1},
    scale : {x:1, y:1, z:1},
    opacity : 0,
    hyperlink : 1,
  };
  availableOptions = {
    shadow: 1,
    play: 1,
    visible: 1,
    lock: 1,
  };

  keepratio = false;

  manager = modelSettings;
  secondmanager = materialSettings;

  constructor (content:contentInterface, modelStyle:modelStyle) {
    super(content, 'model', modelStyle);
    // Parent must be visible for selector
    this.content.pattern.setTransparentMaterial();
  }
}

/*
  +------------------------------------------------------------------------+
  | LIGHT COMPONENT                                                        |
  +------------------------------------------------------------------------+
*/

export class lightcomponent extends component {

  content:lightcontent;
  availableAxis = {
    position : {x:1, y:1, z:1},
    rotation : {x:1, y:1, z:1},
    scale : {x:0, y:0, z:0},
    opacity : 0, // Can't play with opacity in model as we de-activate it in group (a model is a group)
    hyperlink : 0,
  };
  availableOptions = {
    shadow: 0,
    play: 1,
    visible: 1,
    lock: 1,
  };

  keepratio = true;

  manager = lightSettings;

  constructor (content:contentInterface, lightStyle:lightStyle) {
    super(content, 'light', lightStyle);
    this.content.pattern.setMaterialType('standard');
    this.content.pattern.setMaterialProperties({alpha: 0, metallic: 0, roughness: 1});
    this.content.pattern.setTextureColor('emissive', this.content.color);
    this.content.pattern.mesh.visibility = 1;
  }

  select () {
    this.setHelper();
    this._select();
    this.show();
  }

  unselect () {
    if (this.helper) this.helper.mesh.dispose();
    this._unselect();
  }

  show () {
    this._show();
    let radius = zoomManager.cameraedit.radius;
    let scale = sphereHelperSize * radius;
    this.content.pattern.setScaleAxis({x:scale, y:scale, z:scale});
  }

  hide () {
    this._hide();
  }

  helper:shape;
  setHelper () {
    if (this.helper) this.helper.mesh.dispose();
    this.helper = new shape(this.content._system, {waterEnabled:false, shadowEnabled:false, lightEnabled:false, fogEnabled:false, glowEnabled:false});
    if (this.content.mode == 'point') {
      this.setPointHelper();
    } else if (this.content.mode == 'spot') {
      this.setSpotHelper();
    } else if (this.content.mode == 'directional') {
      this.setDirectionalHelper();
    }
  }

  setPointHelper () {
    let mesh = MeshBuilder.CreateSphere("spherehelper", {}, _system.scene);
    this.helper.setMesh(mesh);
    this.helper.setParent(this.content.pattern);
    this.helper.mesh.material = new GridMaterial("groundMaterial", _system.scene);
    let c = this.content.color;
    if (!c) c = [255, 255, 255];
    this.helper.mesh.material.lineColor = new Color3(c[0]/255, c[1]/255, c[2]/255);
    this.helper.mesh.material.mainColor = new Color3(0, 0, 0);
    this.helper.mesh.material.gridRatio = 1;
    // Force opacity != 1 to have no main color on grid material and let the plane2 visible
    this.helper.mesh.material.opacity = 0.99;
    this.helper.mesh.material.maxSimultaneousLights = 0;
    this.helper.mesh.material.backFaceCulling = false;

    let helperScale = this.content.intensity * 2;
    this.helper.setScaleAxis({x:helperScale, y:helperScale, z:helperScale});
  }

  setSpotHelper () {
    let height = 10000;
    let options = {
      diameterTop:0,
      diameterBottom:height * 1.5,
      height: height,
      tessellation: 6,
    };
    let mesh = MeshBuilder.CreateCylinder("cone", options, _system.scene);
    this.helper.setMesh(mesh);
    this.helper.setParent(this.content.pattern);
    this.helper.setMaterialType('standard');
    this.helper.setTextureColor('ambient', this.content.color);
    this.helper.mesh.material.wireframe = true;
    this.helper.setPosAxis({y:- height / 2});
  }

  setDirectionalHelper () {
    let c = this.content.color;
    let bc = new Color4(c[0]/255, c[1]/255, c[2]/255, 1);
    let mesh = MeshBuilder.CreateLines("catmullRom", {points:[new Vector3(0,0,0), new Vector3(0,-10,0)], colors:[bc, bc]}, _system.scene)
    this.helper.setMesh(mesh);
    this.helper.setParent(this.content.pattern);
  }
}

/*
  +------------------------------------------------------------------------+
  | GROUP COMPONENT                                                        |
  +------------------------------------------------------------------------+
*/

export class groupcomponent extends component {

  content:groupcontent;
  availableAxis = {
    position : {x:1, y:1, z:1},
    rotation : {x:0, y:0, z:0},
    scale : {x:0, y:0, z:0},
    opacity : 0,
    hyperlink : 1,
  };
  availableOptions = {
    shadow: 1,
    play: 1,
    visible: 1,
    lock: 1,
  };

  keepratio = true;
  components:Array<component> = [];

  manager = groupSettings;

  constructor (content:contentInterface, groupStyle:groupStyle) {
    super(content, 'group', groupStyle);
    this.content.pattern.setTransparentMaterial();
    this.lockComponents();
  }

  getComponents () {
    return filter(componentList, (o) => { return o.content.type != 'group' && o.content.groupkey == this.content.groupkey; });
  }

  lockComponents () {
    for (let i = 0; i < this.components.length; i++) {
      this.components[i].content.locked = true;
      this.components[i].hideSelector();
    }
  }

  highlightContent (test:boolean) {
    for (let i = 0; i < this.components.length; i++) {
      this.components[i].content.pattern.highlight(test, colormain);
    }
  }

  setTypeComponent (typeStyle:any) {
    if (typeStyle.contentids) {
      for (let i = 0; i < this.content.contentids.length; i++) {
        let id = this.content.contentids[i];
        if (!includes(typeStyle.contentids, id)) { // if component out of group
          let component = find(componentList, (o) => { return o.content.id == id; });
          this.removeComponent(component, true);
        }
      }
      for (let i = 0; i < typeStyle.contentids.length; i++) {
        let id = typeStyle.contentids[i];
        if (!includes(this.content.contentids, id) && id) { // if component out of group
          let component = find(componentList, (o) => { return o.content.id == id; });
          this.addComponent(component, true);
        }
      }
    }
  }

  addComponents () {
    this.content.addContents(contentsManager.list);
    this.components = this.getComponents();
    this.lockComponents();
  }

  addComponent (component:component, undo:boolean) {
    let content = component.content;
    if (this.components.indexOf(component) == -1) this.components.push(component);
    component.content.locked = true;
    component.hideSelector();
    if (undo) {
      this.content.pattern.addMesh(content.pattern);
      this.content.addContentGroupValues(content);
    } else {
      this.content.addContent(content);
      this.content.getGroupNewPosition();
    }
    // Don't know why timeout needed but bad selector position if not
    setTimeout(() => {
      if (this.selected) this.select(); // Force select in order to reset border
    }, 10);
  }

  removeComponent (component:component, undo:boolean) {
    if (this.components.length == 2) return this.store();
    let content = component.content;
    remove(this.components, (o) => { return o.content.id === content.id; });
    if (undo) {
      this.content.pattern.separateMesh(content.pattern);
      this.content.removeContentGroupValues(content);
    } else {
      this.content.separateContent(content);
      this.content.getGroupNewPosition();
    }
    component.content.locked = false;
    setTimeout(() => {
      if (this.selected) this.select(); // Force select in order to reset border
    }, 10);
  }

  store () {
    this.content.separateAllContents();
    for (let i = 0; i < this.components.length; i++) {
      this.components[i].content.locked = false;
    }
    this.content.pattern.hide();

    this._store();
    return this;
  }

  unstore () {
    this.lockComponents();
    this.content.addContents(contentsManager.list);
    this.content.pattern.show();
    this._unstore();
    return this;
  }
}
