
import { ui } from './common';
import { position } from '../../viewer/tools/interface';
import { colortext, colormain, colorgrey } from '../service/colors';

import { el, mount, setAttr, setChildren, setStyle } from 'redom';
import Suggestions from 'suggestions';

export let defaultwithinput = 105;
export let defaultleftinput = 138;

export class ui_input extends ui {
	label:HTMLElement;
	container:any;
}

/*
+------------------------------------------------------------------------+
| BUTTON                                                                 |
+------------------------------------------------------------------------+
*/

export interface textnode {
	ui:string;
	text:string;
}

export class ui_button extends ui {
	textnode:textnode;

	constructor(parent:any, textnode:textnode, className:string) {
    super();
		this.textnode = textnode;
		if (textnode.ui == 'text') {
			this.el = el('div', {class:className}, textnode.text);
			mount(parent, this.el);
		}	else if (textnode.ui == 'icon') {
			this.el = el('div', {class:className+' icon-'+textnode.text}, [el('span.path1'), el('span.path2'), el('span.path3')]);
			mount(parent, this.el);
		} else if (textnode.ui == 'image') {
			this.el = el('div', {class:className},
				el('img', { src: textnode.text})
			);
			mount(parent, this.el);
		}
	}

	setText (text:string) {
		this.el.textContent = text;
	}
}

/*
  +------------------------------------------------------------------------+
  | IMAGE BUTTON                                                           |
  +------------------------------------------------------------------------+
*/

export class ui_imagebutton extends ui {
	image:any;
	position:position;

	constructor(parent:any, imageurl:string, className:string) {
    super();
		this.el = el('div', {class:className});
		mount(parent, this.el);
		this.setImage(imageurl);
	}

	setImage (imageurl:string) {
		this.image = el('img', { src: imageurl, style: {width:'100%', height:'100%', 'object-fit':'contain'} });
		setChildren(this.el, [this.image]);
	}
}

/*
  +------------------------------------------------------------------------+
  | TEXT INPUT                                                             |
  +------------------------------------------------------------------------+
*/

export class ui_textinput extends ui_input {
	label:HTMLElement;
	value:string;

  constructor(parent:any, text:string, className:string) {
		super();
		this.el = el('input', {class:'siimple-input '+className});
		mount(parent, this.el);
		setAttr(this.el, { type: 'text', placeholder:text.toString(), onfocus:()=>{this.el.select()} });
		this.value = text.toString();
		this.setEvents();
		return this;
	}

	setValue (value:string) {
		if (value == undefined) return setAttr(this.el, {value: ''});
		if (value == this.el.value) return this;
		this.value = value.toString();
		setAttr(this.el, {value: value});
		setStyle(this.el, {border: '2px', color: colorgrey});
		setTimeout(()=>{setStyle(this.el, {border: '0px', color: colortext})}, 200);
		return this;
	}

	setPlaceholder (text:string) {
		setAttr(this.el, {value: ''});
		setAttr(this.el, {placeholder:text.toString()});
	}

	setEvents () {
		this.el.addEventListener('input', (evt) => {
			this.value = evt.target.value;
		});
		this.el.addEventListener("keyup", (evt) => {
			event.preventDefault();
			if (evt.keyCode === 13) {
				this.value = evt.target.value;
				for (let i = 0; i < this.enterkeyFunctions.length; i++) {
					this.enterkeyFunctions[i](this.value, this, evt);
				}
			}
		});
	}

	inputEvent = {
		change:'change',
		input:'input',
		focus:'focus',
		blur:'blur',
		click:'click',
		enterkey:'enterkey',
	};
	changeFunctions:Array<Function> = [];
	enterkeyFunctions:Array<Function> = [];
	on (event:string, funct:Function) {
		if (event == 'change') this.changeFunctions.push(funct);
		if (event == 'enterkey') this.enterkeyFunctions.push(funct);
		this.el.addEventListener(this.inputEvent[event], (evt) => {
			funct(evt.target.value, this, evt);
		});
		if (event == 'click') {
			this.el.addEventListener('contextmenu', (evt) => {
				evt.preventDefault();
				funct(evt.target.value, this, evt);
			}, false);
		}
		return this;
	}
}

/*
  +------------------------------------------------------------------------+
  | PARAGRAPH INPUT                                                        |
  +------------------------------------------------------------------------+
*/

export class ui_paragraphinput extends ui_input {
	label:HTMLElement;
	value:string;
	max = 300;

  constructor(parent:any, text:string, className:string) {
		super();
		this.el = el('textarea', {class:'siimple-input '+className, maxlength:this.max});
		mount(parent, this.el);
		setAttr(this.el, { placeholder:text.toString() });
		this.value = text.toString();
		this.setEvents();
		this.setCount();
		return this;
	}

	count:any;
	setCount () {
		this.count = el('div', {class:'input-count'});
		mount(this.el.parentNode, this.count);
	}

	setValue (value:string) {
		if (value == undefined) return setAttr(this.el, {value: ''});
		if (value == this.el.value) return this;
		this.value = value.toString();
		setAttr(this.el, {value: value});
		setStyle(this.el, {border: '2px', color: colorgrey});
		setTimeout(()=>{setStyle(this.el, {border: '0px', color: colortext})}, 200);
		this.count.textContent = this.value.length + '/' + this.max;
		return this;
	}

	setPlaceholder (text:string) {
		setAttr(this.el, {value: ''});
		setAttr(this.el, {placeholder:text.toString()});
	}

	setEvents () {
		this.el.addEventListener('input', (evt) => {
			this.value = evt.target.value;
			this.count.textContent = this.value.length + '/' + this.max;
		});
		this.el.addEventListener("keyup", (evt) => {
			event.preventDefault();
			if (evt.keyCode === 13) {
				this.value = evt.target.value;
				for (let i = 0; i < this.enterkeyFunctions.length; i++) {
					this.enterkeyFunctions[i](this.value, this, evt);
				}
			}
		});
	}

	inputEvent = {
		change:'change',
		input:'input',
		focus:'focus',
		blur:'blur',
		click:'click',
		enterkey:'enterkey',
	};
	changeFunctions:Array<Function> = [];
	enterkeyFunctions:Array<Function> = [];
	on (event:string, funct:Function) {
		if (event == 'change') this.changeFunctions.push(funct);
		if (event == 'enterkey') this.enterkeyFunctions.push(funct);
		this.el.addEventListener(this.inputEvent[event], (evt) => {
			funct(evt.target.value, this, evt);
		});
		if (event == 'click') {
			this.el.addEventListener('contextmenu', (evt) => {
				evt.preventDefault();
				funct(evt.target.value, this, evt);
			}, false);
		}
		return this;
	}
}

/*
  +------------------------------------------------------------------------+
  | NUMBER                                                               |
  +------------------------------------------------------------------------+
*/

export interface numberoption {
  value?:number,
	unit?:string,
	left?:number,
	width?:number,
	min?:number,
	max?:number,
	step?:number
	decimal?:number
}

export class ui_numberinput extends ui_input {

	label:HTMLElement;
	unit:any;
	value:number;
	width = 50;
	left = defaultleftinput;
	max:number;
	min:number;
	decimal:number;

	constructor (parent:any, number:numberoption, className:string) {
		super();
		this.el = el('input', {class:'siimple-input '+className});
		if (number.width !== undefined) this.width = number.width;
		if (number.left !== undefined) this.left = number.left;
		setStyle(this.el, {width:this.width+'px', left:this.left.toString()+'px'})
		mount(parent, this.el);
		if (number.value) setAttr(this.el, {value: number.value});
		let step = (number.step)? number.step : 0.1;
		this.value = number.value;
		setAttr(this.el, { type: 'number', step: step, onfocus:()=>{this.el.select()} });
		this.setMinMax(number);
		if (number.decimal !== undefined) this.decimal = number.decimal;
		this.setUnit(number);
		this.setEvents();
		return this;
	}

	setMinMax (number:numberoption) {
		this.min = number.min;
		this.max = number.max;
		// Prevent the correct step, it can add the minimum value to the step
		// if (number.min !== undefined) setAttr(this.el, { min: number.min });
		// if (number.max !== undefined) setAttr(this.el, { max: number.max });
	}

	setUnit (number:numberoption) {
		this.unit = el('div', {class:'input-unit'});
		this.unit.textContent = number.unit;
		mount(this.el.parentNode, this.unit);

		this.on('focus', () => {setStyle(this.unit, {display:'none'})});
		this.on('blur', () => {setStyle(this.unit, {display:'block'})});

		this.updateUnit(number.unit);
	}

	updateUnit (unit:string) {
		let unitwidth = unit.length * 10
		setStyle(this.unit, {left:(this.left + this.width - unitwidth - 2).toString()+'px', width:unitwidth+'px'});
		this.unit.textContent = unit;
	}

	setValue (value:number) {
		if (value == undefined) return setAttr(this.el, {value: 0});
		if (value === this.el.value) return this;
		this.value = value;
		this.checkDecimal();
		setAttr(this.el, {value: this.value});
		setStyle(this.el, {border: '2px', color: colormain});
		setTimeout(()=>{setStyle(this.el, {border: '0px', color: colortext})}, 200);
		return this;
	}

	checkDecimal () {
		if (this.value) {
			let abs = Math.abs(this.value);
			let decimal = abs - Math.floor(abs);
			if (decimal && this.decimal) {
				let highvalue = this.value * Math.pow(10, this.decimal);
				let signvalue = (this.value > 0)? Math.floor(highvalue) : Math.ceil(highvalue);
				this.value = signvalue / Math.pow(10, this.decimal);
			}
		}
		if (!this.value && this.value !== 0) this.value = 0;
	}

	checkMaxMin () {
		let value = parseFloat(this.el.value);
		if (this.min) value = Math.max(this.min, value);
		if (this.max) value = Math.min(this.max, value);
		// Make sure value is not empty which gives a NaN
		if (!this.value && this.value !== 0) this.value = 0;
		// If decimal number, it can be very anoying to change the value we type
		// str.length > 2 so that we can have empty value
		let str = this.value.toString();
		if (str.indexOf('.') == -1 && str.length > 2) setAttr(this.el, {value: this.value});
		this.value = value;
	}

	setEvents () {
		this.el.addEventListener('blur', (evt) => {
			setAttr(this.el, {value: this.value});
		});
		this.el.addEventListener("keyup", (evt) => {
			event.preventDefault();
			if (evt.keyCode === 13) {
				this.checkMaxMin();
				this.checkDecimal();
				for (let i = 0; i < this.enterkeyFunctions.length; i++) {
					this.enterkeyFunctions[i](this.value, this, evt);
				}
			}
		});
	}

	inputEvent = {
		change:'input',
		focus:'focus',
		blur:'blur',
		click:'click',
		enterkey:'enterkey',
	};
	changeFunctions:Array<Function> = [];
	enterkeyFunctions:Array<Function> = [];
	on (event:string, funct:Function) {
		if (event == 'change') this.changeFunctions.push(funct);
		if (event == 'enterkey') this.enterkeyFunctions.push(funct);
		this.el.addEventListener(this.inputEvent[event], (evt) => {
			this.value = parseFloat(this.el.value);
			// Useless to check value when focus + prevent auto selection on focus
			if (event != 'focus') {
				this.checkMaxMin();
				this.checkDecimal();
			}
			funct(this.value, this, evt);
		});
		return this;
	}
}

/*
  +------------------------------------------------------------------------+
  | CHECKBOX                                                               |
  +------------------------------------------------------------------------+
*/

export class ui_checkbox extends ui_input {
	label:HTMLElement;

  constructor(parent:any, checked:boolean, className:string) {
		super();
		let key = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

		let div = el('div', {class:'siimple-checkbox '+className},
			[
				this.el = el('input', {type: 'checkbox', checked:checked, id:'myCheckbox'+key}),
				el('label', {for:'myCheckbox'+key})
			]
		);

		mount(parent, div);
		return this;
	}

	setValue (checked:boolean) {
		if (checked == undefined) return setAttr(this.el, {checked:false});
		setAttr(this.el, {checked:checked});
	}

	inputEvent = {
		change:'click',
		focus:'mousedown',
		blur:'mouseup',
	};
	on (event:string, funct:Function) {
		this.el.addEventListener(this.inputEvent[event], (evt) => {
			funct(evt.target.checked, this);
		});
		return this;
	}
}

/*
  +------------------------------------------------------------------------+
  | SLIDER                                                                 |
  +------------------------------------------------------------------------+
*/

export interface slideroption {
	value:number;
	max:number;
	min:number;
	step?:number;
}

export class ui_slider extends ui_input {
	label:HTMLElement;
	number:HTMLElement;
	defaultValue:number;
	min:number;
	max:number;
	step:number;

  constructor(parent:any, slideroption:slideroption, className:string) {
		super();
		this.defaultValue = slideroption.value;
		this.min = slideroption.min;
		this.max = slideroption.max;
		this.step = slideroption.step;
		this.el = el('input', { class:'range '+className, type: 'range', value:slideroption.value, min:this.min, max:this.max, step:'0.01' })
		mount(parent, this.el);
		this.number = el('input', { class:'rangenumber '+className, type: 'number', value:slideroption.value, min:this.min, max:this.max, step:this.step })
		mount(parent, this.number);
		return this;
	}

	setValue (value:number) {
		if (value == undefined) {
			setAttr(this.el, {value:this.defaultValue});
			setAttr(this.number, {value:this.defaultValue});
		} else {
			setAttr(this.el, {value:value});
			setAttr(this.number, {value:value});
		}
	}

	checkMaxMin (value:string) {
		let newvalue = parseFloat(value);
		if (this.min) newvalue = Math.max(this.min, newvalue);
		if (this.max) newvalue = Math.min(this.max, newvalue);
		// Make sure value is not empty which gives a NaN
		if (!newvalue && newvalue !== 0) newvalue = 0;
		// If decimal number, it can be very anoying to change the value we type
		let str = value.toString();
		if (str.indexOf('.') == -1) return newvalue;
		else return value;
	}

	inputEvent = {
		change:'input',
		focus:'mousedown',
		blur:'mouseup',
	};
	numberInputEvent = {
		change:'input',
		focus:'focus',
		blur:'blur',
	}
	on (event:string, funct:Function) {
		this.el.addEventListener(this.inputEvent[event], (evt) => {
			let value = evt.target.value;
			setAttr(this.number, {value:value});
			funct(parseFloat(evt.target.value), this);
		});
		this.number.addEventListener(this.numberInputEvent[event], (evt) => {
			let value = this.checkMaxMin(evt.target.value);
			setAttr(this.el, {value:value});
			setAttr(this.number, {value:value});
			funct(parseFloat(evt.target.value), this);
		});
		return this;
	}
}

/*
  +------------------------------------------------------------------------+
  | RADIO                                                                  |
  +------------------------------------------------------------------------+
*/

export interface radiooption {
	value:string;
	list:Array<string>;
}

export class ui_radio extends ui_input {
	option:Array<string>;
	label:HTMLElement;

  constructor(parent:any, radiooption:radiooption, className:string) {
		super();
		this.el = el('div', { class:'radio '+className })
		mount(parent, this.el);
		setStyle(parent, {height:22 * radiooption.list.length+'px'});
		this.setInput(radiooption);
		return this;
	}

	radiobuttons:Array<any> = [];
	radionodes:Array<any> = [];
	setInput (radiooption:radiooption) {
		this.option = radiooption.list;
		for (let i = 0; i < this.option.length; i++) {
			let label = this.option[i]
			let radiobutton;
			let div = el('div', {class:'siimple-radio'},
				[
					radiobutton = el('input', {type: 'radio', id:label}),
					el('label', {for:label})
				]
			);

			this.radiobuttons.push(radiobutton);
			this.radionodes.push(div);
			let radiolabel = el('div', label, {class:'radio-label'});
			mount(div, radiolabel);
			if (label == radiooption.value) setAttr(radiobutton, {checked:true});
		}
		setChildren(this.el, [this.radionodes]);
	}

	setValue (value:string) {
		if (value == undefined) value = this.option[0];
		for (let i = 0; i < this.option.length; i++) {
			if (value == this.option[i]) setAttr(this.radiobuttons[i], {checked:true});
			else setAttr(this.radiobuttons[i], {checked:false});
		}
	}

	inputEvent = {
		change:'click',
		focus:'mousedown',
		blur:'mouseup',
	};
	on (event:string, funct:Function) {
		for (let i = 0; i < this.radiobuttons.length; i++) {
			((i)=>{
				this.radiobuttons[i].addEventListener(this.inputEvent[event], (evt) => {
					funct(evt.target.id, this);
					this.setValue(evt.target.id);
				});
			})(i)
		}
		return this;
	}
}

/*
  +------------------------------------------------------------------------+
  | RADIO ICON                                                             |
  +------------------------------------------------------------------------+
*/

export interface radioiconoption {
	value:string;
	iconperline:number;
	list:Array<string>;
}

export class ui_radioicon extends ui_input {
	option:Array<string>;
	iconperline:number;
	label:HTMLElement;
	linenumber
	row:any;

  constructor(parent:any, radiooption:radioiconoption, className:string) {
		super();
		this.option = radiooption.list;
		this.iconperline = radiooption.iconperline;
		this.linenumber = Math.ceil(radiooption.list.length/radiooption.iconperline);
		this.el = el('div', { class:'radio '+className })
		mount(parent, this.el);
		setStyle(parent, {height:(this.linenumber*22+2).toString()+'px'});
		setStyle(this.el, {height:(this.linenumber*22).toString()+'px'});
		setStyle(this.el, {'z-index':2, overflow: 'hidden'});
		this.setInput(radiooption);
		return this;
	}

	radiobuttons:Array<any> = [];
	setInput (radiooption:radioiconoption) {
		let width = defaultwithinput/this.iconperline;
		for (let i = 0; i < this.option.length; i++) {
			let label = this.option[i];
			let radiobutton = el('div.radio-icon-button.icon-'+label, {id:label, style:{width:width+'px'}},
				[el('span.path1'), el('span.path2'), el('span.path3')]
			);
			this.radiobuttons.push(radiobutton);
		}
		setChildren(this.el, this.radiobuttons);
		this.setValue(radiooption.value);
	}

	setValue (value:string) {
		if (value == undefined) value = this.option[0];
		for (let i = 0; i < this.option.length; i++) {
			let label = this.option[i];
			if (value == this.option[i]) setAttr(this.radiobuttons[i], {class:'radio-icon-button radio-selected icon-'+label});
			else setAttr(this.radiobuttons[i], {class:'radio-icon-button icon-'+label});
		}
	}

	inputEvent = {
		change:'click',
		focus:'mousedown',
		blur:'mouseup',
	};
	on (event:string, funct:Function) {
		for (let i = 0; i < this.radiobuttons.length; i++) {
			((i)=>{
				let radiobutton = this.radiobuttons[i];
				let value = radiobutton.id;
				radiobutton.addEventListener(this.inputEvent[event], (evt) => {
					funct(value, this);
					this.setValue(value);
				});
			})(i)
		}
		return this;
	}
}

/*
  +------------------------------------------------------------------------+
  | SELECT                                                                 |
  +------------------------------------------------------------------------+
*/

export interface selectoption {
	value:string;
	list:Array<string>;
}

export class ui_select extends ui_input {
	options:Array<string>;
	label:HTMLElement;

  constructor(parent:any, selectoption:selectoption, className:string) {
		super();
		this.el = el('div', { class:'select siimple-select '+className })
		mount(parent, this.el);
		this.el.textContent = selectoption.value;
		this.setInput(selectoption);
		this.setEvents();
		return this;
	}

	selectlabels:Array<any> = [];
	suggestion:Suggestions;
	list:Suggestions["List"];
	setInput (selectoption:selectoption) {
		// We trick the suggestion library to be able to use it as select list
		// Classic select style not really beautiful and customizable
		this.suggestion = new Suggestions(this.el, [], {
			minLength:0
		});
		this.suggestion.handleInputChange = () => {}

		this.setOptions(selectoption.list);
		this.list.handleMouseUp = (item) => {
			this.list.hide();
			this.setValue(item.string);
			for (let i = 0; i < this.changeFunctions.length; i++) {
				this.changeFunctions[i](item.string, this);
			}
		};
	}

	setOptions (options:Array<string>) {
		this.options = options;
		this.suggestion.update(options);
		this.list = this.suggestion.list;
		this.list.clear();
		for (var i = 0; i < options.length; i++) {
			this.list.add({string:options[i]});
		}
		this.list.draw();
		this.list.hide();
	}

	setEvents () {
		this.on('focus', () => {
			this.list.show();
		});

		this.on('blur', () => {
			this.list.hide();
		});

		document.addEventListener('click', (e) => {
			 if (e.target !== this.el) this.blur();
		});
	}

	blur () {
		for (let i = 0; i < this.blurFunctions.length; i++) {
			this.blurFunctions[i](this.value, this);
		}
	}

	value:string;
	setValue (value:string) {
		if (value == undefined) value = '';
		this.value = value;
		this.el.textContent = value;
	}

	inputEvent = {
		change:'input',
		focus:'mousedown',
		blur:'blur',
	};
	changeFunctions:Array<Function> = [];
	blurFunctions:Array<Function> = [];
	on (event:string, funct:Function) {
		if (event == 'change') this.changeFunctions.push(funct);
		if (event == 'blur') this.blurFunctions.push(funct);
		this.el.addEventListener(this.inputEvent[event], (evt) => {
			funct(evt.target.value, this);
		});
		return this;
	}
}
