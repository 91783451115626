
import { colortext, colorerror } from '../service/colors';
import { api } from '../service/api';
import { bankSearch } from './banksearch';
import { _system } from '../service/editintale';
import { assetpicker, ui_imageassetbutton, ui_assetpicker, pickerChannel, overlayImages } from '../inputs/pickers';
import { globals } from '../service/globals';
import toastr from 'toastr';

import Dropzone from 'dropzone';
import { el, setStyle, mount } from 'redom';
import { undoBar } from '../project/bottombar';

pickerChannel.subscribe("focus", (input: ui_imageassetbutton | ui_assetpicker, envelope) => {
	let type = input.type;
	if (overlayImages.indexOf(type) != -1) type = 'image';
	dropzoneList[type].show();
});

pickerChannel.subscribe("blur", (input: ui_imageassetbutton | ui_assetpicker, envelope) => {
	let type = input.type;
	if (overlayImages.indexOf(type) != -1) type = 'image';
	dropzoneList[type].hide();
});

export let dropzoneList: any = {};
export let hideDropzones = () => {
	for (let i = 0; i < dropzoneList.length; i++) {
		dropzoneList[i].hide();
	}
}

export class dropzoneClass {

	uploader: HTMLElement;
	dropzoneElement: HTMLElement;
	text: HTMLElement;
	banks: HTMLElement;

	ressource: string;
	formats: Array<string>;
	maxWeight: number;

  constructor (ressource:string, formats:Array<string>, banks:Array<string>, maxWeight:number, callback?:Function) {
    let formattext:string;
    if (ressource == 'model') formattext = 'Note : Your zip must contain a valid .gltf, .glb or .obj file';
    else formattext = 'Supported formats: '+formats.join(', ');
    this.uploader = el('div.upload_overlay', {onclick:(evt) => {this.checkHide(evt)}},
      [
        el('div.modal-close.icon-close', {onclick:(evt)=>{this.checkHide(evt);}},
          [el('span.path1'),el('span.path2'),el('span.path3')]
        ),
        this.text = el('div.download.download2', 'Drop '+ressource+' to upload (or click)'),
        el('div.download', formattext),
        this.dropzoneElement = el('div.upload_dropzone'),
        el('div.comment', 'Tips : You can also copy/paste (Ctrl+C and Ctrl+V) any '+ressource+' url to add it in the project.'),
        (banks.length != 0)? this.banks = el('div.comment2', 'Or use one of our partner bank :') : '',
      ]
    );
    mount(document.body, this.uploader);

		this.ressource = ressource;
		this.formats = formats;
		this.maxWeight = maxWeight;
		this.addDropZone(ressource, formats, maxWeight, callback);
		this.addBankLinks(banks);
		this.hide();
		dropzoneList[ressource] = this;
	}

	checkHide(evt) {
		let elclass = evt.target.className;
		// If click outside then hide
		if (elclass.indexOf('upload_dropzone') == -1 && elclass.indexOf('download') == -1) this.hide();
	}

	uploadurl: string;
	dropzone: Dropzone;
	uploading: boolean = false;
	addDropZone(ressource:string, formats:Array<string>, maxWeight:number, callback?:Function) {
		let ressourceUrls: string;
		if (ressource == 'image' || ressource == 'video') ressourceUrls = 'https://api.cloudinary.com/v1_1/naker-io/' + ressource + '/upload';
		else if (ressource == 'sound' || ressource == 'cubetexture') ressourceUrls = 'https://api.cloudinary.com/v1_1/naker-io/raw/upload';
		else if (ressource == 'model') ressourceUrls = api.host + 'v1/model/import?source=file&projectid=' + globals.projectid;
		// else if (ressource == 'model') ressourceUrls = api.host+'v1/model/import?source=file&projectid=5b323afc3aec6500045d7199'; // IMPORT TEST

		var that = this;
		this.dropzone = new Dropzone(this.dropzoneElement, {
			uploadMultiple: false,
			acceptedFiles: '.' + formats.join(',.'),
			timeout: 300000,
			autoProcessQueue: true,
			parallelUploads: 1,
			createImageThumbnails: false,
			// NOTE Keep "function" to have access to that
			init: function () {
				this.on("error", (file, errorMessage) => {
					this.uploading = false;
					if (typeof(errorMessage) === 'string')
						that.errorDropzone(ressource, errorMessage);
				});
			},
			accept: (file, done) => {
				if (file.size > maxWeight * 1024 * 1024)
					return this.errorDropzone(ressource, "Your file is too large, "+maxWeight+"MB is the maximum.");
				if (this.uploading == true)
					return this.errorDropzone(ressource, "A " + ressource + " is already being uploaded, please wait");
				this.uploading = true;
				// Make sure token is ok before uploading model
				if (ressource == 'model') {
					// For some reason this sends a bad token on test.naker.io
					api.refreshToken(() => {
						done();
					});
				} else {
					done();
				}
			},
			url: ressourceUrls,
		});

      this.dropzone.on('sending', (file, xhr, formData) => {
				if (ressource == 'model') { // Add header for naker api
          xhr.setRequestHeader('Accept', 'application/json, text/plain, */*');
          // xhr.setRequestHeader('Authorization', ''); // IMPORT TEST
          xhr.setRequestHeader('Authorization', api.access_token);
        } else { // Add naker key for cloudinary
					formData.append('timestamp', Date.now() / 1000 | 0);
					formData.append('upload_preset', 'hdtmkzvn');
				}
        // Set to 2 because there is to step : Upload in Cloudnary and Download in Scene
        _system.loader.loadwaiting.upload = 1;
				undoBar.animateLoadingBar(2 / 100);
				undoBar.setText('Uploading file');
        this.hide();
      });

      this.dropzone.on('success', (file, response) => {
				this.uploading = false;
        _system.loader.loadwaiting.upload = 0;
				if (ressource == 'model') {
          let url = response.url;
          let name = response.name;
          callback(url, name);
        } else {
					let url = response.secure_url;
					let name = response.original_filename;
					callback(url, name);
					if (globals.save) this.addAsset(response.public_id, url, response.bytes);
				}
      });

		this.dropzone.on('error', (file, response: any) => {
			this.uploading = false;
			undoBar.errorLoad(response.error.message)
		});

		this.dropzone.on('uploadprogress', (file, response) => {
			response = parseInt(response);
			undoBar.animateLoadingBar(response / 100);
			undoBar.setText('Uploading '+ressource+'...');
		});
	}

	addAsset(id: string, url: string, size: number) {
		let query = {
			designer: globals.designerid,
			project: globals.projectid,
			type: this.ressource,
			id: id,
			url: url,
			size: size
		};
		api.post('v1/project/addasset', query, {}, () => { });
	}

	addBankLinks(banks: Array<string>) {
		for (let i = 0; i < banks.length; i++) {
			this.addBankLink(banks[i]);
		}
	}

	addBankLink(name: string) {
		let node = el('div.bank-link-parent', { 'aria-label': name, 'data-microtip-position': 'bottom', 'role': 'tooltip' }, el('img', { src: 'https://asset.naker.io/image/banks/' + name + '.png', class: 'bank-link' }));
		mount(this.banks, node);
		node.addEventListener('click', () => {
			bankSearch.show(name);
		});
	}

	errorDropzone(ressource: string, error: string) {
		this.text.textContent = error;
		setStyle(this.text, { color: colorerror });
		toastr.error(error);
		setTimeout(() => {
			let text = 'Drop ' + ressource + ' to upload (or click)';
			if (ressource == 'model') text = 'Drop asset zip to upload (or click)';
			this.text.textContent = text;
			setStyle(this.text, { color: colortext });
		}, 3000);
	}

	show() {
		setStyle(this.uploader, { display: 'block' });
		setStyle(this.text, { color: colortext });
	}

	hide() {
		setStyle(this.uploader, { display: 'none' });
		assetpicker.hidePicker();
	}
}

export let imageDropzone = new dropzoneClass('image', ["jpg", "png", "gif", "jpeg", "svg", "tga"], ["unsplash"], 10, (url: string, name: string) => {
	if (url.indexOf('.gif') == url.length - 4) {
		// Cloudinary automatically accept mp4 request
		let gifUrl = url.substring(0, url.length - 4) + '.mp4';
		bankSearch.loadVideo(gifUrl, name);
	} else {
		bankSearch.loadImage(url);
	}
});

export let videoDropzone = new dropzoneClass('video', ["mp4", "webm", "ogv"], ["giphy"], 50, (url: string, name: string) => {
	bankSearch.loadVideo(url, name);
});

export let modelDropzone = new dropzoneClass('model', ["zip"], ["poly", "claraio"], 50, (url: string, name: string) => {
	bankSearch.loadModel(url, name);
});

export let soundDropzone = new dropzoneClass('sound', ["mp3", "wav"], [], 10, (url:string, name:string) => {
  bankSearch.loadSound(url, name);
});

export let cubetextureDropzone = new dropzoneClass('cubetexture', ["env", "dds", "hdr"], [], 10, (url:string, name:string) => {
  bankSearch.loadCubeTexture(url, name);
});
