
import { ui_container } from './group';
import { ui_textinput, ui_button } from './input';

import { el, setAttr, mount, unmount } from 'redom';

/*
  +------------------------------------------------------------------------+
  | INVENTORY MANAGER                                                      |
  +------------------------------------------------------------------------+
*/

export class inventoryClass extends ui_container {

  constructor (parent:ui_container, placeholder:string) {
    super(parent.el, 'inventory');
    this.setSaveInputs(placeholder);
  }

  onClick:Function;
  onDelete:Function;
  onAdd:Function;

  inventorylist:Array<any> = [];

  nameinput:ui_textinput;
  currentname:string;
  placeholder:string;
  setSaveInputs (placeholder:string) {
    this.addTitle(placeholder+'s Inventory');
    this.placeholder = placeholder;
    this.nameinput = this.addInput(placeholder + ' name', 'inventory-input');
    this.nameinput.on('input', (text) => {
      this.currentname = text;
    });
    this.nameinput.on('enterkey', (text) => {
      this.addNewValue(placeholder);
    });
    let button = this.addButton({ui:'text', text:'Save'}, 'inventory-add-button');
    button.on('click', () => {
      this.addNewValue(placeholder);
    });
    this.inventoryContainer = this.addContainer('inventory-container-list editor-scroll');
  }

  addNewValue (placeholder:string) {
    if (this.currentname == '' || this.currentname == undefined) this.currentname = placeholder+' '+(this.inventorylist.length+1).toString();
    let index = this.inventorylist.indexOf(this.currentname);
    if (index != -1) return;
    this.addInInventory(this.currentname);
    if (this.onAdd != undefined) this.onAdd(this.currentname);
    this.setInputValue();
  }

  setInputValue () {
    this.nameinput.setValue('');
    setAttr(this.nameinput.el, {placeholder:this.placeholder+' '+(this.inventorylist.length+1).toString()});
    this.currentname = '';
  }

  inventoryButtons:any = {};
  inventoryContainer:ui_container;
  addInInventory (name:string) {
    this.inventorylist.push(name);
    let button = el('div.inventory-button-list', name, {onclick:()=>{if (this.onClick != undefined) this.onClick(name);}},
      el('div.inventory-button-delete.right-icon.icon-delete', {onclick:(evt)=>{evt.stopPropagation();this.removeValue(button, name);}},
        [el('span.path1'),el('span.path2'),el('span.path3')]
      ),
    )
    mount(this.inventoryContainer.el, button);
    this.inventoryButtons[name] = button;
  }

  removeValue (button:ui_button, name:string) {
    let index = this.inventorylist.indexOf(name);
    if (index != -1) this.inventorylist.splice(index, 1);
    delete this.inventoryButtons[name];
    this.setInputValue();
    unmount(this.inventoryContainer.el, button);
    if (this.onDelete != undefined) this.onDelete(name);
  }
}
