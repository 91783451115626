import { Effect } from "../../Materials/effect";

let name = 'clipPlaneVertexDeclaration';
let shader = `#ifdef CLIPPLANE
uniform vec4 vClipPlane;
varying float fClipDistance;
#endif
#ifdef CLIPPLANE2
uniform vec4 vClipPlane2;
varying float fClipDistance2;
#endif
#ifdef CLIPPLANE3
uniform vec4 vClipPlane3;
varying float fClipDistance3;
#endif
#ifdef CLIPPLANE4
uniform vec4 vClipPlane4;
varying float fClipDistance4;
#endif`;

Effect.IncludesShadersStore[name] = shader;
/** @hidden */
export var clipPlaneVertexDeclaration = { name, shader };
