
import { animationManagerClass } from './animationManager';
import { loaderClass } from './loader';
import { size } from './interface';

import { Tools } from '@babylonjs/core/Misc/Tools'
import '@babylonjs/core/Misc/screenshotTools'
import '@babylonjs/core/Animations/animatable'
import { Engine } from '@babylonjs/core/Engines/engine'
import { Scene } from '@babylonjs/core/scene'
import { Vector3, Color3 } from '@babylonjs/core/Maths/math'
import { FreeCamera } from '@babylonjs/core/Cameras/freeCamera'
import { HighlightLayer } from '@babylonjs/core/Layers/highlightLayer'
import { GlowLayer } from '@babylonjs/core/Layers/glowLayer'
import { ShadowGenerator } from '@babylonjs/core/Lights/Shadows/shadowGenerator';
import { WaterMaterial } from '@babylonjs/materials/water/waterMaterial';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { el, mount, setStyle } from 'redom';

export class coreSystem {

  ///////// MAIN BABYLON OBJECT
  maxScaling = 1;
  scene:Scene;
  engine:Engine;
  freecamera:FreeCamera;
  highlighter:HighlightLayer;
  glowLayer:GlowLayer;

  // Container of canvas and text to preview website
  container:any;
  // Main canvas element used for website scene
  canvas:HTMLCanvasElement;

  loader:loaderClass;
  animationManager:animationManagerClass;
  shadowGenerators:Array<ShadowGenerator> = [];
  waterMaterials:Array<WaterMaterial> = [];
  waterMeshRenderlist:Array<Mesh> = [];
  shadowMeshRenderlist:Array<Mesh> = [];

  constructor (containerEL:any) {
    if (!Engine.isSupported()) throw 'WebGL not supported';
    // Keep that variable def
    this.container = containerEL;
    // -webkit-tap to avoid touch effect on iphone
    setStyle(this.container, { 'overflow-x': 'hidden', '-webkit-tap-highlight-color': 'transparent'});

    this.canvas = el('canvas', {style:{position: 'absolute', 'z-index':0, top: '0px', left: '0px', width: '100%', height: '100%', 'overflow-y': 'hidden !important', 'overflow-x': 'hidden !important', outline:'none', 'touch-action': 'none'}, oncontextmenu:"javascript:return false;"});
    mount(this.container, this.canvas);

    // For now keep false as the last argument of the engine,
    // We don't want the canvas to adapt to screen ratio as it slow down too much the scene
    this.engine = new Engine(this.canvas, true, { limitDeviceRatio: this.maxScaling }, false);
    // NOTE to avoid request for manifest files because it can block loading on safari
    this.engine.enableOfflineSupport = false;
  }

  buildScene () {
    this.scene = new Scene(this.engine);
    this.scene.shadowsEnabled = false;
    this.scene.ambientColor = new Color3(1, 1, 1);

    this.freecamera = new FreeCamera('main_camera', new Vector3(0, 0, -10), this.scene);
    this.freecamera.minZ = 0;

    this.highlighter = new HighlightLayer("hl", this.scene);
    this.highlighter.innerGlow = false;
    this.highlighter.blurHorizontalSize = 100;
    this.highlighter.blurVerticalSize = 100;

    this.glowLayer = new GlowLayer("glow", this.scene);
    this.glowLayer.intensity = 1;

    this.loader = new loaderClass(this.scene);
    this.animationManager = new animationManagerClass();
  }

  launchRender () {
    this.engine.stopRenderLoop();
    this.engine.runRenderLoop( () => {
      this.animationManager.runAnimations(this.engine.getFps());
      this.scene.render();
    });
  }

  stopRender () {
    this.engine.stopRenderLoop();
  }

  takeScreenshot (size:size, callback:Function) {
  // Really bad way to get engine
  // Update of viewer will return it
    Tools.CreateScreenshotUsingRenderTarget(this.engine, this.scene.activeCamera, size, (image) => {
      callback(image);
    });
  }
}
