
import { projectInterface } from '../service/projectInterface';
import { system } from '../tools/system';
import { responsiveClass } from '../service/responsive';

import { Camera } from '@babylonjs/core/Cameras/camera';
// To Enable DepthRendering
import '@babylonjs/core/Rendering/depthRendererSceneComponent';
import { DepthOfFieldEffectBlurLevel } from '@babylonjs/core/PostProcesses/depthOfFieldEffect';
import { DefaultRenderingPipeline } from '@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/defaultRenderingPipeline';
import { ImageProcessingConfiguration } from '@babylonjs/core/Materials/imageProcessingConfiguration';
import { Color4 } from '@babylonjs/core/Maths/math';
import clone from 'lodash/clone';

export interface pipelineInterface {
  fieldOfView?:number,
  vignette?:Array<number>,
  vignetteOffset?:number,
  focalLength?:number,
  focalDistance?:number,
  bloom?:number,
  contrast?:number,
  exposure?:number,
  sharpenEdge?:number,
  sharpenColor?:number,
  chromatic?:number,
}

export let pipelineJson = {
  fieldOfView:'f',
  vignette:'v',
  vignetteOffset:'vo',
  focalLength:'fl',
  focalDistance:'fd',
  bloom:'b',
  contrast:'c',
  exposure:'e',
  sharpenEdge:'se',
  sharpenColor:'sc',
  chromatic:'ch',
};

projectInterface.pipeline = pipelineJson;

export let defaultPipelineValues:pipelineInterface = {
  fieldOfView:0.8,
  vignette:[0, 0, 0, 0],
  vignetteOffset:0,
  focalLength:0,
  focalDistance:1,
  bloom:0,
  contrast:1,
  exposure:1,
  sharpenEdge:0,
  sharpenColor:1,
  chromatic:0,
};

export class pipelineClass {

  _system:system;
  _responsive:responsiveClass;
  defaultPipeline:DefaultRenderingPipeline;

  options:pipelineInterface = {};

  constructor (system:system, responsive:responsiveClass) {
    this._system = system;
    this._responsive = responsive;

    this.defaultPipeline = new DefaultRenderingPipeline("default_pipeline", true, system.scene, [system.freecamera, system.vrcamera]);
    this.defaultPipeline.depthOfFieldBlurLevel = DepthOfFieldEffectBlurLevel.Medium;
    this.defaultPipeline.depthOfField.fStop = 3;
    this.defaultPipeline.depthOfField.focalLength = 500;
    // Set on/off in order to avoid first hotspot show which can freeze a bit
    // NOTE don't do that because it breaks the DepthofField since V4
    // this.defaultPipeline.depthOfFieldEnabled = true;
    // this.defaultPipeline.depthOfFieldEnabled = false;
    this.defaultPipeline.bloomKernel = 20;
    this.defaultPipeline.bloomWeight = 0;
    this.defaultPipeline.bloomThreshold = 0.5;
    // Do not disable here or it creates bug with scene image processing
    // Eventually disable because create bug with water which stays visible behind fog
    this.defaultPipeline.imageProcessingEnabled = false;

    this.cameras = [system.freecamera, system.vrcamera];

    this._responsive.addListener((ratio, width, height, scale) => {
      this.checkVignetteWigthRatio();
    });
  }

  init (pipelineOptions:pipelineInterface) {
    this.checkOption(pipelineOptions);
  }

  start () {
    this.defaultPipeline.imageProcessingEnabled = true;
    this.defaultPipeline.imageProcessing.imageProcessingConfiguration = new ImageProcessingConfiguration();
    this.defaultPipeline.imageProcessing.imageProcessingConfiguration.applyByPostProcess = true;
    this.defaultPipeline.imageProcessing.imageProcessingConfiguration.vignetteEnabled = false;
  }

  setOption (pipelineOptions:pipelineInterface) {
    this.setFieldOfView(pipelineOptions.fieldOfView);
    this.setVignette(pipelineOptions.vignette);
    this.setVignetteOffset(pipelineOptions.vignetteOffset);
    this.setFocalLength(pipelineOptions.focalLength);
    this.setFocalDistance(pipelineOptions.focalDistance);
    this.setBloom(pipelineOptions.bloom);
    this.setContrast(pipelineOptions.contrast);
    this.setExposure(pipelineOptions.exposure);
    this.setSharpenEdge(pipelineOptions.sharpenEdge);
    this.setSharpenColor(pipelineOptions.sharpenColor);
    this.setChromatic(pipelineOptions.chromatic);
  }

  checkOption (pipelineOptions:pipelineInterface) {
    if (pipelineOptions.fieldOfView !== undefined) this.setFieldOfView(pipelineOptions.fieldOfView);
    if (pipelineOptions.vignette !== undefined) this.setVignette(pipelineOptions.vignette);
    if (pipelineOptions.vignetteOffset !== undefined) this.setVignetteOffset(pipelineOptions.vignetteOffset);
    if (pipelineOptions.focalLength !== undefined) this.setFocalLength(pipelineOptions.focalLength);
    if (pipelineOptions.focalDistance !== undefined) this.setFocalDistance(pipelineOptions.focalDistance);
    if (pipelineOptions.bloom !== undefined) this.setBloom(pipelineOptions.bloom);
    if (pipelineOptions.contrast !== undefined) this.setContrast(pipelineOptions.contrast);
    if (pipelineOptions.exposure !== undefined) this.setExposure(pipelineOptions.exposure);
    if (pipelineOptions.sharpenEdge !== undefined) this.setSharpenEdge(pipelineOptions.sharpenEdge);
    if (pipelineOptions.sharpenColor !== undefined) this.setSharpenColor(pipelineOptions.sharpenColor);
    if (pipelineOptions.chromatic !== undefined) this.setChromatic(pipelineOptions.chromatic);
  }

  setOptionProgress (startpipeline:pipelineInterface, endpipeline:pipelineInterface, progress:number) {
    let newpipeline:pipelineInterface = {};
    for (let key in defaultPipelineValues) {
      if (startpipeline[key] != defaultPipelineValues[key] || endpipeline[key] != defaultPipelineValues[key]) {
        // if (startpipeline[key] === undefined) startpipeline[key] = defaultPipelineValues[key];
        // if (endpipeline[key] === undefined) endpipeline[key] = defaultPipelineValues[key];
        if (typeof defaultPipelineValues[key] == 'number') {
          newpipeline[key] = (1 - progress) * startpipeline[key] + progress * endpipeline[key];
        } else {
          newpipeline[key] = [];
          for (let i = 0; i < defaultPipelineValues[key].length; i++) {
            newpipeline[key][i] = (1 - progress) * startpipeline[key][i] + progress * endpipeline[key][i];
          }
        }
      }
    }
    this.checkOption(newpipeline);
  }

  setFieldOfView (fieldOfView:number) {
    this.options.fieldOfView = fieldOfView;
    for (let i = 0; i < this.cameras.length; i++) {
      this.cameras[i].fov = fieldOfView;
      this._system.guicamera.fov = fieldOfView;
    }
  }

  setBloom (bloom:number) {
    this.options.bloom = bloom;
    if (bloom != defaultPipelineValues.bloom) {
      this.defaultPipeline.bloomEnabled = true;
      this.defaultPipeline.bloomWeight = bloom;
    } else {
      this.defaultPipeline.bloomEnabled = false;
    }
  }

  setContrast (contrast:number) {
    this.options.contrast = contrast;
    this.defaultPipeline.imageProcessing.imageProcessingConfiguration.contrast = contrast;
  }

  setExposure (exposure:number) {
    this.options.exposure = exposure;
    this.defaultPipeline.imageProcessing.imageProcessingConfiguration.exposure = exposure;
  }

  setSharpenColor (sharpenColor:number) {
    this.options.sharpenColor = sharpenColor;
    this.defaultPipeline.sharpen.colorAmount = sharpenColor;
    this.checkSharpen();
  }

  setSharpenEdge (sharpenEdge:number) {
    this.options.sharpenEdge = sharpenEdge;
    this.defaultPipeline.sharpen.edgeAmount = sharpenEdge;
    this.checkSharpen();
  }

  checkSharpen () {
    if (this.options.sharpenEdge == defaultPipelineValues.sharpenEdge && this.options.sharpenColor == defaultPipelineValues.sharpenColor) this.defaultPipeline.sharpenEnabled = false;
    else this.defaultPipeline.sharpenEnabled = true;
  }

  setChromatic (chromatic:number) {
    this.options.chromatic = chromatic;
    if (chromatic) {
      this.defaultPipeline.chromaticAberrationEnabled = true;
      this.defaultPipeline.chromaticAberration.aberrationAmount = chromatic;
    } else {
      this.defaultPipeline.chromaticAberrationEnabled = false;
    }
  }

  setFocalLength (focalLength:number) {
    this.options.focalLength = focalLength;
    if (focalLength) {
      this.defaultPipeline.depthOfFieldEnabled = true;
      this.defaultPipeline.depthOfField.focalLength = focalLength;
      this.defaultPipeline.depthOfField.fStop  = 10;
    } else {
      this.defaultPipeline.depthOfFieldEnabled = false;
    }
  }

  setFocalDistance (focalDistance:number) {
    this.options.focalDistance = focalDistance;
    if (this.defaultPipeline.depthOfFieldEnabled) {
      // focusDistance set in millimeters
      this.defaultPipeline.depthOfField.focusDistance = focalDistance * 1000;
    }
  }

  setVignette (color:Array<number>) {
    if (color) {
      this.options.vignette = color;
      this.defaultPipeline.imageProcessing.imageProcessingConfiguration.vignetteEnabled = true;
      this.defaultPipeline.imageProcessing.imageProcessingConfiguration.vignetteColor = new Color4(color[0]/255, color[1]/255, color[2]/255, 1);
      this.defaultPipeline.imageProcessing.imageProcessingConfiguration.vignetteBlendMode = ImageProcessingConfiguration.VIGNETTEMODE_OPAQUE;
      this.setVignetteWeight(color[3]);
    } else {
      this.options.vignette = clone(defaultPipelineValues.vignette);
      this.defaultPipeline.imageProcessing.imageProcessingConfiguration.vignetteEnabled = false;
      this.defaultPipeline.imageProcessing.imageProcessingConfiguration.vignetteWeight = 0;
    }
  }

  vignetteWeightRatio = 40;
  vignetteWeight:number;
  setVignetteWeight (weight:number) {
    this.vignetteWeight = weight;
    if (weight !== undefined) this.defaultPipeline.imageProcessing.imageProcessingConfiguration.vignetteWeight = weight * this.vignetteWeightRatio;
  }

  setVignetteOffset (offset:number) {
    this.options.vignetteOffset = offset;
    this.checkVignetteWigthRatio();
    let ratio = Math.max(Math.min(this._responsive.screenratio, offset), -offset);
    if (ratio >= 0) {
      this.defaultPipeline.imageProcessing.imageProcessingConfiguration.vignetteCentreX = ratio/1.2;
      this.defaultPipeline.imageProcessing.imageProcessingConfiguration.vignetteCentreY = 0;
    } else {
      this.defaultPipeline.imageProcessing.imageProcessingConfiguration.vignetteCentreX = 0;
      this.defaultPipeline.imageProcessing.imageProcessingConfiguration.vignetteCentreY = -ratio/1.5;
    }
  }

  maxVignetteRatio = 0.6;
  checkVignetteWigthRatio () {
    if (this._responsive.screenratio > 0) {
      this.vignetteWeightRatio = 40;
      if (this.vignetteWeight) this.setVignetteWeight(this.vignetteWeight);
    } else {
      this.vignetteWeightRatio = 80;
      if (this.vignetteWeight) this.setVignetteWeight(this.vignetteWeight);
    }
  }

  reset () {
    this.setOption(defaultPipelineValues);
  }

  cameras:Array<Camera> = [];
  addCamera (camera:Camera) {
    let index = this.cameras.indexOf(camera);
    if (index == -1) {
      this.defaultPipeline.addCamera(camera);
      this.cameras.push(camera);
    }
  }

  removeCamera (camera:Camera) {
    let index = this.cameras.indexOf(camera);
    if (index != -1) {
      this.defaultPipeline.removeCamera(camera);
      this.cameras.splice(index, 1);
    }
  }

}
