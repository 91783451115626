import { Observer, Observable } from "@babylonjs/core/Misc/observable";
import { Nullable } from "@babylonjs/core/types";
import { PointerInfo } from "@babylonjs/core/Events/pointerEvents";
import { Vector3, Color3, Color4, Space, Axis } from "@babylonjs/core/Maths/math";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { LinesMesh } from "@babylonjs/core/Meshes/linesMesh";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { BoxBuilder } from "@babylonjs/core/Meshes/Builders/boxBuilder";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { PointerDragBehavior } from "@babylonjs/core/Behaviors/Meshes/pointerDragBehavior";
import { _TimeToken } from "@babylonjs/core/Instrumentation/timeToken";
import { _DepthCullingState, _StencilState, _AlphaState } from "@babylonjs/core/States/index";
import { Gizmo } from "@babylonjs/core/Gizmos/gizmo";
import { UtilityLayerRenderer } from "@babylonjs/core/Rendering/utilityLayerRenderer";
import { ScaleGizmo } from "@babylonjs/core/Gizmos/scaleGizmo";
import { Animation } from '@babylonjs/core/Animations/Animation';
import { Scene } from "@babylonjs/core/scene";
import { GlowLayer } from '@babylonjs/core/Layers/glowLayer'

/**
 * Single axis scale gizmo
 */
export class NakerScaleDragGizmo extends Gizmo {
    /**
     * Drag behavior responsible for the gizmos dragging interactions
     */
    public dragBehavior: PointerDragBehavior;
    private _pointerObserver: Nullable<Observer<PointerInfo>> = null;
    /**
     * Scale distance in babylon units that the gizmo will snap to when dragged (Default: 0)
     */
    public snapDistance = 0;
    size = 0.3;
    /**
     * Event that fires each time the gizmo snaps to a new location.
     * * snapDistance is the the change in distance
     */
    public onSnapObservable = new Observable<{ snapDistance: number }>();
    /**
     * If the scaling operation should be done on all axis (default: false)
     */
    public uniformScaling = true;

    private _isEnabled: boolean = true;
    private _parent: Nullable<ScaleGizmo> = null;
    public _isDragged: boolean = false;

    private _arrow: AbstractMesh;
    private _secondArrow: Mesh;
    private _coloredMaterial: StandardMaterial;

  /**
   * Creates an AxisScaleGizmo
   * @param gizmoLayer The utility layer the gizmo will be added to
   * @param dragAxis The axis which the gizmo will be able to scale on
   * @param color The color of the gizmo
   */

   /** @hidden */
   _CreateBox(gizmoLayer: UtilityLayerRenderer, color: Color3, position:Vector3, glowLayer:GlowLayer): Mesh {
     var corner = MeshBuilder.CreateBox("corner", {size: this.size}, gizmoLayer.utilityLayerScene);
     // corner.isVisible = false;
     corner.visibility = 0.0001;
     corner.isPickable = true;
     corner.scaling.scaleInPlace(2);
     // corner.lookAt(corner.position.add(position));

     let colorLine = new Color4(color.r/2, color.g/2, color.b/2, 1);
     let colors = [colorLine, colorLine];
     this.boxes.push({mesh:corner, vector:position});

     let s = this.size/2;
     var line1 = MeshBuilder.CreateLines("line1", {colors:colors, points: [new Vector3(s, s, s).multiply(position), new Vector3(s, s, -s).multiply(position)]}, gizmoLayer.utilityLayerScene);
     line1.parent = corner;
     line1.isPickable = false;
     glowLayer.addIncludedOnlyMesh(line1);

     var line2 = MeshBuilder.CreateLines("line2", {colors:colors, points: [new Vector3(s, s, s).multiply(position), new Vector3(s, -s, s).multiply(position)]}, gizmoLayer.utilityLayerScene);
     line2.parent = corner;
     line2.isPickable = false;
     glowLayer.addIncludedOnlyMesh(line2);

     var line3 = MeshBuilder.CreateLines("line3", {colors:colors, points: [new Vector3(s, s, s).multiply(position), new Vector3(-s, s, s).multiply(position)]}, gizmoLayer.utilityLayerScene);
     line3.parent = corner;
     line3.isPickable = false;
     glowLayer.addIncludedOnlyMesh(line3);
     return corner;
   }

   /** @hidden */
   public _CreateSecondArrow(gizmoLayer: UtilityLayerRenderer, coloredMaterial: StandardMaterial): Mesh {
     //make sure plane is double sided
     var box = BoxBuilder.CreateBox("box", { size: 0.5 }, gizmoLayer.utilityLayerScene);
     box.material = coloredMaterial.clone('');
     box.material.alpha = 0;
     box.isVisible = false;
     box.isPickable = false;
     // Position plane pointing normal to secondPlane normal
     return box;
   }

   setBoxPosition () {
     this._secondArrow.scaling = this.attachedMesh.getBoundingInfo().boundingBox.extendSizeWorld.scale(12);
     let gapx = this._secondArrow.scaling.x/4 - this.size;
     let gapy = this._secondArrow.scaling.y/4 - this.size;
     let gapz = this._secondArrow.scaling.z/4 - this.size;
     let radius = this._secondArrow._scene.activeCamera.radius;
     let scale = 0.2 * radius;
     for (let i = 0; i < this.boxes.length; i++) {
       let box = this.boxes[i];
       box.mesh.position.x = gapx * box.vector.x;
       box.mesh.position.y = gapy * box.vector.y;
       box.mesh.position.z = gapz * box.vector.z;
       box.mesh.scaling = new Vector3(scale, scale, scale);
     }
   }

   boxes:Array<any> = [];
   constructor(dragAxis: Vector3, color: Color3 = Color3.Gray(), gizmoLayer: UtilityLayerRenderer = UtilityLayerRenderer.DefaultUtilityLayer, glowLayer:GlowLayer) {
      super(gizmoLayer);
      this._updateScale = false;
      this.updateGizmoRotationToMatchAttachedMesh = false;
      // Create Material
      this._coloredMaterial = new StandardMaterial("", gizmoLayer.utilityLayerScene);
      this._coloredMaterial.emissiveColor = color;

      this._arrow = new AbstractMesh("", gizmoLayer.utilityLayerScene);

      this._secondArrow = this._CreateSecondArrow(gizmoLayer, this._coloredMaterial);
      this._arrow.addChild(this._secondArrow);

      let box1 = this._CreateBox(gizmoLayer, color, new Vector3(1, 1, 1), glowLayer);
      this._arrow.addChild(box1);
      let box2 = this._CreateBox(gizmoLayer, color, new Vector3(-1, 1, 1), glowLayer);
      this._arrow.addChild(box2);
      let box3 = this._CreateBox(gizmoLayer, color, new Vector3(1, -1, 1), glowLayer);
      this._arrow.addChild(box3);
      let box4 = this._CreateBox(gizmoLayer, color, new Vector3(1, 1, -1), glowLayer);
      this._arrow.addChild(box4);
      let box5 = this._CreateBox(gizmoLayer, color, new Vector3(-1, -1, 1), glowLayer);
      this._arrow.addChild(box5);
      let box6 = this._CreateBox(gizmoLayer, color, new Vector3(1, -1, -1), glowLayer);
      this._arrow.addChild(box6);
      let box7 = this._CreateBox(gizmoLayer, color, new Vector3(-1, 1, -1), glowLayer);
      this._arrow.addChild(box7);
      let box8 = this._CreateBox(gizmoLayer, color, new Vector3(-1, -1, -1), glowLayer);
      this._arrow.addChild(box8);

      glowLayer.customEmissiveColorSelector = (mesh, subMesh, material, result) => {
        if (mesh.name.indexOf('line') != -1) {
          result.r = 1;
          result.g = 1;
          result.b = 1;
        } else {
          result.r = 0;
          result.g = 0;
          result.b = 0;
        }
      }

      this._rootMesh.addChild(this._arrow);
      this._arrow.scaling.scaleInPlace(1 / 3);

      // Add drag behavior to handle events when the gizmo is dragged
      this.dragBehavior = new PointerDragBehavior({ dragAxis: dragAxis });
      this.dragBehavior.moveAttached = false;
      this._rootMesh.addBehavior(this.dragBehavior);

      var currentSnapDragDistance = 0;
      var tmpVector = new Vector3();
      var tmpSnapEvent = { snapDistance: 0 };
      this.dragBehavior.onDragObservable.add((event) => {
          if (this.attachedMesh) {
              // Drag strength is modified by the scale of the gizmo (eg. for small objects like boombox the strength will be increased to match the behavior of larger objects)
              var dragStrength = event.dragDistance * ((this.scaleRatio * 10) / this._rootMesh.scaling.length());

              // Snapping logic
              var snapped = false;
              var dragSteps = 0;
              if (this.uniformScaling) {
                  this.attachedMesh.scaling.normalizeToRef(tmpVector);
                  if (tmpVector.y < 0) {
                      tmpVector.scaleInPlace(-1);
                  }
              } else {
                  tmpVector.copyFrom(dragAxis);
              }
              if (this.snapDistance == 0) {
                  tmpVector.scaleToRef(dragStrength, tmpVector);
              } else {
                  currentSnapDragDistance += dragStrength;
                  if (Math.abs(currentSnapDragDistance) > this.snapDistance) {
                      dragSteps = Math.floor(Math.abs(currentSnapDragDistance) / this.snapDistance);
                      if (currentSnapDragDistance < 0) {
                          dragSteps *= -1;
                      }
                      currentSnapDragDistance = currentSnapDragDistance % this.snapDistance;
                      tmpVector.scaleToRef(this.snapDistance * dragSteps, tmpVector);
                      snapped = true;
                  } else {
                      tmpVector.scaleInPlace(0);
                  }
              }

              // Prevent scale to go negative
              let absoluteTest = this.attachedMesh.scaling.add(tmpVector);
              let newScale = absoluteTest.maximizeInPlace(new Vector3(0.1, 0.1, 0.1));
              this.attachedMesh.scaling = newScale;

              if (snapped) {
                  tmpSnapEvent.snapDistance = this.snapDistance * dragSteps;
                  this.onSnapObservable.notifyObservers(tmpSnapEvent);
              }
          }
      });

      this._pointerObserver = gizmoLayer.utilityLayerScene.onPointerObservable.add((pointerInfo) => {
          if (this._customMeshSet || this._isDragged) {
              return;
          }
          var isHovered = pointerInfo.pickInfo && (this._rootMesh.getChildMeshes().indexOf(<Mesh>pointerInfo.pickInfo.pickedMesh) != -1);
          if ( isHovered ) {
            this._showHelper(gizmoLayer.utilityLayerScene);
          } else {
            this._hideHelper(gizmoLayer.utilityLayerScene);
          }
      });

      this.dragBehavior.onDragStartObservable.add(() => {
        this._isDragged = true;
      });

      this.dragBehavior.onDragEndObservable.add(() => {
        this._isDragged = false;
      });

      this.gizmoLayer.originalScene.onBeforeRenderObservable.add(() => {
          // Only update the bouding box if scaling has changed
          if (this.attachedMesh) {
              this.setBoxPosition();
          }
      });
    }

    private _helperShown = false;
    private _maxOpacity = 0.1;
    protected _showHelper (scene:Scene) {
      if (this._helperShown) return;
      this._helperShown = true;

      this._secondArrow.isVisible = true;
      var animationPearl = new Animation("second_plane_anim", "material.alpha", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      var keys = [];
      keys.push({
        frame: 0,
        value: 0
      });
      keys.push({
        frame: 5,
        value: this._maxOpacity
      });
      animationPearl.setKeys(keys);
      this._secondArrow.animations = [];
      this._secondArrow.animations.push(animationPearl);
      scene.beginAnimation(this._secondArrow, 0, 5, false);
    }

    protected _hideHelper (scene:Scene) {
      if (!this._helperShown) return;
      this._helperShown = false;

      var animationPearl = new Animation("second_plane_anim", "material.alpha", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      var keys = [];
      keys.push({
        frame: 0,
        value: this._maxOpacity
      });
      keys.push({
        frame: 5,
        value: 0
      });
      animationPearl.setKeys(keys);
      this._secondArrow.animations = [];
      this._secondArrow.animations.push(animationPearl);
      scene.beginAnimation(this._secondArrow, 0, 5, false);
    }

    protected _attachedMeshChanged(value: Nullable<AbstractMesh>) {
        if (this.dragBehavior) {
            this.dragBehavior.enabled = value ? true : false;
        }
    }

    /**
 * If the gizmo is enabled
 */
    public set isEnabled(value: boolean) {
        this._isEnabled = value;
        if (!value) {
            this.attachedMesh = null;
        }
        else {
            if (this._parent) {
                this.attachedMesh = this._parent.attachedMesh;
            }
        }
    }
    public get isEnabled(): boolean {
        return this._isEnabled;
    }

    /**
     * Disposes of the gizmo
     */
    public dispose() {
        this.onSnapObservable.clear();
        this.gizmoLayer.utilityLayerScene.onPointerObservable.remove(this._pointerObserver);
        this.dragBehavior.detach();
        if (this._arrow) {
            this._arrow.dispose();
        }
        [this._coloredMaterial].forEach((matl) => {
            if (matl) {
                matl.dispose();
            }
        });
        super.dispose();
    }

    /**
     * Disposes and replaces the current meshes in the gizmo with the specified mesh
     * @param mesh The mesh to replace the default mesh of the gizmo
     * @param useGizmoMaterial If the gizmo's default material should be used (default: false)
     */
    public setCustomMesh(mesh: Mesh, useGizmoMaterial: boolean = false) {
        super.setCustomMesh(mesh);
        if (useGizmoMaterial) {
            this._rootMesh.getChildMeshes().forEach((m) => {
                m.material = this._coloredMaterial;
                if ((<LinesMesh>m).color) {
                    (<LinesMesh>m).color = this._coloredMaterial.diffuseColor;
                }
            });
            this._customMeshSet = false;
        }
    }
}
