
import { colorsuccess, colorerror, colorthirdgrey, colormain, colordarkgrey, colortext, colorsecondmain } from '../service/colors';
import { undo } from '../service/undo';
import { shortcutsLayer } from '../project/shortcutslayer';
import { performanceLayer } from '../project/performancelayer';
import { component, selectedComponent, componentChannel } from '../component/component';
import { journeyManager } from '../journey/journeyManager';
import { navigation } from '../service/editintale';
import { pointofviewChannel, pointofviewManager } from '../journey/pointofviewmanager';
import { undoChannel } from '../service/undo';
import { zoomzone } from '../dragzone/zoomManager';

import { setStyle, el, mount } from 'redom';
import noUiSlider from 'nouislider';
import ProgressBar from 'progressbar.js';

pointofviewChannel.subscribe("change", (data:pointofviewManager, envelope) => {
  scrollline.updateLegend();
});

pointofviewChannel.subscribe("update", (data:pointofviewManager, envelope) => {
  scrollline.updateLegend();
});

pointofviewChannel.subscribe("added", (pointofviewManager:pointofviewManager, envelope) => {
  scrollline.updateLegend();
});

pointofviewChannel.subscribe("delete", (data:pointofviewManager, envelope) => {
  scrollline.updateLegend();
});

pointofviewChannel.subscribe("rename", (data, envelope) => {
  scrollline.updateLegend();
});

undoChannel.subscribe("pointofviewchange", (data, envelope) => {
  scrollline.updateLegend();
});

undoChannel.subscribe("undelete", (data, envelope) => {
  scrollline.updateLegend();
});

componentChannel.subscribe("select", (data:component, envelope) => {
  scrollline.setComponent(data)
});

componentChannel.subscribe("unselect", (data:component, envelope) => {
  scrollline.unsetComponent();
});

/*
  +------------------------------------------------------------------------+
  | BOTTOM BAR                                                             |
  +------------------------------------------------------------------------+
*/

export class bottombarClass {

    container:HTMLElement;

    constructor () {
      this.container = el('div.bottombar');
      mount(document.body, this.container);
    }
}

export let bottombar = new bottombarClass();

/*
  +------------------------------------------------------------------------+
  | SCROLLINE                                                              |
  +------------------------------------------------------------------------+
*/

export class scrolllineClass {

    container:HTMLElement;
    title:HTMLElement;
    slider:HTMLElement;
    noUiSlider:noUiSlider;

    constructor () {
      this.container = el('div.scrollbar',
         [
           this.title = el('div.scrolltitle'),
           this.slider = el('div.scrollrange'),
         ]
      );
      mount(bottombar.container, this.container);

      let startTooltipformat = {
        to: (value) => {
          return 'Start ' + Math.round(value * 100) + '%';
        },
        from: (value) => {
          return 'Start ' + Math.round(value * 100) + '%';
        }
      };

      let endTooltipformat = {
        to: (value) => {
          return 'End ' + Math.round(value * 100) + '%';
        },
        from: (value) => {
          return 'End ' + Math.round(value * 100) + '%';
        }
      };

      this.noUiSlider = noUiSlider.create(this.slider, {
        range: {'min': 0, 'max': 1},

        step: 0.001,
        // Handles start at ...
        // start: [0, 1],
        start: [0],
        // Display colored bars between handles
        // connect: true,
        // // Move handle on tap, bars are draggable
        // behaviour: 'tap-drag',
        // tooltips: [ startTooltipformat, endTooltipformat ],
      });

      // When the slider value changes, update the input and span
      this.noUiSlider.on('update', (values, handle) => {
        this.setValues(parseFloat(values[0]), parseFloat(values[1]));
      });

      this.setEnable(false);
    }

    stepList:Array<string> = [];
    updateLegend () {
      this.stepList = navigation.getSortedPointofViews();
      let steps = [];
      let l = this.stepList.length;
      for (let i = 0; i < l; i++) {
        steps.push(i * 100 / (l - 1));
      }

      this.noUiSlider.updateOptions(
      	{
          step: 1/(l - 1),
          pips: {
            // mode: 'range',
            density: 4,
            stepped: true,
            mode: 'positions',
            values: steps,
            format: {
              to: (value) => {
                return this.stepList[Math.round((l - 1) * value)]
              },
            }
          }
        }
      );
    }

    setEnable (enable:boolean) {
      if (enable) {
        this.slider.removeAttribute('disabled');
        setStyle(zoomzone, {height:'calc(100% - 145px)'});
        setStyle(this.container, {top:'0px'});
        setStyle(bottombar.container, {height:'100px'});
      } else {
        setStyle(zoomzone, {height:'calc(100% - 105px)'});
        setStyle(this.container, {top:'-20px'});
        setStyle(bottombar.container, {height:'60px'});
        this.slider.setAttribute('disabled', true);
        this.noUiSlider.set([0, 1]);
      }
    }

    component:component;
    setComponent (component:component) {
      if (component.scrollInputs) {
        this.component = component;
        let start = component.content[component.scrollInputs.start];
        let end = component.content[component.scrollInputs.end];
        this.noUiSlider.set([start, end]);
        this.title.textContent = component.scrollInputs.title;
        setStyle(this.title, {display:'block'});
        this.setEnable(true);
      } else {
        this.unsetComponent();
      }
    }

    unsetComponent () {
      // Make sure component delleted first before enabling
      this.title.textContent = '';
      setStyle(this.title, {display:'none'});
      scrollline.component = undefined;
      scrollline.setEnable(false);
    }

    setValues (start:number, end:number) {
      if (this.component) {
        if (end) {
          this.component.content[this.component.scrollInputs.start] = start;
          this.component.content[this.component.scrollInputs.end] = end;
        } else {
          this.component.content[this.component.scrollInputs.start] = start - 0.02;
          this.component.content[this.component.scrollInputs.end] = start + 0.02;
        }
      }
    }
}

export let scrollline = new scrolllineClass();

/*
  +------------------------------------------------------------------------+
  | UNDO BAR                                                               |
  +------------------------------------------------------------------------+
*/

export class undoBarClass {

  container: any;

	backbutton: HTMLElement;
	forwardbutton: HTMLElement;
  railbutton: HTMLElement;
	lastaction: HTMLElement;
	loadingBar: HTMLElement;
	loadingBarElement: HTMLElement;
	loadingBarContainer: HTMLElement;
	loadingBarText: HTMLElement;
	progress: any;

  constructor () {
    this.container = el('div.undobar',
      [
        this.backbutton = el('div.siimple-btn.siimple-btn--primary.undobar-button.leftbutton', { onclick: (() => { this.undo() }), 'aria-label': 'Undo (Ctrl + Z)', 'data-microtip-position': 'top', 'role': 'tooltip' },
          el('i.icon-back', [el('span.path1'), el('span.path2'), el('span.path3')])
        ),
        this.forwardbutton = el('div.siimple-btn.siimple-btn--primary.undobar-button.leftbutton', { onclick: (() => { this.redo() }), 'aria-label': 'Redo (Ctrl + Maj + Z)', 'data-microtip-position': 'top', 'role': 'tooltip' },
          el('i.icon-forward', [el('span.path1'), el('span.path2'), el('span.path3')])
        ),
        el('div.bottomline.leftbutton'),
        this.railbutton = el('div.siimple-btn.siimple-btn--primary.undobar-button.leftbutton', { onclick: (() => { this.setJourneyVisible() }), 'aria-label': 'Show journey path', 'data-microtip-position': 'top', 'role': 'tooltip' },
          el('i.icon-pointofview', [el('span.path1'), el('span.path2'), el('span.path3')])
        ),
        this.loadingBarContainer = el('div.loadingBarContainer', {},
          this.loadingBarText = el('div.loadingBarText', {}),
          this.loadingBarElement = el('div.loadingBarElement', {}),
        ),
        el('div.rightbutton', { onmouseover: (() => { setStyle(shortcutsLayer.control, { display: 'block' }) }), onmouseleave: (() => { setStyle(shortcutsLayer.control, { display: 'none' }) }) },
          el('i.icon-shortcuts', [el('span.path1'), el('span.path2'), el('span.path3')])
        ),
        el('div.rightbutton', { onmouseover: (() => { performanceLayer.show() }), onmouseleave: (() => { performanceLayer.hide() }) },
          el('i.icon-performance', [el('span.path1'), el('span.path2'), el('span.path3')])
        ),
      ]
    );

    mount(bottombar.container, this.container);
    this.setProgressBar();
  }

	// We must check for multiselection before unod or redo
	checkSelectedComponent() {
		if (selectedComponent) {
			if (selectedComponent.type == 'group' && !selectedComponent.content.validated) selectedComponent.unselect();
		}
	}

  undo() {
		this.checkSelectedComponent();
		if (undo.back()) setStyle(this.backbutton, { background: colorsuccess });
		else setStyle(this.backbutton, { background: colorerror });
		setTimeout(() => { setStyle(this.backbutton, { background: colorthirdgrey }) }, 200);
	}

	redo() {
		this.checkSelectedComponent();
		if (undo.forward()) setStyle(this.forwardbutton, { background: colorsuccess });
		else setStyle(this.forwardbutton, { background: colorerror });
		setTimeout(() => { setStyle(this.forwardbutton, { background: colorthirdgrey }) }, 200);
	}

	setJourneyVisible() {
		journeyManager.setRailVisible();
		if (journeyManager.railVisible) setStyle(this.railbutton, { background: colormain, color: colordarkgrey });
		else setStyle(this.railbutton, { background: colorthirdgrey, color: colortext });
	}

	animateLoadingBar(percentage:number) {
		setStyle(this.loadingBarElement, { display: 'block' });
		setStyle(this.loadingBarContainer, { display: 'block' });
		this.loadingBar.animate(percentage / 100);
	}

	successLoad(message:string) {
		setStyle(this.loadingBarElement, { display: 'none' });
		setStyle(this.loadingBarContainer, { background: colorsuccess });
		this.loadingBarText.textContent = message;
		setTimeout(() => {
			setStyle(this.loadingBarContainer, { display: 'none' });
		}, 5000);
	}

	errorLoad(message:string) {
		setStyle(this.loadingBarElement, { display: 'none' });
		setStyle(this.loadingBarContainer, { background: colorerror });
		this.loadingBarText.textContent = message;
		setTimeout(() => {
			setStyle(this.loadingBarContainer, { display: 'none' });
		}, 5000);
	}

	setText(message:string) {
		this.loadingBarText.textContent = message;
	}

	setProgressBar() {
		this.loadingBar = new ProgressBar.Line(this.loadingBarElement, {
			strokeWidth: 20,
			easing: 'easeInOut',
			duration: 200,
			trailColor: colordarkgrey,
			svgStyle: { width: '100%', height: '100%', 'border-radius': '20px' },
			text: {
				autoStyleContainer: false,
				style: {
					color: colortext,
					'font-size': '15px',
					position: 'absolute',
					left: '50%',
					top: '50%',
					padding: 0,
					margin: 0,
					transform: {
						prefix: true,
						value: 'translate(-50%, -50%)'
					}
				}
			},
			from: { color: colorsecondmain },
			to: { color: colormain },
			step: (state, bar) => {
				bar.path.setAttribute('stroke', state.color);
			}
		});
		setStyle(this.loadingBarElement, { width: (105 * this.progress).toString() + 'px' });
		setStyle(this.loadingBarText, {
			color: colortext,
			'font-size': '15px',
			padding: 0,
			margin: 0,
			display: 'block',
			transform: {
				prefix: true,
				value: 'translate(-50%, -50%)'
			},
			'border-radius': '20px'
		});
		setStyle(this.loadingBarContainer, {display: 'none'});
		setStyle(this.loadingBarElement, { display: 'none' });
	}
}

export let undoBar = new undoBarClass();
