import { Observer, Observable } from "@babylonjs/core/Misc/observable";
import { Nullable } from "@babylonjs/core/types";
import { PointerInfo } from "@babylonjs/core/Events/pointerEvents";
import { Axis, Space, Quaternion, Matrix, Vector3, Color3 } from "@babylonjs/core/Maths/math";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { PointerDragBehavior } from "@babylonjs/core/Behaviors/Meshes/pointerDragBehavior";
import { _TimeToken } from "@babylonjs/core/Instrumentation/timeToken";
import { _DepthCullingState, _StencilState, _AlphaState } from "@babylonjs/core/States/index";
import { Gizmo } from "@babylonjs/core/Gizmos/gizmo";
import { UtilityLayerRenderer } from "@babylonjs/core/Rendering/utilityLayerRenderer";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Scene } from "@babylonjs/core/scene";
import { HighlightLayer } from '@babylonjs/core/Layers/highlightLayer'

import "@babylonjs/core/Meshes/Builders/linesBuilder";
import "@babylonjs/core/Meshes/Builders/discBuilder";

/**
 * Single plane rotation gizmo
 */
export class NakerBowDragGizmo extends Gizmo {
    /**
     * Drag behavior responsible for the gizmos dragging interactions
     */
    public dragBehavior: PointerDragBehavior;
    private _pointerObserver: Nullable<Observer<PointerInfo>> = null;

    /**
     * Rotation distance in radians that the gizmo will snap to (Default: 0)
     */
    public snapDistance = 0;
    /**
     * Event that fires each time the gizmo snaps to a new location.
     * * snapDistance is the the change in distance
     */
    public onSnapObservable = new Observable<{ snapDistance: number }>();

    private _isEnabled: boolean = true;
    public _isDragged: boolean = false;

    /**
     * Creates a PlaneRotationGizmo
     * @param gizmoLayer The utility layer the gizmo will be added to
     * @param planeNormal The normal of the plane which the gizmo will be able to rotate on
     * @param color The color of the gizmo
     * @param tessellation Amount of tessellation to be used when creating rotation circles
     */
    constructor(planeNormal: Vector3, color: Color3 = Color3.Gray(), gizmoLayer: UtilityLayerRenderer = UtilityLayerRenderer.DefaultUtilityLayer, highlighter:HighlightLayer) {
        super(gizmoLayer);
        this.updateGizmoRotationToMatchAttachedMesh = true;
        // Create Material
        var coloredMaterial = new StandardMaterial("", gizmoLayer.utilityLayerScene);
        coloredMaterial.emissiveColor = color;
        coloredMaterial.cameraContrast = 4;
        coloredMaterial.cameraExposure = 0.8;
        coloredMaterial.backFaceCulling = false;

        // Build mesh on root node
        var parentMesh = new AbstractMesh("", gizmoLayer.utilityLayerScene);

        let discMesh = Mesh.CreateSphere("", 32, 0.08, gizmoLayer.utilityLayerScene);
        discMesh.position.addInPlace(new Vector3(0.5, 0, 0));
        discMesh.scaling.z = 0.5;
        discMesh.lookAt(this._rootMesh.position);
        discMesh.material = coloredMaterial;

        let ribbon = this.setRibbon(gizmoLayer.utilityLayerScene);
        ribbon.material = coloredMaterial;
        ribbon.addChild(discMesh);
        highlighter.addMesh(ribbon, Color3.Black());

        parentMesh.addChild(ribbon);
        parentMesh.rotation = planeNormal.scale(Math.PI/2);
        parentMesh.rotate(new Vector3(0, 1, 0), Math.PI/2, Space.WORLD);
        parentMesh.scaling.scaleInPlace(1 / 3);

        this._rootMesh.addChild(parentMesh);
        // Add drag behavior to handle events when the gizmo is dragged
        this.dragBehavior = new PointerDragBehavior({ dragPlaneNormal: planeNormal });
        this.dragBehavior.moveAttached = false;
        this.dragBehavior.maxDragAngle = Math.PI * 9 / 20;
        this.dragBehavior._useAlternatePickedPointAboveMaxDragAngle = true;
        this._rootMesh.addBehavior(this.dragBehavior);

        var lastDragPosition = new Vector3();

        this.dragBehavior.onDragStartObservable.add((e) => {
            if (this.attachedMesh) {
                lastDragPosition.copyFrom(e.dragPlanePoint);
            }
        });

        var rotationMatrix = new Matrix();
        var planeNormalTowardsCamera = new Vector3();
        var localPlaneNormalTowardsCamera = new Vector3();

        var tmpSnapEvent = { snapDistance: 0 };
        var currentSnapDragDistance = 0;
        var tmpMatrix = new Matrix();
        var tmpVector = new Vector3();
        var amountToRotate = new Quaternion();
        this.dragBehavior.onDragObservable.add((event) => {
            if (this.attachedMesh) {
                if (!this.attachedMesh.rotationQuaternion) {
                    this.attachedMesh.rotationQuaternion = Quaternion.RotationYawPitchRoll(this.attachedMesh.rotation.y, this.attachedMesh.rotation.x, this.attachedMesh.rotation.z);
                }

                // Remove parent priort to rotating
                var attachedMeshParent = this.attachedMesh.parent;
                if (attachedMeshParent) {
                    this.attachedMesh.setParent(null);
                }

                // Calc angle over full 360 degree (https://stackoverflow.com/questions/43493711/the-angle-between-two-3d-vectors-with-a-result-range-0-360)
                var newVector = event.dragPlanePoint.subtract(this.attachedMesh.absolutePosition).normalize();
                var originalVector = lastDragPosition.subtract(this.attachedMesh.absolutePosition).normalize();
                var cross = Vector3.Cross(newVector, originalVector);
                var dot = Vector3.Dot(newVector, originalVector);
                var angle = Math.atan2(cross.length(), dot);
                planeNormalTowardsCamera.copyFrom(planeNormal);
                localPlaneNormalTowardsCamera.copyFrom(planeNormal);
                if (this.updateGizmoRotationToMatchAttachedMesh) {
                    this.attachedMesh.rotationQuaternion.toRotationMatrix(rotationMatrix);
                    localPlaneNormalTowardsCamera = Vector3.TransformCoordinates(planeNormalTowardsCamera, rotationMatrix);
                }
                // Flip up vector depending on which side the camera is on
                if (gizmoLayer.utilityLayerScene.activeCamera) {
                    var camVec = gizmoLayer.utilityLayerScene.activeCamera.position.subtract(this.attachedMesh.position);
                    if (Vector3.Dot(camVec, localPlaneNormalTowardsCamera) > 0) {
                        planeNormalTowardsCamera.scaleInPlace(-1);
                        localPlaneNormalTowardsCamera.scaleInPlace(-1);
                    }
                }
                var halfCircleSide = Vector3.Dot(localPlaneNormalTowardsCamera, cross) > 0.0;
                if (halfCircleSide) { angle = -angle; }

                // if (planeNormal.y) this.ribbon.rotate(Axis.Y, -angle, Space.LOCAL);
                // else this.ribbon.rotate(Axis.Y, angle, Space.LOCAL);

                // Snapping logic
                var snapped = false;
                if (this.snapDistance != 0) {
                    currentSnapDragDistance += angle;
                    if (Math.abs(currentSnapDragDistance) > this.snapDistance) {
                        var dragSteps = Math.floor(Math.abs(currentSnapDragDistance) / this.snapDistance);
                        if (currentSnapDragDistance < 0) {
                            dragSteps *= -1;
                        }
                        currentSnapDragDistance = currentSnapDragDistance % this.snapDistance;
                        angle = this.snapDistance * dragSteps;
                        snapped = true;
                    } else {
                        angle = 0;
                    }
                }

                // If the mesh has a parent, convert needed world rotation to local rotation
                tmpMatrix.reset();
                if (this.attachedMesh.parent) {
                    this.attachedMesh.parent.computeWorldMatrix().invertToRef(tmpMatrix);
                    tmpMatrix.getRotationMatrixToRef(tmpMatrix);
                    Vector3.TransformCoordinatesToRef(planeNormalTowardsCamera, tmpMatrix, planeNormalTowardsCamera);
                }

                // Convert angle and axis to quaternion (http://www.euclideanspace.com/maths/geometry/rotations/conversions/angleToQuaternion/index.htm)
                var quaternionCoefficient = Math.sin(angle / 2);
                amountToRotate.set(planeNormalTowardsCamera.x * quaternionCoefficient, planeNormalTowardsCamera.y * quaternionCoefficient, planeNormalTowardsCamera.z * quaternionCoefficient, Math.cos(angle / 2));

                // If the meshes local scale is inverted (eg. loaded gltf file parent with z scale of -1) the rotation needs to be inverted on the y axis
                if (tmpMatrix.determinant() > 0) {
                    amountToRotate.toEulerAnglesToRef(tmpVector);
                    Quaternion.RotationYawPitchRollToRef(tmpVector.y, -tmpVector.x, -tmpVector.z, amountToRotate);
                }

                if (this.updateGizmoRotationToMatchAttachedMesh) {
                    // Rotate selected mesh quaternion over fixed axis
                    this.attachedMesh.rotationQuaternion.multiplyToRef(amountToRotate, this.attachedMesh.rotationQuaternion);
                } else {
                    // Rotate selected mesh quaternion over rotated axis
                    amountToRotate.multiplyToRef(this.attachedMesh.rotationQuaternion, this.attachedMesh.rotationQuaternion);
                }

                lastDragPosition.copyFrom(event.dragPlanePoint);
                if (snapped) {
                    tmpSnapEvent.snapDistance = angle;
                    this.onSnapObservable.notifyObservers(tmpSnapEvent);
                }

                // Restore parent
                if (attachedMeshParent) {
                    this.attachedMesh.setParent(attachedMeshParent);
                }

            }
        });

        this._pointerObserver = gizmoLayer.utilityLayerScene.onPointerObservable.add((pointerInfo) => {
            if (this._customMeshSet || this._isDragged) {
                return;
            }
            var isHovered = pointerInfo.pickInfo && (this._rootMesh.getChildMeshes().indexOf(<Mesh>pointerInfo.pickInfo.pickedMesh) != -1);

            if ( isHovered ) {
              this._showHelper();
            } else {
              this._hideHelper();
            }
        });

        this.dragBehavior.onDragStartObservable.add(() => {
          // this.updateGizmoRotationToMatchAttachedMesh = true;
          this._isDragged = true;
        });

        this.dragBehavior.onDragEndObservable.add(() => {
          // this.updateGizmoRotationToMatchAttachedMesh = false;
          this._isDragged = false;
          // this.ribbon.rotation = Vector3.Zero();
        });
    }

    ribbon:Mesh;
    protected setRibbon (scene:Scene) {
      var sideO = Mesh.BACKSIDE;
      let paths = this.getRibbonPath(Math.PI/4);
      this.ribbon = Mesh.CreateRibbon("ribbon", paths, false, false, 0, scene, true, sideO);
      return this.ribbon;
    }

    getRibbonPath (disp:number) {
      var radius = 0.5;
      var gap = 0.8;
      var steps = 80;
      var step = disp / steps;

      var pathLeft =[];
      for(var i = -disp; i < disp; i += step) {
        var x = Math.cos(i) * radius;
        var y = Math.pow((gap * (1 - Math.abs(i/disp)))/5, 2);
        var z = Math.sin(i) * radius;
        pathLeft.push(new Vector3(x, y, z));
      }

      var pathTop =[];
      for(var i = -disp; i < disp; i += step) {
        var x = Math.cos(i) * radius + 0.005;
        var y = 0;
        var z = Math.sin(i) * radius;
        pathTop.push(new Vector3(x, y, z));
      }

      var pathRight =[];
      for(var i = -disp; i < disp; i += step) {
        var x = Math.cos(i) * radius;
        var y = -Math.pow((gap * (1 - Math.abs(i/disp)))/5, 2);
        var z = Math.sin(i) * radius;
        pathRight.push(new Vector3(x, y, z));
      }

      var pathBottom =[];
      for(var i = -disp; i < disp; i += step) {
        var x = Math.cos(i) * radius - 0.005;
        var y = 0;
        var z = Math.sin(i) * radius;
        pathBottom.push(new Vector3(x, y, z));
      }
      return [pathLeft, pathTop, pathRight, pathBottom];
    }

    protected updateRibbon (disp:number) {
      let paths = this.getRibbonPath(disp);
      this.ribbon = Mesh.CreateRibbon(null, paths, null, null, null, null, null, null, this.ribbon);
    }

    private _helperShown = false;
    private _animStep = 5;
    protected _showHelper () {
      if (this._helperShown) return;
      this._helperShown = true;

      let i = 1;
      let int = setInterval(() => {
        let angle = Math.PI/4 + i/this._animStep * 7 * Math.PI/4;
        angle = Math.round(angle*100)/100;
        this.updateRibbon(angle);
        i++
        if (i == this._animStep) {
          this.updateRibbon(2 * Math.PI);
          clearInterval(int);
        }
      }, 30);
    }

    protected _hideHelper () {
      if (!this._helperShown) return;
      this._helperShown = false;

      let i = 1;
      let int = setInterval(() => {
        let angle = Math.PI/4 + (this._animStep - i)/this._animStep * 7 * Math.PI/4;
        angle = Math.round(angle*100)/100;
        this.updateRibbon(angle);
        i++
        if (i == this._animStep) {
          this.updateRibbon(Math.PI/4);
          clearInterval(int);
        }
      }, 30);
    }

    protected _attachedMeshChanged(value: Nullable<AbstractMesh>) {
        if (this.dragBehavior) {
            this.dragBehavior.enabled = value ? true : false;
        }
    }
    /**
         * If the gizmo is enabled
         */
    public set isEnabled(value: boolean) {
        this._isEnabled = value;
        if (!value) {
            this.attachedMesh = null;
        }
    }
    public get isEnabled(): boolean {
        return this._isEnabled;
    }
    /**
     * Disposes of the gizmo
     */
    public dispose() {
        this.onSnapObservable.clear();
        this.gizmoLayer.utilityLayerScene.onPointerObservable.remove(this._pointerObserver);
        this.dragBehavior.detach();
        super.dispose();
    }
}
