
import {
	ui_button,
 	textnode,
	ui_imagebutton,
 	ui_textinput,
	ui_paragraphinput,
 	ui_numberinput,
  numberoption,
  ui_checkbox,
 	ui_slider,
 	slideroption,
	ui_radio,
	radiooption,
 	ui_radioicon,
 	radioiconoption,
  ui_select,
  selectoption,
} from './input';
import {
	ui_colorbutton,
	ui_assetbutton,
	ui_imageassetbutton,
	ui_textassetbutton
} from './pickers';
import { asset } from '../../viewer/tools/loader';

import { el, mount } from 'redom';

export class ui_container {

	el:any;
	constructor (parent:any, className:string) {
		this.el = el('div', {class:className});
		mount(parent, this.el);
		return this;
	}

	addTitle (title:string) {
		let titleparent = el('div.parameter-title',
			el('div.title-text', title)
		);
		mount(this.el, titleparent);
	}

	addContainer (className:string) {
		let container = new ui_container(this.el, className);
		return container;
	}

	addText (text:string, className:string) {
		let textNode = el('div', { class: className }, text);
		mount(this.el, textNode);
		return textNode;
	}

	addLink (text:string, link:string, className:string) {
		let linkNode = el('a', { class: className, 'href':link, 'target':'_blank' }, text);
		mount(this.el, linkNode);
		return linkNode;
	}

	addIcon (icon:string, className:string) {
		let iconNode = el('div', { class: className+' icon-'+icon }, [el('span.path1'),el('span.path2'),el('span.path3')]);
		mount(this.el, iconNode);
		return iconNode;
	}

	addButton (textnode:textnode, className:string) {
		let button = new ui_button(this.el, textnode, className);
		return button;
	}

	addColorButton (color:Array<number>, className:string) {
		let button = new ui_colorbutton(this.el, color, className);
		return button;
	}

	addAssetButton (asset:asset, className:string) {
		let button = new ui_assetbutton(this.el, asset, className);
		return button;
	}

	addImageAssetButton (asset:asset, className:string) {
		let button = new ui_imageassetbutton(this.el, asset, className);
		return button;
	}

	addTextAssetButton (asset:asset, className:string) {
		let button = new ui_textassetbutton(this.el, asset, className);
		return button;
	}

	addImageButton (url:string, className:string) {
		let button = new ui_imagebutton(this.el, url, className);
		return button;
	}

	addInput (input:string, className:string) {
		let inputNode = new ui_textinput(this.el, input, className);
		return inputNode;
	}

	addParagraphInput (input:string, className:string) {
		let inputNode = new ui_paragraphinput(this.el, input, className);
		return inputNode;
	}

	addNumberInput (input:numberoption, className:string) {
		let inputNode = new ui_numberinput(this.el, input, className);
		return inputNode;
	}

	addCheckBox (checked:boolean, className:string) {
		let checkbox = new ui_checkbox(this.el, checked, className);
		return checkbox;
	}

	addSlider (slideroption:slideroption, className:string) {
		let slider = new ui_slider(this.el, slideroption, className);
		return slider;
	}

	addRadio (radiooption:radiooption, className:string) {
		let radio = new ui_radio(this.el, radiooption, className);
		return radio;
	}

	addRadioIcon (radiooption:radioiconoption, className:string) {
		let radio = new ui_radioicon(this.el, radiooption, className);
		return radio;
	}

	addSelect (selectoption:selectoption, className:string) {
		let select = new ui_select(this.el, selectoption, className);
		return select;
	}
}
