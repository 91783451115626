
import { contentMove } from './shared/contentmove';
import { projectInterface } from '../service/projectInterface';
import { contentInterface } from './shared/content';
import { sound } from './patterns/sound';
import { coreSystem } from '../tools/coreSystem';

/*
  +------------------------------------------------------------------------+
  | LIGHT CONTENT                                                          |
  +------------------------------------------------------------------------+
*/

export interface soundStyle {
  url:string;
  start:number;
  end:number;
  volume:number;
  fade:number;
}

projectInterface.sound = {
  url:'u',
  start:'s',
  end:'e',
  volume:'v',
  fade:'f',
}

export class soundcontent extends contentMove {

  type = 'sound';

  url:string;
  start = 0;
  end = 1;
  volume = 1;
  fade = 0.05;

  pattern:sound;

  constructor (coreSystem:coreSystem, content:contentInterface, soundStyle:soundStyle) {
    super(coreSystem, content, 'sound');
    this.setTypeParameter(soundStyle);
    this.setScale({x:0.1, y:0.1, z:0.1});
  }

  setTypeParameter (soundStyle:soundStyle) {
    if (soundStyle.url !== undefined) this.getSound(soundStyle.url);
    if (soundStyle.start !== undefined) this.start = soundStyle.start;
    if (soundStyle.end !== undefined) this.end = soundStyle.end;
    if (soundStyle.volume !== undefined) this.setVolume(soundStyle.volume);
    if (soundStyle.fade !== undefined) this.fade = soundStyle.fade;
  }

  getSound (url:string) {
    this._system.loader.getSound(url, (sound) => {
      this.pattern.setSound(sound);
    });
  }

  setVolume (volume:number) {
    this.volume = volume;
    if (this.playing) this.pattern.setVolume(volume);
  }

  playing = false;
  playMusic () {
    if (!this.playing) this.pattern.show();
    this.playing = true;
  }

  stopMusic () {
    if (this.playing) this.pattern.hide();
    this.playing = false;
  }

  // fullVolume = false;
  fadeVolume (perc:number) {
    let fadeIn = (perc - this.start)/this.fade;
    let fadeOut = (this.end - perc)/this.fade;
    let vol = Math.min(fadeIn, fadeOut, 1) * this.volume;
    vol = Math.max(vol, 0);
    this.pattern.setVolume(vol);
  }
}
