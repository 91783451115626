
import { colorfourthgrey, colormain } from '../service/colors';
import { undo, undoChannel } from '../service/undo';
import { layerLeft } from '../inputs/layer';
import { component, componentList, componentChannel } from '../component/component';
import { groupcomponent } from '../component/componentlist';
import { componentManager } from '../component/componentmanager';
import { environment } from '../service/editintale';
import { gizmoController } from '../dragzone/gizmoController';

import 'hotkeys-js';
import Sortable from 'sortablejs';
import { setAttr, setStyle, setChildren, el, mount, unmount } from 'redom';
import find from 'lodash/find';
import includes from 'lodash/includes';

undoChannel.subscribe("componentchange", (data, envelope) => {
  selectionPanel.drawList();
});

componentChannel.subscribe("unselect", (data, envelope) => {
  selectionPanel.unselectedComponent();
});

componentChannel.subscribe("select", (data, envelope) => {
  selectionPanel.selectedComponent(data);
});

componentChannel.subscribe("delete", (data, envelope) => {
  selectionPanel.drawList();
});

componentChannel.subscribe("undelete", (data, envelope) => {
  selectionPanel.drawList();
});

componentChannel.subscribe("new", (data, envelope) => {
  selectionPanel.drawList();
  selectionPanel.selectedComponent(data);
});

componentChannel.subscribe("rename", (data, envelope) => {
  selectionPanel.renameComponent(data);
});

/*
  +------------------------------------------------------------------------+
  | PARENT BUTTON COMPONENT                                                |
  +------------------------------------------------------------------------+
*/

export class selectionPanelClass {

  selectionContainer:HTMLElement;
  control:HTMLElement;
  mainlock:HTMLElement;
  mainvisible:HTMLElement;
  mainplay:HTMLElement;
  mainshadow:HTMLElement;

  alllocked = false;
  allvisible = false;
  allplay = false;
  allshadow = false;

  constructor () {
    this.control = el('div.preset-group',
      [
        el('div.parameter-title.selection-panel-title',
          el('div.title-text', 'Selection Panel')
        ),
        el('div.select-main-icon-container', [
          this.mainlock = el('div.right-icon.select-main-icon.icon-unlock',
            {onclick:()=>{this.checkLock()}},
            [el('span.path1'), el('span.path2'), el('span.path3')]
          ),
          this.mainvisible = el('div.right-icon.select-main-icon.icon-visible',
            {onclick:()=>{this.checkVisibility()}},
            [el('span.path1'), el('span.path2'), el('span.path3')]
          ),
          this.mainplay = el('div.right-icon.select-main-icon.icon-play',
            {onclick:()=>{this.checkPlay()}},
            [el('span.path1'), el('span.path2'), el('span.path3')]
          ),
          this.mainshadow = el('div.right-icon.select-main-icon.icon-shadowon',
            {onclick:()=>{this.checkShadow()}},
            [el('span.path1'), el('span.path2'), el('span.path3')]
          ),
        ]),
        this.selectionContainer = el('div.selection-container.list-group.editor-scroll'),
      ]
    );
  }

  hideMainButtons () {
    setStyle(this.mainlock, {display:'none'});
    setStyle(this.mainvisible, {display:'none'});
    setStyle(this.mainplay, {display:'none'});
    setStyle(this.mainshadow, {display:'none'});
  }

  showMainButtons () {
    this.hideMainButtons();
    if (this.mode == 'content') {
      setStyle(this.mainlock, {display:'block'});
      setStyle(this.mainvisible, {display:'block'});
      setStyle(this.mainshadow, {display:'block'});
    } else if (this.mode == 'animation') {
      setStyle(this.mainplay, {display:'block'});
    }
  }

  drawList () {
    this.deleteSortables();
    this.deleteCurrentList();
    let selectionList;
    if (this.mode == 'content' || this.mode == 'animation') {
      selectionList = componentManager.getComponents();
      this.showMainButtons();
    } else if (this.mode == 'hotspot') {
      selectionList = componentManager.getHotposts();
      this.hideMainButtons();
    }
    // else if (this.mode == 'pointofview') selectionList = componentManager.getHotposts();
    this.addComponentsSelection(selectionList);
    this.setSortable(this.selectionContainer);
    this.setAllButtonParam();
  }

  mode:'content'|'animation'|'hotspot'|'pointofview' = 'content';
  setMode (mode:'content'|'animation'|'hotspot'|'pointofview') {
    this.mode = mode;
    this.showPanel();
  }

  showPanel () {
    this.drawList();
    setChildren(layerLeft, [this.control]);
  }

  sortableLists:any = [];
  setSortable (el:any) {
    let s = Sortable.create(el,
      {
        animation: 150,
        scroll:true,
        // delay: 100, // FIXME delay not working to avoir shadow showing on click
        group: "panel",
        draggable: '.draggable',
        ghostClass: "sortable-ghost",  // Class name for the drop placeholder
        chosenClass: "sortable-chosen",  // Class name for the chosen item
        dragClass: "sortable-drag",  // Class name for the dragging item
        onEnd: (evt) => {
          let mainel = this.selectionContainer;
          if ((evt.to == mainel || evt.to != evt.from) && evt.from != mainel) { // Left group
            setStyle(evt.item, {paddingLeft:'5px'});
            let group = find(componentList, (o) => { return o.content.id == evt.from.id; });
            let component = find(componentList, (o) => { return o.content.id == evt.item.id; });
            group.removeComponent(component, false);
            undo.pushState();
          } else if (evt.to != mainel && (evt.from == mainel || evt.to != evt.from)) { // Enter group
            setStyle(evt.item, {paddingLeft:'27px'});
            let group = find(componentList, (o) => { return o.content.id == evt.to.id; });
            let component = find(componentList, (o) => { return o.content.id == evt.item.id; });
            group.addComponent(component, false);
            // NOTE Keep twice pushState if component goes from one group to another
            undo.pushState();
          } else {
            undo.pushState();
          }
        },
      }
    );
    this.sortableLists.push(s);
  }

  deleteSortables () {
    for (let i = 0; i < this.sortableLists.length; i++) {
      this.sortableLists[i].destroy();
    }
    this.sortableLists = [];
  }

  componentsSelect:Array<any> = [];
  setAllButtonParam () {
    this.lockcheck = false;
    this.visiblecheck = false;
    this.playcheck = false;
    this.shadowcheck = false;

    for (let i = 0; i < this.componentsSelect.length; i++) {
      setStyle(this.componentsSelect[i].select, {display:'block'});
      this.setButtonParam(this.componentsSelect[i]);
    }

    if (this.lockcheck) setAttr(this.mainlock, {class:'right-icon select-main-icon icon-unlock', style:{color:colorfourthgrey}});
    else setAttr(this.mainlock, {class:'right-icon select-main-icon icon-lock', style:{color:colormain}});

    if (this.visiblecheck) setAttr(this.mainvisible, {class:'right-icon select-main-icon icon-visible', style:{color:colorfourthgrey}});
    else setAttr(this.mainvisible, {class:'right-icon select-main-icon icon-unvisible', style:{color:colormain}});

    if (this.playcheck) setAttr(this.mainplay, {class:'right-icon select-main-icon icon-play', style:{color:colorfourthgrey}});
    else setAttr(this.mainplay, {class:'right-icon select-main-icon icon-pause', style:{color:colormain}});

    if (this.shadowcheck) setAttr(this.mainshadow, {class:'right-icon select-main-icon icon-shadowon', style:{color:colorfourthgrey}});
    else setAttr(this.mainshadow, {class:'right-icon select-main-icon icon-shadowoff', style:{color:colormain}});
  }

  // Check use to know status of main buttons
  lockcheck:boolean;
  visiblecheck:boolean;
  playcheck:boolean;
  shadowcheck:boolean;
  setButtonParam (selection:any) {
    let options = selection.component.availableOptions;

    if (this.mode == 'content') {
      if (options.lock) {
        if (!selection.component.content.locked) setStyle(selection.lockbutton, {'background-color':colorfourthgrey});
        else setStyle(selection.lockbutton, {'background-color':colormain});
        if (!selection.component.content.locked) this.lockcheck = true;
      }

      if (options.visible) {
        if (selection.component.content.visible) setStyle(selection.visiblebutton, {'background-color':colorfourthgrey});
        else setStyle(selection.visiblebutton, {'background-color':colormain});
        if (selection.component.content.visible) this.visiblecheck = true;
      }

      if (options.shadow) {
        if (selection.component.content.shadow) setStyle(selection.shadowbutton, {'background-color':colorfourthgrey});
        else setStyle(selection.shadowbutton, {'background-color':colormain});
        if (selection.component.content.shadow) this.shadowcheck = true;
      }
    } else if (this.mode == 'animation') {
      if (options.play) {
        if (selection.component.content.play) setStyle(selection.playbutton, {'background-color':colorfourthgrey});
        else setStyle(selection.playbutton, {'background-color':colormain});
        if (selection.component.content.play) this.playcheck = true;
      }
    }
  }

  addComponentsSelection (componentSelection:Array<component>) {
    let sortedIndexComponents = this.getSortedIndexedComponent(componentSelection);
    for (let i = 0; i < sortedIndexComponents.length; i++) {
      let component = sortedIndexComponents[i];
      let content = component.content;
      if (!content.ingroup) {
        if (content.type == 'group') {
          this.addGroupSelect(component)
        } else {
          this.addComponentSelect(component.icon, component, this.selectionContainer, false, true);
        }
      }
    }
  }

  sortedlist = ['group', 'model', 'heightmap', 'light', 'image', 'video', 'sphere', 'cube', 'cylinder', 'plane', 'disc', 'hotspot'];
  getSortedIndexedComponent (components:Array<component>) {
    let sortedIndexComponents = [];
    for (let i = 0; i < this.sortedlist.length; i++) {
      let type = this.sortedlist[i];
      for (let i = 0; i < components.length; i++) {
        if (components[i].icon == type) sortedIndexComponents.push(components[i])
      }
    }
    return sortedIndexComponents;
  }

  componentsGroup:Array<any> = [];
  addGroupSelect (component:groupcomponent) {
    let groupContainer = this.addGroupSelectButton(component);
    let sortedIndexComponents = this.getSortedIndexedComponent(component.components);
    for (let i = 0; i < sortedIndexComponents.length; i++) {
      let component = sortedIndexComponents[i];
      this.addComponentSelect(component.icon, component, groupContainer, true, true);
      this.componentsGroup.push(groupContainer);
    }
    this.setSortable(groupContainer);
  }

  addGroupSelectButton (component:component) {
    let icon;
    let groupContainer = el('div.group-container.draggable', {id:component.content.id},
      icon = el('div.expand-icon',
        {onclick:() => {
          component.content.panelopen = !component.content.panelopen;
          this.setGroupCollapse(groupContainer, icon, component);
        }},
        [el('span.path1'), el('span.path2'), el('span.path3')]
      )
    );
    this.setGroupCollapse(groupContainer, icon, component);
    mount(this.selectionContainer, groupContainer)

    this.addComponentSelect('group', component, groupContainer, true, false);
    return groupContainer;
  }

  setGroupCollapse (groupContainer:HTMLElement, icon:HTMLElement, component:component) {
    if (component.content.panelopen) {
      setStyle(groupContainer, {height:'auto'});
      setAttr(icon, {class:'expand-icon icon-collapse'});
    } else {
      setStyle(groupContainer, {height:'25px'});
      setAttr(icon, {class:'expand-icon icon-expand'});
    }
  }

  addComponentSelect (type:string, component:component, parent:HTMLElement, group:boolean, draggable:boolean) {
    let componentselect = el('div',
      {
        id:component.content.id,
        onmouseenter:() => {component.showSelector()},
        onmouseleave:() => {component.hideSelector()},
        onclick:(evt) => {
          gizmoController.setHoveredComponent(component);
          gizmoController.checkSelection(evt);
          gizmoController.unsetHoveredComponent();
        },
      },
      [el('span.path1'),el('span.path2'),el('span.path3')]
    );
    if (group) setStyle(componentselect, {'padding-left':'27px'});
    if (draggable) setAttr(componentselect, {class:'select-button panel draggable icon-'+type});
    else setAttr(componentselect, {class:'select-button panel icon-'+type});

    mount(parent, componentselect);

    // Option buttons
    let options = component.availableOptions;
    let lockbutton:HTMLElement, visiblebutton:HTMLElement, playbutton:HTMLElement, shadowbutton:HTMLElement;
    if (this.mode == 'content') {
      if (options.lock) lockbutton = this.addLockButton(componentselect, component);
      if (options.visible) visiblebutton = this.addVisibilityButton(componentselect, component);
      if (options.shadow) shadowbutton = this.addShadowButton(componentselect, component);
    } else if (this.mode == 'animation') {
      if (options.play) playbutton = this.addAnimationButton(componentselect, component);
    }

    let selectObject = {select:componentselect, component:component, lockbutton:lockbutton, visiblebutton:visiblebutton, playbutton:playbutton, shadowbutton:shadowbutton};
    this.componentsSelect.push(selectObject);
    this.addTag(selectObject);
    return selectObject;
  }

  unselectedComponent () {
    for (let i = 0; i < this.componentsSelect.length; i++) {
      let selection = this.componentsSelect[i];
      setStyle(selection.select, {background:''});
    }
  }

  selectedComponent (selected:component) {
    let rgbstring = 'rgba(240,240,251, 0.5)';
    if (selected.type == 'group') {
      // If group already created, component will be automatically pushed in group and so we have the redraw the list
      if (selected.content.validated) this.drawList();
      for (let i = 0; i < this.componentsSelect.length; i++) {
        let selection = this.componentsSelect[i];
        if (includes(selected.components, selection.component)) setStyle(selection.select, {background:rgbstring});
      }
    }
    for (let i = 0; i < this.componentsSelect.length; i++) {
      let selection = this.componentsSelect[i];
      if (selected == selection.component) setStyle(selection.select, {background:rgbstring});
    }
  }

  renameComponent (component:component) {
    for (let i = 0; i < this.componentsSelect.length; i++) {
      let selection = this.componentsSelect[i];
      if (component == selection.component) selection.tag.textContent = component.content.tag;
    }
  }

  addTag (selectObject:any) {
    let content = selectObject.component.content;
    let tag = el('div.selection-tag');
    let left = (content.ingroup || content.type == 'group')? 50 : 28;
    let width = (content.ingroup || content.type == 'group')? 110 : 130;
    setStyle(tag, {left:left+'px', width:width+'px'});
    if (content.tag) tag.textContent = content.tag;
    mount(selectObject.select, tag);
    selectObject.tag = tag;
    return tag;
  }

  deleteCurrentList () {
    let selections = this.componentsSelect;
    for (let i = 0; i < selections.length; i++) {
      if (selections[i].select.parentNode) unmount(selections[i].select.parentNode, selections[i].select);
    }
    this.componentsSelect = [];
    let groups = this.componentsGroup;
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].parentNode) unmount(groups[i].parentNode, groups[i]);
    }
    this.componentsGroup = [];
    this.componentsSelect = [];
  }

  ////////////////////////// LOCK //////////////////////////
  checkLock () {
    if (!this.alllocked) {
      this.lockAllComponent();
    } else {
      this.unlockAllComponent();
    }
    this.alllocked = !this.alllocked;
    undo.pushState();
  }

  unlockAllComponent () {
    for (let i = 0; i < this.componentsSelect.length; i++) {
      let selection = this.componentsSelect[i];
      if (selection.lockbutton) this.unlockComponent(selection.lockbutton, selection.component);
    }
    setAttr(this.mainlock, {class:'right-icon select-main-icon icon-unlock', style:{color:colorfourthgrey}});
  }

  lockAllComponent () {
    for (let i = 0; i < this.componentsSelect.length; i++) {
      let selection = this.componentsSelect[i];
      if (selection.lockbutton) this.lockComponent(selection.lockbutton, selection.component);
    }
    setAttr(this.mainlock, {class:'right-icon select-main-icon icon-lock', style:{color:colormain}});
  }

  addLockButton (componentselect:HTMLElement, component:component) {
    let lockbutton = el('div.right-icon.select-option-button', {onclick:(evt) => {
      evt.stopPropagation();
      if (!component.content.locked) {
        this.lockComponent(lockbutton, component);
      } else {
        this.unlockComponent(lockbutton, component);
      }
      undo.pushState();
    }});
    mount(componentselect, lockbutton);
    return lockbutton;
  }

  unlockComponent (button:HTMLElement, component:component) {
    component.unlock();
    setStyle(button, {'background-color':colorfourthgrey});
  }

  lockComponent (button:HTMLElement, component:component) {
    component.lock();
    setStyle(button, {'background-color':colormain});
  }

  ////////////////////////// VISIBILITY //////////////////////////
  checkVisibility () {
    if (!this.allvisible) {
      this.unvisibleAllComponent();
    } else {
      this.visibleAllComponent();
    }
    this.allvisible = !this.allvisible;
    undo.pushState();
  }

  visibleAllComponent () {
    for (let i = 0; i < this.componentsSelect.length; i++) {
      let selection = this.componentsSelect[i];
      if (selection.visiblebutton) this.visibleComponent(selection.visiblebutton, selection.component);
    }
    setAttr(this.mainvisible, {class:'right-icon select-main-icon icon-visible', style:{color:colorfourthgrey}});
  }

  unvisibleAllComponent () {
    for (let i = 0; i < this.componentsSelect.length; i++) {
      let selection = this.componentsSelect[i];
      if (selection.visiblebutton) this.unvisibleComponent(selection.visiblebutton, selection.component);
    }
    setAttr(this.mainvisible, {class:'right-icon select-main-icon icon-unvisible', style:{color:colormain}});
  }

  addVisibilityButton (componentselect:HTMLElement, component:component) {
    let visiblebutton = el('div.right-icon.select-option-button', {onclick:(evt) => {
      evt.stopPropagation();
      if (!component.content.visible) {
        this.visibleComponent(visiblebutton, component);
      } else {
        this.unvisibleComponent(visiblebutton, component);
      }
      undo.pushState();
    }});
    mount(componentselect, visiblebutton);
    return visiblebutton;
  }

  visibleComponent (button:HTMLElement, component:component) {
    if (this.mode == 'animation') component.restartAnimations();
    else component.stopAllAnimations();
    component.show();
    setStyle(button, {'background-color':colorfourthgrey});
  }

  unvisibleComponent (button:HTMLElement, component:component) {
    component.hide();
    setStyle(button, {'background-color':colormain});
  }

  ////////////////////////// PLAY //////////////////////////
  checkPlay () {
    if (!this.allplay) {
      this.stopAllComponent();
    } else {
      this.playAllComponent();
    }
    this.allplay = !this.allplay;
    undo.pushState();
  }

  addAnimationButton (componentselect:HTMLElement, component:component) {
    let playbutton = el('div.right-icon.select-option-button', {onclick:(evt) => {
      evt.stopPropagation();
      if (!component.content.play) {
        this.playComponent(playbutton, component);
      } else {
        this.stopComponent(playbutton, component);
      }
      undo.pushState();
    }});
    mount(componentselect, playbutton);
    return playbutton;
  }

  playAllComponent () {
    for (let i = 0; i < this.componentsSelect.length; i++) {
      let selection = this.componentsSelect[i];
      if (selection.playbutton) this.playComponent(selection.playbutton, selection.component);
    }
    setAttr(this.mainplay, {class:'right-icon select-main-icon icon-play', style:{color:colorfourthgrey}});
  }

  stopAllComponent () {
    for (let i = 0; i < this.componentsSelect.length; i++) {
      let selection = this.componentsSelect[i];
      if (selection.playbutton) this.stopComponent(selection.playbutton, selection.component);
    }
    setAttr(this.mainplay, {class:'right-icon select-main-icon icon-pause', style:{color:colormain}});
  }

  playComponent (button:HTMLElement, component:component) {
    component.content.setPlay(true);
    setStyle(button, {'background-color':colorfourthgrey});
  }

  stopComponent (button:HTMLElement, component:component) {
    component.content.setPlay(false);
    setStyle(button, {'background-color':colormain});
  }

  ////////////////////////// SHADOW //////////////////////////
  checkShadow () {
    if (!this.allshadow) {
      this.unshadowAllComponent();
    } else {
      this.shadowAllComponent();
    }
    this.allshadow = !this.allshadow;
    undo.pushState();
  }

  shadowAllComponent () {
    for (let i = 0; i < this.componentsSelect.length; i++) {
      let selection = this.componentsSelect[i];
      if (selection.shadowbutton) this.showShadowComponent(selection.shadowbutton, selection.component);
    }
    setAttr(this.mainshadow, {class:'right-icon select-main-icon icon-shadowon', style:{color:colorfourthgrey}});
    // environment.checkShadow();
  }

  unshadowAllComponent () {
    for (let i = 0; i < this.componentsSelect.length; i++) {
      let selection = this.componentsSelect[i];
      if (selection.shadowbutton) this.hideShadowComponent(selection.shadowbutton, selection.component);
    }
    setAttr(this.mainshadow, {class:'right-icon select-main-icon icon-shadowoff', style:{color:colormain}});
  }

  addShadowButton (componentselect:HTMLElement, component:component) {
    let shadowbutton = el('div.right-icon.select-option-button', {onclick:(evt) => {
      evt.stopPropagation();
      if (!component.content.shadow) {
        this.showShadowComponent(shadowbutton, component);
      } else {
        this.hideShadowComponent(shadowbutton, component);
      }
      undo.pushState();
    }});
    mount(componentselect, shadowbutton);
    return shadowbutton;
  }

  showShadowComponent (button:HTMLElement, component:component) {
    component.content.setShadow(true);
    // environment.checkShadow();
    setStyle(button, {'background-color':colorfourthgrey});
  }

  hideShadowComponent (button:HTMLElement, component:component) {
    component.content.setShadow(false);
    // environment.checkShadow();
    setStyle(button, {'background-color':colormain});
  }
}

export let selectionPanel = new selectionPanelClass();
