
import { coreSystem } from '../tools/coreSystem';
import { animation } from '../tools/animation';

import remove from 'lodash/remove';
import { Vector2, Quaternion } from '@babylonjs/core/Maths/math'
import { Tools } from '@babylonjs/core/Misc/Tools'

export class mouseCatcherClass {

  realmouse = new Vector2(0, 0);
  mousecatch = new Vector2(0, 0);
  animation:animation;
  mouseratio = 5000;
  catching = true;

  constructor (coreSystem:coreSystem) {
    this.animation = new animation(coreSystem, 10);
    window.addEventListener("mousemove", (evt) => {this.mouseOrientation(evt)});
    window.addEventListener("deviceorientation", (evt) => {this.deviceOrientation(evt)});
    window.addEventListener("orientationchange", () => {this.orientationChanged()});
    this.orientationChanged();
  }

  // Code copied from babylon: https://github.com/BabylonJS/Babylon.js/blob/master/src/Cameras/Inputs/freeCameraDeviceOrientationInput.ts
  screenQuaternion:Quaternion = new Quaternion();
  constantTranform = new Quaternion(- Math.sqrt(0.5), 0, 0, Math.sqrt(0.5));
  orientationChanged () {
      let screenOrientationAngle = (<any>window.orientation !== undefined ? +<any>window.orientation : ((<any>window.screen).orientation && ((<any>window.screen).orientation)['angle'] ? ((<any>window.screen).orientation).angle : 0));
      screenOrientationAngle = -Tools.ToRadians(screenOrientationAngle / 2);
      this.screenQuaternion.copyFromFloats(0, Math.sin(screenOrientationAngle), 0, Math.cos(screenOrientationAngle));
  }

  deviceOrientation (evt: DeviceOrientationEvent) {
    if (this.catching) {
      let gamma = evt.gamma !== null ? evt.gamma : 0;
      let beta = evt.beta !== null ? evt.beta : 0;
      let alpha = evt.alpha !== null ? evt.alpha : 0;
      if (evt.gamma !== null) {
        let quaternion = Quaternion.RotationYawPitchRoll(Tools.ToRadians(alpha), Tools.ToRadians(beta), -Tools.ToRadians(gamma));
        quaternion.multiplyInPlace(this.screenQuaternion);
        quaternion.multiplyInPlace(this.constantTranform);
        quaternion.z *= -1;
        quaternion.w *= -1;
        let angles = quaternion.toEulerAngles();

        let mousepos = new Vector2(angles.y, angles.x);
        mousepos.multiplyInPlace(new Vector2(1/3, 1/3));
        let max = 1/10;
        if (Math.abs(mousepos.x) > max) mousepos.x = Math.sign(mousepos.x) * max;
        if (Math.abs(mousepos.y) > max) mousepos.y = Math.sign(mousepos.y) * max;
        this.catch(mousepos);
      }
    }
  }

  mouseOrientation (evt: MouseEvent) {
    if (this.catching) {
      let mousepos = new Vector2(0, 0);
      mousepos.x = (evt.x - window.innerWidth / 2) / this.mouseratio;
      mousepos.y = (evt.y - window.innerHeight / 2) / this.mouseratio;
      this.catch(mousepos);
    }
  }

  start () {
    this.catching = true;
  }

  stop () {
    this.animation.stop();
    this.catching = false;
  }

  step = new Vector2(0, 0);
  catch (mouse:Vector2) {
    this.realmouse = mouse;
    this.animation.infinite(() => {
      let gapmouse = this.realmouse.subtract(this.mousecatch);
      this.step.x = gapmouse.x/20;
      this.step.y = gapmouse.y/20;
      this.mousecatch.x += this.step.x;
      this.mousecatch.y += this.step.y;
      if (Math.abs(gapmouse.x) < (1/this.mouseratio) && Math.abs(gapmouse.y) < (1/this.mouseratio)) this.animation.running = false;
      for (let i = 0; i < this.listeners.length; i++) {
        this.listeners[i](this.mousecatch);
      }
    })
  }

  listeners:Array<Function> = [];
  addListener (callback:Function) {
    this.listeners.push(callback);
  }

  removeListener (callback:Function) {
    remove(this.listeners, (c) => {c == callback});
  }
}
