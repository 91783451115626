
import { colormain, colordarkgrey, colortext } from '../service/colors';
import { _system } from '../service/editintale';
import { meshMaterialClass } from '../../viewer/contents/patterns/meshmaterial';

import postal from 'postal';
import { Rectangle } from '@babylonjs/gui/2D/controls/rectangle';
import { Control } from '@babylonjs/gui/2D/controls/control';
import { TextBlock } from '@babylonjs/gui/2D/controls/textBlock';
import { AdvancedDynamicTexture } from '@babylonjs/gui/2D/AdvancedDynamicTexture';
import { Color3 } from '@babylonjs/core/Maths/math';
import '@babylonjs/core/Rendering/boundingBoxRenderer';

export let componentChannel = postal.channel("component");
let controllerAdvancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("scene", true, this.scene);
controllerAdvancedTexture.layer.layerMask = 0x20000000;

var color = Color3.FromHexString(colortext);
var boundingBoxRenderer = _system.scene.getBoundingBoxRenderer();
boundingBoxRenderer.frontColor = color;
boundingBoxRenderer.showBackLines = false;
// boundingBoxRenderer.layerMask = 0x20000000;

export class selector {

  titleselector:Rectangle;
  textselector:TextBlock;

  constructor () {
    this.createSelector();
  }

  fontSize = 20;
  createSelector () {
    this.titleselector = new Rectangle();
    this.titleselector.background = colormain;
    this.titleselector.cornerRadius = 4;
    this.titleselector.height = (this.fontSize+5)+'px';
    this.titleselector.paddingLeft = '5px';
    this.titleselector.paddingRight = '5px';
    this.titleselector.thickness = 0;
    this.titleselector.hoverCursor = 'pointer';
    this.titleselector.shadowBlur = 2;
    this.titleselector.shadowColor = colordarkgrey;
    this.titleselector.isPointerBlocker = false;
    this.titleselector.linkOffsetY = -50;

    this.textselector = new TextBlock();
    this.textselector.color = colordarkgrey;
    this.textselector.resizeToFit = true;
    this.textselector.paddingRight = '5px';
    this.textselector.fontFamily = 'Raleway';
    this.textselector.fontWeight = '600';
    this.textselector.fontSize = this.fontSize+'px';
    this.textselector.paddingLeft = '5px';
    this.textselector.horizontalAlignment	= Control.HORIZONTAL_ALIGNMENT_CENTER;
    this.titleselector.addControl(this.textselector);
    controllerAdvancedTexture.addControl(this.titleselector);
  }

  // Need to save shape/pattern of object because mesh can change (example with models)
  shape:meshMaterialClass;
  linkWithMesh (shape:meshMaterialClass) {
    this.shape = shape;
    this.titleselector.linkWithMesh(shape.mesh);
  }

  setText (text:string) {
    this.textselector.text = text;
    this.titleselector.width = (text.length * this.fontSize / 1.5) + 'px';
  }

  selected = false;
  showSelector () {
    if (!this.selected) {
      this.titleselector.alpha = 1;
      this.shape.mesh.showBoundingBox = true;
      this.titleselector.linkWithMesh(this.shape.mesh);
    }
    return this;
  }

  hideSelector () {
    this.shape.mesh.showBoundingBox = false;
    this.titleselector.alpha = 0;
    return this;
  }

  visible = true;
  visibleSelector (visible:boolean) {
    this.visible = visible;
  }
}
