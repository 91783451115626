
import { manager, floatingManager, layerRight } from '../inputs/layer';
import { colormain, colorthirdgrey } from '../service/colors';
import { animationInterface, contentMove } from '../../viewer/contents/shared/contentmove';
import { ui_radioicon, ui_numberinput, numberoption, ui_button, ui_slider, ui_textinput, ui_select } from '../inputs/input';
import { ui_container } from '../inputs/group';
import { option3D } from '../../viewer/tools/interface';
import { inventoryClass } from '../inputs/inventory';
import { componentChannel, componentList, component } from '../component/component';
import { undo, undoChannel } from '../service/undo';
// import { contentsManager } from '../service/editintale';
import { threedJsonHelper } from '../../viewer/service/threedjsonhelper';
import { projectInterface } from '../../viewer/service/projectInterface';

import { setStyle, mount, setAttr } from 'redom';
// import filter from 'lodash/filter';
// import find from 'lodash/find';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
// import Suggestions from 'suggestions';
import toastr from 'toastr'

componentChannel.subscribe("unselect", (component:component, envelope) => {
  animationProp.unfreezeAllInputs();
});

componentChannel.subscribe("select", (component:component, envelope) => {
  animationProp.setAvailableInputs(component.availableAxis);
});

undoChannel.subscribe("componentchange", (data, envelope) => {
  // Settimeout to make sure animation data has been change before update
  setTimeout(() => {
    if (data.action == 'animation') animationButtons.updateAnimations(data.animations);
  }, 10)
});

/*
  +------------------------------------------------------------------------+
  | ANIMATION BUTTONS                                                      |
  +------------------------------------------------------------------------+
*/

export class animationButtonsClass extends manager {

  constructor () {
    super();
    this.control = new ui_container(layerRight, 'animation-buttons parameter-group');
    this.control.addTitle('Animations List');
    let addbutton = this.control.addButton({ui:'icon', text:'add'}, 'animation-button');
    // setAttr(addbutton.el, {'aria-label':'Add animation', 'data-microtip-position':'bottom', 'role':'tooltip'});
    addbutton.on('click', () => {
      if (this.buttonnumber == 5) return toastr.error('You have reached the maximum of animation for that component');
      let animationnumber = Object.keys(animationProp.animationInventoryList).length;
      let newAnimation = {name:'Animation '+animationnumber, event:'show', way:'once', curve:'linear', duration:10, delay:0, opacity:0, position:{}, rotation:{}, scale:{}};
      let key = this.addAnimation(newAnimation);
      this.currentContent.addAnimation(key, newAnimation);
      this.showAnimation(key, newAnimation);
      undo.pushState();
    });
  }

  currentContent:contentMove;
  currentContentRealPlay:boolean;
  setComponent (component:component) {
    this.currentContent = component.content;
    this.currentContentRealPlay = this.currentContent.play;
    this.currentContent.play = true;
    setStyle(animationProp.control.el, {display:'none'});
    setStyle(animationParam.control.el, {display:'none'});
    this.currentAnimation = undefined;
    this.addAnimations(this.currentContent.animations);
    if (this.currentContent.type == 'model' && this.currentContent.modelAnimationsName.length != 0) {
      animationProp.setModelAnimationList(this.currentContent.modelAnimationsName);
    } else {
      animationProp.setModelAnimationList([]);
    }
    this.show();
  }

  buttonnumber:number = 1;
  addAnimations (animations:any) {
    this.destroyButtons();
    this.buttonnumber = 1;
    if (animations == undefined) return;
    for (let key in animations) {
      this.addAnimationButton(key, animations[key]);
    }
  }

  addAnimation (contentAnimation:animationInterface) {
    let key = Math.random().toString(36).substring(2, 15);
    this.currentContent.animations[key] = contentAnimation;
    this.addAnimationButton(key, contentAnimation);
    return key;
  }

  currentAnimation:animationInterface;
  buttons:Array<ui_button> = [];
  deletebuttons:Array<ui_button> = [];
  addAnimationButton (key:string, contentAnimation:animationInterface) {
    let button = this.control.addButton({ui:'text', text:this.buttonnumber.toString()}, 'animation-button');
    button.on('click', () => {
      this.setActiveButton(button);
      this.showAnimation(key, contentAnimation);
    });
    this.buttonnumber++;
    this.buttons.push(button);

    let deletebutton = new ui_button(button.el, {ui:'icon', text:'delete'}, 'delete-animation-button');
    deletebutton.on('click', (evt) => {
      evt.stopPropagation();
      this.deleteAnimation(key, button, deletebutton);
      undo.pushState();
    });
    this.deletebuttons.push(deletebutton);

    this.setActiveButton(button);
    return button;
  }

  setActiveButton (button:ui_button) {
    for (let i = 0; i < this.buttons.length; i++) {
      setStyle(this.buttons[i].el, {background:colorthirdgrey, color:colormain});
    }
    setStyle(button.el, {background:colormain, color:colorthirdgrey});
  }

  deleteAnimation (key:string, button:ui_button, deletebutton:ui_button) {
    button.destroy();
    let index = this.buttons.indexOf(button);
    this.buttons.splice(index, 1);

    deletebutton.destroy();
    this.deletebuttons.splice(index, 1);

    let contentAnimation = this.currentContent.animations[key];
    contentAnimation.anim.stop();
    delete this.currentContent.animations[key];

    if (this.currentAnimation == contentAnimation) {
      setStyle(animationProp.control.el, {display:'none'});
      setStyle(animationParam.control.el, {display:'none'});
    }

    this.buttonnumber--;
    this.resetButtonsPosition();
  }

  resetButtonsPosition () {
    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].el.textContent = (i+1).toString();
      mount(this.buttons[i].el, this.deletebuttons[i].el);
    }
  }

  updateAnimations (animations:any) {
    setStyle(animationProp.control.el, {display:'none'});
    setStyle(animationParam.control.el, {display:'none'});
    if (this.currentContent) this.addAnimations(this.currentContent.animations);
    for (let key in animations) {
      if (this.currentContent.animations[key]) return this.showAnimation(key, animations[key]);
    }
  }

  currentKey:string;
  showAnimation (key:string, contentAnimation:animationInterface) {
    this.currentContent.pauseAnimation();
    this.currentContent.resetGeometry();
    this.currentAnimation = contentAnimation;
    this.currentKey = key;
    this.currentContent.startAnimation(contentAnimation);
    animationParam.showAnimation(contentAnimation);
    animationProp.showAnimation(contentAnimation);
  }

  resetAnimation () {
    this.currentContent.resetGeometry();
    this.currentContent.addAnimation(this.currentKey, this.currentAnimation);
    this.currentContent.startAnimation(this.currentAnimation);
  }

  destroyButtons () {
    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].destroy();
    }
    for (let i = 0; i < this.deletebuttons.length; i++) {
      this.deletebuttons[i].destroy();
    }
    this.buttons = [];
    this.deletebuttons = [];
    this.buttonnumber = 1;
  }

  show () {
    let animations = this.currentContent.animations;
    let firstanim = Object.keys(animations)[0];
    if (firstanim) {
      this.showAnimation(firstanim, animations[firstanim]);
      this.setActiveButton(this.buttons[0]);
      animationProp.showAnimation(animations[firstanim]);
    }
  }

  setRealPlay () {
    if (this.currentContent && this.currentContentRealPlay !== undefined) this.currentContent.play = this.currentContentRealPlay;
    this.currentContentRealPlay = undefined;
  }
}

export let animationButtons = new animationButtonsClass();

/*
  +------------------------------------------------------------------------+
  | ANIMATION PARAMATERS                                                   |
  +------------------------------------------------------------------------+
*/

class animationParamClass extends floatingManager {

  eventbutton:ui_radioicon;
  curvebutton:ui_select;
  waybutton:ui_radioicon;
  durationbutton:ui_numberinput;
  delaybutton:ui_numberinput;
  targetinput:ui_textinput;
  constructor () {
    super('Animation settings');
    let eventlist = ['show', 'hover', 'enter', 'click', 'scroll', 'move'];
    this.eventbutton = this.addRadioIcon('Trigger event', {value:'show', iconperline:3, list:eventlist}, (value) => {
      this.currentAnimation.event = value;
      this.setDefaultInputs(value);
      this.checkVisibleInputs(value);
      animationButtons.resetAnimation();
    });
    let curvelist = ['linear', 'bezier', 'circle', 'exponential', 'bounce'];
    this.curvebutton = this.addSelect('Curve', {value:'linear', list:curvelist}, (value) => {
      this.currentAnimation.curve = value;
      animationButtons.resetAnimation();
    });
    this.delaybutton = this.addNumberInput('Delay', {step:1, value:0, unit:'S', min:0, decimal:2}, (value) => {
      // Scroll animation in percentage
      if (this.currentAnimation.event == 'scroll') {
        this.currentAnimation.delay = Math.round(value)/100;
      } else {
        this.currentAnimation.delay = Math.round(value*10)/10;
      }
      animationButtons.resetAnimation();
    });
    this.durationbutton = this.addNumberInput('Duration', {step:1, value:1, unit:'S', min:0, decimal:2}, (value) => {
      // Scroll animation in percentage
      if (this.currentAnimation.event == 'scroll') {
        // As we use show and end at for scroll, we must recalculate duration depending on delay
        this.currentAnimation.duration = Math.round(value)/100 - this.currentAnimation.delay;
      } else {
        this.currentAnimation.duration = Math.round(value*10)/10;
      }
      animationButtons.resetAnimation();
    });
    this.waybutton = this.addRadioIcon('Behavior', {value:'once', iconperline:3, list:['once', 'alternate', 'loop']}, (value) => {
      this.currentAnimation.way = value;
      animationButtons.resetAnimation();
    });
    // this.targetinput = this.addTextInput('Trigger block', 'component tag', (value) => {
    //   let targetid = this.getTargetId(value);
    //   this.currentAnimation.target = targetid;
    // });
    //
    // this.targetinput.on('focus', () => {
    //   this.setAutocomplete();
    // });
    // this.suggestion = new Suggestions(this.targetinput.el, [], {
    //   minLength:0
    // });
  }

  // suggestion:any;
  // setAutocomplete () {
  //   let contents = filter(contentsManager.list, (o) => { return o.tag != '' && o.type != 'hotspot'; });
  //   let items = [];
  //   for (let i = 0; i < contents.length; i++) {
  //     items.push(contents[i].tag);
  //   }
  //   this.suggestion.update(items);
  // }

  checkVisibleInputs (trigger:string) {
    if (trigger == 'scroll' || trigger == 'move') {
      // setStyle(this.targetinput.container.el, {display:'none'});
      // setStyle(this.waybutton.container.el, {display:'none'});
      this.durationbutton.updateUnit('%');
      this.delaybutton.updateUnit('%');
      this.durationbutton.setMinMax({min:0, max:100});
      this.delaybutton.setMinMax({min:0, max:100});
      this.delaybutton.label.textContent = 'Start at';
      this.durationbutton.label.textContent = 'End at';
    } else {
      // setStyle(this.targetinput.container.el, {display:'block'});
      // setStyle(this.waybutton.container.el, {display:'block'});
      this.durationbutton.updateUnit('S');
      this.delaybutton.updateUnit('S');
      this.durationbutton.setMinMax({min:0, max:1000});
      this.delaybutton.setMinMax({min:0, max:1000});
      this.delaybutton.label.textContent = 'Delay';
      this.durationbutton.label.textContent = 'Duration';
    }
  }

  setDefaultInputs (trigger:string) {
    if (trigger == 'scroll' || trigger == 'move') {
      this.currentAnimation.delay = 0;
      this.delaybutton.setValue(0);
      this.currentAnimation.duration = 0.5;
      this.durationbutton.setValue(50);
    } else {
      this.currentAnimation.delay = 0;
      this.delaybutton.setValue(0);
      this.currentAnimation.duration = 10;
      this.durationbutton.setValue(10);
    }
  }

  // getTargetId (tag:string) {
  //   let target = find(componentList, (o) => { return o.content.tag == tag && o.content.tag != ''; });
  //   if (target) return target.content.id;
  //   else return undefined;
  // }
  //
  // getTargetTag (id:string) {
  //   let target = find(componentList, (o) => { return o.content.id == id; });
  //   if (target) return target.content.tag;
  //   else return undefined;
  // }

  currentAnimation:animationInterface;
  showAnimation (animation:animationInterface) {
    if (animation == undefined) return;
    this.currentAnimation = animation;
    this.eventbutton.setValue(animation.event);
    this.checkVisibleInputs(animation.event);
    // Scroll animation in percentage
    if (animation.event == 'scroll') this.delaybutton.setValue(animation.delay * 100);
    else this.delaybutton.setValue(animation.delay);
    if (animation.event == 'scroll') this.durationbutton.setValue((animation.duration + animation.delay) * 100);
    else this.durationbutton.setValue(animation.duration);
    this.waybutton.setValue(animation.way);
    this.curvebutton.setValue(animation.curve);
    // this.targetinput.setValue(this.getTargetTag(animation.target));
    setStyle(this.control.el, {display:'block'});
  }
}

export let animationParam = new animationParamClass();

/*
  +------------------------------------------------------------------------+
  | ANIMATION COMPONENT CHANGE                                             |
  +------------------------------------------------------------------------+
*/

export class animationPropClass extends floatingManager {

  animationInventory:inventoryClass;
  constructor () {
    super('Component changes');
    this.setPointInputs();
    this.createAnimationInventory();
  }

  modelInput:ui_select;
  pointInputs:any = {};
  opacityInput:ui_slider;
  setPointInputs () {
    this.modelInput = this.addSelect('Model Animation', {value:'', list:[]}, (value) => {
      if (value == 'None') value = '';
      if (value) this.freezeAllInputs();
      else this.unfreezeAllInputs();
      this.currentAnimation.model = value;
      animationButtons.resetAnimation();
    });
    this.addVector('Position', 'position', {step:0.5});
    this.addVector('Rotation', 'rotation', {step:10});
    this.addVector('Scale', 'scale', {step:0.5});
    this.opacityInput = this.addSlider('Opacity', {value:0, min:-1, max:1, step:0.1}, (value) => {
      this.currentAnimation.opacity = value;
      animationButtons.resetAnimation();
    });
  }

  addVector (label:string, vectorname:string, numberoption:numberoption) {
    this.pointInputs[vectorname] = this.addVectorInput(label, numberoption, (vector) => {
      let axe = Object.keys(vector)[0];
      if (vector[axe] != 0) { // Because it can be value 0
        this.currentAnimation[vectorname][axe] = vector[axe];
      } else {
        delete this.currentAnimation[vectorname][axe];
      }
      animationButtons.resetAnimation();
    });
  }

  createAnimationInventory () {
    this.animationInventory = new inventoryClass(this.control, 'Animation');
    this.animationInventory.onClick = (name) => {
      let savedAnimation = this.getAnimationFromName(name);
      if (savedAnimation == null) return;
      // Keep loop and don't replace currentAnimation entirely or content animation will be replace and it won't save changes
      // CloneDeep important or contents will share the saved animation parameters
      for (let key in savedAnimation) {
        this.currentAnimation[key] = cloneDeep(savedAnimation[key]);
      }

      this.showAnimation(this.currentAnimation);
      animationParam.showAnimation(this.currentAnimation);
      animationButtons.resetAnimation();
    };
    this.animationInventory.onAdd = (name) => {
      let saveAnimation:any = {};
      saveAnimation = threedJsonHelper.recursiveObjectToObject(projectInterface.animation, this.currentAnimation);
      saveAnimation.name = name;
      let key = Math.random().toString(36).substring(2, 15);
      this.animationInventoryList[key] = saveAnimation;
    };
    this.animationInventory.onDelete = (name) => {
      for (let key in this.animationInventoryList) {
        if (this.animationInventoryList[key].name == name) delete this.animationInventoryList[key];
      }
    };
  }

  setModelAnimationList (list:Array<string>) {
    if (list.length > 0) {
      let options = clone(list);
      options.unshift('None');
      this.modelInput.setOptions(options);
      setStyle(this.modelInput.container.el, {display:'block'});
    } else {
      setStyle(this.modelInput.container.el, {display:'none'});
    }
  }

  getAnimationFromName (name:string) {
    for (let key in this.animationInventoryList) {
      if (this.animationInventoryList[key].name == name) return this.animationInventoryList[key];
    }
    return null;
  }

  animationInventoryList:any = {};
  inventoryButtons:any = {};
  addAnimationInInventory (key:string, contentAnimation:animationInterface) {
    this.animationInventoryList[key] = contentAnimation;
    this.animationInventory.addInInventory(contentAnimation.name);
  }

  currentAnimation:animationInterface;
  showAnimation (animation:animationInterface) {
    if (animation == undefined) return setStyle(this.control.el, {display:'none'});
    this.currentAnimation = animation;
    if (animation.model) {
      this.modelInput.setValue(animation.model);
      this.freezeAllInputs();
    } else {
      this.modelInput.setValue('None');
      this.setAllPointValue(animation);
      this.unfreezeAllInputs();
    }
    setStyle(this.control.el, {display:'block'});
  }

  freezeAllInputs () {
    this.freezeVectorInputs('position', true);
    this.freezeVectorInputs('rotation', true);
    this.freezeVectorInputs('scale', true);
    this.freezeInput(this.opacityInput, true);
  }

  unfreezeAllInputs () {
    this.freezeVectorInputs('position', false);
    this.freezeVectorInputs('rotation', false);
    this.freezeVectorInputs('scale', false);
    this.freezeInput(this.opacityInput, false);
  }

  setAvailableInputs (availableAxis:any) {
    let p = 0;
    for (let key in availableAxis.position) {
      this.freezeInput(this.pointInputs.position[key], !availableAxis.position[key]);
      p += availableAxis.position[key];
    }
    if (p) setStyle(this.pointInputs.position.container.el, {display:'block'});
    else setStyle(this.pointInputs.position.container.el, {display:'none'});

    let r = 0;
    for (let key in availableAxis.rotation) {
      this.freezeInput(this.pointInputs.rotation[key], !availableAxis.rotation[key]);
      r += availableAxis.rotation[key];
    }
    if (r) setStyle(this.pointInputs.rotation.container.el, {display:'block'});
    else setStyle(this.pointInputs.rotation.container.el, {display:'none'});

    let s = 0;
    for (let key in availableAxis.scale) {
      this.freezeInput(this.pointInputs.scale[key], !availableAxis.scale[key]);
      s += availableAxis.scale[key];
    }
    if (s) setStyle(this.pointInputs.scale.container.el, {display:'block'});
    else setStyle(this.pointInputs.scale.container.el, {display:'none'});

    if (availableAxis.opacity) setStyle(this.opacityInput.container.el, {display:'block'});
    else setStyle(this.opacityInput.container.el, {display:'none'});
  }

  freezeVectorInputs (vector:string, bool:boolean) {
    let vectorInput = this.pointInputs[vector];
    for (let key in vectorInput) {
      if (key != 'label') this.freezeInput(vectorInput[key], bool);
    }
  }

  setAllPointValue (animation:animationInterface) {
    if (animation.position != undefined) this.setPointValue('position', animation.position);
    if (animation.rotation != undefined) this.setPointValue('rotation', animation.rotation);
    if (animation.scale != undefined) this.setPointValue('scale', animation.scale);
    if (animation.opacity != undefined) this.opacityInput.setValue(animation.opacity);
  }

  decimal = 100;
  setPointValue (vector:string, point:option3D) {
    if (point.x !== undefined) this.pointInputs[vector].x.setValue(Math.round(point.x*this.decimal)/this.decimal);
    else this.pointInputs[vector].x.setValue(0);
    if (point.y !== undefined) this.pointInputs[vector].y.setValue(Math.round(point.y*this.decimal)/this.decimal);
    else this.pointInputs[vector].y.setValue(0);
    if (point.z !== undefined) this.pointInputs[vector].z.setValue(Math.round(point.z*this.decimal)/this.decimal);
    else this.pointInputs[vector].z.setValue(0);
  }
}

export let animationProp = new animationPropClass();
