
import { projectInterface } from '../../viewer/service/projectInterface';
import { progressBar, environment, navigation, contentsManager } from './editintale';
import { contentMove } from '../../viewer/contents/shared/contentmove';
import { threedJsonHelper } from '../../viewer/service/threedjsonhelper';

import postal from 'postal';
import cloneDeep from 'lodash/cloneDeep';
import defaultsDeep from 'lodash/defaultsDeep';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import head from 'lodash/head';
import merge from 'lodash/merge';
import transform from 'lodash/transform';
import find from 'lodash/find';
import isObject from 'lodash/isObject';

let plugins = {
  navigation:navigation,
  environment:environment,
  progressBar:progressBar,
}

export let undoChannel = postal.channel("undo");

interface change {
  back:any
  forward:any
}

class undoClass {

  presentState:any = {};
  pastChange:Array<change> = [];
  futureChange:Array<change> = [];

  saveState () {
    this.presentState = this.getProjectJson();
  }

  pushState () {
    let projectJson = this.getProjectJson();
    if (isEqual(projectJson, this.presentState)) return; // Nothing has change
    let backchange = this.getDifference(this.presentState, projectJson);
    let forwardchange = this.getDifference(projectJson, this.presentState);
    // Set default value to null in case new values added so that we can go back to null/undefined value
    let backDefault = this.setNullValues(backchange);
    let forwardDefault = this.setNullValues(forwardchange);
    // clone otherwise back object will match with forward object
    backchange = cloneDeep(defaultsDeep(backchange, forwardDefault));
    forwardchange = cloneDeep(defaultsDeep(forwardchange, backDefault));

    // console.log({back:backchange, forward:forwardchange});
    this.pastChange.push({back:backchange, forward:forwardchange});
    this.futureChange = [];
    this.presentState = projectJson;
  }

  back () {
    if (this.pastChange.length != 0) {
      let past = last(this.pastChange);
      let newState = merge(this.presentState, past.back);
      let backState = this.getDifference(newState, past.forward);
      if (past.back.environment) this.setEnvironmentChange(past.back.environment);
      if (past.back.progressBar) this.setProgressBarChange(past.back.progressBar);
      if (past.back.navigation) this.setNavigationChange(past.back.navigation);
      if (past.back.pointofviews) this.setPointofviewsChange(past.back.pointofviews);
      if (past.back.contents) this.setContentsChange(past.back.contents);
      this.futureChange.unshift(this.pastChange.pop());
      this.presentState = backState;
      return true;
    } else {
      return false;
    }
  }

  forward () {
    if (this.futureChange.length != 0) {
      let future = head(this.futureChange);
      let newState = merge(this.presentState, future.forward);
      let forwardState = this.getDifference(newState, future.back);
      if (future.forward.environment) this.setEnvironmentChange(future.forward.environment);
      if (future.forward.navigation) this.setNavigationChange(future.forward.navigation);
      if (future.forward.pointofviews) this.setPointofviewsChange(future.forward.pointofviews);
      if (future.forward.contents) this.setContentsChange(future.forward.contents);
      this.pastChange.push(this.futureChange.shift());
      this.presentState = forwardState;
      return true;
    } else {
      return false;
    }
  }

  setEnvironmentChange (toEnvironmentChange:any) {
    let environmentChange = this.jsonToObject(projectInterface.environment, toEnvironmentChange);
    undoChannel.publish('environmentchange', environmentChange);
  }

  setProgressBarChange (toProgressBarChange:any) {
    let progressBarChange = this.jsonToObject(projectInterface.progressBar, toProgressBarChange);
    undoChannel.publish('progressBarchange', progressBarChange);
  }

  setNavigationChange (toNavigationChange:any) {
    let environmentChange = this.jsonToObject(projectInterface.navigation, toNavigationChange);
    undoChannel.publish('navigationchange', environmentChange);
  }

  setPointofviewsChange (toPointofviewChange:any) {
    // Check point of view change
    for (let pointofview in toPointofviewChange) {
      // If pointofview has been renamed, stored or unstored, there is nothing else to do
      if (toPointofviewChange[pointofview].n === null) {
        undoChannel.publish('pointofviewchange', {action:'store', who:pointofview});
      } else if (navigation.pointofviews[pointofview] == undefined) {
        undoChannel.publish('pointofviewchange', {action:'unstore', who:pointofview});
      } else {
        let pointofviewChange = this.jsonToObject(projectInterface.pointofview, toPointofviewChange[pointofview]);
        undoChannel.publish('pointofviewchange', {action:'parameter', who:pointofview, pointofview:pointofviewChange});
      }
    }
  }

  setContentsChange (toContentsChange:any) {
    for (let id in toContentsChange) {
      if (toContentsChange[id].i === null) {
        undoChannel.publish('componentchange', {action:'store', who:id});
      }
    }
    for (let id in toContentsChange) {
      if (toContentsChange[id].i !== null) {
        let content = find(contentsManager.list, (o) => { return o.id == id });
        if (content == undefined) {
          undoChannel.publish('componentchange', {action:'unstore', who:id});
        } else {
          let animations = toContentsChange[id].animations;
          if (animations) {
            let contentanimations = {};
            for (let animkey in animations) {
              // If no event, it means it has been deleted
              contentanimations[animkey] = this.jsonToObject(projectInterface.animation, animations[animkey]);
            }
            undoChannel.publish('componentchange', {action:'animation', who:content.id, animations:contentanimations});
          } else {
            let contentChange = this.jsonToObject(projectInterface.content, toContentsChange[id]);
            let typeChange = this.jsonToObject(projectInterface[content.type], toContentsChange[id]);
            undoChannel.publish('componentchange', {action:'parameter', who:content.id, content:contentChange, type:typeChange});
          }
        }
      }
    }
  }

  getProjectJson () {
    let projectjson:any = {};
    projectjson.pointofviews = this.getPointofviewsJson();
    projectjson.contents = this.getContentsJson();
    for (let pluginkey in plugins) {
      let plugin = plugins[pluginkey];
      projectjson[pluginkey] = this.objectToJson(projectInterface[pluginkey], plugin);
    }
    return projectjson;
  }

  getPointofviewsJson () {
    let pointofviewsJson:any = {};
    for (let key in navigation.pointofviews) {
      pointofviewsJson[key] = this.objectToJson(projectInterface.pointofview, navigation.pointofviews[key]);
    }
    return pointofviewsJson;
  }

  getContentsJson () {
    let contentsJson:any = {};
    for (let i = 0; i < contentsManager.list.length; i++) {
      contentsJson[contentsManager.list[i].id] = this.getContentJson(contentsManager.list[i]);
    }
    return contentsJson;
  }

  getContentJson (content:contentMove) {
    let contentdata = this.objectToJson(projectInterface.content, content);
    let contenttypedata = this.objectToJson(projectInterface[content.type], content);
    let contentmerged = merge(contentdata, contenttypedata);
    let contentanimations = {};
    for (let animkey in content.animations) {
      contentanimations[animkey] = this.objectToJson(projectInterface.animation, content.animations[animkey]);
    }
    contentmerged.animations = contentanimations;
    return contentmerged;
  }

  objectToJson (object:Object, content:any) {
    return threedJsonHelper.recursiveObjectToJson(object, content);
  }

  jsonToObject (object:Object, content:any) {
    return threedJsonHelper.recursiveJsonToObject(object, content);
  }

  limitAccuracy (number:number, length:number) {
    if (length == 1) return Math.round(number);
    let powLength = Math.pow(10, length);
    return Math.round(number*powLength)/powLength;
  }

  getDifference (object, base) {
  	let changes = (object, base) => {
  		return transform(object, (result, value, key) => {
  			if (!isEqual(value, base[key])) {
  				result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
  			}
  		});
  	}
  	return changes(object, base);
  }

  setNullValues (object) {
    let changes = (object) => {
  		return transform(object, (result, value, key) => {
        // Keep null and not undefined or it won't be considered by jsontocontent when back or forward
  			result[key] = (isObject(value)) ? changes(value) : null;
  		});
  	}
  	return changes(object);
  }
}

export let undo = new undoClass();
