
import { coreSystem } from '../../tools/coreSystem';
import { meshMovementClass } from './meshmove';

import { WaterMaterial } from '@babylonjs/materials/water/waterMaterial';

import { Vector2, Color3, Color4 } from '@babylonjs/core/Maths/math'
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { PBRMaterial } from '@babylonjs/core/Materials/PBR/pbrMaterial';
import { Texture } from '@babylonjs/core/Materials/Textures/texture';

// This is the common variable shared between different content to set the maxSimultaneousLights properties of materials
export let maxLightforMaterial = 10;
interface changeableParameters {
  alpha?:number,
  metallic?:number,
  roughness?:number,
  windforce?:number,
  waveheight?:number,
  bumpheight?:number,
  colorblendfactor?:number,
  wavelength?:number,
  bumplevel?:number
  maxSimultaneousLights?:number
};

export interface materialOptions {
  waterEnabled?:boolean;
  shadowEnabled?:boolean;
  lightEnabled?:boolean;
  fogEnabled?:boolean;
  glowEnabled?:boolean;
}

export class meshMaterialClass extends meshMovementClass {

  waterEnabled = true;
  shadowEnabled = true;
  lightEnabled = true;
  fogEnabled = true;
  glowEnabled = false;
  constructor (coreSystem:coreSystem, materialOptions?:materialOptions) {
    super(coreSystem);
    this.setMaterialOptions(materialOptions);
  }

  setMaterialOptions (materialOptions?:materialOptions) {
    for (let key in materialOptions) {
      this[key] = materialOptions[key];
    }
  }

  setMesh (mesh:Mesh) {
    if (this.mesh) {
      mesh.position = this.mesh.position.clone();
      mesh.rotation = this.mesh.rotation.clone();
      mesh.scaling = this.mesh.scaling.clone();
      if (this.mesh.material) mesh.material = this.mesh.material.clone('copywithnewmesh');
      if (this.materialtype == 'water') this.setWaterRenderList(mesh.material);
      mesh.isVisible = this.mesh.isVisible;
      this.mesh.dispose();
    }

    this._setMesh(mesh);
    if (mesh.isVisible) this.show(); // call show function to take material options into account
  }

  show () {
    return this._show();
  }

  _show () {
    this.mesh.isVisible = true;
    // TODO Add option in selection for shadow, water and light
    if (this.waterEnabled) this.addToWaters();
    else this.removeFromWaters();
    if (this.shadowEnabled) this.receiveShadow(true);
    else this.receiveShadow(false);
    if (this.lightEnabled) this.setMaxLights(maxLightforMaterial);
    else this.setMaxLights(0);
    if (this.fogEnabled) this.addFog();
    else this.removeFog();
    if (this.glowEnabled) this.addGlow();
    else this.removeGlow();
    return this;
  }

  hide () {
    return this._hide();
  }
  _hide () {
    this.mesh.isVisible = false;
    this.removeFromWaters();
    this.receiveShadow(false);
    this.setMaxLights(0);
    this.removeFog();
    this.removeGlow();
    return this;
  }

  highlight (test:boolean, color?:string) {
    let babyloncolor;
    if (color) babyloncolor = Color3.FromHexString(color);
    else babyloncolor = Color3.White();
    if (test) this._system.highlighter.addMesh(this.mesh, babyloncolor);
    else this._system.highlighter.removeMesh(this.mesh);
    return this;
  }

  addGlow () {
    this._system.glowLayer.addIncludedOnlyMesh(this.mesh);
    this.checkSceneGlow();
    return this;
  }

  removeGlow () {
    this._system.glowLayer.removeIncludedOnlyMesh(this.mesh);
    this.checkSceneGlow();
    return this;
  }

  checkSceneGlow () {
    if (this._system.glowLayer._includedOnlyMeshes.length > 0) this._system.glowLayer.isEnabled = true;
    else this._system.glowLayer.isEnabled = false;
  }

  addFog () {
    if (this.mesh.material) this.mesh.material.fogEnabled = true;
    return this;
  }

  removeFog () {
    if (this.mesh.material) this.mesh.material.fogEnabled = false;
    return this;
  }

  receiveShadow (bool:boolean) {
    this.mesh.receiveShadows = bool;
  }

  addShadow () {
    for (let i = 0; i < this._system.shadowGenerators.length; i++) {
      this._system.shadowGenerators[i].addShadowCaster(this.mesh);
    }
    let index = this._system.shadowMeshRenderlist.indexOf(this.mesh);
    if (index == -1) this._system.shadowMeshRenderlist.push(this.mesh);
    return this;
  }

  removeShadow () {
    for (let i = 0; i < this._system.shadowGenerators.length; i++) {
      this._system.shadowGenerators[i].removeShadowCaster(this.mesh);
    }
    let index = this._system.shadowMeshRenderlist.indexOf(this.mesh);
    if (index != -1) this._system.shadowMeshRenderlist.splice(index, 1);
    return this;
  }

  addToWaters () {
    if (this.materialtype != 'water') {
      let index = this._system.waterMeshRenderlist.indexOf(this.mesh);
      if (index == -1) this._system.waterMeshRenderlist.push(this.mesh);
      for (let i = 0; i < this._system.waterMaterials.length; i++) {
        this.setWaterRenderList(this._system.waterMaterials[i]);
      }
    }
  }

  removeFromWaters () {
    let index = this._system.waterMeshRenderlist.indexOf(this.mesh);
    if (index != -1) this._system.waterMeshRenderlist.splice(index, 1);
    for (let i = 0; i < this._system.waterMaterials.length; i++) {
      this.setWaterRenderList(this._system.waterMaterials[i]);
    }
  }

  createPBRM (mkey:string, options:any) {
    let material = new PBRMaterial(mkey, this._system.scene);
    for (let key in options) {
      let option = options[key];
      material[key] = option;
    }
    // material.subSurface.isRefractionEnabled = true;
    return material;
  }

  createWM (mkey:string, options:any) {
    let material = new WaterMaterial(mkey, this._system.scene);
    for (let key in options) {
      let option = options[key];
      material[key] = option;
    }
    this.removeFromWaters();
    this._system.waterMaterials.push(material);
    this.setWaterRenderList(material);
    return material;
  }

  setWaterRenderList (material:WaterMaterial) {
    material._refractionRTT.renderList = this._system.waterMeshRenderlist;
    material._reflectionRTT.renderList = this._system.waterMeshRenderlist;
  }

  materialtype:'standard'|'water';
  setMaterialType (materialtype?:any, temp?:boolean) {
    this.deleteMaterial();
    this.addToWaters();
		if (!materialtype) materialtype = this.materialtype;
		if (!temp) this.materialtype = materialtype;
    let material;
		if (materialtype == 'standard') material = this.createPBRM('pbr'+this.key, {backFaceCulling:false, metallic:0, roughness:1});
		if (materialtype == 'water') material = this.createWM('water'+this.key, {backFaceCulling:false, windDirection:new Vector2(1, 1), bumpHeight:0.5, waveLength:0.5, windForce:-15, waveHeight:0.001, colorBlendFactor:0.5});
    this.mesh.material = material;
    if (this.lightEnabled) this.setMaxLights(maxLightforMaterial);
    this.setTextures();
    this.setTextureColors();
    return this;
  }

  transparentmaterial:PBRMaterial;
  setTransparentMaterial (visibility?:number) {
    this._setTransparentMaterial(visibility);
  }

  _setTransparentMaterial (visibility?:number) {
    this.mesh.isVisible = true;
    this.mesh.isPickable = true;
    if (visibility) this.mesh.visibility = visibility;
    else this.mesh.visibility = 0.0001;
    this.transparentmaterial = new PBRMaterial("", this._system.scene);
    this.transparentmaterial.maxSimultaneousLights = 0;
    this.mesh.material = this.transparentmaterial;
  }

  setMaxLights (maxLights:number) {
    if (this.mesh.material) {
      this.mesh.material.maxSimultaneousLights = maxLights;
      // Or object is still visible
      if (maxLights == 0) this.mesh.material.directIntensity = 0;
      else this.mesh.material.directIntensity = 1;
    }
  }

  deleteMaterial () {
    if (this.mesh.material) {
      let index = this._system.waterMaterials.indexOf(this.mesh.material);
      if (index != -1) this._system.waterMaterials.splice(index, 1);
      this.mesh.material.dispose();
    }
  }

  setMaterialProperties (prop:changeableParameters) {
    for (let key in prop) {
      this.setMaterialProperty(key, prop[key]);
    }
  }

  changeParameters = {
    alpha:'alpha',
    metallic:'metallic',
    roughness:'roughness',
    windforce:'windForce',
    waveheight:'waveHeight',
    bumpheight:'bumpHeight',
    colorblendfactor:'colorBlendFactor',
    wavelength:'waveLength',
    bumplevel:'bumpTexture.level',
  };
  setMaterialProperty (key:string, value:any) {
    if (key == 'bumpheight' && this.materialtype == 'standard') {
      if (this.mesh.material.bumpTexture) {
        this.mesh.material.bumpTexture.level = value;
      }
    }
    if (this.changeParameters[key] != undefined) {
      key = this.changeParameters[key];
      this.mesh.material[key] = value;
    }
  }

  deleteTextureColors () {
    for (let key in this.colors) {
      this.deleteTextureColor(key);
    }
    this.colors = {};
  }

  defaultColorValues = {
    particle:'white',
    albedo:'white',
    ambient:'black',
    specular:'black',
    emissive:'black',
    bump:'black',
    opacity:'white',
    reflection:'white',
    reflectivity:'white',
  };
  deleteTextureColor (type:string) {
    if (this.defaultColorValues[type] == 'white') this.mesh.material[type+'Color'] = Color3.White();
    else this.mesh.material[type+'Color'] = Color3.Black();
    delete this.colors[type];
  }

  colors:any = {};
  setTextureColors () {
    for (let key in this.colors) {
      this.mesh.material[key+'Color'] = this.colors[key];
    }
  }

  setTextureColor (type:string, color:Array<number>) {
    if (this.mesh.material) {
      if (color == undefined) {
        if (this.defaultColorValues[type] == 'white') this.mesh.material[type+'Color'] = Color3.White();
        else this.mesh.material[type+'Color'] = Color3.Black();
        return this;
      }
      let babyloncolor = new Color3(color[0]/255, color[1]/255, color[2]/255);
      this.colors[type] = babyloncolor;
      this.mesh.material[type+'Color'] = babyloncolor;
    }
    return this;
  }

  deleteTextures () {
    for (let key in this.textures) {
      this.deleteTexture(key);
    }
    this.textures = {};
  }

  deleteTexture (type:string, always?:boolean) {
    if (this.mesh.material == undefined) return;
    if (this.mesh.material[type+'Texture'] != undefined) this.mesh.material[type+'Texture'].dispose();
    this.mesh.material[type+'Texture'] = undefined;
    if (always !== false) delete this.textures[type];
  }

  setTextures () {
    for (let key in this.textures) {
      let clonedTexture = this.textures[key].clone();
      this.mesh.material[key+'Texture'] = clonedTexture;
    }
    return this;
  }

  textures:any = {};
  setTexture (type:string, texture?:any, clone?:boolean) {
    if (texture == undefined) texture = this.textures[type];
    if (texture == undefined) return this.mesh.material[type+'Texture'] = undefined;
    this.textures[type] = texture;
    let clonedTexture = (clone === false)? texture : texture.clone();
    this.mesh.material[type+'Texture'] = clonedTexture;
    if (type == 'opacity') {
      this.mesh.material.needDepthPrePass = true;
      this.mesh.material.opacityTexture.getAlphaFromRGB = true;
    }
    return this;
  }

  setTextureSize (size:number) {
    let textures = ['albedo', 'ambient', 'specular', 'emissive', 'bump', 'reflectivity'];
    for (let i = 0; i < textures.length; i++) {
      let texture = textures[i];
      if (this.mesh.material[texture+'Texture'] != undefined) {
        this.mesh.material[texture+'Texture'].vScale = size;
        this.mesh.material[texture+'Texture'].uScale = size;
      }
    }
    return this;
  }

  enableReflection (enable:boolean) {
    if (this.mesh.material) {
      if (enable) this.mesh.material.environmentIntensity = 1;
      else this.mesh.material.environmentIntensity = 0;
    }
  }

  enableRefraction (enable:boolean) {
    if (this.mesh.material) {
      if (this.mesh.material.subSurface) this.mesh.material.subSurface.isRefractionEnabled = enable;
    }
  }

  setIndexOfRefraction (index:number) {
    if (this.mesh.material) {
      this.mesh.material.subSurface.indexOfRefraction = index;
    }
  }

  setOpacity (op:number) {
    this.mesh.visibility = op;
    if (this.mesh.material) {
      // For shadow/hasAlpha purpose
      // NOTE keep that for the day we put shadow back in Naker
      if (this.mesh.material.albedoTexture) {
        this.mesh.material.useAlphaFromAlbedoTexture = true;
        // if (op == 1) this.mesh.material.useAlphaFromAlbedoTexture = false;
        // else this.mesh.material.useAlphaFromAlbedoTexture = true;
      }
    }
    return this;
  }

	setFrame (frame:boolean) {
		if (this.mesh.material != undefined) this.mesh.material.wireframe = frame;
	}

  coordinatesModes = {
    none: Texture.EXPLICIT_MODE,
    fixed: Texture.FIXED_EQUIRECTANGULAR_MODE,
    skybox: Texture.SKYBOX_MODE,
    projection: Texture.PROJECTION_MODE,
    cubic: Texture.CUBIC_MODE,
    planar: Texture.PLANAR_MODE,
    spherical: Texture.SPHERICAL_MODE
  };
  setReflectionMode (mode:string) {
    if (this.mesh.material) {
      if (this.mesh.material.reflectionTexture) {
        // if (mode == 'none') this.mesh.material.reflectionTexture.level = 0;
        // else this.mesh.material.reflectionTexture.level = 1;
        this.mesh.material.reflectionTexture.coordinatesMode = this.coordinatesModes[mode]﻿﻿;
      }
    }
  }

  destroy () {
    this.deleteMaterial();
		this.deleteTextures();
    this.removeFromWaters();
    this.mesh.dispose();
		return this;
  }
}
