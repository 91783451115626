
import { meshparent } from './meshparent';
import { meshMaterialClass, maxLightforMaterial } from './meshmaterial';
import { option3D } from '../../tools/interface';

import { Vector3 } from '@babylonjs/core/Maths/math'
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';

export class modelparent extends meshparent {

  setMeshes (meshes:Array<Mesh>) {
    this.destroyMeshes();
    this.setGroupParent();
    if (meshes.length == 0) return;
    this.addParentMeshes(meshes);
    // Get the group maximum bounds
    this.checkBounds(this.mainParentList);
    // Get center of the group of meshes
    this.getCenter();
    // Set mesh position so that the group is centered in the boundmesh
    this.setParentsRelativePosition();
    // Get and Set the group scale so that it fits in the boundmesh
    this.scaleGroup();
  }

  setGroupParent () {
    // Reset the main mesh for correct scale and center calculation

    let mesh = MeshBuilder.CreateBox('__modelroot__', {}, this._system.scene);
    // this.mesh.showBoundingBox = true;
    // this.setMeshInvisible(this.mesh);
    // if (righthanded) this.mesh.scaling = new Vector3(-1, 1, 1);
    // else this.mesh.scaling = new Vector3(1, 1, 1);
    mesh.scaling = new Vector3(1, 1, 1);

    this.scalemesh.mesh.scaling = new Vector3(1, 1, 1);
    this.scalemesh.mesh.parent = mesh;

    // Need to keep former value for edition mode to show selector
    mesh.isVisible = this.mesh.isVisible;
    mesh.isPickable = this.mesh.isPickable;
    mesh.visibility = this.mesh.visibility;

    if (this.mesh) this.mesh.dispose();
    this.mesh = mesh;
    if (this.transparentmaterial) this.setTransparentMaterial();
  }

  setScaleAxis (axis:option3D) {
    for (let axe in axis) {
      this.mesh.scaling[axe] = axis[axe];
    }
    return this;
  }

  mainParentListId:Array<string> = [];
  mainParentList:Array<meshMaterialClass> = [];
  addParentMeshes (meshes:Array<Mesh>) {
    for (let i = 0; i < meshes.length; i++) {
      let mesh = meshes[i];
      mesh.parent = this.scalemesh.mesh;
      let newmesh = new meshMaterialClass(this._system);
      newmesh.setMesh(mesh);
      this.mainParentList.push(newmesh);
      let children = mesh.getDescendants(false);
      if (children.length) this.addChildren(children);
      else this.addChildren([mesh]);
    }
  }

  addChildren (meshes:Array<Mesh>) {
    for (let i = 0; i < meshes.length; i++) {
      let mesh = meshes[i];
      if (mesh.material != undefined && mesh.subMeshes != undefined) {
        let newmesh = new meshMaterialClass(this._system);
        // clone material or models will share the same material property like color
        mesh.material = mesh.material.clone('');
        mesh.material.maxSimultaneousLights = maxLightforMaterial;
        newmesh.setMesh(mesh);
        if (this.meshes.indexOf(newmesh) == -1) this.meshes.push(newmesh);
      }
    }
  }

  setParentsRelativePosition () {
    for (let i = 0; i < this.mainParentList.length; i++) {
      this.setMeshRelativePosition(this.mainParentList[i]);
    }
  }

  setMeshRelativePosition (mesh:meshMaterialClass) {
    mesh.mesh.position.subtractInPlace(this.center);
  }

  getCenter () {
    if (this.minimum == undefined) return;
    let sum = this.maximum.add(this.minimum);
    this.center = sum.divide(new Vector3(2, 2, 2));
  }

  scaleVector:Vector3;
  scaleGroup () {
    let max = 0;
    for (let key in {x:0, y:0, z:0}) {
      let scale = Math.abs(this.maximum[key] - this.minimum[key]);
      if (scale > max) max = scale;
    }
    this.scaleVector = new Vector3(1/max, 1/max, 1/max);
    this.scalemesh.mesh.scaling = this.scaleVector;
  }

  destroy () {
    this.mesh.dispose();
    this.scalemesh.mesh.dispose();
    this.destroyMeshes();
    return this;
  }

  destroyMeshes () {
    this.mainParentListId = [];
    this.mainParentList = [];
    this._destroyMeshes();
  }
}
