
import { _system } from '../service/editintale';
import { colorGizmoX, colorGizmoY, colorGizmoZ, colormain } from '../service/colors';
import { NakerPlaneDragGizmo } from './customPlaneDrag';
import { NakerBowDragGizmo } from './customBowDrag';
import { NakerAxisDragGizmo } from './customAxisDrag';
import { NakerScaleDragGizmo } from './customScaleDrag';

import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh';
import { Vector3, Color3 } from '@babylonjs/core/Maths/math';
import { UtilityLayerRenderer } from "@babylonjs/core/Rendering/utilityLayerRenderer";
import { Observable } from "@babylonjs/core/Misc/observable";
import { Nullable } from "@babylonjs/core/types";
import { HighlightLayer } from '@babylonjs/core/Layers/highlightLayer'
import { GlowLayer } from '@babylonjs/core/Layers/glowLayer'

export class nakerGizmo {

    xColor:Color3;
    yColor:Color3;
    zColor:Color3;
    mainColor:Color3;

    public xPlaneGizmo: NakerPlaneDragGizmo;
    public yPlaneGizmo: NakerPlaneDragGizmo;
    public zPlaneGizmo: NakerPlaneDragGizmo;
    public topGizmo:NakerAxisDragGizmo;

    public xBowGizmo: NakerBowDragGizmo;
    public yBowGizmo: NakerBowDragGizmo;
    public zBowGizmo: NakerBowDragGizmo;

    public scaleGizmo: NakerScaleDragGizmo;

    /** Fires an event when any of it's sub gizmos are dragged */
    public onDragStartObservable = new Observable();
    /** Fires an event when any of it's sub gizmos are released from dragging */
    public onDragEndObservable = new Observable();

    constructor (layer:UtilityLayerRenderer) {
      this.setColors();
      this.createNakerGizmo(layer);
      this.relayDragEvents();
    }

    _meshAttached:AbstractMesh;
    public get attachedMesh() {
      return this._meshAttached;
    }
    public set attachedMesh(mesh: Nullable<AbstractMesh>) {
      this._meshAttached = mesh;
      [this.xPlaneGizmo, this.yPlaneGizmo, this.zPlaneGizmo, this.topGizmo, this.xBowGizmo, this.yBowGizmo, this.zBowGizmo, this.scaleGizmo].forEach((gizmo) => {
        if (gizmo.isEnabled) {
            gizmo.attachedMesh = mesh;
        }
        else {
            gizmo.attachedMesh = null;
        }
      });
    }

    setColors () {
      this.xColor = Color3.FromHexString(colorGizmoX);
      this.yColor = Color3.FromHexString(colorGizmoY);
      this.zColor = Color3.FromHexString(colorGizmoZ);
      this.mainColor = Color3.FromHexString(colormain);
    }

    createNakerGizmo (layer:UtilityLayerRenderer) {
      let highlighter = new HighlightLayer("hl", layer.utilityLayerScene);
      // highlighter.innerGlow = false;
      highlighter.blurHorizontalSize = 0.00005;
      highlighter.blurVerticalSize = 0.00005;

      let glower = new GlowLayer("hl", layer.utilityLayerScene);
      glower.intensity = 0.2;

      this.setNakerPositionGizmo(layer, highlighter);
      this.setNakerRotationGizmo(layer, highlighter);
      this.scaleGizmo = new NakerScaleDragGizmo(new Vector3(0, 1, 0), this.mainColor, layer, glower);
    }

    setNakerPositionGizmo (layer:UtilityLayerRenderer, highlighter:HighlightLayer) {
      this.xPlaneGizmo = new NakerPlaneDragGizmo(new Vector3(1, 0, 0), this.xColor, layer, _system.scene, highlighter);
      this.yPlaneGizmo = new NakerPlaneDragGizmo(new Vector3(0, 1, 0), this.yColor, layer, _system.scene, highlighter);
      this.zPlaneGizmo = new NakerPlaneDragGizmo(new Vector3(0, 0, 1), this.zColor, layer, _system.scene, highlighter);
      let middleColor = this.zColor.add(this.xColor).multiply(new Color3(1/2, 1/2, 1/2));
      this.topGizmo = new NakerAxisDragGizmo(new Vector3(0, 1, 0), middleColor, layer, _system.scene, highlighter);
    }

    setNakerRotationGizmo (layer:UtilityLayerRenderer, highlighter:HighlightLayer) {
      this.xBowGizmo = new NakerBowDragGizmo(new Vector3(1, 0, 0), this.xColor, layer, highlighter);
      this.yBowGizmo = new NakerBowDragGizmo(new Vector3(0, 1, 0), this.yColor, layer, highlighter);
      this.zBowGizmo = new NakerBowDragGizmo(new Vector3(0, 0, 1), this.zColor, layer, highlighter);
    }

    relayDragEvents () {
      [this.xPlaneGizmo, this.yPlaneGizmo, this.zPlaneGizmo, this.topGizmo, this.xBowGizmo, this.yBowGizmo, this.zBowGizmo, this.scaleGizmo].forEach((gizmo) => {
          gizmo.dragBehavior.onDragStartObservable.add(() => {
              this.disableControlForDrag();
              gizmo.attachedMesh = this._meshAttached;
              this.onDragStartObservable.notifyObservers({});
          });
          gizmo.dragBehavior.onDragEndObservable.add(() => {
              this.enableControlAfterDrag();
              this.onDragEndObservable.notifyObservers({});
          });
      });
    }

    enableRotation (bool:boolean) {
      this.xBowGizmo.isEnabled = bool;
      this.yBowGizmo.isEnabled = bool;
      this.zBowGizmo.isEnabled = bool;
    }

    enablePosition (bool:boolean) {
      this.xPlaneGizmo.isEnabled = bool;
      this.yPlaneGizmo.isEnabled = bool;
      this.zPlaneGizmo.isEnabled = bool;
      this.topGizmo.isEnabled = bool;
    }

    enableScale (bool:boolean) {
      this.scaleGizmo.isEnabled = bool;
    }

    _isEnabled = false;
    public set enabled(value: boolean) {
        this._isEnabled = value;
        [this.xPlaneGizmo, this.yPlaneGizmo, this.zPlaneGizmo, this.topGizmo, this.xBowGizmo, this.yBowGizmo, this.zBowGizmo, this.scaleGizmo].forEach((gizmo) => {
            if (gizmo) {
                gizmo.isEnabled = value;
                if (value) {
                    gizmo.attachedMesh = this.attachedMesh;
                }
            }
        }, this);
    }

    public get enabled(): boolean {
        return this._isEnabled;
    }

    disableControlForDrag () {
      [this.xPlaneGizmo, this.yPlaneGizmo, this.zPlaneGizmo, this.topGizmo, this.xBowGizmo, this.yBowGizmo, this.zBowGizmo, this.scaleGizmo].forEach((gizmo) => {
          gizmo.attachedMesh = null;
      });
    }

    enableControlAfterDrag () {
      [this.xPlaneGizmo, this.yPlaneGizmo, this.zPlaneGizmo, this.topGizmo, this.xBowGizmo, this.yBowGizmo, this.zBowGizmo, this.scaleGizmo].forEach((gizmo) => {
          if (gizmo.isEnabled) gizmo.attachedMesh = this.attachedMesh;
      });
    }
}
