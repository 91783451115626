
import { coreSystem } from '../../tools/coreSystem';
import { meshMaterialClass } from './meshmaterial';
import { option3D, point3D } from '../../tools/interface';
import { util } from '../../tools/util';

import { Color3, Vector3, Matrix, Axis } from '@babylonjs/core/Maths/math'
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh';
import { PointLight } from '@babylonjs/core/Lights/pointLight';
import { DirectionalLight } from '@babylonjs/core/Lights/directionalLight';
import { SpotLight } from '@babylonjs/core/Lights/spotLight';
import '@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent';
import { ShadowGenerator } from '@babylonjs/core/Lights/Shadows/shadowGenerator';

export class light extends meshMaterialClass {

  light:PointLight|DirectionalLight|SpotLight;
  constructor (coreSystem:coreSystem, lightmode?:'point'|'directional'|'spot') {
		super(coreSystem, {fogEnabled:false, lightEnabled:false, waterEnabled:false, shadowEnabled:false});
		this.key = Math.random().toString(36).substring(7);
    let mesh = MeshBuilder.CreateSphere(this.key+"lightsphere", {}, this._system.scene);
    this.setMesh(mesh);
    this.setTransparentMaterial();
    mesh.isVisible = false;
    if (lightmode) this.setLightMode(lightmode);
    return this;
  }

  setLightMode (lightmode:'point'|'directional'|'spot') {
    this.removeShadowGenerator();
    if (this.light) this.light.dispose();
    if (lightmode == 'point') this.light = new PointLight(this.key, new Vector3(0, 0, 0), this._system.scene);
    if (lightmode == 'directional') this.light = new DirectionalLight(this.key, new Vector3(0, 0, 0), this._system.scene);
    if (lightmode == 'spot') this.light = new SpotLight(this.key, new Vector3(0, 0, 0), new Vector3(0, -1, 0), Math.PI / 2, 2, this._system.scene);
    this.light.parent = this.mesh;
    this.light.direction = new Vector3(0, 0, 0);
    this.light.setEnabled(true);
  }

  intensity:number = 0;
  setIntensity (int:number) {
    this.intensity = int;
    this.setPower();
    return this;
  }

  // Replace setOpacity to set light intensity
  opacity = 1;
  setOpacity (op:number) {
    if (!this.light) return this;
    this.opacity = op;
    this.setPower();
    return this;
  }

  rotation:point3D = {x:0, y:0, z:0};
  setAngleAxis (angles:option3D) {
    if (!this.light) return this;
    for (let axe in angles) {
      this.rotation[axe] = angles[axe] * util.degtoradratio;
      if (axe == 'x') this.rotation[axe] = -this.rotation[axe];
      this.mesh.rotation[axe] = this.rotation[axe];
    }
    this.light.direction = new Vector3(0, -1, 0);
    for (let axe in this.rotation) {
      var matrix = Matrix.RotationAxis(Axis[axe.toUpperCase()], this.rotation[axe]/2);
      var newDirection = Vector3.TransformCoordinates(this.light.direction, matrix);
      // Angle set in degrees in editor but used in radian in babylon
      this.light.direction = newDirection;
    }
    return this;
  }

  setColor (color:Array<number>) {
    if (color == undefined) color = [0, 0, 0];
    let babyloncolor = new Color3(color[0]/255, color[1]/255, color[2]/255);
    this.light.diffuse = babyloncolor;
    this.light.specular = babyloncolor;
    // Use for helper in editor
    this.setTextureColor('emissive', color);
  }

  setPower () {
    this.light.intensity = this.opacity * this.intensity;
    // Not used by Point light and doesn't change anything
    // this.light.shadowMinZ = 0;
    // this.light.shadowMaxZ = 100000000;
    // this.light.shadowFrustumSize = this.opacity * this.intensity;
  }

  shadowGenerator:ShadowGenerator;
  setCastShadow (castshadow:boolean) {
    if (castshadow) {
      this.addShadowGenerator();
    } else {
      this.removeShadowGenerator();
    }
  }

  addShadowGenerator () {
    if (this.shadowGenerator == undefined) {
      this.shadowGenerator = new ShadowGenerator(1024, this.light);
      this.shadowGenerator.useBlurExponentialShadowMap = true;
      this.shadowGenerator.depthScale = 2500;
      this.shadowGenerator.usePoissonSampling = true;
      this.shadowGenerator.useKernelBlur = true;
      this.shadowGenerator.blurKernel = 64;
      this.shadowGenerator.forceBackFacesOnly = true;
      this.shadowGenerator.frustumEdgeFalloff = 0.5;
      // Bias needed or shadow won't be precise
      this.shadowGenerator.bias = 0.000001;
      // this.shadowGenerator.useContactHardeningShadow = true;
      // this.shadowGenerator.contactHardeningLightSizeUVRatio = 0.05;
      for (let i = 0; i < this._system.shadowMeshRenderlist.length; i++) {
        this.shadowGenerator.addShadowCaster(this._system.shadowMeshRenderlist[i]);
      }
      this._system.shadowGenerators.push(this.shadowGenerator);
    }
  }

  renderList:Array<AbstractMesh> = [];
  removeShadowGenerator () {
    if (this.shadowGenerator != undefined) {
      this.renderList = this.shadowGenerator.getShadowMap().renderList;
      this.shadowGenerator.getShadowMap().renderList = [];
      this.shadowGenerator.dispose();
      let index = this._system.shadowGenerators.indexOf(this.shadowGenerator);
      if (index != -1) this._system.shadowGenerators.splice(index, 1);
      this.shadowGenerator = undefined;
    }
  }

  setShadowDarkness (darkness?:number) {
    if (this.shadowGenerator) {
      this.shadowGenerator.setDarkness(darkness);
    }
  }

  show () {
    if (this.light) this.light.setEnabled(true);
    this._show();
    return this;
  }

  hide () {
    if (this.light) this.light.setEnabled(false);
    this._hide();
    return this;
  }

  destroy () {
    this.mesh.dispose();
    this.light.dispose();
    return this;
  }
}
