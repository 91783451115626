
import { coreSystem } from '../../tools/coreSystem';
import { option3D } from '../../tools/interface';
import { util } from '../../tools/util';

import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { ActionManager } from '@babylonjs/core/Actions/actionManager';
import { ExecuteCodeAction } from '@babylonjs/core/Actions/directActions';

export class meshMovementClass {

  key:string;
  _system:coreSystem;

  mesh:Mesh;

  constructor (coreSystem:coreSystem) {
    this._system = coreSystem;
  }

  _setMesh (mesh:Mesh) {
    if (this.mesh) this.mesh.dispose();
    this.mesh = mesh;
    // Need to set other layer for edit pointofview preview mode
    this.mesh.layerMask = 0x0FFFFFFF|0x20000000;
    this.flat = false;
    // this.listenEvent(false);
  }

  setParent (parent:meshMovementClass) {
    this.mesh.parent = parent.mesh;
    return this;
  }

  unSetParent () {
    this.mesh.parent = undefined;
    return this;
  }

  setPosAxis (pos:option3D) {
    for (let axe in pos) {
      this.mesh.position[axe] = pos[axe];
    }
    return this;
  }

  getPosition () {
    let pos = this.mesh.position.clone();
    return {x:pos.x, y:pos.y, z:pos.z};
  }

  setAngleAxis (angles:option3D) {
    for (let axe in angles) {
      // Angle set in degrees in editor but used in radian in babylon
      this.mesh.rotation[axe] = angles[axe] * util.degtoradratio;
    }
    return this;
  }

  setScaleAxis (axis:option3D) {
    for (let axe in axis) {
      this.mesh.scaling[axe] = axis[axe];
    }
    return this;
  }

  setOpacity (op:number) {
    this.mesh.visibility = op;
    return this;
  }

	setIndex (index:number) {
		this.mesh.renderingGroupId = index;
		return this;
	}

  destroy () {
    this.mesh.dispose();
		return this;
  }

  show () {
    this.mesh.isVisible = true;
    return this;
  }

  hide () {
    this.mesh.isVisible = false;
    return this;
  }

  renameEvent = {
    click:'OnPickTrigger',
    down:'OnPickDownTrigger',
    up:'OnPickUpTrigger',
    enter:'OnPointerOverTrigger',
    leave:'OnPointerOutTrigger',
  };
  on (event:string, funct:Function) {
    if (this.mesh.actionManager == undefined) this.mesh.actionManager = new ActionManager(this._system.scene);
    this.listenEvent(true);
    let babylonevent = this.renameEvent[event];
    this.mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager[babylonevent], (evt) => {
      let newEvt:any = evt;
      if (evt.sourceEvent != undefined) newEvt.which = evt.sourceEvent.which;
      funct(newEvt);
    }));
    return this;
  }

  off () {
    if (this.mesh.actionManager) this.mesh.actionManager.actions = [];
  }

  listenEvent (bool:boolean) {
    if (bool && this.mesh.actionManager) this.mesh.isPickable = true;
    else this.mesh.isPickable = false;
  }

  forcelistenEvent (bool:boolean) {
    this.mesh.isPickable = bool;
  }

  // Make sur change is needed becasue very heavy calculation
  flat = false;
  setFlatMesh (flat:boolean) {
    if (flat && !this.flat) this.mesh.convertToFlatShadedMesh();
    else if (!flat && this.flat) this.mesh.forceSharedVertices();
    this.flat = flat;
  }
}
