import { Observer, Observable } from "@babylonjs/core/Misc/observable";
import { Nullable } from "@babylonjs/core/types";
import { PointerInfo } from "@babylonjs/core/Events/pointerEvents";
import { Vector3, Color3, Matrix } from "@babylonjs/core/Maths/math";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { CylinderBuilder } from "@babylonjs/core/Meshes/Builders/cylinderBuilder";
import { PointerDragBehavior } from "@babylonjs/core/Behaviors/Meshes/pointerDragBehavior";
import { _TimeToken } from "@babylonjs/core/Instrumentation/timeToken";
import { _DepthCullingState, _StencilState, _AlphaState } from "@babylonjs/core/States/index";
import { Gizmo } from "@babylonjs/core/Gizmos/gizmo";
import { UtilityLayerRenderer } from "@babylonjs/core/Rendering/utilityLayerRenderer";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Scene } from "@babylonjs/core/scene";
import { PositionGizmo } from "@babylonjs/core/Gizmos/positionGizmo";
import { Animation } from '@babylonjs/core/Animations/Animation';
import { HighlightLayer } from '@babylonjs/core/Layers/highlightLayer'
import { GridMaterial } from '@babylonjs/materials/grid/gridMaterial';
import { PlaneBuilder } from "@babylonjs/core/Meshes/Builders/planeBuilder";

/**
 * Single axis drag gizmo
 */
export class NakerAxisDragGizmo extends Gizmo {
    /**
     * Drag behavior responsible for the gizmos dragging interactions
     */
    public dragBehavior: PointerDragBehavior;
    private _pointerObserver: Nullable<Observer<PointerInfo>> = null;
    /**
     * Drag distance in babylon units that the gizmo will snap to when dragged (Default: 0)
     */
    public snapDistance = 0;
    /**
     * Event that fires each time the gizmo snaps to a new location.
     * * snapDistance is the the change in distance
     */
    public onSnapObservable = new Observable<{ snapDistance: number }>();

    private _isEnabled: boolean = true;
    private _parent: Nullable<PositionGizmo> = null;
    public _isDragged: boolean = false;

    private _arrow: TransformNode;
    private _secondArrow: Mesh;
    private _coloredMaterial: StandardMaterial;
    private _hoverMaterial: StandardMaterial;

    /** @hidden */
    public static _CreateArrow(scene: Scene, material: StandardMaterial, highlighter:HighlightLayer): TransformNode {
        var arrow = new TransformNode("arrow", scene);
        var cylinder = CylinderBuilder.CreateCylinder("cylinder", { diameterTop: 0, height: 0.1, diameterBottom: 0.04, tessellation: 96 }, scene);
        var line = CylinderBuilder.CreateCylinder("cylinder", { diameterTop: 0.01, height: 0.275, diameterBottom: 0.01, tessellation: 96 }, scene);
        line.material = material;
        cylinder.parent = arrow;
        line.parent = arrow;
        highlighter.addMesh(line, Color3.Black());

        // Position arrow pointing in its drag axis
        cylinder.material = material;
        cylinder.rotation.x = Math.PI / 2;
        cylinder.position.z += 0.3;
        line.position.z += 0.275 / 2;
        line.rotation.x = Math.PI / 2;

        arrow.scaling.z = 0.3;
        arrow.position.y = -0.04;
        arrow.position.z = -0.02;
        arrow.position.x = 0.02;
        return arrow;
    }

    /** @hidden */
    public static _CreateSecondArrow(scene: Scene, color: Color3): Mesh {
      //make sure plane is double sided
      var cylinder = CylinderBuilder.CreateCylinder("cylinder", { diameterTop: 0.1, height: 500, diameterBottom: 0.1, tessellation: 6 }, scene);
      let material = new StandardMaterial("groundMaterial", scene);
      material.emissiveColor = color;
      material.alpha = 0;
      material.maxSimultaneousLights = 0;
      cylinder.material = material;
      cylinder.isVisible = false;

      // Position plane pointing normal to secondPlane normal
      return cylinder;
    }

     /** @hidden */
     public static _CreateSecondPlane(scene: Scene, color: Color3): Mesh {
       //make sure plane is double sided
       var secondPlane = PlaneBuilder.CreatePlane("secondPlane", { width: .1, height: .1, sideOrientation: 2 }, scene);
       let material = new GridMaterial("groundMaterial", scene);

       material.lineColor = color;
       material.mainColor = color;
       material.gridRatio = 0.00007;
       material.gridOffset = new Vector3(0.00003, 0.00003, 0);
       // Force opacity != 1 to have no main color on grid material and let the plane2 visible
       material.opacity = 0;
       material.maxSimultaneousLights = 0;
       secondPlane.material = material;
       secondPlane.isVisible = false;

       // Position plane pointing normal to secondPlane normal
       return secondPlane;
     }

    /** @hidden */
    public static _CreateArrowInstance(scene: Scene, arrow: TransformNode): TransformNode {
        const instance = new TransformNode("arrow", scene);
        for (const mesh of arrow.getChildMeshes()) {
            const childInstance = (mesh as Mesh).createInstance(mesh.name);
            childInstance.parent = instance;
        }
        return instance;
    }

    /**
     * Creates an AxisDragGizmo
     * @param gizmoLayer The utility layer the gizmo will be added to
     * @param dragAxis The axis which the gizmo will be able to drag on
     * @param color The color of the gizmo
     */
    constructor(dragAxis: Vector3, color: Color3 = Color3.Gray(), gizmoLayer: UtilityLayerRenderer = UtilityLayerRenderer.DefaultUtilityLayer, scene:Scene, highlighter:HighlightLayer) {
        super(gizmoLayer);
        this.updateGizmoRotationToMatchAttachedMesh = false;
        // Create Material
        this._coloredMaterial = new StandardMaterial("", gizmoLayer.utilityLayerScene);
        this._coloredMaterial.emissiveColor = color;
        this._coloredMaterial.backFaceCulling = false;

        this._hoverMaterial = new StandardMaterial("", gizmoLayer.utilityLayerScene);
        this._hoverMaterial.diffuseColor = color.add(new Color3(0.3, 0.3, 0.3));

        // Build mesh on root node
        this._arrow = NakerAxisDragGizmo._CreateArrow(gizmoLayer.utilityLayerScene, this._coloredMaterial, highlighter);
        this._secondArrow = NakerAxisDragGizmo._CreateSecondPlane(scene, color);
        this._secondArrow.lookAt(this._rootMesh.position.add(dragAxis));
        this._secondArrow.scaling.scaleInPlace(5000);

        this._arrow.lookAt(this._rootMesh.position.add(dragAxis));
        this._arrow.scaling.scaleInPlace(1 / 3);
        this._arrow.parent = this._rootMesh;

        var currentSnapDragDistance = 0;
        var tmpVector = new Vector3();
        var tmpSnapEvent = { snapDistance: 0 };
        // Add drag behavior to handle events when the gizmo is dragged
        this.dragBehavior = new PointerDragBehavior({ dragAxis: dragAxis });
        this.dragBehavior.moveAttached = false;
        this._rootMesh.addBehavior(this.dragBehavior);

        var localDelta = new Vector3();
        var tmpMatrix = new Matrix();
        this.dragBehavior.onDragObservable.add((event) => {
            if (this.attachedMesh) {
                // Convert delta to local translation if it has a parent
                if (this.attachedMesh.parent) {
                    this.attachedMesh.parent.computeWorldMatrix().invertToRef(tmpMatrix);
                    tmpMatrix.setTranslationFromFloats(0, 0, 0);
                    Vector3.TransformCoordinatesToRef(event.delta, tmpMatrix, localDelta);
                } else {
                    localDelta.copyFrom(event.delta);
                }
                // Snapping logic
                if (this.snapDistance == 0) {
                    this.attachedMesh.position.addInPlace(localDelta);
                } else {
                    currentSnapDragDistance += event.dragDistance;
                    if (Math.abs(currentSnapDragDistance) > this.snapDistance) {
                        var dragSteps = Math.floor(Math.abs(currentSnapDragDistance) / this.snapDistance);
                        currentSnapDragDistance = currentSnapDragDistance % this.snapDistance;
                        localDelta.normalizeToRef(tmpVector);
                        tmpVector.scaleInPlace(this.snapDistance * dragSteps);
                        this.attachedMesh.position.addInPlace(tmpVector);
                        tmpSnapEvent.snapDistance = this.snapDistance * dragSteps;
                        this.onSnapObservable.notifyObservers(tmpSnapEvent);
                    }
                }
                this._secondArrow.position = this.attachedMesh.position.clone();
                this._secondArrow.position.y = this.attachedMesh.getBoundingInfo().boundingBox.minimumWorld.y;

            }
        });

        this._pointerObserver = gizmoLayer.utilityLayerScene.onPointerObservable.add((pointerInfo) => {
            if (this._customMeshSet || this._isDragged) {
                return;
            }
            var isHovered = pointerInfo.pickInfo && (this._rootMesh.getChildMeshes().indexOf(<Mesh>pointerInfo.pickInfo.pickedMesh) != -1);
            if ( isHovered ) {
              this._showHelper(scene);
              this._secondArrow.position = this._rootMesh.position.clone();
            } else {
              this._hideHelper(scene);
            }
        });

        this.dragBehavior.onDragStartObservable.add(() => {
          this._isDragged = true;
        });

        this.dragBehavior.onDragEndObservable.add(() => {
          this._isDragged = false;
        });
    }

    private _helperShown = false;
    private _maxOpacity = 0.5;
    protected _showHelper (scene:Scene) {
      if (this._helperShown) return;
      this._helperShown = true;

      this._secondArrow.isVisible = true;
      var animationPearl = new Animation("second_plane_anim", "material.opacity", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      var keys = [];
      keys.push({
        frame: 0,
        value: 0
      });
      keys.push({
        frame: 5,
        value: this._maxOpacity
      });
      animationPearl.setKeys(keys);
      this._secondArrow.animations = [];
      this._secondArrow.animations.push(animationPearl);
      scene.beginAnimation(this._secondArrow, 0, 5, false);
    }

    protected _hideHelper (scene:Scene) {
      if (!this._helperShown) return;
      this._helperShown = false;

      var animationPearl = new Animation("second_plane_anim", "material.opacity", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      var keys = [];
      keys.push({
        frame: 0,
        value: this._maxOpacity
      });
      keys.push({
        frame: 5,
        value: 0
      });
      animationPearl.setKeys(keys);
      this._secondArrow.animations = [];
      this._secondArrow.animations.push(animationPearl);
      scene.beginAnimation(this._secondArrow, 0, 5, false);
    }

    protected _attachedMeshChanged(value: Nullable<AbstractMesh>) {
        if (this.dragBehavior) {
            this.dragBehavior.enabled = value ? true : false;
        }
    }

    /**
     * If the gizmo is enabled
     */
    public set isEnabled(value: boolean) {
        this._isEnabled = value;
        if (!value) {
            this.attachedMesh = null;
        }
        else {
            if (this._parent) {
                this.attachedMesh = this._parent.attachedMesh;
            }
        }
    }
    public get isEnabled(): boolean {
        return this._isEnabled;
    }

    /**
     * Disposes of the gizmo
     */
    public dispose() {
        this.onSnapObservable.clear();
        this.gizmoLayer.utilityLayerScene.onPointerObservable.remove(this._pointerObserver);
        this.dragBehavior.detach();
        if (this._arrow)
        {
            this._arrow.dispose();
        }
        [this._coloredMaterial, this._hoverMaterial].forEach((matl) => {
            if (matl) {
                matl.dispose();
            }
        });
        super.dispose();
    }
}
