
import { size } from '../../tools/interface';
import { coreSystem } from '../../tools/coreSystem';
import { meshMaterialClass, materialOptions } from './meshmaterial';
import { meshMovementClass } from './meshmove';
import { pearlMesh } from './pearlMesh';

import { Vector3 } from '@babylonjs/core/Maths/math'
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';

export class shape extends meshMaterialClass {

  constructor (coreSystem:coreSystem, materialOptions?:materialOptions, shape?:'sphere'|'cube'|'plane'|'cylinder'|'disc'|'cone') {
		super(coreSystem, materialOptions);
		this.key = Math.random().toString(36).substring(7);
    if (shape) this.setShape(shape);
    return this;
  }

  setShape (shape:'sphere'|'cube'|'plane'|'cylinder'|'disc'|'cone'|'pearl') {
    let mesh:Mesh;
    if (mesh) {
    } else if (shape == 'sphere') {
      mesh = MeshBuilder.CreateSphere(this.key+"sphere", {}, this._system.scene);
    } else if (shape == 'cube') {
      mesh = MeshBuilder.CreateBox(this.key+"cube", {}, this._system.scene);
    } else if (shape == 'plane') {
      mesh = MeshBuilder.CreatePlane(this.key+"plane", {width:1, height:1}, this._system.scene);
    } else if (shape == 'cylinder') {
      mesh = MeshBuilder.CreateCylinder(this.key+"cylinder", {height:1, diameter:1, tessellation:32}, this._system.scene);
    } else if (shape == 'cone') {
      mesh = MeshBuilder.CreateCylinder(this.key+"cone", {diameterTop:0, height: 1, tessellation: 32}, this._system.scene);
    } else if (shape == 'disc') {
      mesh = Mesh.CreateDisc(this.key+"disc", 0.65, 32, this._system.scene);
    } else if (shape == 'pearl') {
      mesh = new pearlMesh(this._system.scene);
    }

    this.setMesh(mesh);
    this.mesh.isVisible = true;
    this.mesh.position = new Vector3(0, 0, 0);
  }
}

export class simpleShape extends meshMovementClass {

  constructor (system:coreSystem, shape:'sphere'|'cube'|'plane'|'cylinder'|'disc'|'cone'|'pearl', size:size) {
		super(system);
		this.key = Math.random().toString(36).substring(7);
    let mesh:Mesh;
		if (mesh) {
		} else if (shape == 'sphere') {
			mesh = MeshBuilder.CreateSphere(this.key+"sphere", {}, this._system.scene);
		} else if (shape == 'cube') {
			mesh = MeshBuilder.CreateBox(this.key+"cube", {}, this._system.scene);
		} else if (shape == 'plane') {
			mesh = MeshBuilder.CreatePlane(this.key+"plane", {width:1, height:1}, this._system.scene);
		} else if (shape == 'cylinder') {
			mesh = MeshBuilder.CreateCylinder(this.key+"cylinder", {height:1, diameter:1, tessellation:32}, this._system.scene);
		} else if (shape == 'cone') {
      mesh = MeshBuilder.CreateCylinder(this.key+"cone", {diameterTop:0, height: 1, tessellation: 32}, this._system.scene);
		} else if (shape == 'disc') {
			mesh = Mesh.CreateDisc(this.key+"disc", 0.65, 32, this._system.scene);
		} else if (shape == 'pearl') {
      mesh = new pearlMesh(this._system.scene);
    }

    this._setMesh(mesh);

    this.mesh.isVisible = true;
    this.mesh.position = new Vector3(0, 0, 0);
		this.setScaleAxis({x:size.width, y:size.height});
    return this;
  }
}
