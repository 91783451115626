
import { projectInterface } from '../service/projectInterface';
import { contentInterface } from './shared/content';
import { materialInterface, materialcontent } from './shared/contentmaterial';
import { coreSystem } from '../tools/coreSystem';

/*
  +------------------------------------------------------------------------+
  | MESH CONTENT                                                           |
  +------------------------------------------------------------------------+
*/

export interface shapeStyle {
  mesh:string;
  flat:boolean;
  material:materialInterface,
}

export let shapeInterface = {
  mesh:'m',
  flat:'f',
  material:{
    dim:'ma',
    next:projectInterface.material
  },
}

projectInterface.shape = shapeInterface;

export class shapecontent extends materialcontent {

  type = 'shape';
  flat = false;
  mesh = 'cube';

  constructor (coreSystem:coreSystem, content:contentInterface, shapeStyle:shapeStyle) {
    super(coreSystem, content, shapeStyle.mesh);
    this.setTypeParameter(shapeStyle);
  }

  setTypeParameter (shapeStyle:shapeStyle) {
    if (shapeStyle.material) this.setMaterial(shapeStyle.material);
    if (shapeStyle.mesh) this.setShape(shapeStyle.mesh);
    // Deleted because not very useful and make scene freeze
    // if (shapeStyle.flat !== undefined) this.setFlatMesh(shapeStyle.flat);
  }

  setShape (shape:string) {
    this.mesh = shape;
  }

  setFlatMesh (flat:boolean) {
    this.flat = flat;
    this.pattern.setFlatMesh(flat);
  }
}
