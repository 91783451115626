
import { ui_colorbutton } from '../inputs/pickers';
import { ui_checkbox } from '../inputs/input';
import { progressBarInterface } from '../../viewer/scenery/progressBar'
import { progressBar } from '../service/editintale';
import { virtualJoystick } from '../service/editintale';
import { floatingManager } from '../inputs/layer';
import { undoChannel } from '../service/undo';

undoChannel.subscribe("progressBarchange", (data, envelope) => {
  progressBarSettings.checkInput(data);
  progressBar.checkOption(data);
});

/*
  +------------------------------------------------------------------------+
  | PIPELINE MANAGER                                                    |
  +------------------------------------------------------------------------+
*/

class progressBarSettingsClass extends floatingManager {

    constructor () {
      super('Progress Bar');
      this.addInput();
    }

    color:ui_colorbutton;
    visible:ui_checkbox;

    addInput () {
      this.visible = this.addCheckBox('Visible', true, (value) => {
        progressBar.setVisibility(value);
        virtualJoystick.setVisibility(value);
      });

      this.color = this.addColorInput('Color', undefined, (value) => {
        progressBar.setColor(value);
        virtualJoystick.setColor(value);
      });
    }

    resetInput (progressBarOptions:progressBarInterface) {
      this.color.setValue(progressBarOptions.color);
      this.visible.setValue(progressBarOptions.visible);
    }

    checkInput (progressBarOptions:progressBarInterface) {
      if (progressBarOptions.color !== undefined) this.color.setValue(progressBarOptions.color);
      if (progressBarOptions.visible !== undefined) this.visible.setValue(progressBarOptions.visible);
    }
}

export let progressBarSettings = new progressBarSettingsClass();
