
import { contentMove } from './shared/contentmove';
import { projectInterface } from '../service/projectInterface';
import { contentInterface } from './shared/content';
import { coreSystem } from '../tools/coreSystem';
import { shape } from './patterns/shape';

import { Color3, Vector3 } from '@babylonjs/core/Maths/math'
import { ParticleSystem } from '@babylonjs/core/Particles/particleSystem'
import { GPUParticleSystem } from '@babylonjs/core/Particles/gpuParticleSystem'

/*
  +------------------------------------------------------------------------+
  | PARTICLE CONTENT                                                       |
  +------------------------------------------------------------------------+
*/

export interface particleStyle {
  color1:Array<number>;
  color2:Array<number>;
  particleTexture?:string;
  power?:number;
  particleSize?:number;
  life?:number;
  number?:number;
}

projectInterface.particle = {
  color1:'c1',
  color2:'c2',
  particleTexture:'pt',
  power:{dim:'pw', accuracy:3},
  particleSize:{dim:'ps', accuracy:3},
  life:{dim:'lf', accuracy:3},
  number:{dim:'n', accuracy:1},
}

export class particlecontent extends contentMove {

  type = 'particle';

  color1:Array<number>;
  color2:Array<number>;
  particleTexture:string;
  number:number = 100;
  particleSize:number = 1;
  life:number = 1;
  power:number = 1;
  pattern:shape;
  shadow = false;

  constructor (coreSystem:coreSystem, content:contentInterface, psStyle:particleStyle) {
    super(coreSystem, content, 'plane');
    this.pattern.setMaterialType('standard');
    this.pattern.setMaterialProperty('alpha', 0);
    this.setParticle();
    this.setTypeParameter(psStyle);
    this.particle.start();
    this.pattern.setOpacity(1);
  }

  setTypeParameter (psStyle:particleStyle) {
    if (psStyle.color1 !== undefined) this.setParticleColor1(psStyle.color1);
    if (psStyle.color2 !== undefined) this.setParticleColor2(psStyle.color2);
    if (psStyle.power !== undefined) this.setParticlePower(psStyle.power);
    if (psStyle.particleSize !== undefined) this.setParticleSize(psStyle.particleSize);
    if (psStyle.life !== undefined) this.setParticleLife(psStyle.life);
    if (psStyle.number !== undefined) this.setParticleNumber(psStyle.number);
    if (psStyle.particleTexture !== undefined) this.setParticleTexture(psStyle.particleTexture);
    else this.setParticleTexture('https://asset.naker.io/textures/particles/Flare.png');
  }

  maxnumber = 200;
  particle:any;
  setParticle () {
    // If webGl2 is supported, we have better performance with GPUParticleSystem
    if (this._system.engine.webGLVersion == 2) this.particle = new GPUParticleSystem("particles", { capacity:this.maxnumber }, this._system.scene);
    else this.particle = new ParticleSystem("particles", this.maxnumber, this._system.scene);
    this.particle.gravity = new Vector3(0,0,0);
    this.particle.minEmitBox = new Vector3(-0.5, -0.5, 0);
    this.particle.maxEmitBox = new Vector3(0.5, 0.5, 0);
    this.particle.direction1 = new Vector3(1, 1, 0);
    this.particle.direction2 = new Vector3(-1, -1, 0);
    this.particle.emitter = this.pattern.mesh;
  }

  setParticleColor1 (color:Array<number>) {
    this.color1 = color;
    let partcolor = (color)? color : [0, 0, 0];
    this.particle.color1 = new Color3(partcolor[0]/255, partcolor[1]/255, partcolor[2]/255);
  }

  setParticleColor2 (color:Array<number>) {
    this.color2 = color;
    let partcolor = (color)? color : [0, 0, 0];
    this.particle.color2 = new Color3(partcolor[0]/255, partcolor[1]/255, partcolor[2]/255);
  }

  setParticleTexture (url:string) {
    this.particleTexture = url;
    if (url == undefined) return this.particle.particleTexture = undefined;
    this._system.loader.getParticle(url, (asset) => {
      if (asset) {
        this.particle.particleTexture = asset.texture;
      } else {
        this.particle.particleTexture = undefined;
      }
    });
  }

  setParticlePower (power:number) {
    this.power = power;
    this.particle.minEmitPower = power;
    this.particle.maxEmitPower = power * 5;
  }

  setParticleSize (size:number) {
    this.particleSize = size;
    this.particle.minSize = size / 10;
    this.particle.maxSize = size / 2;
  }

  setParticleLife (life:number) {
    this.life = life;
    this.particle.minLifeTime = life / 10;
    this.particle.maxLifeTime = life * 3 / 10;
  }

  setParticleNumber (value:number) {
    value = Math.min(Math.round(value), this.maxnumber);
    this.number = value;
    this.particle.emitRate = value;
    this.particle.activeParticleCount = value;
  }

  hide () {
    this._hideMove();
    this.particle.stop();
    return this;
  }

  show () {
    this._showMove();
    this.particle.start();
    return this;
  }
}
