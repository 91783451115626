
import { system } from '../../tools/system';
import { projectInterface } from '../../service/projectInterface';
import { util } from '../../tools/util';
import { animation } from '../../tools/animation';
import { pipelineClass, pipelineInterface, pipelineJson, defaultPipelineValues } from '../../scenery/pipeline';

import WebFont from 'webfontloader';
import { Rectangle } from '@babylonjs/gui/2D/controls/rectangle';
import { Control } from '@babylonjs/gui/2D/controls/control';
import { TextBlock } from '@babylonjs/gui/2D/controls/textBlock';
import { Vector3 } from '@babylonjs/core/Maths/math';
import clone from 'lodash/clone';

/*
  +------------------------------------------------------------------------+
  | TEXT PATTERN                                                           |
  +------------------------------------------------------------------------+
*/

export let renameEvent = {
  click:'onPointerUpObservable',
  mousedown:'onPointerDownObservable',
  mouseup:'onPointerUpObservable',
  enter:'onPointerEnterObservable',
  leave:'onPointerOutObservable',
}

export interface textStyle {
  text?:string;
  title?:string;
  textColor:Array<number>;
  fontFamily?:string,
  pipeline:pipelineInterface,
}

projectInterface.text = {
  text:'tx',
  title:'tt',
  textColor:'tc',
  fontFamily:'ff',
  pipeline:{
    dim:'pip',
    next:pipelineJson
  },
}

let fontloaded = [];
export class text {

  _system:system;
  _pipeline:pipelineClass;

  text:string;
  title:string;
  textColor:Array<number>;
  fontFamily:string;
  pipeline:pipelineInterface = clone(defaultPipelineValues);

  texture:any;
  delta:any;
  shadow = false;
  anim:animation;
  shown = false;

  constructor (system:system, textStyle:textStyle, pipeline:pipelineClass) {
    this._system = system;
    this._pipeline = pipeline;

    this.setContainers();
    this.setCross();
    this.setTextNode();
    this.setEvents();
    this.setTypeParameter(textStyle);
    this.anim = new animation(this._system, 50);
  }

  setTypeParameter (textStyle:textStyle) {
    if (textStyle.text !== undefined) this.setText(textStyle.text);
    if (textStyle.title !== undefined) this.setTitle(textStyle.title);
    if (textStyle.textColor !== undefined) this.setTextColor(textStyle.textColor);
    if (textStyle.fontFamily !== undefined) this.setFontFamily(textStyle.fontFamily);
    this.setPipelineValues(textStyle.pipeline);
  }

  setPipelineValues (pipeline:pipelineInterface) {
    for (let key in pipeline) {
      this.pipeline[key] = pipeline[key];
    }
    this.pipeline.vignetteOffset = this._pipeline.maxVignetteRatio;
    if (this.present || this.shown) this._pipeline.checkOption(this.pipeline);
  }

  container:Rectangle;
  block:Rectangle;
  setContainers () {
    this.container = new Rectangle("");
    this.container.isPointerBlocker = true;
    this.container.hoverCursor = 'default';
    this.container.width = '100%';
    this.container.height = '100%';
    this.container.top = 0;
    this.container.left = 0;
    this.container.thickness = 0;

    this.block = new Rectangle();
    this.block.thickness = 0;
    // For test purpose
    // this.block.background = 'rgba(100, 100, 100, 0.5)';
    this.block.isHitTestVisible = false;
    this.container.addControl(this.block);

    this.line = new Rectangle();
    this.block.addControl(this.line);
  }

  crosscontainer:Rectangle;
  crossline1:Rectangle;
  crossline2:Rectangle;
  setCross () {
    this.crosscontainer = new Rectangle();
    this.crosscontainer.thickness = 0;
    this.container.addControl(this.crosscontainer);
    this.crosscontainer.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.crosscontainer.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.crosscontainer.hoverCursor = 'pointer';
    this.crosscontainer.isPointerBlocker = true;
    this.crosscontainer.zIndex = 10000;

    this.crossline1 = new Rectangle();
    this.crossline1.rotation = Math.PI/4;
    this.crossline1.thickness = 0;
    this.crosscontainer.addControl(this.crossline1);

    this.crossline2 = new Rectangle();
    this.crossline2.rotation = - Math.PI/4;
    this.crossline2.thickness = 0;
    this.crosscontainer.addControl(this.crossline2);
  }

  titlecontainer:Rectangle;
  titleblock:TextBlock;
  textblock:TextBlock;
  line:Rectangle;
  titlefont = 30;
  textfont = 20;
  setTextNode () {
    this.titlecontainer = new Rectangle();
    this.titlecontainer.isHitTestVisible = false;
    this.titlecontainer.width = '100%';
    this.titlecontainer.height = '200px';
    this.titlecontainer.thickness = 0;
    this.block.addControl(this.titlecontainer);
    this.titleblock = new TextBlock();
    this.titleblock.isHitTestVisible = false;
    this.titleblock.textVerticalAlignment	= Control.VERTICAL_ALIGNMENT_BOTTOM;
    this.titleblock.textHorizontalAlignment	= Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.titleblock.fontWeight = '600';
    this.titleblock.left = 0;
    this.titleblock.textWrapping = true;
    this.titlecontainer.addControl(this.titleblock);

    this.textblock = new TextBlock();
    this.textblock.isHitTestVisible = false;
    this.textblock.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.textblock.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.textblock.width = '100%';
    this.textblock.left = 0;
    this.textblock.top = 80;
    this.textblock.textWrapping = true;
    this.block.addControl(this.textblock);
  }

  setEvents () {
    this.container[renameEvent["click"]].add(() => {
      this.fadeOut();
    });
  }

  setTextColor (color:Array<number>) {
    this.textColor = color;
    if (color == undefined) color = [0, 0, 0, 1];
    let stringcolor = util.rgbaToString(color);
    this.container.color = stringcolor;
    this.line.color = stringcolor;
    this.line.background = stringcolor;
    this.crossline1.background = stringcolor;
    this.crossline2.background = stringcolor;
  }

  setTextFromDelta (delta:any) {
    let text = '';
    for (let i = 0; i < delta.ops.length; i++) {
      text += delta.ops[i].insert;
    }
    this.setText(text);
  }

  setText (text:string) {
    this.text = text;
    this.textblock.text = text;
  }

  setTitle (title:string) {
    this.title = title;
    this.titleblock.text = title;
  }

  setFontFamily (value:string) {
    this.fontFamily = value;
    if (fontloaded.indexOf(value) != -1) {
      this.textblock.fontFamily = value;
      this.titleblock.fontFamily = value;
      return;
    }
    fontloaded.push(value);
    WebFont.load({
      google: {families: [value]},
      fontactive: (familyName, fvd) => {
        this.textblock.fontFamily = value;
        this.titleblock.fontFamily = value;
      }
    });
  }

  target:Vector3;
  blockheight = 400;
  blockwidth = 500;
  checkResponsive (ratio:number, width:number, height:number) {
    // If portrait, text goes on bottom else left
    if (ratio > 0) {
      this.block.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      this.block.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    } else {
      this.block.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
      this.block.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    }

    // If small screen, less padding, smaller size and font
    if (width < this.blockwidth || height < this.blockheight) {
      if (ratio > 0) {
        this.setBlockSize(height, this.blockwidth / 1.5);
        this.setPadding(40);
      } else {
        this.setBlockSize(this.blockheight / 1.2, width);
        this.setPadding(20);
      }
      this.setTextScale(1/1.2);
      this.block.top = '0px';
    } else {
      if (ratio > 0) this.setBlockSize(this.blockheight * 1.2, this.blockwidth);
      else this.setBlockSize(this.blockheight, width);
      this.setPadding(40);
      this.setTextScale(1);
      // If big screen title line put in the middle
      if (height > 600 && ratio > 0) this.block.top = '100px';
      else this.block.top = '0px';
    }

    if (this.present) {
      this.setShowAnim(1);
      this.setCameraToHotspot(this.target);
    }
  }

  setPadding (padding:number) {
    this.block.paddingLeft = padding + 'px';
    this.block.paddingRight = padding + 'px';
    this.block.paddingTop = padding + 'px';
    this.block.paddingBottom = padding + 'px';
  }

  setBlockSize (height:number, width:number) {
    this.titlecontainer.top = 20 - height/2 + 'px';
    this.textblock.height = height - 125 + 'px';
    this.block.height = height + 'px';
    this.line.top = - (height/2 - 125).toString() + 'px';
    this.block.width = width + 'px';
  }

  setTextScale (textscale:number) {
    this.titleblock.fontSize = textscale * this.titlefont + 'px';
    this.textblock.fontSize = textscale * this.textfont + 'px';
    this.crosscontainer.height = textscale * 100 + 'px';
    this.crosscontainer.width = textscale * 100 + 'px';
    this.line.height = textscale * 2 + 'px';
    this.crossline1.height = textscale * 2 + 'px';
    this.crossline1.width = textscale * 30 + 'px';
    this.crossline2.height = textscale * 2 + 'px';
    this.crossline2.width = textscale * 30 + 'px';
  }

  formertarget:Vector3;
  camerachangetarget:Vector3;
  startpipeline:pipelineInterface;
  startShow (position:Vector3) {
    this.startpipeline = clone(this._pipeline.options);
    this.initVignetteAndDepth(position);
    this._system.sceneAdvancedTexture.addControl(this.container);
    this.getChangeTarget(position);
  }

  initVignetteAndDepth (position:Vector3) {
    let dist = Vector3.Distance(position, this._system.freecamera.position);
    this.pipeline.focalDistance = this.startpipeline.focalDistance = dist;
  }

  getChangeTarget (position:Vector3) {
    this.formertarget = this._system.freecamera.getFrontPosition(10);
    this.setCameraToHotspot(position);
    let newtarget = this._system.freecamera.getFrontPosition(10);
    this.camerachangetarget = newtarget.subtract(this.formertarget);
  }

  ratioVignettePixel = 200;
  setCameraToHotspot (position:Vector3) {
    this._system.freecamera.setTarget(position);
    this.getScreenRatio();
    let ratio = this.screenratio;
    if (ratio > 0) this._system.freecamera.rotation.addInPlace(new Vector3(0, -ratio * 0.5, 0));
    else this._system.freecamera.rotation.addInPlace(new Vector3(-ratio * 0.35, 0, 0));
  }

  screenratio = 1;
  getScreenRatio  () {
    let width = this._system.engine.getRenderWidth();
    let height = this._system.engine.getRenderHeight();
    this.screenratio = width / height - 1;
  }

  present = false;
  onShow:Function;
  fadeIn (position:Vector3) {
    this.target = position;
    if (this.present) return;
    if (this.onShow) this.onShow();
    this.startShow(position);
    this.anim.go((count, perc) => {
      let p = Math.pow(perc, 2);
      this.setShowAnim(p);
    }, () => {
      this.present = true;
      this.setShowAnim(1);
    });
  }

  onHide:Function;
  endHide (dontdocallback?:boolean) {
    this._system.sceneAdvancedTexture.removeControl(this.container);
    if (this.formertarget) this._system.freecamera.setTarget(this.formertarget);
    this.formertarget = null;
    if (this.onHide && !dontdocallback) this.onHide();
  }

  fadeOut () { // fast used when we leave live mode because we don't want the text animation
    if (!this.present) return;
    this.present = false;
    if (!this.formertarget) return this.endHide();
    this.anim.go((count, perc) => {
      let p = Math.pow((1 - perc), 2);
      this.setShowAnim(p);
    }, () => {
      this.setShowAnim(0);
      this.endHide();
    });
  }

  setShowAnim (p:number) {
    if (this.formertarget) {
      let temppos = this.camerachangetarget.multiply(new Vector3(p, p, p));
      temppos = this.formertarget.add(temppos);
      this._system.freecamera.setTarget(temppos);
    }
    this.container.alpha = p;
    this.line.width = (p * 100).toString() + '%';
    this._pipeline.setOptionProgress(this.startpipeline, this.pipeline, p);
  }

  show (position:Vector3) {
    this.shown = true;
    this.target = position;
    this._system.sceneAdvancedTexture.addControl(this.container);
    this.startShow(position);
    this.setShowAnim(1);
    return this;
  }

  hide () {
    this.shown = false;
    this.endHide();
    return this;
  }
}
