
import { modal } from './modal';
import { ui_container } from '../inputs/group';
import { ui_imagebutton, ui_button, ui_textinput } from '../inputs/input';
import { colorpicker, assetpicker } from '../inputs/pickers';
import { componentManager } from '../component/componentmanager';
import { api } from '../service/api';
import { editorLoadingScreen } from '../project/editorloadingscreen';
import { asset } from '../../viewer/tools/loader';
import { _system } from '../service/editintale';
import { globals } from '../service/globals';
import {undoBar} from '../project/bottombar';

import { setAttr, setChildren, unmount } from 'redom';
import axios from 'axios'
declare let MarvinImage;
declare let Marvin;

/*
  +------------------------------------------------------------------------+
  | BANKSEARCH                                                             |
  +------------------------------------------------------------------------+
*/

class bankSearchClass extends modal {

  apis = {
    claraio : {
      title:'Clara.io',
      description:'3D modeling software: Design, editor, animation, rendering, & lots of free 3D models',
      search:true,
      link:'https://clara.io/library',
      image:'https://asset.naker.io/image/banks/claraio.png',
      startquery:'https://clara.io/api/scenes?type=library&public=true',
      classicquery:'https://clara.io/api/scenes?type=library&public=true&query=',
    },
    sketchfab : {
      title:'Sketchfab',
      description:'Sketchfab is making it easy for anyone to publish, share, and discover 3D content',
      link:'https://sketchfab.com/feed',
      image:'https://asset.naker.io/image/banks/sketchfab.png',
      startquery:'https://api.sketchfab.com/v3/search?type=models&downloadable=true',
      classicquery:'https://api.sketchfab.com/v3/search?type=models&downloadable=true&q=',
    },
    poly : {
      title:'Google Poly',
      description:'Development in 3D made simple. Discover, view, download, and upload 3D assets for AR and VR',
      link:'https://poly.google.com/',
      image:'https://asset.naker.io/image/banks/poly.png',
      startquery:'https://poly.googleapis.com/v1/assets?key=AIzaSyDwXUFOSFMF44soDpKz2WbHKelntWtl9yU&format=GLTF2',
      classicquery:'https://poly.googleapis.com/v1/assets?key=AIzaSyDwXUFOSFMF44soDpKz2WbHKelntWtl9yU&format=GLTF2&keywords=',
    },
    giphy : {
      title:'GIPHY',
      description:'GIPHY is your top source for the best & newest GIFs & Animated Stickers online.',
      link:'https://giphy.com/',
      image:'https://asset.naker.io/image/banks/giphy.png',
      startquery:'https://api.giphy.com/v1/gifs/trending?limit=30&api_key=42GX9gl2USBMwBkuD0cBdxkyzxSvWvh6',
      classicquery:'https://api.giphy.com/v1/gifs/search?api_key=42GX9gl2USBMwBkuD0cBdxkyzxSvWvh6&q=',
    },
    unsplash : {
      title:'Unsplash',
      description:'Beautiful, free photos. Gifted by the world’s most generous community of photographers.',
      link:'https://unsplash.com/',
      image:'https://asset.naker.io/image/banks/unsplash.png',
      startquery:'https://api.unsplash.com/photos/curated?client_id=230082ffa9cfa890aa30d8732f2d111bcb6843e651c1336c025398aecefcf70c&per_page=30&order_by=popular',
      classicquery:'https://api.unsplash.com/search/photos?client_id=230082ffa9cfa890aa30d8732f2d111bcb6843e651c1336c025398aecefcf70c&per_page=30&query=',
    },
    // nounproject : {
    //   title:'Nounproject',
    //   description:'Icons for everything. Over a million curated icons, created by a global community.',
    //   link:'https://thenounproject.com/',
    //   image:'https://asset.naker.io/image/banks/nounproject.png',
    //   startquery:'https://naker-backend-prod.herokuapp.com/v1/thirdparty/nounproject/search?text=latest',
    //   classicquery:'https://naker-backend-prod.herokuapp.com/v1/thirdparty/nounproject/search?text=',
    // },
    // Turbosquid : {
    //   description:'Not available yet!',
    //   link:'https://www.turbosquid.com/',
    //   image:'disc',
    // },
    // Free3d : {
    //   description:'Not available yet!',
    //   link:'https://free3d.com/',
    //   image:'disc',
    // }
  }

  constructor () {
    super({width:585, height:400}, '', '');
    this.setContent();
    return this;
  }

  searchControl:ui_container;
  apiicon:ui_imagebutton;
  searchIcon:HTMLElement;
  searchInput:ui_textinput;
  searchcontainer:ui_container;
  setContent () {
    unmount(this.header, this.icon);
    this.searchControl = new ui_container(this.control, '');
    this.apiicon = this.searchControl.addImageButton('', 'api-icon');
    this.apiicon.on('click', () => {
      window.open(this.apis[this.currentSearch].link, '_blank');
    });

    this.searchIcon = this.searchControl.addIcon('search', 'api-search-icon');
    this.searchInput = this.searchControl.addInput("What are you looking for?", 'api-search-input');
    this.searchInput.on('input', (text) => {
      this.searchApi(text);
    });
    setAttr(this.searchInput.el, {value:'', placeholder:"What are you looking for?"});

    this.searchcontainer = this.searchControl.addContainer('result-container editor-scroll');
  }

  show (api:string) {
    this.showModal();
    this.showApi(api);
    this.searchInput.setValue('');
    this.searchInput.el.focus();
  }

  hide () {
    this.hideModal();
    colorpicker.hidePicker();
  }

  currentContent:any;
  currentSearch:string;
  showApi (api:string) {
    this.currentSearch = api;
    let apidetail = this.apis[api];
    this.deleteSearchResult();
    this.title.textContent = apidetail.title;
    this.description.textContent = apidetail.description;
    this.apiicon.setImage(apidetail.image);
    this['search'+this.currentSearch](apidetail.startquery, '');
  }

  searchTimeout:any;
  searchApi (text:string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      setAttr(this.searchIcon, {class:'api-search-icon icon-searching'});
      this['search'+this.currentSearch](this.apis[this.currentSearch].classicquery, text);
    }, 400);
  }

  buttons:Array<ui_button> = [];
  addSearchResult (image:string, callback:Function) {
    let button = this.searchcontainer.addImageButton(image, 'result-image');
    button.on('click', () => {
      callback();
      this.hide();
    });
    return button;
  }

  addSearchResultWithLink (image:string, text:string, linkurl:string, callback:Function) {
    let container = this.searchcontainer.addContainer('result-image-container');
    let button = container.addImageButton(image, 'result-image with-label');
    container.addLink(text, linkurl, 'result-label');
    button.on('click', () => {
      callback();
      this.hide();
    });
    return button;
  }

  /*
    +------------------------------------------------------------------------+
    | IMAGE                                                                  |
    +------------------------------------------------------------------------+
  */
  loadImage (url:string) {
    let type:asset["type"] = (assetpicker.waitingAsset == null)? 'image' : assetpicker.waitingAsset;
		undoBar.animateLoadingBar(97);
		undoBar.setText('Image is loading');
    if (assetpicker.waitingAsset == null) {
      assetpicker.waitingAsset = type;
      assetpicker.addWaitedAssetButton(url, url);
      this.addImage(url);
    } else {
      _system.loader.loadAsset(type, url, () => {
        assetpicker.waitingAsset = type;
				assetpicker.addWaitedAssetButton(url, url);
				undoBar.successLoad('Image successfully loaded!');
      });
      }
  }

  addImage (url:string) {
		let newImage = componentManager.addComponent('image', {scale:{x:2, y:2, z:1}, rotation:{x:0, y:0, z:0}, position:null, shadow:false}, {url:url});
		undoBar.successLoad('Image successfully loaded!');
    newImage.newComponent();
  }

  /*
    +------------------------------------------------------------------------+
    | VIDEO                                                                  |
    +------------------------------------------------------------------------+
  */
  loadVideo (url:string, assetimage:string) {
		undoBar.animateLoadingBar(95);
		undoBar.setText('Video is loading');
    if (assetpicker.waitingAsset == null) {
      assetpicker.waitingAsset = 'video';
      assetpicker.addWaitedAssetButton(url, assetimage);
      this.addVideo(url);
    } else{
      _system.loader.loadAsset('video', url, () => {
        assetpicker.waitingAsset = 'video';
				assetpicker.addWaitedAssetButton(url, assetimage);
				undoBar.successLoad('Video successfully loaded!');
      });
    }
  }

  addVideo (url:string) {
		let video = componentManager.addComponent('video', {scale:{x:2, y:2, z:1}, rotation:{x:0, y:0, z:0}, position:null}, {url:url});
		undoBar.successLoad('Video successfully loaded!');
    video.newComponent();
  }

  /*
    +------------------------------------------------------------------------+
    | MODEL                                                                  |
    +------------------------------------------------------------------------+
  */
  downloadModel (id:string, assetimage:string) {
		undoBar.animateLoadingBar(90);
		undoBar.setText('Model is loading');
    api.post('v1/model/import', {source:this.currentSearch.toLowerCase(), modelid:id, projectid:globals.projectid}, {}, (data) => {
      if (data.success) {
        this.loadModel(data.url, assetimage);
      } else {
				if (data.error)
					undoBar.errorLoad(data.error);
				else
					undoBar.errorLoad("This model can't be uploaded");
      }
    });
  }

  loadModel (url:string, modelname:string) {
    undoBar.animateLoadingBar(95);
    undoBar.setText('Model is loading');
    if (assetpicker.waitingAsset == null) {
      assetpicker.waitingAsset = 'model';
      assetpicker.addWaitedAssetButton(url, modelname);
      this.addModel(url);
    } else {
      _system.loader.loadAsset('model', url, (success) => {
        if (success) {
          assetpicker.waitingAsset = 'model';
					assetpicker.addWaitedAssetButton(url, modelname);
					undoBar.successLoad('Model successfully loaded!');
        }
      });
    }
  }

  /*
    +------------------------------------------------------------------------+
    | CUBETEXTURE                                                            |
    +------------------------------------------------------------------------+
  */
  loadCubeTexture (url:string, cubetexturename:string) {
    undoBar.animateLoadingBar(95);
    undoBar.setText('CubeTexture is loading');
      if (assetpicker.waitingAsset == null) {
        assetpicker.waitingAsset = 'cubetexture';
        assetpicker.addWaitedAssetButton(url, cubetexturename);
      } else{
        _system.loader.loadAsset('cubetexture', url, () => {
          assetpicker.waitingAsset = 'cubetexture';
          assetpicker.addWaitedAssetButton(url, cubetexturename);
          undoBar.successLoad('CubeTexture successfully loaded!');
        });
      }
  }

  /*
    +------------------------------------------------------------------------+
    | SOUND                                                                  |
    +------------------------------------------------------------------------+
  */
  loadSound (url:string, soundname:string) {
    undoBar.animateLoadingBar(95);
    undoBar.setText('Sound is loading');
      if (assetpicker.waitingAsset == null) {
        assetpicker.waitingAsset = 'sound';
        assetpicker.addWaitedAssetButton(url, soundname);
        this.addSound(url);
      } else{
        _system.loader.loadAsset('sound', url, () => {
          assetpicker.waitingAsset = 'sound';
          assetpicker.addWaitedAssetButton(url, soundname);
          undoBar.successLoad('Sound successfully loaded!');
        });
      }
  }

  addSound (url:string) {
    let sound = componentManager.addComponent('sound', {position:null}, {url:url});
    undoBar.successLoad('Sound successfully loaded!');
    sound.newComponent();
  }

  // NOTE First attemp to create model screenshot for the asset picker
  // createModelScreenshot (callback:Function) {
  //   let canvas:any = document.getElementById('canvasScreeshot');
  //   let engine = new Engine(canvas, true, { preserveDrawingBuffer: true, stencil: true }, true);
  //   engine.enableOfflineSupport = false;
  //   // engine.setHardwareScalingLevel(0.5);
  //   let scene = new Scene(engine);
  //   SceneLoader.Append("https://www.babylonjs.com/Assets/DamagedHelmet/glTF/", "DamagedHelmet.gltf", scene, (meshes) => {
  //       scene.createDefaultCameraOrLight(true, true, true);
  //       scene.createDefaultEnvironment();
  //       var helperCamera = scene.activeCamera;
  //       helperCamera.radius = 10;
  //       helperCamera.alpha = Math.PI / 4;
  //       helperCamera.beta = Math.PI / 4;
  //       Tools.CreateScreenshotUsingRenderTarget(engine, helperCamera, {width:200, height:200}, (image) => {
  //         scene.dispose();
  //         engine.dispose();
  //         callback(image);
  //       });
  //   });
  // }

  addModel (url:string) {
		let newModel = componentManager.addComponent('model', {scale:{x:1, y:1, z:1}, rotation:{x:0, y:0, z:0}, position:null}, {url:url});
		undoBar.successLoad('Model successfully loaded!');
    newModel.newComponent();
  }

  deleteSearchResult () {
    setAttr(this.searchIcon, {class:'api-search-icon icon-search'});
    setChildren(this.searchcontainer.el, []);
  }

  noResult () {
    this.deleteSearchResult();
    this.searchcontainer.addText('No results found 😿', 'no-result');
  }

  /*
    +------------------------------------------------------------------------+
    | GIPHY SEARCH                                                           |
    +------------------------------------------------------------------------+
  */
  searchgiphy (mainurl:string, text?:string) {
    axios.get(mainurl+text)
    .then((response) => {
      response = response.data;
      let res = response.data;
      this.showGiphyResult(res);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  showGiphyResult (results:any) {
    if (results.length == 0) return this.noResult();
    this.deleteSearchResult();
    for (let i = 0; i < results.length; i++) {
      let images = results[i].images;
      this.addSearchResult(images.downsized.url, () => {
        this.loadVideo(images.original.mp4, images.downsized.url);
      });
    }
  }

  /*
    +------------------------------------------------------------------------+
    | SKETCHFAB SEARCH                                                       |
    +------------------------------------------------------------------------+
  */
  searchsketchfab (mainurl:string, text?:string) {
    axios.get(mainurl+text)
    .then((response) => {
      let data:any = response.data;
      let res = data.results;
      this.showSketchfabResult(res);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  showSketchfabResult (results:any) {
    if (results.length == 0) return this.noResult();
    this.deleteSearchResult();
    for (let i = 0; i < results.length; i++) {
      let image = results[i].thumbnails.images[0].url;
      this.addSearchResult(image, () => {
        this.downloadModel(results[i].uid, image);
      });
    }
  }

  /*
    +------------------------------------------------------------------------+
    | CLARAIO SEARCH                                                         |
    +------------------------------------------------------------------------+
  */
  searchpoly (mainurl:string, text?:string) {
    axios.get(mainurl+text)
    .then((response) => {
      let data:any = response.data;
      if (Object.keys(data).length == 0) return this.noResult();
      let res = data.assets;
      this.showPolyResult(res);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  showPolyResult (results:any) {
    if (results.length == 0) return this.noResult();
    this.deleteSearchResult();
    for (let i = 0; i < results.length; i++) {
      let thumbnails = results[i].thumbnail.url;
      let formats = results[i].formats;
      let modelurl;
      for (let i = 0; i < formats.length; i++) {
        if (formats[i].formatType == 'OBJ' || formats[i].formatType == 'GLTF' || formats[i].formatType == 'GLTF2') modelurl = formats[i].root.url;
      }
      if (modelurl !== undefined) {
        this.addSearchResult(thumbnails, () => {
          this.loadModel(modelurl, thumbnails);
        });
      }
    }
  }

  /*
    +------------------------------------------------------------------------+
    | CLARAIO SEARCH                                                         |
    +------------------------------------------------------------------------+
  */
  searchclaraio (mainurl:string, text?:string) {
    axios.get(mainurl+text)
    .then((response) => {
      let data:any = response.data;
      let res = data.models;
      this.showClaraioResult(res);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  showClaraioResult (results:any) {
    if (results.length == 0) return this.noResult();
    this.deleteSearchResult();
    for (let i = 0; i < results.length; i++) {
      let hash;
      let thumbnails = results[i].thumbnails;
      for (let j = 0; j < thumbnails.length; j++) {
        if (thumbnails[j].thumbnailType == 'webgl') hash = thumbnails[j].hash;
      }
      let image = 'https://resources.clara.io/'+hash;
      if (hash !== undefined) this.addSearchResult(image, () => {
        this.downloadModel(results[i]._id, image);
      });
    }
  }

  /*
    +------------------------------------------------------------------------+
    | UNSPLASH SEARCH                                                        |
    +------------------------------------------------------------------------+
  */
  searchunsplash (mainurl:string, text?:string) {
    axios.get(mainurl+text)
    .then((response) => {
      let data:any = response.data;
      let res:any = (data.results !== undefined)? data.results : data;
      this.showUnsplashResult(res);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  showUnsplashResult (results:any) {
    if (results.length == 0) return this.noResult();
    this.deleteSearchResult();
    for (let i = 0; i < results.length; i++) {
      let id = results[i].id;
      let thumb = results[i].urls.thumb;
      let image = results[i].urls.regular;
      let photographer = results[i].user.name;
      let photographerlink = results[i].user.links.html;
      let button = this.addSearchResultWithLink(thumb, 'by '+photographer, photographerlink, () => {
        this.loadImage(image);
      });
      // Trigger click to be compliant with unsplash api and send them download info
      button.on('click', () => {
        let url = 'https://api.unsplash.com/photos/'+id+'/download?client_id=230082ffa9cfa890aa30d8732f2d111bcb6843e651c1336c025398aecefcf70c';
        axios.get(url)
        .then(() => {})
        .catch(() => {});
      });
    }
  }

  /*
    +------------------------------------------------------------------------+
    | NOUNPROJECT SEARCH                                                     |
    +------------------------------------------------------------------------+
  */
  // searchnounproject (mainurl:string, text?:string) {
  //   axios.get(mainurl+text)
  //   .then((response) => {
  //     this.showNounprojectResult(response.data);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
  // }

  // showNounprojectResult (results:any) {
  //   if (results.length == 0) return this.noResult();
  //   this.deleteSearchResult();
  //   for (let i = 0; i < results.length; i++) {
  //     let thumb = results[i].preview_url;
  //     let id = results[i].id;
  //     this.addSearchResult(thumb, () => {
  //       editorLoadingScreen.showLoader('Image is loading');
  //       axios.get('https://naker-backend-prod.herokuapp.com/v1/thirdparty/nounproject/icon?id='+id)
  //       // axios.get('http://localhost:3000/v1/thirdparty/nounproject/icon?id='+id)
  //       .then((response) => {
  //         let imageurl = response.data.url;
  //         try {
  //           let image = new MarvinImage();
  //           image.load(imageurl, (res) => {
  //             let imageOut = image.clone();
  //             Marvin.invertColors(image, imageOut);
  //             this.uploadFileToCloudinary('image', imageOut.toBlob(), (imageurl) => {
  //               this.loadImage(imageurl);
  //             });
  //           });
  //         } catch {
  //           editorLoadingScreen.errorLoader("Can't load Image");
  //         }
  //       })
  //       .catch((error) => {
  //         editorLoadingScreen.errorLoader('Oups, there was an error during loading.');
  //       });
  //     });
  //   }
  // }

  // uploadFileToCloudinary (ressource:string, file:string, callback:Function) {
  //   var url = `https://api.cloudinary.com/v1_1/naker-io/upload`;
  //   var fd = new FormData();
  //   fd.append("upload_preset", 'hdtmkzvn');
  //   fd.append("file", file);
  //   // fd.append("public_id", projectid);
  //   const config = {headers: { "X-Requested-With": "XMLHttpRequest" }};
  //   axios.post(url, fd, config)
  //   .then((res) => {
  //     let response = res.data;
  //     let url = response.secure_url;
  //     callback(url);
  //     let query = {
  //       designer:globals.designerid,
  //       project:globals.projectid,
  //       type:ressource,
  //       id:response.public_id,
  //       url:url,
  //       size:response.bytes
  //     };
  //     api.post( 'v1/project/addasset', query, {}, ()=>{});
  //   })
  //   .catch((err) => {
  //     console.error('err', err);
  //     undoBar.errorLoad(err);
  //   });
  // }
}

export let bankSearch = new bankSearchClass();
