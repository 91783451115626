
import { contentMove } from './shared/contentmove';
import { projectInterface } from '../service/projectInterface';
import { contentInterface } from './shared/content';
import { light } from './patterns/light';
import { coreSystem } from '../tools/coreSystem';

/*
  +------------------------------------------------------------------------+
  | LIGHT CONTENT                                                          |
  +------------------------------------------------------------------------+
*/

export interface lightStyle {
  color?:Array<number>;
  intensity?:number;
  mode?:'point'|'directional'|'spot';
  castshadow?:boolean;
  shadowdarkness?:number;
}

projectInterface.light = {
  color:'c',
  intensity:'it',
  mode:'mo',
  castshadow:'ch',
  shadowdarkness:'sd',
}

export class lightcontent extends contentMove {

  type = 'light';

  color:Array<number>;
  intensity:number;
  mode:'point'|'directional'|'spot';

  castshadow:boolean;
  shadowdarkness:number;
  shadow = false;
  pattern:light;

  constructor (coreSystem:coreSystem, content:contentInterface, lightStyle:lightStyle) {
    super(coreSystem, content, 'light');
    this.setTypeParameter(lightStyle);
    this.pattern.addGlow();
  }

  setTypeParameter (lightStyle:lightStyle) {
    if (lightStyle.mode !== undefined) this.setMode(lightStyle.mode);
    if (lightStyle.color !== undefined) this.setColor(lightStyle.color);
    if (lightStyle.intensity !== undefined) this.setIntensity(lightStyle.intensity);
    if (lightStyle.castshadow !== undefined) this.setCastShadow(lightStyle.castshadow);
    if (lightStyle.shadowdarkness !== undefined) this.setShadowDarkness(lightStyle.shadowdarkness);
  }

  setIntensity (intensity:number) {
    this.intensity = intensity;
    this.pattern.setIntensity(intensity);
  }

  setMode (mode:lightStyle["mode"]) {
    this.mode = mode;
    this.pattern.setLightMode(mode);
    this.pattern.setCastShadow(this.castshadow);
    this.pattern.setAngleAxis(this.rotation);
    this.pattern.setColor(this.color);
    this.pattern.setIntensity(this.intensity);
    this.setVisible(this.visible);
  }

  setColor (color:Array<number>) {
    this.color = color;
    this.pattern.setColor(color);
  }

  setCastShadow (castshadow:boolean) {
    this.castshadow = castshadow;
    this.pattern.setCastShadow(castshadow);
    this.pattern.setShadowDarkness(this.shadowdarkness);
  }

  setShadowDarkness (darkness?:number) {
    this.shadowdarkness = darkness;
    this.pattern.setShadowDarkness(darkness);
  }
}
