
import { modal } from './modal';
import { exporter } from '../service/exporter';
import { globals } from '../service/globals';
import { colorsuccess, colorerror, colorthirdgrey } from '../service/colors';

import { el, unmount, mount, setStyle } from 'redom';

/*
  +------------------------------------------------------------------------+
  | PUBLISH                                                             |
  +------------------------------------------------------------------------+
*/

class publishClass extends modal {

  publishing = false;
  publish:HTMLElement;
  url:HTMLElement;
  embed:HTMLElement;
  export:HTMLElement;
  sharezone:HTMLElement;

  constructor () {
    super({width:500, height:420}, 'publish', 'Publish my project', 'Dock your website in the harbor of naker');
    let container = el('div',
      [
        this.publish = el('div.siimple-btn.siimple-btn--primary.siimple-btn--fluid.publish-button.publish-button-common', {onclick:(evt) => {this.publishOnHarbor()}}, 'Publish/Update Intale'),
        el('div.publish-text', 'Click to copy sharing url'),
        this.url = el('div.copy-button.publish-button-common', {onclick:() => {this.copyUrl()}}),
        el('div.publish-text', 'Click to copy embed code'),
        this.embed = el('div.copy-button.copy-button2.publish-button-common', {onclick:() => {this.copyEmbed()}}),
        el('div.publish-text', 'Share'),
        this.sharezone = el('div.sharezone',
          [
            el('div.share-button.facebook-button.icon-facebook', {onclick:() => {this.shareFacebook()}}),
            el('div.share-button.twitter-button.icon-twitter', {onclick:() => {this.shareTwitter()}}),
            this.export = el('div.share-button.export-button', {onclick:() => {this.exportLocal()}}, 'export'),
          ]
        )
      ]
    );

    mount(this.control, container);
    if (globals.mode == 'app') unmount(this.sharezone, this.export);
  }

  publishOnHarbor () {
    if (this.publishing) return;
    this.publishing = true;
    this.publish.textContent = 'Publishing...';
    exporter.publish((saved) => {
      if (saved) {
        this.publish.textContent = 'Published!';
        setStyle(this.publish, {background:colorsuccess});
        if (globals.mode == 'app') window.Intercom('trackEvent', 'Publish Project');
      } else {
        this.publish.textContent = 'Error publishing';
        setStyle(this.publish, {background:colorerror});
      }
      setTimeout(() => {
        this.publishing = false;
        this.publish.textContent = 'Publish/Update';
        setStyle(this.publish, {background:colorthirdgrey});
      }, 2000);
    });
  }

  copyUrl () {
    this.copyToClipboard(this.url.textContent);
    this.url.textContent = 'Copied';
    setStyle(this.url, {background:colorsuccess});
    setTimeout(() => {
      setStyle(this.url, {background:colorthirdgrey});
      this.setTextToCopy();
    }, 1000);
  }

  copyEmbed () {
    this.copyToClipboard(this.embed.textContent);
    this.embed.textContent = 'Copied';
    setStyle(this.embed, {background:colorsuccess});
    setTimeout(() => {
      setStyle(this.embed, {background:colorthirdgrey});
      this.setTextToCopy();
    }, 1000);
  }

  copyToClipboard (text:string) {
    let el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  shareFacebook () {
    let url = encodeURIComponent('https://harbor.naker.io/'+globals.projectid+'/')
    window.open("https://www.facebook.com/sharer.php?u="+url);
  }

  shareTwitter () {
    let url = encodeURIComponent('https://harbor.naker.io/'+globals.projectid+'/')
    window.open("http://www.twitter.com/share?url="+url+"&text=See my new Intale made on Naker : ");
  }

  exportLocal () {
    exporter.local();
  }

  setTextToCopy () {
    let embed = '<div class="naker-embed-wrapper"><iframe src="https://harbor.naker.io/'+globals.projectid+'/" frameborder="0" width="640" height="480" allow="autoplay; fullscreen; vr" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe></div>';
    this.embed.textContent = embed;
    this.url.textContent = 'https://harbor.naker.io/'+globals.projectid+'/';
  }

  show () {
    this.setTextToCopy();
    this.showModal();
  }

  hide () {
    this.hideModal();
  }

}

export let publish = new publishClass();
