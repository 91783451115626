
import { projectInterface } from '../service/projectInterface';
import { contentInterface } from './shared/content';
import { materialInterface, materialcontent } from './shared/contentmaterial';
import { coreSystem } from '../tools/coreSystem';

import { Mesh } from '@babylonjs/core/Meshes/mesh';

/*
  +------------------------------------------------------------------------+
  | HEIGHTMAP CONTENT                                                      |
  +------------------------------------------------------------------------+
*/

export interface heightmapStyle {
  flat:boolean;
  heightmapurl:string;
  bumpheight:number;
  heightquality:number;
  material:materialInterface,
}

export let heightmapInterface = {
  flat:'f',
  heightmapurl:'hu',
  bumpheight:'bh',
  heightquality:'hq',
  material:{
    dim:'ma',
    next:projectInterface.material
  },
}

projectInterface.heightmap = heightmapInterface;

export class heightmapcontent extends materialcontent {

  type = 'heightmap';

  flat = false;
  heightmapurl:string;
  bumpheight = 1;
  heightquality = 100;

  constructor (coreSystem:coreSystem, content:contentInterface, heightmapStyle:heightmapStyle) {
    super(coreSystem, content, 'plane');
    this.setTypeParameter(heightmapStyle);
  }

  setTypeParameter (heightmapStyle:heightmapStyle) {
    if (heightmapStyle.flat !== undefined) this.setFlatMesh(heightmapStyle.flat);
    if (heightmapStyle.heightmapurl !== undefined) this.setHeightMap(heightmapStyle.heightmapurl);
    if (heightmapStyle.bumpheight !== undefined) this.setBumpHeight(heightmapStyle.bumpheight);
    if (heightmapStyle.heightquality !== undefined) this.setHeightQuality(heightmapStyle.heightquality);
    if (heightmapStyle.material) this.setMaterial(heightmapStyle.material);
  }

  setFlatMesh (flat:boolean) {
    this.flat = flat;
    this.pattern.setFlatMesh(flat);
  }

  heightMap:any;
  setHeightMap (heightmapurl:string) {
    this.heightmapurl = heightmapurl;
    if (!this.heightmapurl) this.heightmapurl = 'https://asset.naker.io/image/main/blacksquare.png';
    this.updateMesh();
    // Keep that to force asset loader to save heightmap
    this._system.loader.getHeightMap(this.heightmapurl, () => {});
  }

  setHeightQuality (heightquality:number) {
    this.heightquality = heightquality;
    this.updateMesh();
  }

  setBumpHeight (height:number) {
    this.bumpheight = height;
    this.setScale({y:height});
  }

  loadedurl:string;
  loadedquality:number;
  updateMesh () {
    if (this.loadedurl == this.heightmapurl && this.loadedquality == this.heightquality) return;
    if (this.heightmapurl === undefined || this.heightquality === undefined) return;
    this.loadedurl = this.heightmapurl;
    this.loadedquality = this.heightquality;
    Mesh.CreateGroundFromHeightMap(this.id+"height", this.heightmapurl, 1, 1, this.heightquality, 0, 1, this._system.scene, false, (mesh) => {
      this.pattern.setMesh(mesh);
      if (this.shown) this.show();
      else this.hide();
      this.setFlatMesh(this.flat);
    });
  }
}
