
import { Scene } from '@babylonjs/core/scene'

import '@babylonjs/loaders';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { Sound } from '@babylonjs/core/Audio/sound';
import { VideoTexture } from '@babylonjs/core/Materials/Textures/videoTexture';
import { AssetsManager } from '@babylonjs/core/Misc/assetsManager';
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import '@babylonjs/core/Misc/dds';
import '@babylonjs/core/Materials/Textures/Loaders/ddsTextureLoader';
import '@babylonjs/core/Materials/Textures/Loaders/envTextureLoader';

export interface asset {
  type:'image'|'particle'|'albedo'|'ambient'|'specular'|'emissive'|'reflectivity'|'reflection'|'refraction'|'heightmap'|'cubetexture'|'bump'|'opacity'|'model'|'video'|'sound',
  url:string
};

export class loaderClass {

  _scene:Scene;

  particle:any = {};
  albedo:any = {};
  ambient:any = {};
  specular:any = {};
  emissive:any = {};
  bump:any = {};
  opacity:any = {};
  reflection:any = {};
  reflectivity:any = {};
  heightmap:any = {};
  cubetexture:any = {};
  image:any = {};
  video:any = {};
  model:any = {};
  sound:any = {};

  loadwaiting:any;
  onSuccessLoad:Function;
  onErrorLoad:Function;
  manager:AssetsManager;
  firstload = true;

  constructor (scene:Scene) {
    this._scene = scene;
    this.resetLoadWaiting();
  }

  createManager () {
    this.manager = new AssetsManager(this._scene);
    this.manager.useDefaultLoadingScreen = false;
  }

  textures = ['image', 'particle', 'albedo', 'ambient', 'specular', 'emissive', 'reflection', 'reflectivity', 'bump', 'opacity'];
  successes:any = {};

  getAsset (type:asset["type"], url:string, callback:Function) {
    if (this[type][url] !== undefined) {
      // Animation can't be duplicated so we have to download model everytime (See modelSuccessWithAnimation function)
      if (type == 'model' && this[type][url] == 'animated') {
        this.loadAsset('model', url, callback);
      } else if (type == 'model') {
        let newModelParents = this.getClonedParentModel(this.model[url]);
        callback(newModelParents);
      } else {
        callback(this[type][url]);
      }
    } else {
      this.loadAsset(type, url, callback);
    }
  }

  loadAsset (type:asset["type"], url:string, callback:Function) {
    if (url.length == 0) return;
    let assettype = (this.textures.indexOf(type) != -1)? 'image' : type;
    // only textures can have the same success callback, otherwise heightmap and images use the same success with different object in the callback
    let name = assettype+url;
    if (this.successes[name] !== undefined) return this.successes[name].push(callback);
    this.successes[name] = [];
    this.successes[name].push(callback);
    this.loadwaiting[type]++;
    let task:any;
    if (type == 'model') {
      task = this.getModelTask(url, name);
    } else if (this.textures.indexOf(type) != -1) {
      task = this.manager.addTextureTask(name, url);
    } else if (type == 'video') {
      task = this.getVideoTask(url, name);
    } else if (type == 'heightmap') {
      task = this.getHeightMapTask(url, name);
    } else if (type == 'cubetexture') {
      task = this.getCubeTextureTask(url, name);
    } else if (type == 'sound') {
      task = this.getSoundTask(url, name);
    }

    // this.getFileSize(type, url);
    task.onSuccess = (task) => {
      this.success(type, url, name, task);
    }

    task.onError = (error) => {
      this.error(type, name, error);
    }

    if (!this.firstload) {
      // In case assets are loaded just after scene otherwise load won't launch
      setTimeout(() => {
        this.manager.load();
      }, 10);
    }
    return task;
  }

  getModelTask (url:string, name:string) {
    let modelpath = this.getModelPath(url);
    return this.manager.addMeshTask(name, "", modelpath.folder, modelpath.file);
  }

  getVideoTask (url:string, name:string) {
    let texture = new VideoTexture(name, [url], this._scene, true);
    let video = texture.video;
    let task = {onSuccess:null, onError:null};
    video.addEventListener('error', (evt) => {
      task.onError({errorObject:{message:"Error loading file " + url}});
    }, true);
    video.addEventListener('loadeddata', (evt) => {
      task.onSuccess({texture: texture});
    });
    return task;
  }

  getHeightMapTask (url:string, name:string) {
    let task = {onSuccess:null, onError:null};
    Mesh.CreateGroundFromHeightMap(name+"height", url, 1, 1, 100, 0, 1, this._scene, false, (mesh) => {
      mesh.isVisible = false;
      // Didn't find a way to trigger file load error
      // if (!task.onSuccess) {
      //   task.onError({errorObject:{message:"Error loading height image"}});
      //   return;
      // }
      task.onSuccess(mesh);
    });
    return task;
  }

  getCubeTextureTask (url:string, name:string) {
    if (url.indexOf('.hdr') != -1) return this.manager.addHDRCubeTextureTask(name, url, 512);
    else return this.manager.addCubeTextureTask(name, url);
  }

  getSoundTask (url:string, name:string) {
    let task = {onSuccess:null, onError:null};
    let music = new Sound(name, url, this._scene, () => {
      // Sound has been downloaded & decoded
      task.onSuccess(music);
    });
    return task;
  }

  getFileSize (type, url) {
    var fileSize = '';
    var http = new XMLHttpRequest();
    http.open('HEAD', url, true); // true = Asynchronous
    http.onreadystatechange = function () {
      if (this.readyState == this.DONE) {
        if (this.status === 200) {
          fileSize = this.getResponseHeader('Content-Length');
          console.log(type+' '+url+' = ' + fileSize);
          // ok here is the only place in the code where we have our request result and file size ...
          // the problem is that here we are in the middle of anonymous function nested into another function and it does not look pretty
          // this stupid ASYNC pattern makes me hate Javascript even more than I already hate it :)
        }
      }
    };
    http.send(); // it will submit request and jump to the next line immediately, without even waiting for request result b/c we used ASYNC XHR call
    return ('At this moment, we do not even have Request Results b/c we used ASYNC call to follow with stupid JavaScript pattern');
  }

  success (type:string, url:string, name:string, task) {
    try {
        // Models use different success function to handle animations
        if (type == 'model') {
          this.modelSucces(url, name, task);
        } else {
          this[type][url] = task;
          for (let i = 0; i < this.successes[name].length; i++) {
            this.successes[name][i](task);
          }
          if (this.onSuccessLoad) this.onSuccessLoad(type, url);
          // Must delete success url or loading the same file later will not work
        }
        delete this.successes[name];
    } catch (e) {
      console.log(e)
    }
  }

  error (type:string, name:string, error) {
    console.log(error)
    // console.log('error', type, url)
    // console.log(error.errorObject.exception.message)
    for (let i = 0; i < this.successes[name].length; i++) {
      this.successes[name][i](false);
    }

    if (this.onErrorLoad) this.onErrorLoad(type, error.errorObject.message);
    // Must delete success url or loading the same file later will not work
    delete this.successes[name];
    this.loadwaiting[type]--;
  }

  getModelPath (url:string) {
    let path = {file:'', folder:''};
    let urlsplit = url.split('/');
    path.file = urlsplit.pop();
    path.folder = urlsplit.join('/')+'/';
    return path;
  }

  modelSucces (url:string, name:string, task) {
    let animations = task.loadedAnimationGroups;
    if (animations.length != 0) this.modelSuccessWithAnimation(url, name, task)
    else this.modelSuccessWithoutAnimation(url, name, task)
  }

  // When model have animation we can't clone it and keep animation attach to meshes
  // So we reload it in order to make sure every model as its own animations
  // See topic here: https://forum.babylonjs.com/t/how-to-clone-a-glb-model-and-play-seperate-animation-on-each-clone/2351/10
  modelSuccessWithAnimation (url:string, name:string, task) {
    this.model[url] = 'animated';
    let modelparents = this.getModelParents(task.loadedMeshes);
    this.successes[name][0](modelparents, task.loadedAnimationGroups);
    if (this.successes[name].length == 1) return;
    let modelpath = this.getModelPath(url);
    // Animation can't be duplicated so we have to download model everytime (See getAsset function)
    for (let i = 1; i < this.successes[name].length; i++) {
      let callback = this.successes[name][i];
      SceneLoader.ImportMesh(null, modelpath.folder, modelpath.file, this._scene, (meshes, particleSystems, skeletons, animationGroups) => {
        let modelparents = this.getModelParents(meshes);
        callback(modelparents, animationGroups);
      });
    }
  }

  modelSuccessWithoutAnimation (url:string, name:string, task) {
    let meshes = task.loadedMeshes;
    if (meshes == undefined) return console.warn('Missing meshes in model file');
    for (let i = 0; i < meshes.length; i++) {
      meshes[i].isVisible = false;
    }
    // If no animation we save meshes for easy and fast model duplication
    let modelparents = this.getModelParents(meshes);
    this.model[url] = modelparents;
    for (let i = 0; i < this.successes[name].length; i++) {
      let newModelParents = this.getClonedParentModel(modelparents);
      this.successes[name][i](newModelParents);
    }
  }

  getModelParents (meshes:Array<Mesh>) {
    let mainParentListId:Array<string> = [];
    let mainParentList:Array<any> = [];
    for (let i = 0; i < meshes.length; i++) {
      let mesh = meshes[i];
      let rootParent = this.getMeshRootParent(mesh);
      if (mainParentListId.indexOf(rootParent.id) == -1) {
        mainParentListId.push(rootParent.id);
        mainParentList.push(rootParent);
      }
    }
    return mainParentList;
  }

  getClonedParentModel (modelparents:Array<Mesh>) {
    let newModelParents:Array<Mesh> = [];
    for (let i = 0; i < modelparents.length; i++) {
      // Clone meshes on order to have a new model
      newModelParents.push(modelparents[i].clone());
    }
    return newModelParents;
  }

  getMeshRootParent (mesh:Mesh) {
    // let previousMesh = mesh;
    // Get the last parent
    while (mesh.parent) {
      // previousMesh = mesh;
      mesh = mesh.parent;
    }
    // If last parent is babylon __root__, we use it
    // __root__ are mainly created by babylon for gltf model
    // if (mesh.id == '__root__') return previousMesh;
    // else return mesh;
    return mesh;
  }

  resetLoadWaiting () {
    this.loadwaiting = {
      particle:0,
      albedo:0,
      ambient:0,
      specular:0,
      emissive:0,
      bump:0,
      opacity:0,
      reflection:0,
      refraction:0,
      reflectivity:0,
      cubetexture:0,
      heightmap:0,
      image:0,
      video:0,
      model:0,
      sound:0,
      upload:0,
    };
  }

  getParticle (url:string, callback:Function) {
    this.getAsset('particle', url, callback);
  }

  getAlbedo (url:string, callback:Function) {
    this.getAsset('albedo', url, callback);
  }

  getAmbient (url:string, callback:Function) {
    this.getAsset('ambient', url, callback);
  }

  getSpecular (url:string, callback:Function) {
    this.getAsset('specular', url, callback);
  }

  getEmissive (url:string, callback:Function) {
    this.getAsset('emissive', url, callback);
  }

  getBump (url:string, callback:Function) {
    this.getAsset('bump', url, callback);
  }

  getOpacity (url:string, callback:Function) {
    this.getAsset('opacity', url, callback);
  }

  getReflection (url:string, callback:Function) {
    this.getAsset('reflection', url, callback);
  }

  getRefraction (url:string, callback:Function) {
    this.getAsset('reflection', url, callback);
  }

  getReflectivity (url:string, callback:Function) {
    this.getAsset('reflectivity', url, callback);
  }

  getCubeTexture (url:string, callback:Function) {
    this.getAsset('cubetexture', url, callback);
  }

  getHeightMap (url:string, callback:Function) {
    this.getAsset('heightmap', url, callback);
  }

  getModel (url:string, callback:Function) {
    this.getAsset('model', url, callback);
  }

  getImage (url:string, callback:Function) {
    this.getAsset('image', url, callback);
  }

  getVideo (url:string, callback:Function) {
    this.getAsset('video', url, callback);
  }

  getSound (url:string, callback:Function) {
    this.getAsset('sound', url, callback);
  }
}
