
import { projectInterface } from '../service/projectInterface';
import { contentInterface } from './shared/content';
import { animationInterface } from './shared/contentmove';
import { modelparent } from './patterns/modelparent';
import { materialInterface, materialcontent } from './shared/contentmaterial';
import { coreSystem } from '../tools/coreSystem';

import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { AnimationGroup } from '@babylonjs/core/Animations/animationGroup';

import find from 'lodash/find';

/*
  +------------------------------------------------------------------------+
  | MODEL CONTENT                                                          |
  +------------------------------------------------------------------------+
*/

export interface modelStyle {
  url:string;
  material:materialInterface,
}

projectInterface.model = {
  url:'u',
  material:{
    dim:'ma',
    next:projectInterface.material
  },
}

export class modelcontent extends materialcontent {

  type = 'model';

  url:string;
  pattern:modelparent;

  constructor (coreSystem:coreSystem, content:contentInterface, modelStyle:modelStyle) {
    super(coreSystem, content, 'model');
    this.setTypeParameter(modelStyle);
  }

  setTypeParameter (modelStyle:modelStyle) {
    if (modelStyle.url !== undefined) this.loadModel(modelStyle.url);
    if (modelStyle.material) this.setMaterial(modelStyle.material);
  }

  loadModel (url:string, callback?:Function) {
    this.url = url;
    this._system.loader.getModel(url, (meshes:Array<Mesh>, modelAnimations?:Array<AnimationGroup>) => {
      if (meshes) {
        this.setModel(meshes);
        if (modelAnimations) this.setModelAnimations(modelAnimations);
        this.setShadow(this.shadow);
        this.addAnimations(this.animations);
        if (this.ingroup) this.pattern.setParent(this.groupcontent.pattern);
        if (this.material) this.setMaterial(this.material);
        if (this.shown) this.show();
        else this.hide();
      } else {
        this.pattern.destroyMeshes();
      }
      if (callback) callback();
    });
  }

  setModel (meshes:Array<Mesh>) {
    this.pattern.setMeshes(meshes);
  }

  setModelAnimations (animations:Array<AnimationGroup>) {
    this.modelAnimations = animations;
    for (let i = 0; i < this.modelAnimations.length; i++) {
      this.modelAnimationsName.push(this.modelAnimations[i].name);
    }
    this.stopAllModelAnimations();
  }

  resetGeometry () {
    this.stopAllModelAnimations();
    this._resetGeometry();
  }

  goToFirstFrame (animation:animationInterface) {
    let anim = animation.modelanim;
    anim.goToFrame(anim.from);
  }

  goToLastFrame (animation:animationInterface) {
    let anim = animation.modelanim;
    anim.goToFrame(anim.to);
  }

  goToFramePercentage (animation:animationInterface, perc:number) {
    let anim = animation.modelanim;
    let frame = (anim.to - anim.from) * perc;
    anim.goToFrame(frame);
  }

  playModelAnimation (animation:animationInterface, loop?:boolean) {
    let anim = animation.modelanim;
    anim.start(loop);
  }

  pauseModelAnimation (animation:animationInterface) {
    let anim = animation.modelanim;
    anim.pause();
  }

  stopModelAnimation (animation:animationInterface) {
    let anim = animation.modelanim;
    anim.stop();
  }

  stopAllModelAnimations () {
    for (let i = 0; i < this.modelAnimations.length; i++) {
      this.modelAnimations[i].goToFrame(0);
      this.modelAnimations[i].stop();
    }
  }

  animateWay (contentAnimation:animationInterface) {
    if (contentAnimation.model && this.modelAnimations.length == 0) return;
    if (!contentAnimation.model) return this._animateWay(contentAnimation);
    let anim = find(this.modelAnimations, (animation) => {return animation.name == contentAnimation.model});
    if (!anim) return;
    contentAnimation.modelanim = anim;
    if (contentAnimation.way == 'once') {
      this.playModelAnimation(contentAnimation)
    } else if (contentAnimation.way == 'alternate') {
      this.playModelAnimation(contentAnimation)
    } else if (contentAnimation.way == 'loop') {
      this.playModelAnimation(contentAnimation, true)
    }
    contentAnimation.timeout = setTimeout(() => {
      this.stopModelAnimation(contentAnimation);
    }, contentAnimation.duration * 1000);
  }

  animateScroll (contentAnimation:animationInterface, top:number) {
    if (!contentAnimation.model) return this._animateScroll(contentAnimation, top);
    if (!contentAnimation.modelanim) {
      let anim = find(this.modelAnimations, (animation) => {return animation.name == contentAnimation.model});
      if (!anim) return;
      contentAnimation.modelanim = anim;
    }
    if (top <= contentAnimation.delay) {
      if (contentAnimation.started) {
        contentAnimation.launched = false;
        contentAnimation.finished = false;
        contentAnimation.started = false;
        this.goToFirstFrame(contentAnimation);
        this.stopModelAnimation(contentAnimation);
      }
    } else if (top > contentAnimation.delay + contentAnimation.duration) {
      if (!contentAnimation.finished) {
        contentAnimation.launched = false;
        contentAnimation.started = true;
        contentAnimation.finished = true;
        this.goToLastFrame(contentAnimation);
        this.stopModelAnimation(contentAnimation);
      }
    } else {
      let loop = (contentAnimation.way == 'loop')? true : false;
      if (!contentAnimation.launched) {
        if (loop) this.playModelAnimation(contentAnimation, loop);
        else {
          this.playModelAnimation(contentAnimation, loop);
          this.pauseModelAnimation(contentAnimation);
        }
      }
      let perc = (top - contentAnimation.delay)/contentAnimation.duration;
      if (!loop) this.goToFramePercentage(contentAnimation, perc);
      contentAnimation.launched = true;
      contentAnimation.started = true;
      contentAnimation.finished = false;
    }
  }

  // animateMouse () {
  //
  // }
}
