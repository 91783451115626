import { Effect } from "../../Materials/effect";

let name = 'clipPlaneVertex';
let shader = `#ifdef CLIPPLANE
fClipDistance=dot(worldPos,vClipPlane);
#endif
#ifdef CLIPPLANE2
fClipDistance2=dot(worldPos,vClipPlane2);
#endif
#ifdef CLIPPLANE3
fClipDistance3=dot(worldPos,vClipPlane3);
#endif
#ifdef CLIPPLANE4
fClipDistance4=dot(worldPos,vClipPlane4);
#endif`;

Effect.IncludesShadersStore[name] = shader;
/** @hidden */
export var clipPlaneVertex = { name, shader };
