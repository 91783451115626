
import { coreSystem } from './coreSystem';

// To Enable Picking
import '@babylonjs/core/Culling/ray';
import { Vector3, Frustum } from '@babylonjs/core/Maths/math';
import { FreeCamera } from '@babylonjs/core/Cameras/freeCamera';
import { VRDeviceOrientationFreeCamera } from '@babylonjs/core/Cameras/VR/vrDeviceOrientationFreeCamera';
import { AdvancedDynamicTexture } from '@babylonjs/gui/2D/AdvancedDynamicTexture';
import '@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent';
import { setStyle } from 'redom';
import 'pepjs';

export class system extends coreSystem {

  ///////// MAIN BABYLON OBJECT
  maxScaling = 1;
  vrcamera:VRDeviceOrientationFreeCamera;
  guicamera:FreeCamera;
  guivrcamera:VRDeviceOrientationFreeCamera;

  ///////// ENVIRONMENT OBJECT
  sceneAdvancedTexture:AdvancedDynamicTexture;
  // sceneAdvancedTexture:AdvancedDynamicTexture;

  constructor (containerEL:any) {
    super(containerEL);
    // Keep that variable def
    this.container = containerEL;
    let browser = this.getBrowser();
    let canvasposition = (browser == 'Safari')? '-webkit-sticky' : 'sticky';
    if (this.container == document.body) canvasposition = 'fixed';
    setStyle(this.canvas, {	position: canvasposition });
    // if (this.container == document.body) setStyle(this.container, {	'overflow-y': 'auto' });
    // Problem with scroll and touch action with android, discussion here:
    // https://forum.babylonjs.com/t/scroll-issues-with-touch-action/2135
    // 'touch-action': 'none' Keep it to avoid refresh on android phones

    this.buildScene();
    this.guicamera = new FreeCamera("gui_camera", new Vector3(0, 5, -10), this.scene);
    this.guicamera.setTarget(Vector3.Zero());
    this.guicamera.layerMask = 0x10000000;
    this.guicamera.minZ = 0;

    this.vrcamera = new VRDeviceOrientationFreeCamera("vr_camera", new Vector3(0, 0, 0), this.scene);
    this.guivrcamera = new VRDeviceOrientationFreeCamera("guivr_camera", new Vector3(0, 5, -10), this.scene);
    this.guivrcamera.setTarget(Vector3.Zero());
    this.guivrcamera.layerMask = 0x10000000;

    this.scene.shadowsEnabled = true;
    this.sceneAdvancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("scene", true, this.scene);
    this.sceneAdvancedTexture.layer.layerMask = 0x10000000;

    // Code to avoid mouse pointer showing when mesh is hovered but not visible
    this.scene.pointerMovePredicate = (mesh) => {
      return mesh.isPickable && mesh.isVisible && mesh.isReady() && mesh.isEnabled();
    }

    // NOTE Code to test who is under mouse
    // window.addEventListener("click", () => {
    //    // We try to pick an object
    //    var pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);
    //    console.log(pickResult.pickedMesh)
    // });

    // NOTE Code to launch BabylonJS Spector
    // scene.debugLayer.show({
    //     popup:false,
    //     initialTab : 2,
    //     // parentElement:document.getElementById('#mydiv'),
    //     newColors: {
    //         backgroundColor: '#eee',
    //         backgroundColorLighter: '#fff',
    //         backgroundColorLighter2: '#fff',
    //         backgroundColorLighter3: '#fff',
    //         color: '#333',
    //         colorTop:'red',
    //         colorBottom:'blue'
    //     }
    // });

    this.engine.resize();
    this.setFrustrum();
  }

  launchRender () {
    // Force stop before run to avoid environment.checkFirstSceneRendering on focus back on window
    this.engine.stopRenderLoop();
    this.engine.runRenderLoop( () => {
      this.animationManager.runAnimations(this.engine.getFps());
      this.scene.render();
    });
  }

  getBrowser () {
    var nav = navigator.userAgent;
    if ((nav.indexOf("Opera") || nav.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (nav.indexOf("Edge") != -1) {
      return 'Edge';
    } else if (nav.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if (nav.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (nav.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else if ((nav.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
      return 'IE';
    } else {
      return 'unknown';
    }
  }

  frustumPlanes:any;
  setFrustrum () {
    this.frustumPlanes = Frustum.GetPlanes(this.scene.getTransformMatrix());
  }

}
