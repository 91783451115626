
import { _system, _responsive } from '../service/editintale';
import { colorGizmoX, colorGizmoY, colorGizmoZ } from '../service/colors';

import { Scene } from '@babylonjs/core/scene'
import { Vector3, Color3, Viewport } from '@babylonjs/core/Maths/math'
import { Mesh } from '@babylonjs/core/Meshes/Mesh'
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera'
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';

export class gizmoAxisClass {

  scene:Scene;
  camera:ArcRotateCamera;
  cameraToFollow:ArcRotateCamera;
  xColor:Color3;
  yColor:Color3;
  zColor:Color3;

  constructor (cameraToFollow:ArcRotateCamera) {
    this.cameraToFollow = cameraToFollow;
    this.xColor = Color3.FromHexString(colorGizmoX);
    this.yColor = Color3.FromHexString(colorGizmoY);
    this.zColor = Color3.FromHexString(colorGizmoZ);

    this.scene = new Scene(_system.engine);
	  this.scene.autoClear = false;
    this.camera = new ArcRotateCamera("gizmoCamera",  5 * Math.PI / 8, 5 * Math.PI / 8, 30, new Vector3(0, 2, 0), this.scene);

    this.setCube();
    this.setAxis(3.5);

    _responsive.addListener(() => {
      let w = _system.engine.getRenderWidth();
      let gizmoWidth = w * 0.08 / 500;
      this.camera.viewport = new Viewport(0, 0, 0.08, gizmoWidth);
    });
  }

  setCube () {
    var box = MeshBuilder.CreateBox("gizmoBox", {size:1.5}, this.scene);
    let material = new StandardMaterial("", this.scene);
    let averageColor = this.zColor.add(this.yColor.add(this.xColor)).multiply(new Color3(1/3, 1/3, 1/3));
    material.emissiveColor = averageColor;
    box.material = material;
  }

  arrow = 0.1
	setAxis (size:number) {
  	var axisX = Mesh.CreateLines("gizmoAxisX", [
  		Vector3.Zero(), new Vector3(size, 0, 0), new Vector3(size * (1 - this.arrow), this.arrow * size, 0),
  		new Vector3(size, 0, 0), new Vector3(size * (1 - this.arrow), -this.arrow * size, 0)
    ], this.scene);
  	axisX.color = this.xColor;
    var axisY = Mesh.CreateLines("gizmoAxisY", [
        Vector3.Zero(), new Vector3(0, size, 0), new Vector3( -this.arrow * size, size * (1 - this.arrow), 0),
        new Vector3(0, size, 0), new Vector3( this.arrow * size, size * (1 - this.arrow), 0)
      ], this.scene);
    axisY.color = this.yColor;
    var axisZ = Mesh.CreateLines("gizmoAxisZ", [
        Vector3.Zero(), new Vector3(0, 0, size), new Vector3( 0 , -this.arrow * size, size * (1 - this.arrow)),
        new Vector3(0, 0, size), new Vector3( 0, this.arrow * size, size * (1 - this.arrow))
      ], this.scene);
    axisZ.color = this.zColor;
	}

  show () {
    _system.scene.afterRender = () => {
      this.scene.render();
      this.camera.alpha = this.cameraToFollow.alpha;
      this.camera.beta = this.cameraToFollow.beta;
      this.camera.radius = 13;
    };
  }

  hide () {
    _system.scene.afterRender = () => {};
  }
}
