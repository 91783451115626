
import { system } from '../tools/system';
import { contentMove } from './shared/contentmove';
import { projectInterface } from '../service/projectInterface';
import { contentInterface } from './shared/content';
import { shape } from './patterns/shape';
import { animation } from '../tools/animation';
import { renameEvent, text, textStyle } from './patterns/text';
import { pipelineClass } from '../scenery/pipeline';

import { Rectangle } from '@babylonjs/gui/2D/controls/rectangle';
import { Control } from '@babylonjs/gui/2D/controls/control';
import { Line } from '@babylonjs/gui/2D/controls/line';
import { TextBlock } from '@babylonjs/gui/2D/controls/textBlock';
import { GlowLayer } from '@babylonjs/core/Layers/glowLayer';

/*
  +------------------------------------------------------------------------+
  | HOTSPOT CONTENT                                                       |
  +------------------------------------------------------------------------+
*/

export interface hotspotStyle {
  color:Array<number>;
  textColor:Array<number>;
  lineWidth:number;
  lineDash:number;
  visibilityStart:number;
  visibilityEnd:number;
  text:textStyle,
}

projectInterface.hotspot = {
  color:'c',
  textColor:'tc',
  lineWidth:'lw',
  lineDash:'ld',
  visibilityStart:'vs',
  visibilityEnd:'ve',
  text:{
    dim:'tx',
    next:projectInterface.text
  },
}

export class hotspotcontent extends contentMove {

  _system:system;

  type = 'hotspot';

  color:Array<number>;
  textColor:Array<number>;
  lineWidth:number = 2;
  lineDash:number;

  text:text;
  pattern:shape;
  shadow = false;
  anim:animation;
  visibilityStart = 0;
  visibilityEnd = 1;
  opacity = 1;

  constructor (system:system, content:contentInterface, hsStyle:hotspotStyle, pipeline:pipelineClass) {
    super(system, content, 'sphere');
    this.anim = new animation(this._system, 20);

    this.show();
    this.setLabel();
    this.setLabelAnim();
    this.setEvents();

    this.setTypeParameter(hsStyle);
    this.launchPermanentAnim();

    this.text = new text(this._system, hsStyle.text, pipeline);
    this.setLabelDesign(hsStyle.text);
  }

  showText () {
    this.text.fadeIn(this.pattern.mesh.position);
    this.over = false;
  }

  hideText () {
    this.text.endHide(true);
  }

  fontsize = 25;
  label:Rectangle;
  line:Line;
  textBlock:TextBlock;
  plus:TextBlock;
  subline:Rectangle;
  setLabel () {
    this.label = new Rectangle();
    this.label.cornerRadius = 4;
    this.label.thickness = 0;
    this.label.hoverCursor = 'pointer';
    this.label.isPointerBlocker = true;
    this.label.adaptWidthToChildren = true;
    this.label.shadowBlur = 5;
    this.label.shadowColor = '#000';

    this.textBlock = new TextBlock();
    this.textBlock.resizeToFit = true;
    this.textBlock.paddingRight = '5px';
    this.textBlock.paddingLeft = '5px';
    this.textBlock.horizontalAlignment	= Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.label.addControl(this.textBlock);

    this.plus = new TextBlock();
    this.plus.resizeToFit = false;
    this.plus.width = '20px';
    this.plus.height = '20px';
    this.plus.horizontalAlignment	= Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.plus.left = '-5px';
    this.plus.text = '+';
    this.label.addControl(this.plus);

    this.subline = new Rectangle();
    this.subline.height = '0px';
    this.subline.top = '47%';
    this.subline.left = '49%';
    this.subline.width = '0px';
    this.label.addControl(this.subline);

    this.line = new Line();
    this.line.alpha = 0;
    this.line.connectedControl = this.subline;
  }

  labelpop:Rectangle;
  circle:Rectangle;
  setLabelAnim () {
    this.circle = new Rectangle();
    this.circle.cornerRadius = 10;
    this.circle.width = '5px';
    this.circle.height = '5px';
    this.circle.thickness = 0;

    this.labelpop = new Rectangle();
    this.labelpop.cornerRadius = 4;
    this.labelpop.thickness = 0;
    this.labelpop.zIndex = -1;
  }

  launchPermanentAnim () {
    this.anim.loop(10000000, 100, (count, perc) => {
      let percpow = Math.pow(perc, 1/5);
      this.labelpop.scaleX = 1 + percpow * 0.1;
      this.labelpop.scaleY = 1 + percpow * 0.4;
      this.labelpop.alpha = 1 - percpow;
    }, () => {}, () => {
      this.labelpop.alpha = 0;
    });
    // Keep show after launch anim as there is hide is function stop
    this.labelpop.alpha = 1;
  }

  startglow = 0.5;
  changeglow = 2;
  over = false;
  setEvents () {
    this.label[renameEvent["click"]].add(() => {
      if (this.listening) this.showText();
    });
  }

  listening = true;
  listenEvent (bool:boolean) {
    this.listening = bool;
  }

  hoveredGlowLayer:GlowLayer;
  setHoveredGlowLayer (hoveredGlowLayer:GlowLayer) {
    this.hoveredGlowLayer = hoveredGlowLayer;
  }

  setTypeParameter (hsStyle:hotspotStyle) {
    if (hsStyle.color !== undefined) this.setColor(hsStyle.color);
    if (hsStyle.textColor !== undefined) this.setTextColor(hsStyle.textColor);
    if (hsStyle.lineWidth !== undefined) this.setLineWidth(hsStyle.lineWidth);
    if (hsStyle.lineDash !== undefined) this.setLineDash(hsStyle.lineDash);
    if (hsStyle.visibilityStart !== undefined) this.visibilityStart = hsStyle.visibilityStart;
    if (hsStyle.visibilityEnd !== undefined) this.visibilityEnd = hsStyle.visibilityEnd;
  }

  setColor (color:Array<number>) {
    this.color = color;
    if (color == undefined) color = [255, 255, 255, 1];
    let rgbbackop = 'rgba('+color[0]+', '+color[1]+', '+color[2]+', '+color[3]+')';
    this.labelpop.background = rgbbackop;
    this.circle.background = rgbbackop;
    this.label.background = rgbbackop;
    this.line.color = rgbbackop;
    // Used in editor
    this.pattern.setTextureColor('emissive', color);
  }

  titlewidth = 100;
  setLabelDesign (textStyle:textStyle) {
    this.setLabelFont(textStyle.fontFamily);
    // When created, there is not a title yet
    if (textStyle.title) this.setLabelText(textStyle.title);
    if (!this.textColor) this.setTextColor(textStyle.textColor);
  }

  setLabelText (text:string) {
    // Add space for the "+"
    this.textBlock.text = text + '       ';
    this.labelpop.widthInPixels = this.label.widthInPixels;
  }

  setTextColor (color:Array<number>) {
    this.textColor = color;
    if (color == undefined) color = [0, 0, 0, 0];
    let rgbtext = 'rgba('+color[0]+', '+color[1]+', '+color[2]+', '+color[3]+')';
    this.textBlock.color = rgbtext;
    this.plus.color = rgbtext;
  }

  setLabelFont (fontFamily:string) {
    this.textBlock.fontFamily = fontFamily;
  }

  setLineWidth (lineWidth:number) {
    this.lineWidth = lineWidth;
    this.line.lineWidth = lineWidth;
  }

  setLineDash (lineDash:number) {
    this.lineDash = lineDash;
    this.line.dash = [lineDash/2, lineDash];
  }

  setStart (start:number) {
    this.visibilityStart = start;
    this.label.zIndex = Math.round(start * 100);
  }

  setEnd (end:number) {
    this.visibilityEnd = end;
  }

  showLabel (smallFormat:boolean) {
    if (smallFormat) {
      this.setLabelAtBottom(this.label);
      this.setLabelAtBottom(this.labelpop);
      this._system.sceneAdvancedTexture.removeControl(this.line);
      this._system.sceneAdvancedTexture.removeControl(this.circle);
      this.fontsize = 20;
    } else {
      this.setLabelBesideMesh(this.label);
      this.setLabelBesideMesh(this.labelpop);
      this._system.sceneAdvancedTexture.addControl(this.line);
      this.line.linkWithMesh(this.pattern.mesh);
      this._system.sceneAdvancedTexture.addControl(this.circle);
      this.circle.linkWithMesh(this.pattern.mesh);
      this.fontsize = 25;
    }
    this.label.height = (this.fontsize + 5) + 'px';
    this.labelpop.height = (this.fontsize + 5) + 'px';
    this.labelpop.widthInPixels = this.label.widthInPixels;
    this.textBlock.fontSize = this.fontsize;
    this.plus.fontSize = this.fontsize + 10;
  }

  setLabelBesideMesh (label:Rectangle) {
    label.linkOffsetY = -80;
    label.linkOffsetX = -150;
    label.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    label.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    this._system.sceneAdvancedTexture.addControl(label);
    label.linkWithMesh(this.pattern.mesh);
  }

  setLabelAtBottom (label:Rectangle) {
    label.linkWithMesh(null);
    label.left = 10 + 'px';
    label.top = -10 + 'px';
    label.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    label.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    this._system.sceneAdvancedTexture.addControl(label);
  }

  hideLabel () {
    this._system.sceneAdvancedTexture.removeControl(this.line);
    this._system.sceneAdvancedTexture.removeControl(this.label);
    this._system.sceneAdvancedTexture.removeControl(this.labelpop);
    this._system.sceneAdvancedTexture.removeControl(this.circle);
  }

  present = false;
  fadeIn () {
    if (this.present) return;
    this.present = true;
    this.line.alpha = 0;
    this.label.alpha = 0;
    this.anim.simple(20, (count, perc) => {
      if (perc < 0.5) {
        this.line.alpha = perc * 2;
        this.line.lineWidth = this.lineWidth * perc * 2;
      } else {
        this.label.alpha = (perc - 0.5) * 2;
      }
    }, () => {
      if (this.present) {
        this.line.alpha = 1;
        this.line.lineWidth = this.lineWidth;
        this.label.alpha = 1;
        this.launchPermanentAnim();
      }
    });
  }

  fadeOut () {
    if (!this.present) return;
    this.present = false;
    this.anim.stop();
    this.anim.simple(20, (count, perc) => {
      perc =  1 - perc;
      if (perc > 0.5) {
        this.label.alpha = (perc - 0.5);
      } else {
        this.line.alpha = perc * 2;
        this.line.lineWidth = perc * 4;
      }
    }, () => {
      this.hideLabel();
    });
  }
}
