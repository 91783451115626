
import { modal } from './modal';
import { globals } from '../service/globals';

import { el, mount } from 'redom';

/*
  +------------------------------------------------------------------------+
  | PUBLISH                                                             |
  +------------------------------------------------------------------------+
*/

class tutorialClass extends modal {

  constructor () {
    super({width:500, height:350}, 'help', 'Cruise', 'Go for a cruise and learn how to use Naker');

    let container = el('div',
      [
        el('div.siimple-btn.siimple-btn--primary.siimple-btn--fluid.tutorial-button.publish-button-common', {onclick:() => {this.checkHowtoStartTutorial('42263')}}, 'Step 0 : Introduction ️🙋'),
        el('div.siimple-btn.siimple-btn--primary.siimple-btn--fluid.tutorial-button.publish-button-common', {onclick:() => {this.checkHowtoStartTutorial('32621')}}, 'Step 1 : Environment 🏞️'),
        el('div.siimple-btn.siimple-btn--primary.siimple-btn--fluid.tutorial-button.publish-button-common', {onclick:() => {this.checkHowtoStartTutorial('33090')}}, 'Step 2 : Content 🌈'),
        el('div.siimple-btn.siimple-btn--primary.siimple-btn--fluid.tutorial-button.publish-button-common', {onclick:() => {this.checkHowtoStartTutorial('33157')}}, 'Step 3 : Animation 🦄️'),
        el('div.siimple-btn.siimple-btn--primary.siimple-btn--fluid.tutorial-button.publish-button-common', {onclick:() => {this.checkHowtoStartTutorial('33227')}}, 'Step 4 : Journey ️⛵'),
        el('div.siimple-btn.siimple-btn--primary.siimple-btn--fluid.tutorial-button.publish-button-common', {onclick:() => {this.checkHowtoStartTutorial('33264')}}, 'Step 5 : Hotspot ️📝'),
        el('div.siimple-btn.siimple-btn--primary.siimple-btn--fluid.tutorial-button.publish-button-common', {onclick:() => {this.checkHowtoStartTutorial('33898')}}, 'Step 6 : Publish ️🕊️'),
      ]
    );

    mount(this.control, container);
  }

  checkHowtoStartTutorial (tutorialId:string) {
    if (globals.mode != 'cruise') window.open('https://cruise.naker.io/?product_tour_id='+tutorialId);
    else window.Intercom('startTour', tutorialId);
    this.hide();
  }

  show () {
    this.showModal();
  }

  hide () {
    this.hideModal();
  }

}

export let tutorial = new tutorialClass();
