
import { materialInterface, materialcontent } from '../../viewer/contents/shared/contentmaterial';
import { text } from '../../viewer/contents/patterns/text';
import { hotspotcontent } from '../../viewer/contents/hotspot';
import { shapecontent } from '../../viewer/contents/shape';
import { particlecontent } from '../../viewer/contents/particle';
import { imagecontent } from '../../viewer/contents/image';
import { videocontent } from '../../viewer/contents/video';
import { modelcontent } from '../../viewer/contents/model';
import { lightcontent } from '../../viewer/contents/light';
import { heightmapcontent } from '../../viewer/contents/heightmap';
import { groupcontent } from '../../viewer/contents/group';
import { _system, contentsManager, _responsive } from '../service/editintale';
import { asset } from '../../viewer/tools/loader';
import { floatingManager } from '../inputs/layer';
import { ui_numberinput, ui_slider, ui_radioicon, ui_checkbox, ui_button, ui_textinput, ui_paragraphinput } from '../inputs/input';
import { ui_assetbutton, ui_colorbutton, ui_imageassetbutton } from '../inputs/pickers';
import { inventoryClass } from '../inputs/inventory';
import { pipelineSettings } from '../settings/pipelineSettings';
import { util } from '../../viewer/tools/util';
import { component, componentChannel, componentList } from './component';
import { threedJsonHelper } from '../../viewer/service/threedjsonhelper';
import { projectInterface } from '../../viewer/service/projectInterface';
import { layerRightList } from '../inputs/layer';
import { zoomManager } from '../dragzone/zoomManager';

import { setStyle, setAttr, mount, unmount } from 'redom';
import Suggestions from 'suggestions';
import axios from 'axios';
import find from 'lodash/find';
import map from 'lodash/map';
import toastr from 'toastr'

/*
  +------------------------------------------------------------------------+
  | TEXT SETTINGS                                                          |
  +------------------------------------------------------------------------+
*/

componentChannel.subscribe("rename", (component:component, envelope) => {
  if (component.type == 'hotspot') textSettings.titleinput.setValue(component.content.tag);
});

export class textSettingsClass extends floatingManager {

  content:hotspotcontent;
  text:text;

  textinput:ui_paragraphinput;
  titleinput:ui_textinput;
  textColor:ui_colorbutton;
  fontFamily:ui_textinput;

  constructor () {
    super('Hotspot story');
    this.setShowButton();
    this.setFontInput();
    this.setStyleInput();
  }

  showCheck = false;
  showButton:ui_button;
  setShowButton () {
    this.showButton = this.addButton({ui:'text', text:'Show'}, () => {
      if (!this.showCheck) {
        this.showInputs();
      } else {
        this.hideInputs();
      }
    });
  }

  fontsuggestion:any;
  setFontInput () {
    this.fontFamily = this.addTextInput('Font family', "Search", (value) => {
      this.content.text.setFontFamily(value);
      this.content.setLabelFont(value);
    });
    this.fontFamily.on('focus', () => {
      this.fontsuggestion.update(this.fontNames);
    });

    this.fontsuggestion = new Suggestions(this.fontFamily.el, [], {
      minLength:0,
      limit: 20,
    });
    this.getGoogleFonts();
  }

  fontNames:Array<string> = [];
  getGoogleFonts () {
    axios.get('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyDwXUFOSFMF44soDpKz2WbHKelntWtl9yU')
    .then((response) => {
      let res = response.data;
      let fontlist = res.items;
      this.fontNames = map(fontlist, 'family');
    })
    .catch((error) => {
      console.log(error);
    });
  }

  setStyleInput () {
    this.textColor = this.addColorInput('Text color', undefined, (rgba) => {
      this.text.setTextColor(rgba);
    });

    this.titleinput = this.addTextInput('', 'Your title', (value) => {
      this.text.setTitle(value);
      let hotspotComponent:component = find(componentList, (c) => {return c.content.id == this.content.id});
      hotspotComponent.rename(value);
      this.content.setLabelText(value);
    });
    setStyle(this.titleinput.label, {display:'none'});
    setStyle(this.titleinput.el, {width:'230px', 'max-width':'230px', left:'15px'});
    this.titleinput.on('input', (value) => {
      this.text.setTitle(value);
    });
    setAttr(this.titleinput.el, {maxlength:30});

    this.textinput = this.addParagraphInput('Text', 'What is the subject of this hotspot?', (value) => {
      this.text.setText(value);
    });
    setStyle(this.textinput.label, {display:'none'});
    this.textinput.on('input', (value) => {
      this.text.setText(value);
    });
  }

  setContent (content:hotspotcontent) {
    this.content = content;
    this.text = content.text;
    this.textColor.setValue(this.text.textColor);
    this.fontFamily.setValue(this.text.fontFamily);
    this.textinput.setValue(this.text.text);
    this.titleinput.setValue(this.text.title);
    this.hideInputs();
  }

  showInputs () {
    this.showCheck = true;
    this.content.text.show(this.content.pattern.mesh.position);
    this.content.text.checkResponsive(_responsive.screenratio, _responsive.realwidth, _responsive.realheight, _responsive.scale);
    this.showButton.setText('Hide');
    componentChannel.publish('showtext');
    setStyle(this.textColor.container.el, {display:'block'});
    setStyle(this.fontFamily.container.el, {display:'block'});
    setStyle(this.textinput.container.el, {display:'block'});
    setStyle(this.titleinput.container.el, {display:'block'});
    pipelineSettings.setEntity(this.text);
    mount(layerRightList, pipelineSettings.control.el);
    _system.scene.activeCameras = [zoomManager.cameraedit, _system.guicamera];
    _system.sceneAdvancedTexture.layer.layerMask = 0x10000000;
  }

  hideInputs () {
    this.showCheck = false;
    // Text hidden in preview call this function when it shouldn't
    if (!this.content) return;
    this.content.text.hide();
    this.showButton.setText('Show');
    componentChannel.publish('hidetext');
    setStyle(this.textColor.container.el, {display:'none'});
    setStyle(this.fontFamily.container.el, {display:'none'});
    setStyle(this.textinput.container.el, {display:'none'});
    setStyle(this.titleinput.container.el, {display:'none'});
    unmount(layerRightList, pipelineSettings.control.el);
    _system.scene.activeCameras = [];
    _system.scene.activeCamera = zoomManager.cameraedit;
    _system.sceneAdvancedTexture.layer.layerMask = 0x20000000;
  }
}

export let textSettings = new textSettingsClass();

/*
  +------------------------------------------------------------------------+
  | PARTICLE SETTINGS                                                      |
  +------------------------------------------------------------------------+
*/

class hotspotSettingsClass extends floatingManager {

  content:hotspotcontent;
  color:ui_colorbutton;
  textColor:ui_colorbutton;
  lineWidth:ui_slider;
  lineDash:ui_slider;

  constructor () {
    super('Hotspot');
    this.color = this.addColorInput('Label color', undefined, (rgba) => {
      this.content.setColor(rgba);
    });

    this.textColor = this.addColorInput('Label text color', undefined, (rgba) => {
      this.content.setTextColor(rgba);
    });

    this.lineWidth = this.addSlider('lineWidth', {value:2, min:0, max:5, step:1}, (lineWidth) => {
      this.content.setLineWidth(lineWidth);
    });

    this.lineDash = this.addSlider('lineDash', {value:0, min:0, max:10, step:1}, (lineDash) => {
      this.content.setLineDash(lineDash);
    });
  }

  setContent (content:hotspotcontent) {
    this.content = content;
    this.color.setValue(content.color);
    this.textColor.setValue(content.textColor);
    this.lineWidth.setValue(content.lineWidth);
    this.lineDash.setValue(content.lineDash);
  }
}

export let hotspotSettings = new hotspotSettingsClass();

/*
  +------------------------------------------------------------------------+
  | PARTICLE SETTINGS                                                      |
  +------------------------------------------------------------------------+
*/

class particleSettingsClass extends floatingManager {

  content:particlecontent;
  color1:ui_colorbutton;
  color2:ui_colorbutton;
  particleTexture:ui_assetbutton;
  number:ui_numberinput;
  particleSize:ui_numberinput;
  life:ui_numberinput;
  power:ui_numberinput;

  constructor () {
    super('Particle');
    this.color1 = this.addColorInput('Color 1', undefined, (rgba) => {
      this.content.setParticleColor1(rgba);
    });
    this.color2 = this.addColorInput('Color 2', undefined, (rgba) => {
      this.content.setParticleColor2(rgba);
    });
    this.particleTexture = this.addAssetInput('Particle shape', {type:'particle', url:''}, (image) => {
      this.content.setParticleTexture(image);
    });
    this.number = this.addNumberInput('Number', {step:100, value:0, min:0, max:200, unit:''}, (value) => {
      if (value == 200) toastr.warning('In order to keep your scene performant on any device, we limit the particle number to 200');
      this.content.setParticleNumber(value);
    });
    this.particleSize = this.addNumberInput('Size', {step:1, value:1, min:0, unit:''}, (value) => {
      this.content.setParticleSize(value);
    });
    this.life = this.addNumberInput('Life', {step:1, value:1, min:0, unit:''}, (value) => {
      this.content.setParticleLife(value);
    });
    this.power = this.addNumberInput('Power', {step:1, value:1, min:0, unit:''}, (value) => {
      this.content.setParticlePower(value);
    });
  }

  setContent (content:particlecontent) {
    this.content = content;
    this.color1.setValue(content.color1);
    this.color2.setValue(content.color2);
    this.particleTexture.setValue(content.particleTexture);
    this.number.setValue(content.number);
    this.particleSize.setValue(content.particleSize);
    this.life.setValue(content.life);
    this.power.setValue(content.power);
  }
}

export let particleSettings = new particleSettingsClass();

/*
  +------------------------------------------------------------------------+
  | MATERIAL SETTINGS                                                          |
  +------------------------------------------------------------------------+
*/

class materialSettingsClass extends floatingManager {

  content:materialcontent;
  material:ui_radioicon;
  texturesize:ui_numberinput;
  alpha:ui_slider;
  metallic:ui_slider;
  roughness:ui_slider;
  reflection:ui_checkbox;
  refraction:ui_checkbox;
  indexofrefraction:ui_slider;
  glow:ui_checkbox;
  // COLORS
  albedocolor:ui_colorbutton;
  emissivecolor:ui_colorbutton;
  ambientcolor:ui_colorbutton;
  reflectivitycolor:ui_colorbutton;
  // specularcolor:ui_colorbutton;
  watercolor:ui_colorbutton;
  // TEXTURES
  albedotexture:ui_assetbutton;
  ambienttexture:ui_assetbutton;
  // speculartexture:ui_assetbutton;
  emissivetexture:ui_assetbutton;
  reflectivitytexture:ui_assetbutton;
  opacitytexture:ui_assetbutton;
  bumptexture:ui_assetbutton;

  windforce:ui_numberinput;
  waveheight:ui_numberinput;
  bumpheight:ui_slider;
  // winddirection:ui_numberinput;
  wavelength:ui_numberinput;

  ColorAssetInputs:Array<asset["type"]> = ['albedo', 'emissive', 'ambient', 'reflectivity'];
  SliderInputs = ['roughness', 'metallic', 'alpha'];
  AssetInputs:Array<asset["type"]> = ['bump', 'opacity'];

  currentMaterial:materialInterface;
  constructor () {
    super('Mesh Material');
    this.material = this.addRadioIcon('Material type', {value:'standard', iconperline:2, list:['standard', 'water']}, (value) => {
      if (this.currentMaterial.type == value) return;
      this.currentMaterial.type = value;
      this.content.setType(value);
      this.content.setTypeDefaultValues(value);
      this.checkMaterialType(value);
      this.setValues(this.content.material);
    });

    this.reflection = this.addCheckBox('Reflection', true, (value) => {
      this.content.setReflection(value);
    });

    this.refraction = this.addCheckBox('Refraction', false, (value) => {
      this.content.setRefraction(value);
    });

    this.indexofrefraction = this.addSlider('Index of refraction', {value:1, min:1, max:2, step:0.1}, (value) => {
      this.content.setIndexOfRefraction(value);
    });

    this.glow = this.addCheckBox('Glow', false, (value) => {
      this.content.setGlow(value);
    });

    // COLORS AND TEXTURES
    for (let i = 0; i < this.ColorAssetInputs.length; i++) {
      let colorasset = this.ColorAssetInputs[i];
      let duoinput = this.addColorAndAssetInput(this.ColorAssetInputs[i], undefined, {type:colorasset, url:''}, (type, value) => {
        if (type == 'color') {
          this.currentMaterial[colorasset+'color'] = value;
          this.content.setTextureColor(colorasset, value);
        } else {
          this.currentMaterial[colorasset] = value;
          this.content.setTexture(colorasset, value);
        }
      });
      this[colorasset+'color'] = duoinput.color;
      this[colorasset+'texture'] = duoinput.asset;
    }

    // TEXTURES
    for (let i = 0; i < this.AssetInputs.length; i++) {
      this[this.AssetInputs[i]+'texture'] = this.addAssetInput(this.AssetInputs[i], {type:this.AssetInputs[i], url:''}, (image) => {
        this.currentMaterial[this.AssetInputs[i]] = image;
        this.content.setTexture(this.AssetInputs[i], image);
      });
    }

    this.watercolor = this.addColorInput('water color', undefined, (rgba) => {
      if (rgba) {
        // If opacity = 1, there is no more water effect
        rgba[3] = rgba[3]/2;
        this.currentMaterial.colorblendfactor = rgba[3];
        this.content.setMaterialProperties({colorblendfactor:rgba[3]});
      }
      this.currentMaterial.watercolor = rgba;
      this.content.setTextureColor('water', rgba);
    });

    // WATER
    this.windforce = this.addNumberInput('Wind force', {step:1, value:15, unit:'', max:100, min:0}, (value) => {
      this.currentMaterial.windforce = value;
      this.content.setMaterialProperties({windforce:value});
    });

    this.waveheight = this.addNumberInput('wave height', {step:0.01, value:0.001, unit:'', max:1, min:0}, (value) => {
      this.currentMaterial.waveheight = value;
      this.content.setMaterialProperties({waveheight:value});
    });

    this.wavelength = this.addNumberInput('wave length', {step:0.1, value:0.5, unit:'', max:1, min:0}, (value) => {
      this.currentMaterial.wavelength = value;
      this.content.setMaterialProperties({wavelength:value});
    });

    this.bumpheight = this.addSlider('bump height', {value:0.5, max:10, min:0, step:0.1}, (value) => {
      this.currentMaterial.bumpheight = value;
      this.content.setMaterialProperties({bumpheight:value});
    });

    // SLIDERS
    for (let i = 0; i < this.SliderInputs.length; i++) {
      this[this.SliderInputs[i]] = this.addSlider(this.SliderInputs[i], {value:1, min:0, max:1, step:0.1}, (value) => {
        this.currentMaterial[this.SliderInputs[i]] = value;
        let propchange = {};
        propchange[this.SliderInputs[i]] = value;
        this.content.setMaterialProperties(propchange);
      });
    }

    this.texturesize = this.addNumberInput('Texture size', {step:1, value:1, unit:'', min:0.1}, (value) => {
      this.currentMaterial.texturesize = value;
      this.content.setTextureSize(value);
    });

    // winddirection:ui_numberinput;

    this.createMaterialInventory();
  }

  materialInventory:inventoryClass;
  materialInventoryList:any = {};
  createMaterialInventory () {
    this.materialInventory = new inventoryClass(this.control, 'Material');
    this.materialInventory.onClick = (name) => {
      let material = this.getMaterialFromName(name);
      if (material == null) return;
      this.currentMaterial = util.copyObject(material);
      this.setMaterial(this.currentMaterial);
      this.setValues(this.currentMaterial);
    };
    this.materialInventory.onAdd = (name) => {
      let saveMaterial:materialInterface = threedJsonHelper.recursiveObjectToObject(projectInterface.material, this.currentMaterial);
      saveMaterial.name = name;
      let key = Math.random().toString(36).substring(2, 15);
      this.materialInventoryList[key] = saveMaterial;
    };
    this.materialInventory.onDelete = (name) => {
      for (let key in this.materialInventoryList) {
        if (this.materialInventoryList[key].name == name) delete this.materialInventoryList[key];
      }
    };
  }

  getMaterialFromName (name:string) {
    for (let key in this.materialInventoryList) {
      if (this.materialInventoryList[key].name == name) return this.materialInventoryList[key];
    }
    return null;
  }

  addMaterialInInventory (key:string, material:materialInterface) {
    this.materialInventoryList[key] = material;
    this.materialInventory.addInInventory(material.name);
  }

  setContent (content:materialcontent) {
    this.content = content;
    this.currentMaterial = content.material;
    this.currentMaterial.name = '';
    this.setValues(content.material);
  }

  setValues (material:materialInterface) {
    this.material.setValue(material.type);
    this.texturesize.setValue(material.texturesize);
    this.reflection.setValue(material.reflection);
    this.refraction.setValue(material.refraction);
    this.indexofrefraction.setValue(material.indexofrefraction);
    this.glow.setValue(material.glow);

    // COLORS AND TEXTURES
    for (let i = 0; i < this.ColorAssetInputs.length; i++) {
      this[this.ColorAssetInputs[i]+'color'].setValue(material[this.ColorAssetInputs[i]+'color']);
      this[this.ColorAssetInputs[i]+'texture'].setValue(material[this.ColorAssetInputs[i]+'texture']);
    }
    // TEXTURES
    for (let i = 0; i < this.AssetInputs.length; i++) {
      this[this.AssetInputs[i]+'texture'].setValue(material[this.AssetInputs[i]+'texture']);
    }

    // SLIDER
    for (let i = 0; i < this.SliderInputs.length; i++) {
      this[this.SliderInputs[i]].setValue(material[this.SliderInputs[i]]);
    }

    this.windforce.setValue(material.windforce);
    this.waveheight.setValue(material.waveheight);
    this.bumpheight.setValue(material.bumpheight);
    this.wavelength.setValue(material.wavelength);
    this.watercolor.setValue(material.watercolor);
    this.checkMaterialType(material.type);
  }

  setMaterial (material:materialInterface) {
    this.content.setType(material.type);
    this.content.setReflection(material.reflection);
    this.content.setRefraction(material.refraction);
    this.content.setIndexOfRefraction(material.indexofrefraction);
    this.content.setGlow(material.glow);

    // COLORS AND TEXTURES
    for (let i = 0; i < this.ColorAssetInputs.length; i++) {
      this.content.setTextureColor(this.ColorAssetInputs[i], material[this.ColorAssetInputs[i]+'color']);
      this.content.setTexture(this.ColorAssetInputs[i], material[this.ColorAssetInputs[i]]);
    }

    // TEXTURES
    for (let i = 0; i < this.AssetInputs.length; i++) {
      this.content.setTexture(this.AssetInputs[i], material[this.AssetInputs[i]]);
    }

    this.content.setMaterialProperties(material);
    this.content.setTextureSize(material.texturesize);
    this.checkMaterialType(material.type);
  }

  commonInputs = ['texturesize', 'bumpheight'];
  materialTypeInputs = {
    standard:['alpha', 'refraction', 'albedocolor', 'emissivecolor', 'reflectivitycolor', 'ambientcolor', 'opacitytexture', 'bumptexture', 'metallic', 'roughness'],
    water:['watercolor', 'bumptexture', 'windforce', 'waveheight', 'wavelength'],
  };
  checkMaterialType (type:string) {
    if (type == undefined) type = 'standard';
    // You can't material of a model, only add properties
    if (this.content.type == 'model') setStyle(this.material.container.el, {display:'none'});
    else setStyle(this.material.container.el, {display:'block'});

    for (let key in this.materialTypeInputs) {
      for (let i = 0; i < this.materialTypeInputs[key].length; i++) {
        setStyle(this[this.materialTypeInputs[key][i]].container.el, {display:'none'});
      }
    }
    for (let i = 0; i < this.materialTypeInputs[type].length; i++) {
      setStyle(this[this.materialTypeInputs[type][i]].container.el, {display:'block'});
    }
    // If material change, we have to put back the reflexion texture
    contentsManager.checkContentReflection(this.content, contentsManager.reflectionColor);
  }
}

export let materialSettings = new materialSettingsClass();


/*
  +------------------------------------------------------------------------+
  | MESH SETTINGS                                                         |
  +------------------------------------------------------------------------+
*/

export class meshSettingsClass extends floatingManager {

  content:shapecontent;

  flatInput:ui_checkbox;

  constructor () {
    super('Mesh');
    this.flatInput = this.addCheckBox('Flat Mesh', false, (value) => {
      this.content.setFlatMesh(value);
    });
  }

  setContent (content:shapecontent) {
    this.content = content;
    this.flatInput.setValue(content.flat);
    materialSettings.setContent(content);
  }

}

export let meshSettings = new meshSettingsClass();

/*
  +------------------------------------------------------------------------+
  | HEIGHTMAP SETTINGS                                                         |
  +------------------------------------------------------------------------+
*/

export class heightmapSettingsClass extends floatingManager {

  content:heightmapcontent;

  flatInput:ui_checkbox;
  heightmapInput:ui_assetbutton;
  bumpheightInput:ui_numberinput;
  heightqualityInput:ui_numberinput;

  constructor () {
    super('Heightmap');
    this.flatInput = this.addCheckBox('Flat Mesh', false, (value) => {
      this.content.setFlatMesh(value);
    });
    this.heightmapInput = this.addAssetInput('Height Map', {type:'heightmap', url:''}, (value) => {
      this.content.setHeightMap(value);
    });
    this.bumpheightInput = this.addNumberInput('Height Power', {step:5, min:0, max:100, value:0.1, unit:''}, (value) => {
      this.content.setBumpHeight(value);
    });
    this.heightqualityInput = this.addNumberInput('Height Quality', {step:50, value:10, unit:'', max:200, min:0}, (value) => {
      this.content.setHeightQuality(value);
    });
  }

  setContent (content:heightmapcontent) {
    this.content = content;
    this.flatInput.setValue(content.flat);
    this.heightmapInput.setValue(content.heightmapurl);
    this.bumpheightInput.setValue(content.bumpheight);
    this.heightqualityInput.setValue(content.heightquality);
    materialSettings.setContent(content);
  }

}

export let heightmapSettings = new heightmapSettingsClass();

/*
  +------------------------------------------------------------------------+
  | IMAGE SETTINGS                                                         |
  +------------------------------------------------------------------------+
*/

export class imageSettingsClass extends floatingManager {

  content:imagecontent;
  coloroverlay:ui_colorbutton;
  image:ui_imageassetbutton;

  constructor () {
    super('Image');
    this.image = this.addImageAssetInput('Image', {type:'image', url:undefined}, (image) => {
      this.content.loadImage(image);
      componentChannel.publish('move');
    });

    this.coloroverlay = this.addColorInput('Color overlay', undefined, (rgba) => {
      this.content.setColorOverlay(rgba);
      if (rgba) this.content.setOpacity(rgba[3]);
    });
  }

  setContent (content:imagecontent) {
    this.content = content;
    this.image.setValue(content.url);
    this.coloroverlay.setValue(content.coloroverlay);
  }

}

export let imageSettings = new imageSettingsClass();

/*
  +------------------------------------------------------------------------+
  | VIDEO SETTINGS                                                         |
  +------------------------------------------------------------------------+
*/

export class videoSettingsClass extends floatingManager {

  content:videocontent;
  loop:ui_checkbox;
  autoplay:ui_checkbox;
  coloroverlay:ui_colorbutton;
  video:ui_imageassetbutton;

  constructor () {
    super('Video');
    this.video = this.addImageAssetInput('Video', {type:'video', url:undefined}, (video) => {
      this.content.loadVideo(video);
      componentChannel.publish('move');
    });

    this.loop = this.addCheckBox('Video loop', true, (checked) => {
      this.content.setLoop(checked);
    });

    this.autoplay = this.addCheckBox('Auto play', true, (checked) => {
      this.content.setAuto(checked);
    });

    this.coloroverlay = this.addColorInput('Color overlay', undefined, (rgba) => {
      this.content.setColorOverlay(rgba);
      if (rgba) this.content.setOpacity(rgba[3]);
    });
  }

  setContent (content:videocontent) {
    this.content = content;
    this.loop.setValue(content.loop);
    this.autoplay.setValue(content.autoplay);
    this.coloroverlay.setValue(content.coloroverlay);
    this.video.setValue(content.url);
  }

}

export let videoSettings = new videoSettingsClass();

/*
  +------------------------------------------------------------------------+
  | MODEL SETTINGS                                                         |
  +------------------------------------------------------------------------+
*/

export class modelSettingsClass extends floatingManager {

  content:modelcontent;
  model:ui_imageassetbutton;
  reset:ui_button;

  constructor () {
    super('Model');
    this.model = this.addImageAssetInput('Model', {type:'model', url:undefined}, (model) => {
      this.content.loadModel(model);
      componentChannel.publish('move');
    });

    this.reset = this.addButton({ui:'text', text:'Reset model'}, () => {
      this.content.eraseMaterial();
      this.content.loadModel(this.content.url);
      materialSettings.setContent(this.content);
      // componentChannel.publish('move');
    });
  }

  setContent (content:modelcontent) {
    this.content = content;
    this.model.setValue(content.url);
    materialSettings.setContent(content);
  }
}

export let modelSettings = new modelSettingsClass();

/*
  +------------------------------------------------------------------------+
  | LIGHT SETTINGS                                                         |
  +------------------------------------------------------------------------+
*/

export class lightSettingsClass extends floatingManager {

  content:lightcontent;
  mode:ui_radioicon;
  color:ui_colorbutton;
  intensity:ui_numberinput;
  // NOTE Temporary delete shadow generator (bug on ios)
  castshadow:ui_checkbox;
  shadowdarkness:ui_slider;

  constructor () {
    super('Light');
    this.mode = this.addRadioIcon('Mode', {value:'point', iconperline:3, list:['point', 'directional', 'spot']}, (value:'point'|'directional'|'spot') => {
      this.content.setMode(value);
      this.updateHelper();
    });
    this.intensity = this.addNumberInput('Intensity', {step:0.1, value:1, unit:''}, (value:number) => {
      this.content.setIntensity(value);
      this.updateHelper();
    });
    this.color = this.addColorInput('Color', undefined, (rgba:Array<number>) => {
      this.content.setColor(rgba);
      this.updateHelper();
    });
    // NOTE Temporary delete shadow generator (bug on ios)
    this.castshadow = this.addCheckBox('Cast shadow', false, (value) => {
      this.content.setCastShadow(value);
    });
    this.shadowdarkness = this.addSlider('Shadow darkness', {value:0, min:0, max:1, step:0.1}, (value) => {
      this.content.setShadowDarkness(value);
    });
  }

  updateHelper () {
    let lightComponent:component = find(componentList, (c) => {return c.content.id == this.content.id});
    lightComponent.setHelper();
  }

  setContent (content:lightcontent) {
    this.mode.setValue(content.mode);
    this.content = content;
    this.color.setValue(content.color);
    this.intensity.setValue(content.intensity);
    // NOTE Temporary delete shadow generator (bug on ios)
    this.castshadow.setValue(content.castshadow);
    this.shadowdarkness.setValue(content.shadowdarkness);
  }

}

export let lightSettings = new lightSettingsClass();

/*
  +------------------------------------------------------------------------+
  | GROUP SETTINGS                                                         |
  +------------------------------------------------------------------------+
*/

export class groupSettingsClass extends floatingManager {

  content:groupcontent;
  ungroupbutton:ui_button;

  constructor () {
    super('Group');
    this.ungroupbutton = this.addButton({ui:'text', text:'Ungroup'}, () => {
      // Ungroup Function = Delete group Component (see in componentMenu)
      if (this.content.validated) {
        contentsManager.removeContent(this.content);
        this.content.removeContentsGroupKey();
      } else {
        contentsManager._addContent(this.content);
        this.content.addContentsGroupKey();
      }
      this.content.validated = !this.content.validated;
      this.groupeEvent(this.content.validated);
      if (this.content.validated) this.ungroupbutton.el.textContent = 'Ungroup';
      else this.ungroupbutton.el.textContent = 'Group selection';
    });
  }

  groupeEvent:Function;
  groupStatusChange (callback:Function) {
    this.groupeEvent = callback;
  }

  setContent (content:groupcontent) {
    this.content = content;
    if (content.validated) this.ungroupbutton.el.textContent = 'Ungroup';
    else this.ungroupbutton.el.textContent = 'Group selection';
  }

}

export let groupSettings = new groupSettingsClass();
